import styled from "styled-components";
import Icon from "../Icon";
import { Label } from "../Typography";
import { QuestionStatus } from "@/components/organisms/Quiz/QuizBody";

const ICON_SIZE = "20px";

interface StyledRadioButtonProps {
  checked: boolean;
}

interface RadioButtonProps {
  questionStatus?: QuestionStatus;
}

export const RadioButtonContainer = styled.div<Partial<RadioButtonProps>>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(12)};
  position: relative;
  background: ${({ theme, questionStatus }) => {
    switch (questionStatus) {
      case QuestionStatus.CORRECT:
        return "#B6E4C3";
      case QuestionStatus.INCORRECT:
        return "#FFC9C9";
      case QuestionStatus.UNANSWERED:
      default:
        return theme.colorScheme.white.surface.foreground;
    }
  }};
  border-radius: ${({ theme }) => theme.rem(8)};
  padding: ${({ theme }) => theme.rem(16)};
  width: 100%;
  cursor: pointer;
  border: 1px solid
    ${({ theme, questionStatus }) => {
      switch (questionStatus) {
        case QuestionStatus.CORRECT:
          return "#3DC66B";
        case QuestionStatus.INCORRECT:
          return "#F00";
        case QuestionStatus.UNANSWERED:
        default:
          return theme.colorScheme.white.surface.borderSecondary;
      }
    }};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(16, 32)}}
`)}
`;

export const HiddenRadioButton = styled.input.attrs({ type: "radio" })`
  // Nasconde il radio button ma lo mantiene accessibile
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledRadioButton = styled.div<{ checked: boolean }>`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  transition: all 150ms;

  ${HiddenRadioButton}:hover + & {
    box-shadow: 5px 5px 20px 3px
      ${({ theme }) => theme.colorScheme.white.button.primaryHoverBackground};
    box-shadow: 5px 5px 8px 8px rgba(255, 182, 131, 0.1);
  }

  ${HiddenRadioButton}:focus + & {
    box-shadow: 5px 5px 20px 3px
      ${({ theme }) => theme.colorScheme.white.button.primaryPressedBackground};
    box-shadow: 5px 5px 8px 8px rgba(255, 182, 131, 0.16);
  }

  ${HiddenRadioButton}:active + & {
    box-shadow: 5px 5px 20px 3px
      ${({ theme }) => theme.colorScheme.white.button.primaryPressedBackground};
    box-shadow: 5px 5px 8px 8px rgba(255, 182, 131, 0.4);
  }

  ${HiddenRadioButton}:disabled + & {
    background: ${({ theme }) =>
      theme.colorScheme.white.button.disabledBackground};
    border: 1px solid
      ${({ theme }) => theme.colorScheme.white.button.disabledBackground};
    cursor: not-allowed;
  }
`;

export const StyledCheckbox = styled.div<StyledRadioButtonProps>`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const StyledRadioButtonIcon = styled(Icon)`
  font-size: ${ICON_SIZE};
`;

export const RadioButtonLabel = styled(Label)`
  color: #4f4f4f;
  ${({ theme }) => theme.typo("paragraphLG")};
`;
