import { useContext, FC } from "react";
import { ColorBrand, StyledTag } from "./styles";
import { SectionThemeContext } from "@contexts/SectionTheme";
import { Paragraph } from "../Typography";
import { Link } from "react-router-dom";
import { QuestionStatus } from "@/components/organisms/Quiz/QuizBody";

interface TagProps {
  id?: string;
  label?: string;
  href?: string;
  variant?: string;
  inheritedColorScheme?: string;
  colorBrand?: ColorBrand;
  size?: "sm" | "md" | "lg",
  onClick?: () => void;
  status?: QuestionStatus;
}

const Tag: FC<TagProps> = ({
  label,
  href = "",
  variant = "square",
  inheritedColorScheme = "white",
  colorBrand,
  size,
  onClick,
  status,
  id,
  ...rest
}) => {
  const selectedColorScheme =
    useContext(SectionThemeContext)?.selectedColorScheme || "white";

  return (
    <StyledTag
      colorScheme={inheritedColorScheme || selectedColorScheme}
      colorBrand={colorBrand}
      variant={variant}
      size={size}
      onClick={onClick}
      status={status}
      id={id}
      {...rest}
    >
      {Boolean(href) ? (
        <Link to={href}>
          <Paragraph weight={"semibold"} uppercase typo={"paragraphLG"}>
            {label}
          </Paragraph>
        </Link>
      ) : (
        <Paragraph weight={"semibold"} uppercase typo={"paragraphLG"}>
          {label}
        </Paragraph>
      )}
    </StyledTag>
  );
};

export default Tag;
