import React from 'react';

import { Heading } from '../Typography';

import * as S from './styles';

export interface SelectProps {
  label: string;
  options: { label: string; value: string | number }[];
  selectedValue: string | number;
  onChange: (value: string) => void;
}

const Select: React.FC<SelectProps> = ({ options, label, selectedValue, onChange }) => {

  return (
    <S.SelectWrapper>
      {label && <S.Label as={Heading} typo="paragraphSM" weight="semibold">{label}</S.Label>}
      <S.Select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
        {options && options.map((option) => (
          <S.Option key={option.value} value={option.value}>
            {option.label}
          </S.Option>
        ))}
      </S.Select>
    </S.SelectWrapper>
  )
};

export default Select;
