import styled from "styled-components";
import { Heading, Paragraph } from "../Typography";
import { HorizontalCardProps } from "./types";

export const StyledHorizontalCard = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.rem(16, 12)};
  border: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderTertiary};
  border-radius: ${({ theme }) => theme.rem(16)};
  background-color: #fff;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const StyledHorizontalCardContainer = styled.div`
  gap: ${({ theme }) => theme.rem(12)};
  display: flex;
  align-items: center;
`;

export const StyledIconContainer = styled.div<Partial<HorizontalCardProps>>`
  background: ${({ iconBg }) => iconBg || "#CFDBEA"};
  width: ${({ theme }) => theme.rem(52)};
  height: ${({ theme }) => theme.rem(52)};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledHorizontalCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(4)};
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textSecondary};
`;

export const StyledHeading = styled(Heading)``;
