import Counter from "@/components/atoms/Counter";

import * as S from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { UserInterface } from "@/store/slices/authSlice";

const calculateDaysRemaining = (targetDate: string): number => {
  const target = new Date(targetDate);
  const today = new Date();

  today.setHours(0, 0, 0, 0);
  target.setHours(0, 0, 0, 0);

  const difference = target.getTime() - today.getTime();
  const daysRemaining = Math.ceil(difference / (1000 * 60 * 60 * 24));

  return daysRemaining < 0 ? daysRemaining : -daysRemaining;
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString("it-IT", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

const Label = ({ date }: { date: string }) => (
  <S.StyledLabelContainer>
    <S.StyledParagraph typo="paragraphSM">Test: </S.StyledParagraph> &nbsp;
    <S.StyledDateParagrah typo="paragraphSM">
      {formatDate(date)}
    </S.StyledDateParagrah>
  </S.StyledLabelContainer>
);

const ExamDate = () => {
  const user = useSelector<RootState>(
    (state) => state.auth.user
  ) as UserInterface;
  console.log({ user });
  if (!user?.exams) {
    return;
  }

  const renderedValue = calculateDaysRemaining(user.exams[0].date);
  const date = user.exams[0].date;

  return (
    <Counter
      icon="AverageTime"
      value={renderedValue}
      label={<Label date={date} />}
    />
  );
};

export default ExamDate;
