import useHistorical from "@/hooks/useHistorical";

import BaseLayout from "@/layouts/BaseLayout";
import { Heading } from "@/components/atoms/Typography";
import Sidebar from "@/components/organisms/Sidebar";
import HistoricalCharts, {
  HistoricalChartsProps,
} from "@/components/organisms/HistoricalCharts";
import HistoricalList, {
  HistoricalListProps,
} from "@/components/organisms/HistoricalList";

import * as S from "./styles";
import { isProductionMode } from "@/utils/isProductionMode";
import useIsMobile from "@/hooks/useIsMobile";

const HistoricalPage = () => {
  const isMobile = useIsMobile();
  // const {  historicalList } = useHistorical();
  const { historicalCharts, historicalList } = useHistorical();
  console.log({ historicalList });

  return (
    <BaseLayout
      headerComponent={<Sidebar />}
      flexDirection="row"
      isHeaderSticky={isMobile ? true : false}
    >
      <S.PageWrapper>
        <Heading typo="headingLG" weight="semibold">
          Storico Esercitazioni e Simulazioni
        </Heading>
        <Heading typo="paragraphMD">
          Visualizza tutte le esercitazioni e simulazioni che hai completato,
          ordinate per data.
        </Heading>
        {!isProductionMode() ? (
          <HistoricalCharts {...(historicalCharts as HistoricalChartsProps)} />
        ) : null}
        <HistoricalList {...(historicalList as HistoricalListProps)} />
      </S.PageWrapper>
    </BaseLayout>
  );
};

export default HistoricalPage;
