import { FC } from "react";
import * as S from "./styles";
import { CounterProps } from "./types";
import Icon from "../Icon";
import { Heading } from "../Typography";

const Counter: FC<CounterProps> = ({ icon, value, label }) => {
  const renderedDaysString = value && (value !== 1 && value !== -1) ? `${value} giorni` : `${value} giorno`;
  return (
    <S.StyledCounter>
      <S.StyledIconContainer>
        <Icon name={icon} size="big" />
      </S.StyledIconContainer>
      <S.StyledContentContainer>
        <Heading typo="headingMD" weight="semibold">
          {`${renderedDaysString} `}
        </Heading>
        {label}
      </S.StyledContentContainer>
    </S.StyledCounter>
  );
};

export default Counter;
