import styled from "styled-components";

// style={{ display: "flex", gap: 24, alignItems: "center", justifyContent: 'center', flexWrap: 'wrap' }}

export const StyledTimeRadioContainer = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`