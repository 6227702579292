import styled from "styled-components";

type NumberOrString = number | string;

interface SpacerBaseProps {
  xs?: NumberOrString;
  sm?: NumberOrString;
  md?: NumberOrString;
  lg?: NumberOrString;
  xl?: NumberOrString;
  xxl?: NumberOrString;
}

const SpacerBase = styled.div<SpacerBaseProps>`
  ${({ theme, ...p }) =>
    Object.entries(theme.breakpoints).map(
      ([dim]) =>
        p[dim as keyof typeof p] &&
        theme.mediaQuery[dim](`
    height: ${theme.spacing(p[dim as keyof typeof p])};
  `)
    )}
`;

export default SpacerBase;
