import React from 'react';

import Icon from '@/components/atoms/Icon';
import { Heading } from '@/components/atoms/Typography';
import { Visible } from '@/components/atoms/GridSystem/index.ts';

import * as S from './styles.ts';

interface CardHistoricalListPointProps {
  iconName: string;
  content: string;
  labelDesktop: string;
  labelMobile: string;
}

const CardHistoricalListPoint: React.FC<CardHistoricalListPointProps> = ({ iconName, content, labelDesktop, labelMobile }) => (
  <S.CardHistoricalListPoint>
    <Visible breakpoints={['md', 'lg', 'xl', 'xxl']}>
      <Heading content={labelDesktop} typo="paragraphSM" />
    </Visible>
    <S.CardHistoricalListPointContent>
      <Icon name={iconName} />
      <Heading content={content} typo="paragraphSM" weight="semibold" />
    </S.CardHistoricalListPointContent>
    <Visible breakpoints={['xs', 'sm']}>
      <Heading content={labelMobile} typo="paragraphSM" />
    </Visible>
  </S.CardHistoricalListPoint>
);

export default CardHistoricalListPoint;
