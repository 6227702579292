import styled from 'styled-components'

export const LoadingStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
  padding: ${({ theme }) => theme.rem(16)};
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
  border-radius: ${({ theme }) => theme.rem(8)};
`

export const LoadingStackItem = styled.div`
  width: 100%;
  height: 100px;
`

export const HistoricalList = styled.div`
  margin-top: ${({ theme }) => theme.rem(24)};
`

export const HistoricalListHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.rem(24)};
`

export const HistoricalListFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.rem(24)};
  margin-bottom: ${({ theme }) => theme.rem(24)};
`

export const HistoricalListFiltersTypes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.rem(12)};
`

export const HistoricalListFiltersOrder = styled.div`

`

export const HistoricalListItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.rem(24)};
`

export const HistoricalListItemsGroup = styled.div`
  width: 100%;
`

export const HistoricalListItemDate = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(16)};
`

export const HistoricalListButton = styled.button`
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
  color: #404853;
  padding: ${({ theme }) => theme.rem(8, 16)};
  border: 0.5px solid #CFDBEA;
  border-radius: ${({ theme }) => theme.rem(40)};
  font-size: ${({ theme }) => theme.rem(14)};
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    background-color: #404853;
    color: #F0F4F9;
  }

  &:focus-visible {
    outline: 2px solid #404853;
    outline-offset: 2px;
  }

  &[aria-current="true"] {
    background-color: #404853;
    color: #F0F4F9;
  }
`
