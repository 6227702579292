// import { useAuth0 } from "@auth0/auth0-react";
import { getUserInfo } from "@/api/services/auth";
import LoadingUI from "@/components/organisms/LoadingUI";
import { RootState } from "@/store";
import { setToken, setUser } from "@/store/slices/authSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate()
  const token = useSelector<RootState>((state) => state.auth.token);
  let [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  const code = searchParams.get("code");

  const getUserInfoHandler = async () => {
    try {
      const { data } = await getUserInfo();
      if (data) {
        dispatch(setUser(data));
      }
      navigate(`/`)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getUserInfoHandler();
    }
  }, [token]);

  if (code) {
    dispatch(setToken(code));
  }
  return <LoadingUI />
};

export default LoginPage;
