import styled from 'styled-components'

export const StyledDividerVertical = styled.div`
  height: 30px;
  border-right: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
`

export const StyledDividerHorizontal = styled.div`
  width: 100%;
  padding-inline: 1rem;
  border-top: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
`
