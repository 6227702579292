import Lottie from "lottie-react";
import loadingBooks from "@/assets/lottie/loading-books.json";

import * as S from './styles'

const LoadingUI = () => {
  return (
    <S.StyledLoadingContainer>
      <Lottie loop animationData={loadingBooks} />
    </S.StyledLoadingContainer>
  );
}

export default LoadingUI
