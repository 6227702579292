import Icon from "@/components/atoms/Icon";
import { Paragraph } from "@/components/atoms/Typography";
import { useId, useState, useRef, useEffect } from "react";
import {
  Bar,
  BarChart as BaseBarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Props as XAxisProps } from "recharts/types/cartesian/XAxis";
import { Props as YAxisProps } from "recharts/types/cartesian/YAxis";
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";

import * as S from "./styles";
import InfoMessage from "@/components/atoms/InfoMessage";

export type VerticalChartDataType = {
  subject: string;
  data: {
    name: string;
    value: number;
  }[];
};

interface VerticalBarChartProps {
  data: VerticalChartDataType[];
  withoutWrapper?: boolean;
  copy?: {
    title: string;
    infoBox: string;
  };
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

const _copy = {
  title: "Tempo medio per domanda",
  infoBox: "Puoi fare di meglio rispetto alla tua media!",
};

const formatTime = (milliseconds: number) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

  return {
    hours,
    minutes,
    seconds,
  };
};

const getStringChartTime =
  (hasLetters: boolean = false) =>
  (milliseconds: number) => {
    const { hours, minutes, seconds } = formatTime(milliseconds);

    if (!hasLetters) {
      const addZero = (value: number, valueBefore: number) => {
        if (valueBefore) {
          return value < 10 ? `0${value}` : `${value}`;
        }
        return value;
      };
      return `${hours ? `${hours}:` : ""}${
        minutes ? `${addZero(minutes, hours)}:` : "00:"
      }${seconds ? `${addZero(seconds, minutes)}` : ":00"}`;
    }

    return `${hours ? `${hours}h` : ""} ${minutes ? `${minutes}m` : ""} ${
      seconds ? `${seconds}s` : ""
    }`;
  };

const VerticalBarChart = ({
  data = [],
  withoutWrapper = false,
  copy = _copy,
  margin,
}: VerticalBarChartProps) => {
  const uniqueId = useId();
  const [activeSubjectIndex, setActiveSubjectIndex] = useState(0);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  const hasData = data.every(
    (item) =>
      item.subject &&
      Array.isArray(item.data) &&
      item.data.every(
        (entry) =>
          typeof entry.name === "string" && typeof entry.value === "number"
      )
  );
  const activeSubjectData: VerticalChartDataType = data[activeSubjectIndex] ?? {
    subject: "",
    data: [],
  };
  const subjectList = hasData ? data.map((item) => item.subject) : [];

  const {
    responsiveContainerProps,
    baseBarChartProps,
    cartesianGridProps,
    xAxisProps,
    yAxisProps,
    barProps,
    labelListProps,
  } = options;

  if (!hasData) {
    console.warn(
      "VerticalBarChart: data is empty or invalid. Is Data like {key: {name: string, value: number}[]}[] ?"
    );
    return null;
  }

  const handleSubjectClick = (index: number) => {
    setActiveSubjectIndex(index);
  };

  useEffect(() => {
    setIsPrevDisabled(activeSubjectIndex === 0);
    setIsNextDisabled(activeSubjectIndex === subjectList.length - 1);

    if (scrollContainerRef.current) {
      const activeElement = scrollContainerRef.current.children[
        activeSubjectIndex
      ] as HTMLElement;
      if (activeElement) {
        scrollContainerRef.current.scrollTo({
          left:
            activeElement.offsetLeft -
            (scrollContainerRef.current.clientWidth -
              activeElement.clientWidth) /
              2,
          behavior: "smooth",
        });
      }
    }
  }, [activeSubjectIndex, subjectList.length]);

  const handleMouseDown = (e: React.MouseEvent) => {
    isDraggingRef.current = true;
    startXRef.current = e.pageX - scrollContainerRef.current!.offsetLeft;
    scrollLeftRef.current = scrollContainerRef.current!.scrollLeft;
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    isDraggingRef.current = true;
    startXRef.current =
      e.touches[0].pageX - scrollContainerRef.current!.offsetLeft;
    scrollLeftRef.current = scrollContainerRef.current!.scrollLeft;
  };

  const handleMouseLeaveOrUp = () => {
    isDraggingRef.current = false;
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current!.offsetLeft;
    const walk = (x - startXRef.current) * 2; // Scroll speed
    scrollContainerRef.current!.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDraggingRef.current) return;
    const x = e.touches[0].pageX - scrollContainerRef.current!.offsetLeft;
    const walk = (x - startXRef.current) * 2; // Scroll speed
    scrollContainerRef.current!.scrollLeft = scrollLeftRef.current - walk;
  };

  const lastQuizAverageTime = activeSubjectData && activeSubjectData.data.length > 0 ? activeSubjectData.data[0].value : 0
  const lastFiveQuizzesAverageTime = activeSubjectData && activeSubjectData.data.length > 0 ? activeSubjectData.data[1].value : 0
  const hasInfoBox = activeSubjectData && activeSubjectData.data.length > 0 && (lastQuizAverageTime > 0 || lastFiveQuizzesAverageTime > 0) ? true : false;
  const infoBoxType = lastQuizAverageTime < lastFiveQuizzesAverageTime ? 'positive' : 'negative'
  const infoBoxText = lastQuizAverageTime < lastFiveQuizzesAverageTime ? "Sei migliorato/a rispetto alle ultime 5 prove! Continua così!" : "Puoi fare di meglio la prossima prova!"

  return (
    <S.ChartContainer $unstyled={withoutWrapper}>
      <button
        className="custom-prev-nav custom-prev-nav--chart-vertical-bar"
        disabled={isPrevDisabled}
        onClick={() => setActiveSubjectIndex((prevState) => prevState - 1)}
      >
        <Icon name="ArrowLeft" />
      </button>
      <button
        className="custom-next-nav custom-next-nav--chart-vertical-bar"
        disabled={isNextDisabled}
        onClick={() => setActiveSubjectIndex((prevState) => prevState + 1)}
      >
        <Icon name="ArrowRight" />
      </button>
      {!withoutWrapper ? (
        <S.ChartWrapper>
          <Paragraph
            content={copy.title}
            typo={"headingXS"}
            weight={"semibold"}
            style={{
              paddingInline: "1.5rem",
            }}
          />
          {hasData && subjectList.length > 0 ? (
            <S.ChartChipWrapper
              ref={scrollContainerRef}
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeaveOrUp}
              onMouseUp={handleMouseLeaveOrUp}
              onMouseMove={handleMouseMove}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleMouseLeaveOrUp}
              onTouchMove={handleTouchMove}
              onDragStart={(e) => e.preventDefault()}
            >
              {subjectList.map((subject, index) => (
                <div
                  key={`${subject}-${uniqueId}`}
                  style={{
                    width: "fit-content",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  <S.ChartChip
                    key={subject}
                    onClick={() => handleSubjectClick(index)}
                    $active={activeSubjectIndex === index}
                  >
                    <Paragraph content={subject} typo={"paragraphMD"} />
                  </S.ChartChip>
                </div>
              ))}
            </S.ChartChipWrapper>
          ) : null}
        </S.ChartWrapper>
      ) : null}
      <ResponsiveContainer {...responsiveContainerProps}>
        <BaseBarChart
          data={activeSubjectData.data}
          {...(baseBarChartProps as CategoricalChartProps)}
          margin={margin}
        >
          <CartesianGrid {...cartesianGridProps} />
          <XAxis {...(xAxisProps as XAxisProps)} />
          <YAxis {...(yAxisProps as YAxisProps)} />
          <Bar dataKey="value" {...(barProps as any)}>
            {activeSubjectData.data.map((_entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={index === 0 ? "#CFDBEA" : "#92A8C4"}
              />
            ))}
            <LabelList dataKey="value" {...(labelListProps as any)} />
          </Bar>
        </BaseBarChart>
      </ResponsiveContainer>
      {!withoutWrapper && hasInfoBox ? (
        <S.ChartInfo>
          <InfoMessage
            info={infoBoxText}
            type={infoBoxType}
            hasBackground
            hasIcon
          />
        </S.ChartInfo>
      ) : null}
    </S.ChartContainer>
  );
};

const options = {
  responsiveContainerProps: {
    style: {
      paddingInline: "1rem 2rem",
    },
  },
  baseBarChartProps: {
    width: 600,
    height: 400,
    layout: "vertical",
    margin: {
      left: 6,
    },
  },
  cartesianGridProps: {
    horizontal: false,
    type: "monotone",
    stroke: "#DCE5F0",
    strokeWidth: 2,
  },
  xAxisProps: {
    dataKey: "value",
    type: "number",
    domain: [0, "dataMax + 600000"],
    tickFormatter: getStringChartTime(true),
    axisLine: false,
    fontSize: 12,
    tickLine: false,
  },
  yAxisProps: {
    dataKey: "name",
    type: "category",
    strokeWidth: 0,
    fontSize: 12,
    tickMargin: 6,
    padding: { top: 25, bottom: 25 },
  },
  barProps: {
    barSize: 24,
    radius: [0, 8, 8, 0],
    fill: "#8884d8",
  },
  labelListProps: {
    position: "right",
    formatter: getStringChartTime(false),
    fill: "#666D75",
    fontSize: 12,
    fontWeight: 400,
  },
};

export default VerticalBarChart;
