import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Icon from '@/components/atoms/Icon';

import * as S from './styles';

export type PaginationProps = {
  total: number;
  perPage: number;
  currentPage: number;
  lastPage: number;
  firstPage: number;
  firstPageUrl: string;
  lastPageUrl: string;
  nextPageUrl: string | null;
  previousPageUrl: string | null;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  lastPage,
  firstPage,
  nextPageUrl,
  previousPageUrl,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const generatePageNumbers = () => {
    const pageNumbers = [];
    if (lastPage <= 6) {
      for (let i = 1; i <= lastPage; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always include the first page
      pageNumbers.push(firstPage);

      // Determine if we need to show the ellipsis after the first page
      if (currentPage > 3) {
        pageNumbers.push("...");
      }

      // Calculate the range of numbers around the current page
      let startPage = Math.max(2, currentPage - 2);
      let endPage = Math.min(lastPage - 1, currentPage + 2);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      // Determine if we need to show the ellipsis before the last page
      if (currentPage < lastPage - 2) {
        pageNumbers.push("...");
      }

      // Always include the last page
      pageNumbers.push(lastPage);
    }
    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers();

  const handlePageClick = (pageNumber: number | string) => {
    if (typeof pageNumber === "number") {
      searchParams.set('page', pageNumber.toString());
      setSearchParams(searchParams);
    }
  };

  return (
    <S.Pagination aria-label="Pagination Navigation">
      <S.PaginationList>
        <S.PaginationListPrevNext>
          <S.PaginationListItem>
            <S.PaginationButton onClick={() => handlePageClick(firstPage)} aria-label="Go to first page" disabled={currentPage <= firstPage}>
              <Icon name="FirstPage" />
              <span>Prima</span>
            </S.PaginationButton>
          </S.PaginationListItem>
          <S.PaginationListItem>
            <S.PaginationButton onClick={() => handlePageClick(currentPage - 1)} aria-label="Go to previous page" disabled={!previousPageUrl}>
              <Icon name="ChevronLeft" />
              <span>Indietro</span>
            </S.PaginationButton>
          </S.PaginationListItem>
        </S.PaginationListPrevNext>
        <S.PaginationListNumbers>
          {pageNumbers.map((number, index) => (
            <S.PaginationListItem key={index}>
              {number === "..." ? (
                <div>{number}</div>
              ) : (
                <S.PaginationButton
                  onClick={() => handlePageClick(number)}
                  aria-label={`Go to page ${number}`}
                  aria-current={currentPage === number ? 'page' : undefined}
                  style={{ fontWeight: currentPage === number ? 'bold' : 'normal' }}
                >
                  {number}
                </S.PaginationButton>
              )}
            </S.PaginationListItem>
          ))}
        </S.PaginationListNumbers>
        <S.PaginationListPrevNext>
          <S.PaginationListItem>
            <S.PaginationButton onClick={() => handlePageClick(currentPage + 1)} aria-label="Go to next page" disabled={!nextPageUrl}>
              <span>Avanti</span>
              <Icon name="ChevronRight" />
            </S.PaginationButton>
          </S.PaginationListItem>
          <S.PaginationListItem>
            <S.PaginationButton onClick={() => handlePageClick(lastPage)} aria-label="Go to last page" disabled={currentPage >= lastPage}>
              <span>Ultima</span>
              <Icon name="LastPage" />
            </S.PaginationButton>
          </S.PaginationListItem>
        </S.PaginationListPrevNext>
      </S.PaginationList>
    </S.Pagination>
  );
};

export default Pagination;
