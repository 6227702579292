import styled from 'styled-components';

export const SelectWrapper = styled.div`

`;

export const Label = styled.div`

`;

export const Select = styled.select`
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: ${({ theme }) => theme.rem(14)};
  font-weight: 600;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const Option = styled.option``;
