import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Paragraph } from '../Typography'

interface StyledMenuButtonInterface {
  isExpanded?: boolean
}

export const StyledMenuButton = styled(motion.div)<StyledMenuButtonInterface>`
  display: flex;
  justify-content: ${({ isExpanded }) =>
    isExpanded ? 'space-between' : 'center'};
  align-items: center;
  padding: ${({ theme }) => theme.rem(8)};
  border-radius: ${({ theme }) => theme.rem(8)};
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
  width: ${({ isExpanded }) => (isExpanded ? '100%' : 'auto')};

  &.active {
    background-color: ${({ theme }) =>
      theme.colorScheme.dark.surface.backgroundColor};
  }

  &:hover {
    background-color: #dce5f0;
  }

  &:active {
    background-color: #afc1d8;
  }
`

export const StyledParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo('paragraphMD')};
  font-weight: 600;
  color: ${({ theme }) => theme.colorScheme.white.button.primary.textHover};
`
