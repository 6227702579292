import styled, { keyframes } from "styled-components";
import LogoAlphaTest from "@/assets/images/logo-alpha-test.svg?react";
import { Heading, Label } from "@/components/atoms/Typography";
import ButtonV2 from "@/components/atoms/ButtonV2";

const scaleAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.5); }
  100% { transform: scale(1); }
`;

export const StyledCountdownContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #2D3035;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999
`;

export const StyledCountdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.rem(32)};
`;

export const StyledLogo = styled(LogoAlphaTest)`
  fill: #fff;
  width: 90px;
  height: 80px;
`;

export const StyledCountdownContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.rem(24, 0)};
  gap: ${({ theme }) => theme.rem(24)};
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.rem(4)};
`;

export const StyledLabel = styled(Label)`
  ${({ theme }) => theme.typo("label")};
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
`;

export const StyledHeading = styled(Heading)`
  ${({ theme }) => theme.typo("headingLG")};
  color: #fff;
  font-weight: 600;
`;

export const StyledSecondsContainer = styled.div`
  height: 220px;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #404854;
  box-shadow: 0px 0.545px 1.091px 0px rgba(16, 24, 40, 0.05);
  animation: ${scaleAnimation} 3s ease-in-out;
`;

export const StyledSeconds = styled.p`
  font-weight: 600;
  font-size: ${({ theme }) => theme.rem(60)};
  color: #fff;
`;

export const StyledButton = styled(ButtonV2)`
  width: 100%;
  background-color: #fff;
`;
