import styled from "styled-components";
import { Heading, Paragraph } from "@/components/atoms/Typography";
import InfoMessage from "@/components/atoms/InfoMessage";
import LaunchCardList from "@/components/organisms/LaunchCardList";
import Skeleton from "@/components/atoms/Skeleton";

export const StyledMainContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(16)};
  flex-direction: column;
  // padding-top: ${({ theme }) => theme.rem(48)};
  // padding-bottom: ${({ theme }) => theme.rem(150)};
`;

export const StyledHeaderContainer = styled.div`
  padding-top: ${({ theme }) => theme.rem(32)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(24)};

  justify-content: space-between;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    flex-direction: row;

  `)}
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(24)};
`;

export const StyledMaterial = styled.div`
  display: flex;
  width: fit-content;
  padding: ${({ theme }) => theme.rem(4, 32)};
  border-radius: ${({ theme }) => theme.rem(24)};
  border: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
`;

export const StyledMaterialParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  font-weight: 600;
`;

export const StyledTextContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(24)};
  padding: ${({ theme }) => theme.rem(48, 24, 24, 0)};
  align-items: center;
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaQuery.xl(`
  flex-direction: row;
`)}
`;

export const StyledText = styled.div`
  flex: 1;
  word-wrap: break-word;
`;

export const StyledParagraphLG = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  ${({ theme }) => theme.typo("paragraphLG")};
`;

//V2 - Chart
export const StyledSubjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.rem(24, 32)};
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
  border-radius: ${({ theme }) => theme.rem(8)};

  ${({ theme }) =>
    theme.mediaQuery.xl(`
flex-direction: row;
gap: ${theme.rem(80)};
`)}
`;

export const StyledSubjectsChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>
    theme.mediaQuery.xl(`
  height: 600px;
  margin-bottom: 0px;
  width: 400px;
  flex: 1;
  // max-width: 450px;
`)}
`;

export const StyledSubjectsChartButtonContainer = styled.div`
  flex: 0 0 auto;

  ${({ theme }) =>
    theme.mediaQuery.md(`
    display: none
  `)}
`;
export const StlyedSubjectChartContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledSubjectsChartContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(4)};
`;

export const StyledSubjectsChartParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  ${({ theme }) => theme.typo("paragraphSM")};
  font-weight: 600;
  opacity: 0.4;
`;

export const StyledSubjectsChartHeading = styled(Heading)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  ${({ theme }) => theme.typo("headingSM")};
  font-weight: 600;
`;

export const StyledSubjectsChartDescription = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
  ${({ theme }) => theme.typo("paragraphSM")};
`;

export const StyledSubjectsChart = styled.div`
  height: 350px;
  margin: ${({ theme }) => theme.rem(12, 0)};
`;

export const StyledSubjectsChartFooter = styled.div`
  // padding: ${({ theme }) => theme.rem(12)};
  // border-radius: ${({ theme }) => theme.rem(8)};
  // background-color: #dce5f0;
`;

export const StyledInfoMessage = styled(InfoMessage)`
  background-color: #ffebeb;
`;

export const StyledLaunchCardContainer = styled(LaunchCardList)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${({ theme }) => theme.rem(12)};
  flex: 2;

  ${({ theme }) =>
    theme.mediaQuery.md(`
  grid-template-columns: repeat(2, minmax(0, 1fr));
  `)}
`;

export const StyledLaunchCardContainerMobile = styled.div`
  margin-top: ${({ theme }) => theme.rem(24)};
`;

export const StyledLaunchCardListContainerMobile = styled(LaunchCardList)`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: ${({ theme }) => theme.rem(8)};
  margin: ${({ theme }) => theme.rem(16, 0)};
`;

export const StyledBottomSheetListContainer = styled(LaunchCardList)`
  padding: ${({ theme }) => theme.rem(16, 12)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
`;

//LaunchCardModalContent
export const StyledModalContainer = styled.div`
  padding: ${({ theme }) => theme.rem(12, 16)};
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(40)};
`;

export const StyledImageContainer = styled.div`
  margin: auto;
  width: 200px;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(40)};
`;

export const StyledContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.rem(12)};
`;

export const StyledModalParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseTertiary};
  opacity: 0.7;
`;

export const StyledModalHeading = styled(Heading)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  text-align: center;
`;

export const StyledContentCTA = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledShowMoreContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledStreakContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(40)};
`;

export const StyledStreak = styled.div`
  display: flex;
  width: 180px;

  ${({ theme }) =>
    theme.mediaQuery.md(`
  width: auto;
  `)}
`;

export const StyledSkeletonContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  gap: ${({ theme }) => theme.rem(12)};
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaQuery.md(`
  flex-direction: row;
  `)}
`;

export const StyledSkeleton = styled(Skeleton)`
  flex: 1;
`;
