import Skeleton from '@/components/atoms/Skeleton'

import * as S from './styles'

const LoadingStack = () => {
  return (
    <S.LoadingStack>
      <S.LoadingStackItem>
        <Skeleton />
      </S.LoadingStackItem>
      <S.LoadingStackItem>
        <Skeleton />
      </S.LoadingStackItem>
      <S.LoadingStackItem>
        <Skeleton />
      </S.LoadingStackItem>
      <S.LoadingStackItem>
        <Skeleton />
      </S.LoadingStackItem>
    </S.LoadingStack>
  )
}

export default LoadingStack
