import { Heading, Paragraph } from "@/components/atoms/Typography";
import styled from "styled-components";

export const StyledQuizBodyContainer = styled.div`
  // position: sticky;
  // top: ${({ theme }) => theme.rem(20)};
  // padding-bottom: 50vh;
  // max-height: 90vh;
  // overflow: auto;
  // box-shadow: inset 4px 4px 15px 0px #000000, inset 5px 5px 11px -1px rgba(0,0,0,0);
  // overflow: scroll;
  // padding-bottom: 50px;
  padding: ${({ theme }) => theme.rem(8, 12)};
  ${({ theme }) =>
    theme.mediaQuery.lg(`
  padding: ${theme.rem(0, 32)};
`)}
`;

export const StyledQuizBodyTitle = styled(Paragraph)`
  margin-bottom: ${({ theme }) => theme.rem(12)};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
`;

export const StyledQuizBodyContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(48)};

  // span:first-of-type {
  //   display: inline-flex;
  //   align-items: center;
  //   white-space: nowrap;
  // }

  span:first-of-type {
    // display: inline-block;
  }

  span:first-of-type img {
    vertical-align: middle;
  }

  img {
    max-width: 100%;
    height: auto;
    // margin-right: 10px;
    // margin-left: 10px;
  }
`;

export const StyledAnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
`;

export const SkipAnswerParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphSM")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledSemiboldParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphLG")};
  font-weight: 600;
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
`;

export const StyledRegularParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphMD")};
  font-weight: 300;
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
`;

export const StyledQuestionAnswer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
`;

export const StyledExcerptContainer = styled.div`
  border: 2px solid #ddd;
  // margin: ${({ theme }) => theme.rem(0, 16)}
  padding: ${({ theme }) => theme.rem(16)};
  border-radius: ${({ theme }) => theme.rem(16)};
`;

export const StyledQuizQuestionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
`;

export const StyledQuizHeading = styled(Heading)`
  word-break: break-word;
`;
