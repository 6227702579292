import styled, { css } from "styled-components";

import { StyledIcon } from "../Icon/styles";

export const StyledIconButtonCotainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorScheme.dark.button.iconButton.iconDefault};
    background-color: ${theme.colorScheme.dark.button.iconButton.iconDefaultBg};
    border: 1px solid ${theme.colorScheme.dark.button.iconButton.borderDefault};
    display: inline-flex;
    border-radius: 50%;
    cursor: pointer;
    width: ${({ theme }) => theme.rem(44)};
    height: ${({ theme }) => theme.rem(44)};
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;

    ${StyledIcon} {
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
      width: ${({ theme }) => theme.rem(18)};
      height: ${({ theme }) => theme.rem(18)};
      border-radius: 50%;
    }

    &:hover {
      background-color: ${theme.colorScheme.dark.button.iconButton.bgHover};
      border: 1px solid ${theme.colorScheme.dark.button.iconButton.borderHover};
    }

    &:active {
      background-color: ${theme.colorScheme.dark.button.iconButton.bgActive};
      border: 1px solid ${theme.colorScheme.dark.button.iconButton.borderActive};
    }

    &:disabled {
      background-color: ${theme.colorScheme.dark.button.iconButton.bgDisabled};
      border: 1px solid ${theme.colorScheme.dark.button.iconButton.borderDisabled};
      cursor: not-allowed;
    }
  `}
`;