import styled from "styled-components";
import { Heading, Label } from "../Typography";

export const StyledProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export const StyledProgressBarLabel = styled(Heading)`
  ${({ theme }) => theme.typo("headingSM")};
  font-weight: 600;
  padding-top:
    ${({ theme }) => theme.rem(16)};
`;

export const StyledProgressBarValue = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledProgressBarNumeric = styled(Label)`
  ${({ theme }) => theme.typo("label")}
  font-weight: 600;
`;

export const StyledProgress = styled.progress`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;

  &::-webkit-progress-bar {
    background-color: #AFC1D8;
    border-radius: 24px;
  }

  &::-webkit-progress-value {
    background-color: ${({ theme }) =>
      theme.colorScheme.white.button.iconColor};
    border-radius: 24px;
  }
`;
