import { InfoMessageProps } from "./types";

import * as S from "./styles";
import Icon from "../Icon";

const InfoMessage = ({
  info,
  type,
  hasBackground = false,
  hasIcon = false,
  message,
  icon,
  ...props
}: InfoMessageProps) => {
  const renderedIcon = () => {
    switch (type) {
      case "neutral":
        return icon;
      case "negative":
        return "ArrowDown";
      case "positive":
        return "ArrowUp";
      default:
        return "";
    }
  };
  return (
    <S.StyledInfoMessage type={type} hasBackground={hasBackground} {...props}>
      {hasIcon ? (
        <S.StyledIconContainer type={type}>
            <Icon name={icon ? icon : renderedIcon()} />
        </S.StyledIconContainer>
      ) : null}
      <S.StyledParagraphLabel>
        {info} <span>{message}</span>
      </S.StyledParagraphLabel>
    </S.StyledInfoMessage>
  );
};

export default InfoMessage;
