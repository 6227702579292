import styled, { css } from 'styled-components'

interface CardHistoricalListProps {
  $isEmbedded?: boolean;
}

export const CardHistoricalListInfo = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 33%;
  gap: ${({ theme }) => theme.rem(12)};
`

export const CardHistoricalListDate = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(4)};
`

export const CardHistoricalListIconImage = styled.div`
  width: 40px;
  height: 40px;
`

export const CardHistoricalListPoints = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 33%;
  gap: ${({ theme }) => theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.sm(`
    margin-left: ${theme.rem(52)};
  `, true)}
`

export const CardHistoricalListPoint = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(4)};

  ${({ theme }) => theme.mediaQuery.sm(`
    flex-direction: row;
  `, true)}
`

export const CardHistoricalListPointContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};
`

export const CardHistoricalListCTA = styled.div`
  display: flex;
  background-color: #F0F4F9;
  color: #404853;
  padding: ${({ theme }) => theme.rem(8)};
  border: 0.5px solid #CFDBEA;
  border-radius: 50%;
  font-size: ${({ theme }) => theme.rem(14)};
  font-weight: 500;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  ${({ theme }) => theme.mediaQuery.sm(`
    position: absolute;
    top: 50%;
    right: ${theme.rem(16)};
    transform: translateY(-50%);
  `, true)}

  &:hover {
    background-color: #404853;
    color: #F0F4F9;
  }

  &:focus-visible {
    outline: 2px solid #404853;
    outline-offset: 2px;
  }

  &[aria-current="true"] {
    background-color: #404853;
    color: #F0F4F9;
  }
`

export const CardHistoricalList = styled.button<CardHistoricalListProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.rem(12)};
  position: relative;
  padding: ${({ theme }) => theme.rem(24, 16)};
  border: 1px solid #DCE5F0;
  border-radius: ${({ theme }) => theme.rem(8)};
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
  margin-bottom: ${({ theme }) => theme.rem(16)};
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  color: #000;

  ${({ theme }) => theme.mediaQuery.sm(`
    flex-direction: column;
    align-items: flex-start;
    padding-right: ${theme.rem(64)};
  `, true)}

  ${({ theme, $isEmbedded }) => $isEmbedded && css`
    padding: ${theme.rem(16)};
    margin-bottom: 0;
    border-radius: 0;
    border-width: 1px 0 0 0;
  `}

  &:hover {
    background-color: #FBF5F0;
    border-color: #92A8C4;

    ${CardHistoricalListCTA} {
      background-color: #FFE9D4;
      border-color: #FFE9D4;
    }
  }

  &:focus-visible {
    outline: 2px solid #404853;
    outline-offset: 2px;
  }
`
