import { Paragraph } from '../Typography'
import { StyledCardTag } from './styles'

export type TagStatusType = 'success' | 'warning' | 'danger' | 'info'

export interface CardTagProps {
  typo?: string
  weight?: string
  label?: string
  status?: TagStatusType
  small?: boolean
}

const CardTag = ({
  typo = 'paragraphSM',
  weight = 'semibold',
  label = '',
  status = 'info',
  small = false,
}: CardTagProps) => {
  if (!label) return null
  return (
    <StyledCardTag
      $variant={status}
      $isSmall={small}
    >
      <Paragraph
        typo={small ? 'paragraphSM' : typo}
        content={label}
        weight={weight}
      />
    </StyledCardTag>
  )
}

export default CardTag
