import styled, { css } from "styled-components";
import gridConfig from "@theme/grid-config";
import { ReactNode } from "react";

interface RowProps {
  reverse?: boolean | string[];
  align?: string | Record<string, string>;
  justify?: string | Record<string, string>;
  debug?: boolean;
  children?: ReactNode;
}

const Row = styled.div<RowProps>`
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;

  ${({ theme }) =>
    Object.entries(theme.breakpoints).map(([dim]) =>
      theme.mediaQuery[dim](`
    margin-left: -${theme.rem(gridConfig({ theme }).gutterWidth[dim] / 2)};
    margin-right: -${theme.rem(gridConfig({ theme }).gutterWidth[dim] / 2)};
  `)
    )}

  ${({ theme, reverse }) =>
    reverse &&
    (Array.isArray(reverse)
      ? Object.entries(theme.breakpoints).map(([dim]) =>
          theme.mediaQuery[dim](`
          flex-direction: ${
            reverse.indexOf(dim) !== -1 ? "row-reverse" : "row"
          };
          flex-wrap: ${reverse.indexOf(dim) !== -1 ? "wrap-reverse" : "wrap"};
        `)
        )
      : `
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
      `)}

  ${({ theme, align }) =>
    align &&
    (typeof align === "object"
      ? Object.entries(theme.breakpoints).map(
          ([dim]) =>
            align[dim] &&
            theme.mediaQuery[dim](`
          align-items: ${align[dim]};
        `)
        )
      : `align-items: ${align};`)}

  ${({ theme, justify }) =>
    justify &&
    (typeof justify === "object"
      ? Object.entries(theme.breakpoints).map(
          ([dim]) =>
            justify[dim] &&
            theme.mediaQuery[dim](`
          justify-content: ${justify[dim]};
        `)
        )
      : `justify-content: ${justify};`)}

  ${({ debug }) =>
    debug &&
    css`
      background-color: #f5f9fc;
      outline: #fff solid 1px !important;
    `}
`;

export default Row;
