import { FC } from "react";

import * as S from "./styles";

import { useTranslation } from "react-i18next";
import IconButton from "@/components/atoms/ButtonIcon";

const Stepper: FC<StepperProps> = ({
  steps,
  currentStep,
  onStepChange,
  onCloseStepper,
  ...props
}) => {
  const { t } = useTranslation();
  const ComponentXIcon = onCloseStepper
    ? S.IconContainer
    : S.IconContainerHidden;
  return (
    <S.StepperContainer {...props}>
      <S.StepperHeader>
        <S.IconContainerHidden></S.IconContainerHidden>
        <S.StyledParagraph typo="paragraphMD">
          {t("step_of", {currentStep, steps})}
        </S.StyledParagraph>
        <ComponentXIcon onClick={onCloseStepper}>
          {onCloseStepper && <IconButton name="XMark" />}
        </ComponentXIcon>
      </S.StepperHeader>
      <S.StepIndicatorsContainer>
        {Array.from({ length: steps }, (_, index) => {
          const stepNumber = index + 1;
          return (
            <S.StepIndicator
              key={stepNumber}
              active={stepNumber === currentStep}
              onClick={() => onStepChange?.(stepNumber)}
            ></S.StepIndicator>
          );
        })}
      </S.StepIndicatorsContainer>
    </S.StepperContainer>
  );
};

export default Stepper;
