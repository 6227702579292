import styled from "styled-components";

export const StyledBottomNavigation = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.rem(8, 16)};
    gap: ${({ theme }) => theme.rem(16)};
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(5px);
    height: 80px;
`;