import Icon from "@/components/atoms/Icon";
import { CardProps } from "./types";
import { Paragraph } from "@/components/atoms/Typography";

import * as S from "./styles";

const Card = ({
  icon,
  title,
  description,
  value,
  selected,
  onClick,
}: CardProps) => {
  return (
    <S.StyledCardRadioContainer
      onClick={onClick}
      selected={selected}
      value={value}
    >
      <S.StyledCardRadioContent>
        <S.StyledIcon name={icon} ariaLabel={title} size="big" />
        <div>
          <S.StyledTitle>{title}</S.StyledTitle>
          <Paragraph typo="paragraphXS">{description}</Paragraph>
        </div>
      </S.StyledCardRadioContent>
      <S.StyledCardRadioIconContainer>
        <Icon
          name={selected ? "RadioAtom" : "RadioButtonUnchecked"}
          checked={selected}
          size="default"
        />
      </S.StyledCardRadioIconContainer>
    </S.StyledCardRadioContainer>
  );
};

export default Card;
