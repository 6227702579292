import React, { useEffect, useState } from "react";
import Answer from "@/components/molecules/Quiz/Answer";
import { QuizValues } from "@/pages/QuizPage/interface";
import useIsMobile from "@/hooks/useIsMobile";
import * as S from "./styles";
import { isProductionMode } from "@/utils/isProductionMode";
import { formatMathJax } from "@/utils/mathJaxFormatter";

export enum QuestionStatus {
  CORRECT = "CORRECT",
  INCORRECT = "INCORRECT",
  SKIPPED = "SKIPPED",
  UNANSWERED = "UNANSWERED",
}

interface Props {
  quiz: QuizValues;
  currentQuestionNumber: number;
  selectedAnswer: number | undefined;
  setSelectedAnswer: React.Dispatch<React.SetStateAction<number | undefined>>;
  onAddAnswer?: () => void;
  answeredQuestions: { answerId: number; questionId: number }[];
  onClick?: (id: NumberOrString) => void;
}

const QuizBody = ({
  quiz,
  currentQuestionNumber,
  selectedAnswer,
  onClick,
  setSelectedAnswer,
  answeredQuestions,
}: Props) => {
  const isMobile = useIsMobile();
  const [formattedQuestionBody, setFormattedQuestionBody] = useState<string>("");
  const [formattedAnswers, setFormattedAnswers] = useState<{ id: number; body: string }[]>([]);
  const [formattedSolution, setFormattedSolution] = useState<string | null>(null);

  const currentSchoolSubjectObject = quiz.schoolSubjectQuestions.find(
    (question) => question.schoolSubject === quiz.schoolSubject
  );

  const question = currentSchoolSubjectObject?.questions[currentQuestionNumber - 1] ||
    quiz.questions[currentQuestionNumber - 1];
  const questionIndex = currentSchoolSubjectObject?.questions?.findIndex(
    (ques) => ques.id === question.id
  ) as number;
  const schoolSubjectLabel = currentSchoolSubjectObject?.schoolSubject;
  const questionBody = question?.body;
  const renderedQuestion = !isProductionMode()
    ? `${question?.strapiId} - ${questionBody}`
    : questionBody;

  useEffect(() => {
    const formatted = formatMathJax(renderedQuestion);
    setFormattedQuestionBody(formatted);
  }, [renderedQuestion]);

  useEffect(() => {
    const formatted = question?.answers.map((answer) => ({
      ...answer,
      body: formatMathJax(answer.body),
    })) || [];
    setFormattedAnswers(formatted);
  }, [question?.answers]);

  useEffect(() => {
    if (question?.solution) {
      setFormattedSolution(formatMathJax(question.solution));
    } else {
      setFormattedSolution(null);
    }
  }, [question?.solution]);

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetClear();
      window.MathJax.typesetPromise().catch((err: Error) =>
        console.error("MathJax typeset failed: ", err.message)
      );
    }
  }, [formattedQuestionBody, formattedAnswers, formattedSolution]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setSelectedAnswer(Number(event.target.value));
    } else {
      setSelectedAnswer(0);
    }
  };

  const quizHasAnswer = answeredQuestions.find(
    (answer) => answer.questionId === question.id
  );

  const isRandom = currentSchoolSubjectObject?.schoolSubject === "random";

  interface AnswerInterface {
    id: number;
    body: string;
  }

  const questionStatus = (answer: AnswerInterface): QuestionStatus => {
    if (quiz.isCorrectionMode) {
      if (question.answerCorrectId === answer.id) {
        return QuestionStatus.CORRECT;
      } else if (question.answerUserId === answer.id) {
        return QuestionStatus.INCORRECT;
      } else {
        return QuestionStatus.UNANSWERED;
      }
    } else {
      if (answer.id === question.answerCorrectId && !isProductionMode()) {
        return QuestionStatus.CORRECT;
      } else {
        return QuestionStatus.UNANSWERED;
      }
    }
  };

  const isQuestionChecked = (answer: AnswerInterface) => {
    if (quiz.isCorrectionMode) {
      return answer.id === question.answerUserId;
    } else {
      return selectedAnswer === answer.id;
    }
  };

  return (
    <S.StyledQuizBodyContainer>
      {!isMobile && (
        <S.StyledQuizBodyTitle typo="paragraphMD">
          {`Domanda ${questionIndex + 1} su ${
            currentSchoolSubjectObject?.questions.length
          } ${
            !isRandom && schoolSubjectLabel ? `di ${schoolSubjectLabel}` : ""
          }`}
        </S.StyledQuizBodyTitle>
      )}

      <S.StyledQuizBodyContent>
        <S.StyledQuizQuestionBody>
          <S.StyledQuizHeading typo="headingSM">
            {formattedQuestionBody}
          </S.StyledQuizHeading>
          {question?.hasExcerpt && question?.excerpt && (
            <S.StyledExcerptContainer>
              <q>{question.excerpt}</q>
            </S.StyledExcerptContainer>
          )}
        </S.StyledQuizQuestionBody>

        <S.StyledAnswersContainer>
          {formattedAnswers.length > 0 && formattedAnswers.map((answer, index) => (
            <Answer
              key={answer.id}
              id={String(question.id)}
              name={String(question.id)}
              onChange={onChange}
              onClick={onClick}
              checked={isQuestionChecked(answer)}
              label={answer.body}
              value={answer.id}
              index={index}
              disabled={quiz.isCorrectionMode}
              status={questionStatus(answer)}
            />
          ))}
          {quizHasAnswer && !quiz.isCorrectionMode ? (
            <S.SkipAnswerParagraph onClick={onChange}>
              Annulla la risposta
            </S.SkipAnswerParagraph>
          ) : null}
          {quiz.isCorrectionMode && formattedSolution && (
            <S.StyledQuestionAnswer>
              <S.StyledSemiboldParagraph typo="paragraphLG">
                Soluzione commentata
              </S.StyledSemiboldParagraph>
              <S.StyledRegularParagraph typo="paragraphLG">
                {formattedSolution}
              </S.StyledRegularParagraph>
            </S.StyledQuestionAnswer>
          )}
        </S.StyledAnswersContainer>
      </S.StyledQuizBodyContent>
    </S.StyledQuizBodyContainer>
  );
};

export default QuizBody;
