import styled from "styled-components";

export const StyledCardRadioContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.rem(24)};
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.lg(`
    flex-direction: row;
  `)}
`;
