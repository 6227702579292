import Tag from "@/components/atoms/Tag";
import { Label } from "@/components/atoms/Typography";
import { QuestionStatus } from "@/components/organisms/Quiz/QuizBody";
import styled, { css } from "styled-components";

interface TagProps {
  active?: boolean;
  answered?: boolean;
  status: QuestionStatus;
  activeSchoolSubject?: boolean;
}

interface SectionInterface {
  active: boolean;
}

export const StyledMaterialQuestionsNumber = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
  overflow: auto;
  height: calc(100vh - 280px);

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

export const StyledMaterialLabel = styled(Label)`
  ${({ theme }) => theme.typo("label")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
  font-weight: 600;
`;

export const StyledMaterialQuestions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.rem(12)};
`;

export const StyledMaterialTag = styled(Tag)<TagProps>`
  background: #fff;
  color: ${({ theme, activeSchoolSubject }) => activeSchoolSubject ?
    theme.colorScheme.white.quiz.scrollbarProgressNumberBorderSecondary : '#B0B0B0'};
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  background: ${({ status }) => {
    switch (status) {
      case QuestionStatus.CORRECT:
        return "#B6E4C3";
      case QuestionStatus.INCORRECT:
        return "#FFBBBB";
      case QuestionStatus.SKIPPED:
        return "#E7E7E7";
      default:
        return "#fff";
    }
  }};

  border: 1px solid ${({activeSchoolSubject}) => activeSchoolSubject ? '#000' : '#B0B0B0'};

  border: 1px solid
    ${({ status }) => {
      switch (status) {
        case QuestionStatus.CORRECT:
        case QuestionStatus.INCORRECT:
        case QuestionStatus.SKIPPED:
          return "transparent";
        case QuestionStatus.UNANSWERED:
        default:
          return "";
      }
    }};

  ${({ answered, theme }) =>
    answered &&
    css`
      color: ${theme.colorScheme.white.quiz
        .scrollbarProgressNumberTextDone};
      background: ${theme.colorScheme.white.quiz
        .scrollbarProgressNumberBackgroundDone};
    `}
  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.colorScheme.white.quiz
        .scrollbarProgressNumberTextDone};
      background: ${theme.colorScheme.white.quiz
        .scrollbarProgressNumberBackgroundSelected};
      border: 1px solid #000;
    `}
`;

export const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(24, 16)};
  width: 100%;
`;

export const StyledSection = styled.div<SectionInterface>`
  padding: ${({ theme }) => theme.rem(24, 16)};
  border: 1px solid
    ${({ theme }) => theme.colorScheme.white.quiz.scrollbarCardBorderSecondary};
  background-color: ${({ theme }) => theme.colorScheme.white.quiz.scrollbarCardBackgroundDefault};
  border-radius: ${({ theme }) => theme.rem(16)};
  gap: ${({ theme }) => theme.rem(24, 16)};
  display: flex;
  flex-direction: column;



  ${({ active }) =>
    active &&
    css`
      border: 2px solid
        ${({ theme }) =>
          theme.colorScheme.white.quiz.scrollbarCardBorderPrimary};
      background-color: ${({ theme }) => theme.colorScheme.white.quiz.scrollbarCardBackgroundActive};
      // box-shadow: 0px 2px 0px 0px #3b3838;
    `}


`;
