import styled from "styled-components";

interface FlexProps {
  fullWidth?: string;
  fullHeight?: string;
  wrap?: string;
  justifyContent?: string;
  alignItems?: string;
  $direction?: string | Record<string, string>;
  align?: string | Record<string, string>;
  justify?: string | Record<string, string>;
  $v?: number | Record<string, number>;
  $offset?: number | Record<string, number>;
  offsetRight?: number | Record<string, number>;
  reverse?: boolean | string[];
  noGutter?: boolean;
  debug?: boolean;
  grow?: string | Record<string, string>;
  gap?: number | Record<string, number>;
}

const Flex = styled.div<FlexProps>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "auto")};
  display: flex;
  flex-wrap: ${({ wrap }) => (wrap ? wrap : "nowrap")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "flex-start"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "flex-start")};

  ${({ theme, $direction }) => {
    if ($direction) {
      return typeof $direction === "object"
        ? Object.entries(theme.breakpoints).map(
            ([dim]) =>
              $direction[dim] &&
              theme.mediaQuery[dim](`
            flex-direction: ${$direction[dim]};
          `)
          )
        : `flex-direction: ${$direction};`;
    }
    return "flex-direction: row;";
  }};

  ${({ theme, alignItems }) =>
    alignItems &&
    (typeof alignItems === "object"
      ? Object.entries(theme.breakpoints).map(
          ([dim]) =>
            alignItems[dim] &&
            theme.mediaQuery[dim](`
          align-items: ${alignItems[dim]};
        `)
        )
      : `align-items: ${alignItems};`)}

  ${({ theme, justifyContent }) =>
    justifyContent &&
    (typeof justifyContent === "object"
      ? Object.entries(theme.breakpoints).map(
          ([dim]) =>
            justifyContent[dim] &&
            theme.mediaQuery[dim](`
          justify-content: ${justifyContent[dim]};
        `)
        )
      : `justify-content: ${justifyContent};`)}

  ${({ theme, gap }) =>
    gap &&
    (typeof gap === "object"
      ? Object.entries(theme.breakpoints).map(
          ([dim]) =>
            gap[dim] &&
            theme.mediaQuery[dim](`
          gap: ${theme.rem(gap[dim] * 8)}};
        `)
        )
      : `gap: ${gap ? theme.rem(gap * 8) : 0};`)}

  ${({ theme, grow }) =>
    grow &&
    (typeof grow === "object"
      ? Object.entries(theme.breakpoints).map(
          ([dim]) =>
            grow[dim] &&
            theme.mediaQuery[dim](`
          flex-grow: ${grow[dim]};
        `)
        )
      : `flex-grow: ${grow ? grow : "auto"};`)}
`;

export default Flex;
