import ClassicOptions, {
  TIMES,
} from "@/components/organisms/Configuration/ClassicOptions";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import * as S from "./styles";
import Recap from "@/components/organisms/Configuration/Recap";
import { useEffect, useState } from "react";
import { generateQuiz } from "@/api/services/quizzes";
import { CARDS } from "@/components/organisms/Quiz/CardRadio";
import { Card } from "@/components/organisms/Quiz/CardRadio/types";
import { Time } from "@/components/organisms/Quiz/TimeRadio/types";
import { SchoolSubject } from "../QuizPage/interface";
import Countdown from "@/components/organisms/Countdown";

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import useIsMobile from "@/hooks/useIsMobile";
// import BaseLayout from "@/layouts/BaseLayout";
import BottomNavigation from "@/components/organisms/Configuration/BottomNavigation";
// import Stepper from "@/components/organisms/Stepper";

export interface QuestionSet {
  label: string;
  value: number;
  min: number;
  max: number;
  minWarning: number;
  maxWarning: number;
}

export const QUESTIONS_NUMBER: QuestionSet[] = [
  { label: "5", value: 3, min: 1, max: 10, minWarning: 2, maxWarning: 5 },
  { label: "15", value: 8, min: 3, max: 30, minWarning: 5, maxWarning: 15 },
  { label: "30", value: 15, min: 6, max: 60, minWarning: 10, maxWarning: 30 },
  { label: "45", value: 23, min: 9, max: 90, minWarning: 15, maxWarning: 45 },
  { label: "60", value: 30, min: 12, max: 120, minWarning: 20, maxWarning: 60 },
  {
    label: "3000",
    value: 500,
    min: 1,
    max: 1000,
    minWarning: 250,
    maxWarning: 750,
  },
];

const ConfigurationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const { state: quiz } = location;

  if (!quiz) {
    return <Navigate to="/" replace />;
  }

  const getDifficultyValue = () => {
    const difficulyValueString = quiz?.attributes?.difficulty?.difficultyValue; //easy, medium, hard
    switch (difficulyValueString) {
      case "easy":
        return 1;
      case "medium":
        return 3;
      case "hard":
        return 5;
      default:
        undefined;
    }
  };

  const difficultyNumber = getDifficultyValue();
  const currentMode: Card | undefined = CARDS.find(
    (card) => card.value === quiz?.attributes?.group?.type
  );
  const currentTime: Time | undefined | { value: number } = TIMES.find(
    (time) => time.value === quiz?.attributes?.time?.minutes
  ) || { value: quiz?.attributes?.time.minutes };

  const [selectedDifficulty, setSelectedDifficulty] = useState<
    string | number | undefined
  >(difficultyNumber);

  const [selectedQuizTime, setSelectedQuizTime] = useState<
    Time | undefined | { value: number }
  >(currentTime);

  const [selectedQuizQuestionsNumber, setSelectedQuizQuestionsNumber] =
    useState(quiz?.attributes?.questionsNumber?.questionsNumber);
  const [selectedQuizMode, setSelectedQuizMode] = useState(currentMode);

  const [selectedSchoolSubjects, setSelectedSchoolSubjects] = useState<
    Option[]
  >([{ value: "ALL", label: "Tutte le materie" }]);

  const [generatedQuiz, setGeneratedQuiz] = useState();
  const [isQuizReady, setIsQuizReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [questionSet, setQuestionSet] = useState<QuestionSet | undefined>();

  const { attributes } = quiz || {};
  // console.log({ attributes });
  const { time, questionsNumber, difficulty, group, schoolSubjects } =
    attributes || {};
  const { isConfigurableByUser: isModeConfigurable } = group;
  const { isConfigurableByUser: isQuestionsNumberConfigurable } =
    questionsNumber || {};
  const { isConfigurableByUser: isTimeConfigurable } = time || {};
  const { isConfigurableByUser: isDifficultyConfigurable } = difficulty || {};
  const { isConfigurableByUser: isSchoolSubjectsConfigurableByUser } =
    schoolSubjects;

  const STEPS = [
    {
      label: "presentation",
      active: true,
    },
    {
      label: "time",
      active: isTimeConfigurable,
    },
    {
      label: "questions-number",
      active: isQuestionsNumberConfigurable,
    },
    {
      label: "school-subjects",
      active: isSchoolSubjectsConfigurableByUser,
    },
    {
      label: "difficulty",
      active: isDifficultyConfigurable,
    },
    {
      label: "mode",
      active: isModeConfigurable,
    },
    {
      label: "summary",
      active: true,
    },
  ];

  const activeSteps = STEPS.filter((step) => step.active);

  const goBackHandler = () => {
    //CHECK IF MOBILE OR DESKTOP, TO GO BACK OR CHANGE STEP
    if (!isMobile || (isMobile && currentStep === 0)) {
      navigate(-1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const selectDifficultyHandler = (val: string | number | undefined) => {
    setSelectedDifficulty(val);
  };

  const schoolSubjectIds = () => {
    if (selectedSchoolSubjects.length === 0) {
      return [];
    } else if (
      selectedSchoolSubjects.length === 1 &&
      selectedSchoolSubjects[0].value === "ALL"
    ) {
      return quiz?.attributes?.schoolSubjects.list.map(
        (subject: SchoolSubject) => subject.id
      );
    } else {
      return selectedSchoolSubjects.map((subject) => subject.value);
    }
  };

  const generateQuizHandler = async () => {
    setIsLoading(true);
    try {
      const data = {
        data: {
          type: quiz.attributes?.type,
          difficulty: selectedDifficulty,
          time: selectedQuizTime?.value,
          group: selectedQuizMode?.value,
          questionsNumber: selectedQuizQuestionsNumber,
          schoolSubjects: schoolSubjectIds(),
          rightAnswerScore: quiz.attributes.score.rightAnswerScore,
          wrongAnswerScore: quiz.attributes.score.wrongAnswerScore,
          nullAnswerScore: quiz.attributes.score.nullAnswerScore,
          idConfig: quiz.id,
        },
      };
      const { data: generatedQuiz } = await generateQuiz(data);
      setGeneratedQuiz(generatedQuiz);
    } catch (e) {
      console.log({ e });
    } finally {
      setIsLoading(false);
    }
  };

  const onTimeEnd = () => {
    console.log({ generatedQuiz });
    // const editedGenertatetdQuiz = {
    //   quiz: {
    //     id: 382,
    //     type: "exercise",
    //     difficulty: 3,
    //     time: 30,
    //     questionsNumber: 15,
    //     group: "random",
    //     questions: undefined,
    //   },
    // };
    navigate("/quiz", {
      state: { generatedQuiz: generatedQuiz, config: quiz },
      replace: true,
    });
  };

  useEffect(() => {
    if (generatedQuiz) {
      setIsQuizReady(true);
    }
  }, [generatedQuiz]);

  useEffect(() => {
    if (!quiz) {
      navigate("/");
    }
  }, [quiz]);

  const onDismissHandler = () => {
    setOpen(false);
  };

  const isSimulation = quiz.attributes.type === "simulation";

  //REMOVE SUMMARY STEP
  const finalSteps = isSimulation ? 0 : activeSteps.length - 1;

  const BottomNavigationNextLabel = () => {
    if (
      (isSimulation && currentStep === 0) ||
      (!isSimulation && currentStep === finalSteps)
    ) {
      return "Comincia";
    } else {
      return "Avanti";
    }
  };

  const goNextHandler = () => {
    if (isSimulation && currentStep === 0) {
      generateQuizHandler();
    } else {
      if (currentStep < finalSteps) {
        console.log("HERE", currentStep);
        setCurrentStep(currentStep + 1);
      } else {
        generateQuizHandler();
      }
    }
  };

  useEffect(() => {
    if (isMobile) {
      scrollTo(0, 0);
    }
  }, [isMobile, currentStep]);

  useEffect(() => {
    if (selectedQuizTime) {
      let questionNumberSet = QUESTIONS_NUMBER.find(
        (questionNumber) =>
          Number(questionNumber.label) === selectedQuizTime.value
      );
      if (questionNumberSet) {
        setSelectedQuizQuestionsNumber(questionNumberSet.value);
        setQuestionSet(questionNumberSet);
      }
    }
  }, [selectedQuizTime]);

  const renderedHeaderTitle = isSimulation ? "Simulazione Classica" : "Esercitazione Componibile";

  return (
    <S.StyledLayout
      headerComponent={currentStep === 0 && <S.StyledMinimalHeader isSimulation={isSimulation} hasGoBackIcon={true} onIconClick={goBackHandler} title={renderedHeaderTitle} />}
      flexDirection="column"
    >
      {isQuizReady && (
        <Countdown
          exerciseType={quiz?.attributes?.type}
          onTimeEnd={onTimeEnd}
        />
      )}
      {isMobile && currentStep > 0 && (
        <S.StyledStepper
          steps={finalSteps}
          currentStep={currentStep}
          onStepChange={setCurrentStep}
          onCloseStepper={() => navigate(-1)}
        />
      )}
      {/* <Header onBack={goBackHandler} /> */}
      <S.StyledMain isSimulation={isSimulation}>
        <S.StyledSection>
          <ClassicOptions
            quiz={quiz}
            selectedDifficulty={selectedDifficulty}
            setSelectedDifficulty={selectDifficultyHandler}
            selectedQuizTime={selectedQuizTime}
            setSelectedQuizTime={setSelectedQuizTime}
            selectedQuizQuestionsNumber={selectedQuizQuestionsNumber}
            setSelectedQuizQuestionsNumber={setSelectedQuizQuestionsNumber}
            selectedQuizMode={selectedQuizMode}
            setSelectedQuizMode={setSelectedQuizMode}
            selectedSchoolSubjects={selectedSchoolSubjects}
            setSelectedSchoolSubjects={setSelectedSchoolSubjects}
            currentStep={currentStep}
            activeSteps={activeSteps}
            questionSet={questionSet}
            createQuiz={goNextHandler}
            isLoading={isLoading}

          />
        </S.StyledSection>
        {isMobile ? (
          <>
            <BottomSheet
              open={open}
              onDismiss={onDismissHandler}
              // snapPoints={({ maxHeight }) => [
              //   maxHeight * 0.5,
              //   maxHeight * 0.25,
              // ]}
            >
              <Recap
                quiz={quiz}
                selectedDifficulty={selectedDifficulty}
                selectedQuizTime={selectedQuizTime}
                selectedQuizQuestionsNumber={selectedQuizQuestionsNumber}
                selectedQuizMode={selectedQuizMode}
                selectedSchoolSubjects={selectedSchoolSubjects}
                onGenerateQuiz={generateQuizHandler}
                isLoading={isLoading}
                showGenerateButton={false}
              />
            </BottomSheet>
          </>
        ) : (
          <S.StyledSection>
            <Recap
              quiz={quiz}
              selectedDifficulty={selectedDifficulty}
              selectedQuizTime={selectedQuizTime}
              selectedQuizQuestionsNumber={selectedQuizQuestionsNumber}
              selectedQuizMode={selectedQuizMode}
              selectedSchoolSubjects={selectedSchoolSubjects}
              onGenerateQuiz={generateQuizHandler}
              isLoading={isLoading}
            />
          </S.StyledSection>
        )}
      </S.StyledMain>
      {isMobile && !isSimulation && currentStep !== 0 && (
        <BottomNavigation
          onBack={goBackHandler}
          onNext={goNextHandler}
          onOpenRecap={() => setOpen(true)}
          nextButtonLabel={BottomNavigationNextLabel()}
          isLoading={isLoading}
        />
      )}
    </S.StyledLayout>
  );
};

export default ConfigurationPage;
