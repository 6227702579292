import { FC, useEffect, useState } from "react";
import { CircularProgressBarProps } from "./types";

import * as S from "./styles";
import { Livel } from "../LaunchCard/types";

const CircularProgressBar: FC<CircularProgressBarProps> = ({
  value,
  icon,
  bg,
  label
}) => {
  const [progress, setProgress] = useState(0);

  const percentageLabel = (): Livel => {
    let label: Livel;
    if (value > 66) {
      label = "Ottimo!";
    } else if (value > 33 && value <= 66) {
      label = "Medio";
    } else {
      label = "Scarso";
    }
    return label;
  };

  useEffect(() => {
    setProgress((value / 100) * 100);
  }, [value]);

  const radius = 90;
  const cx = 100;
  const cy = 100;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <S.CircleContainer>
      <S.Svg width="200" height="200">
        <S.Circle
          stroke="#E9EDF0"
          cx={cx}
          cy={cy}
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={100}
        />
        <S.ProgressCircle
          stroke="#CFDBEA"
          cx={cx}
          cy={cy}
          r={radius}
          strokeDasharray={circumference - value}
          strokeDashoffset={offset}
          style={{ transition: "stroke-dashoffset 0.5s ease" }}
        />
      </S.Svg>
      <S.ProgressText>
        <S.StyledIcon name={icon} bg={bg} />
        <S.StyledHeading typo="headingXL" weight="semibold">
          {label}
        </S.StyledHeading>
        <S.StyledParagraph typo="desktopMD" weight="semibold">
          {percentageLabel()}
        </S.StyledParagraph>
      </S.ProgressText>
    </S.CircleContainer>
  );
};

export default CircularProgressBar;
