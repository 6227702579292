import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { StyledIcon } from "../Icon/styles";

interface ButtonProps {
  colorScheme: string; // Adding colorScheme to props
  variant: "primary" | "secondary" | "tertiary" | "quaternary";
  fullWidth?: boolean;
  disabled?: boolean;
  size?: "small" | "large";
}

export const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.rem(100)};
  transition: all 0.3s ease-in-out;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  padding: ${({ theme, size }) =>
    size === "small" ? theme.rem(4, 16) : theme.rem(12, 24)};
  font-size: ${({ theme }) => theme.rem(16)};

  ${({ theme, variant, colorScheme, disabled }) => css`
    color: ${theme.colorScheme[colorScheme].button[variant].textDefault};
    background-color: ${theme.colorScheme[colorScheme].button[variant]
      .bgDefault};
    border: 1px solid ${theme.colorScheme[colorScheme].button[variant].border};

    ${StyledIcon} {
      color: ${theme.colorScheme[colorScheme].button[variant].iconDefault};
      background-color: ${theme.colorScheme[colorScheme].button[variant]
        .bgIconDefault};
      transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
      width: ${({ theme }) => theme.rem(24)};
      height: ${({ theme }) => theme.rem(24)};
      border: 1px solid ${theme.colorScheme[colorScheme].button[variant].border};
      border-radius: 50%;
    }

    span {
      transition: transform 0.3s ease;
    }

    ${variant === "tertiary" &&
    !disabled &&
    css`
      justify-content: flex-start;

      span {
        transform: translateX(${({ theme }) => theme.rem(-12)});
      }

      &:hover span {
        transform: translateX(0);
      }
    `}

    &:hover {
      color: ${theme.colorScheme[colorScheme].button[variant].textHover};
      background-color: ${theme.colorScheme[colorScheme].button[variant]
        .bgHover};

      ${StyledIcon} {
        color: ${theme.colorScheme[colorScheme].button[variant].iconHover};
        background-color: ${theme.colorScheme[colorScheme].button[variant]
          .iconHoverBg};
      }
    }

    &:active {
      color: ${theme.colorScheme[colorScheme].button[variant].textPressed};
      background-color: ${theme.colorScheme[colorScheme].button[variant]
        .bgPressed};

      ${StyledIcon} {
        color: ${theme.colorScheme[colorScheme].button[variant].iconPressed};
        background-color: ${theme.colorScheme[colorScheme].button[variant]
          .iconPressedBg};
      }
    }

    &:focus-visible {
      color: ${theme.colorScheme[colorScheme].button[variant].textFocused};
      background-color: ${theme.colorScheme[colorScheme].button[variant]
        .bgFocused};
      outline: 2px solid
        ${theme.colorScheme[colorScheme].button[variant].strokeFocus};

      ${StyledIcon} {
        background-color: ${theme.colorScheme[colorScheme].button[variant]
          .iconFocusedBg};
      }
    }

    &:disabled {
      color: ${theme.colorScheme[colorScheme].button[variant].textDisabled};
      background-color: ${theme.colorScheme[colorScheme].button[variant]
        .bgDisabled};
      cursor: not-allowed;

      ${StyledIcon} {
        color: ${theme.colorScheme[colorScheme].button[variant].textDisabled};
        background: transparent;
        border: transparent;
      }
    }
  `}
`;

export const StyledLink = styled(Link)`
  a {
    width: 100%;
  }
`;
