import { QuestionStatus } from '@/components/organisms/Quiz/QuizBody'
import styled, { css } from 'styled-components'
import { TagStatusType } from './CardTag'

const getSizeStyles = (size: 'sm' | 'md' | 'lg' | undefined) => {
  switch (size) {
    case 'sm':
      return 'width: 32px; height: 32px;'
    case 'md':
      return 'width: 56px; height: 56px;'
    case 'lg':
      return 'width: 70px; height: 70px;'
    default:
      return 'width: 32px; height: 32px;'
  }
}

// Define the type for the color brands
export type ColorBrand =
  | 'warmGrey'
  | 'lightGrey'
  | 'oliveGreen'
  | 'neutralTeal'
  | 'warmYellow'
  | 'neutralBlack'
  | 'warmClay'
  | 'warmOrange'
  | 'darkGreen'
  | 'nightSky'

// Define the type for the props
interface StyledTagProps {
  colorScheme?: string
  colorBrand?: ColorBrand
  variant?: string
  label?: string
  size?: 'sm' | 'md' | 'lg' | undefined
  status?: QuestionStatus
}

// Function to get color from brand
const getColorFromBrand = (brand: ColorBrand): string => {
  const brandColorMap: { [key in ColorBrand]: string } = {
    warmGrey: 'black',
    lightGrey: 'black',
    oliveGreen: 'black',
    neutralTeal: 'black',
    warmYellow: 'black',
    neutralBlack: 'white',
    warmClay: 'white',
    warmOrange: 'white',
    darkGreen: 'white',
    nightSky: 'white',
  }

  return brandColorMap[brand] || 'white'
}

export const StyledTag = styled.div<StyledTagProps>`
  ${({ size }) => getSizeStyles(size || 'sm')};
  padding: ${({ theme }) => theme.rem(12)};
  // margin-right: ${({ theme }) => theme.rem(8)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) =>
    theme.colorScheme.white.quiz.radioButtonTextReplyDefault};

  ${({ theme, colorBrand, colorScheme }) =>
    !colorBrand &&
    colorScheme &&
    css`
      background-color: ${theme.colorScheme.white.quiz
        .radioButtonBgReplyDefault};
    `}

  ${({ theme, colorBrand }) =>
    colorBrand &&
    css`
      background-color: ${theme.colors.brands[`${colorBrand}`]};
      color: ${getColorFromBrand(colorBrand)};
    `}

${({ variant }) =>
    variant === 'round' &&
    css`
      border-radius: 50%;
    `}
`

interface EditorialCardTagProps {
  $variant: TagStatusType
  $isSmall?: boolean
}

export const StyledCardTag = styled.div<EditorialCardTagProps>`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: ${({ theme }) => theme.rem(8) + ' ' + theme.rem(16)};
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #cfdbea;
  border-radius: 0px 8px 0px 24px;
  z-index: 1;

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      padding: ${({ theme }) => theme.rem(4) + ' ' + theme.rem(24)};
      ${({ theme }) =>
        theme.mediaQuery.md(`
      padding: ${theme.rem(8)} ${theme.rem(32)};
    `)}
    `}

  ${({ $variant }) => {
    switch ($variant) {
      case 'success':
        return `
          background-color: #95C9B4;
        `
      case 'warning':
        return `
          background-color: #FDDFB6;
        `
      case 'danger':
        return `
          background-color: #FEA7A0;
        `
      default:
      case 'info':
        return `
          background-color: #CFDBEA;
        `
    }
  }}
`
