import { RootState } from "@/store";
import { useSelector } from "react-redux";

const useAuth = () => {
  const isAuthenticated = useSelector<RootState>((state) => state.auth.token);
  const user = useSelector<RootState>((state) => state.auth.user);

  const hasAccess = isAuthenticated

  return {
    hasAccess,
    user
  };
};

export default useAuth;
