import { Paragraph } from '../Typography'
import * as S from './styles'
interface BadgeProps {
  accentColor?: string
  badgeLabel?: string
  typo?: string
  weight?: string
  _style?: React.CSSProperties
}

const Badge = ({
  accentColor = '',
  badgeLabel = '',
  typo = 'paragraphXS',
  weight = 'semibold',
  _style = {},
}: BadgeProps) => {
  if (!badgeLabel) return null
  return (
    <S.StyledBadgeContainer
      id={'badge'}
      $backgroundColor={accentColor}
    >
      <Paragraph
        content={String(badgeLabel)}
        typo={typo}
        weight={weight}
        style={{
          whiteSpace: 'nowrap',
          ..._style,
        }}
      />
    </S.StyledBadgeContainer>
  )
}

export default Badge
