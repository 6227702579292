import { AnsweredQuestion } from "@/pages/QuizPage/interface";
import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  quizId: string | number | undefined;
  answeredQuestions: AnsweredQuestion[] | undefined;
  currentSchoolSubject: string | undefined;
  currentQuestionNumber: number | undefined;
}

const initialState: InitialState = {
  quizId: undefined,
  answeredQuestions: undefined,
  currentSchoolSubject: undefined,
  currentQuestionNumber: undefined,
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setQuizIdReducer(state, action) {
      state.quizId = action.payload;
    },
    setAnsweredQuestionsReducer(state, action) {
      state.answeredQuestions = action.payload;
    },
    setCurrentQuestionNumberReducer(state, action) {
      state.currentQuestionNumber = action.payload;
    },
    setCurrentSchoolSubjectReducer(state, action) {
      state.currentSchoolSubject = action.payload;
    },
    resetQuizSlice(state) {
      state.quizId = undefined;
      state.answeredQuestions = undefined;
      state.currentSchoolSubject = undefined;
      state.currentQuestionNumber = undefined;
    },
  },
});

export const {
  setQuizIdReducer,
  setAnsweredQuestionsReducer,
  setCurrentQuestionNumberReducer,
  setCurrentSchoolSubjectReducer,
  resetQuizSlice
} = quizSlice.actions;
export const quizReducer = quizSlice.reducer;
