import * as S from "./styles";
import { QuizValues } from "@/pages/QuizPage/interface";

import { useTranslation } from "react-i18next";
import ButtonV2 from "@/components/atoms/ButtonV2";

interface Props {
  quiz?: QuizValues;
  onAddAnswer: (goNext: boolean) => void;
  currentQuestionNumber?: number;
  nextButtonLabel: string;
  isFirstQuestion: boolean;
  onGoBack: () => void;
}

const BottomNavigation = ({
  onAddAnswer,
  isFirstQuestion,
  nextButtonLabel,
  onGoBack,
}: Props) => {
  const { t } = useTranslation();

  return (
    <S.StyledBottomNavigation>
      <ButtonV2
        label={t("go_back")}
        variant="secondary"
        iconStart="ArrowLeft"
        disabled={isFirstQuestion}
        handleClick={onGoBack}
      />
      <ButtonV2
        label={nextButtonLabel}
        variant="primary"
        iconEnd="ArrowRight"
        handleClick={() => onAddAnswer(true)}
      />
    </S.StyledBottomNavigation>
  );
};

export default BottomNavigation;
