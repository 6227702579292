import styled from "styled-components";
import { Material } from "../MaterialsTable";
import { Paragraph } from "@/components/atoms/Typography";
import Divider from "@/components/atoms/Divider";

interface StyledBodyItemInterface {
  hidden?: boolean;
  full?: boolean;
}

const subjectIconsBg: { [key: string]: string } = {
  "Competenze di lettura": "#DFD1EC",
  "Competenze di lettura e conoscenze acquisite negli studi": "#DFD1EC",
  Chimica: "#D2D9D6",
  "Fisica e matematica": "#FFF0D5",
  "Fisica e Matematica": "#FFF0D5",
  "Ragionamento logico e problemi": "#E7E7E7",
  Biologia: "#CEDEE7",
};

export const StyledAccordionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(12)};
`;

export const StyledIconContainer = styled.div<Partial<Material>>`
  min-width: ${({ theme }) => theme.rem(40)};
  height: ${({ theme }) => theme.rem(40)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ schoolSubject }) => subjectIconsBg[schoolSubject || ""]};
`;

export const StyledTitleParagraph = styled(Paragraph)``;

//Body Item
export const StyledBodyItem = styled.div<StyledBodyItemInterface>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(8)};
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visibile")};
  ${({ full }) => full && "width: 100%"};
`;


export const StyledBodyItemParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseTertiary};
`;

export const StyledBodyItemValue = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseTertiary};
`;

export const StyledHiddenDivider = styled(Divider)`
  visibility: hidden;
`;
