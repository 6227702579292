import { FC } from 'react'

import Polygon from '@/components/atoms/Icon/glyphs/Polygon.svg?react'
import ShapeCheck from '@/components/atoms/Icon/glyphs/Shape-Check.svg?react'
import ShapeCircle from '@/components/atoms/Icon/glyphs/Shape-Circle.svg?react'
import ShapeSquare from '@/components/atoms/Icon/glyphs/Shape-Square.svg?react'
import ShapeStar from '@/components/atoms/Icon/glyphs/Shape-Star.svg?react'
import ShapeTwoLines from '@/components/atoms/Icon/glyphs/Shape-Two-Lines.svg?react'
import ShapeX from '@/components/atoms/Icon/glyphs/Shape-X.svg?react'
import RadioAtom from '@/components/atoms/Icon/glyphs/_radio atom.svg?react'
import Alarm from '@/components/atoms/Icon/glyphs/alarm.svg?react'
import ArrowDownRight from '@/components/atoms/Icon/glyphs/arrow-down-right.svg?react'
import ArrowDown from '@/components/atoms/Icon/glyphs/arrow-down.svg?react'
import ArrowLeft from '@/components/atoms/Icon/glyphs/arrow-left.svg?react'
import ArrowRightTriangle from '@/components/atoms/Icon/glyphs/arrow-right-triangle.svg?react'
import ArrowRight from '@/components/atoms/Icon/glyphs/arrow-right.svg?react'
import ArrowUpRight from '@/components/atoms/Icon/glyphs/arrow-up-right.svg?react'
import AverageTime from '@/components/atoms/Icon/glyphs/average-time.svg?react'
import BarsTwo from '@/components/atoms/Icon/glyphs/bars-2.svg?react'
import Biologia from '@/components/atoms/Icon/glyphs/biologia.svg?react'
import BookOpen from '@/components/atoms/Icon/glyphs/book-open.svg?react'
import CheckCircle from '@/components/atoms/Icon/glyphs/check-circle.svg?react'
import Check from '@/components/atoms/Icon/glyphs/check.svg?react'
import ChevronDown from '@/components/atoms/Icon/glyphs/chevron-down.svg?react'
import ChevronLeft from '@/components/atoms/Icon/glyphs/chevron-left.svg?react'
import ChevronRight from '@/components/atoms/Icon/glyphs/chevron-right.svg?react'
import ChevronUp from '@/components/atoms/Icon/glyphs/chevron-up.svg?react'
import Chimica from '@/components/atoms/Icon/glyphs/chimica.svg?react'
import Cog6Tooth from '@/components/atoms/Icon/glyphs/cog-6-tooth.svg?react'
import Correction from '@/components/atoms/Icon/glyphs/correction.svg?react'
import CulturaGenerale from '@/components/atoms/Icon/glyphs/cultura-generale.svg?react'
import CursorArrowRays from '@/components/atoms/Icon/glyphs/cursor-arrow-rays.svg?react'
import EllipseError from '@/components/atoms/Icon/glyphs/ellipse-error.svg?react'
import EllipseSuccess from '@/components/atoms/Icon/glyphs/ellipse-success.svg?react'
import EllipseWarning from '@/components/atoms/Icon/glyphs/ellipse-warning.svg?react'
import Ellipse from '@/components/atoms/Icon/glyphs/ellipse.svg?react'
import EyeSlash from '@/components/atoms/Icon/glyphs/eye-slash.svg?react'
import Eye from '@/components/atoms/Icon/glyphs/eye.svg?react'
import Facebook from '@/components/atoms/Icon/glyphs/facebook.svg?react'
import FirstPage from '@/components/atoms/Icon/glyphs/first-page.svg?react'
import Fisica from '@/components/atoms/Icon/glyphs/fisica.svg?react'
import Home from '@/components/atoms/Icon/glyphs/home.svg?react'
import Info from '@/components/atoms/Icon/glyphs/info.svg?react'
import InformationCircle from '@/components/atoms/Icon/glyphs/information-circle.svg?react'
import Inventory from '@/components/atoms/Icon/glyphs/inventory.svg?react'
import LastPage from '@/components/atoms/Icon/glyphs/last-page.svg?react'
import LibraryBooks from '@/components/atoms/Icon/glyphs/library-books.svg?react'
import LifeBuoy from '@/components/atoms/Icon/glyphs/lifebuoy.svg?react'
import Linkedin from '@/components/atoms/Icon/glyphs/linkedin.svg?react'
import ListNumber from '@/components/atoms/Icon/glyphs/list-number.svg?react'
import List from '@/components/atoms/Icon/glyphs/list.svg?react'
import Logout from '@/components/atoms/Icon/glyphs/logout.svg?react'
import Matematica from '@/components/atoms/Icon/glyphs/matematica.svg?react'
import MinusSmall from '@/components/atoms/Icon/glyphs/minus-small.svg?react'
import MoreVertical from '@/components/atoms/Icon/glyphs/more-vertical.svg?react'
import NumericList from '@/components/atoms/Icon/glyphs/numeric-list.svg?react'
import Pencil from '@/components/atoms/Icon/glyphs/pencil.svg?react'
import QuestionMarkCircle from '@/components/atoms/Icon/glyphs/question-mark-circle.svg?react'
import QueueList from '@/components/atoms/Icon/glyphs/queue-list.svg?react'
import Quiz from '@/components/atoms/Icon/glyphs/quiz.svg?react'
import RadioButtonUnchecked from '@/components/atoms/Icon/glyphs/radio_button_unchecked.svg?react'
import RagionamentoLogico from '@/components/atoms/Icon/glyphs/ragionamento-logico.svg?react'
import Random from '@/components/atoms/Icon/glyphs/random.svg?react'
import Reddit from '@/components/atoms/Icon/glyphs/reddit.svg?react'
import Reset from '@/components/atoms/Icon/glyphs/reset.svg?react'
import Score from '@/components/atoms/Icon/glyphs/score.svg?react'
import Seen from '@/components/atoms/Icon/glyphs/seen.svg?react'
import Settings from '@/components/atoms/Icon/glyphs/settings.svg?react'
import SkippedAnswer from '@/components/atoms/Icon/glyphs/skipped-answer.svg?react'
import Subject from '@/components/atoms/Icon/glyphs/subject.svg?react'
import Support from '@/components/atoms/Icon/glyphs/support.svg?react'
import TimeCardCircle from '@/components/atoms/Icon/glyphs/timecard.svg?react'
import Twitter from '@/components/atoms/Icon/glyphs/twitter.svg?react'
import TwoStars from '@/components/atoms/Icon/glyphs/two-stars.svg?react'
import User from '@/components/atoms/Icon/glyphs/user.svg?react'
import VideoCamera from '@/components/atoms/Icon/glyphs/video-camera.svg?react'
import XMark from '@/components/atoms/Icon/glyphs/x-mark.svg?react'
import ArrowUp from '@/components/atoms/Icon/glyphs/arrow-up.svg?react'
import ResultBad from '@/components/atoms/Icon/glyphs/result-bad.svg?react'
import ResultGood from '@/components/atoms/Icon/glyphs/result-good.svg?react'

//react-icons
import { LiaSpinnerSolid } from 'react-icons/lia'
import { TbLogout2 } from 'react-icons/tb'

import { StyledIcon, StyledIconChildrenWrapper } from './styles'

interface IconProps {
  name?: string
  ariaLabel?: string
  spinner?: boolean
  [key: string]: any
}

const iconMap: {
  [key: string]: React.ComponentType<any> | (() => JSX.Element)
} = {
  ArrowDown,
  ArrowUp,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  BookOpen,
  CheckCircle,
  RadioAtom,
  RadioButtonUnchecked,
  Check,
  MinusSmall,
  XMark,
  Eye,
  EyeSlash,
  ArrowLeft,
  ArrowRight,
  Polygon,
  Subject,
  Random,
  Home,
  CursorArrowRays,
  Pencil,
  VideoCamera,
  LifeBuoy,
  QuestionMarkCircle,
  Cog6Tooth,
  Ellipse,
  EllipseSuccess,
  EllipseWarning,
  EllipseError,
  Facebook,
  Twitter,
  Linkedin,
  Reddit,
  User,
  QueueList,
  InformationCircle,
  List,
  MoreVertical,
  Settings,
  Support,
  Logout,
  Inventory,
  Quiz,
  BarsTwo,
  ArrowUpRight,
  ArrowDownRight,
  CulturaGenerale,
  Chimica,
  Fisica,
  RagionamentoLogico,
  Biologia,
  Matematica,
  Alarm,
  Seen,
  LiaSpinnerSolid,
  TbLogout2,
  TimeCardCircle,
  ArrowRightTriangle,
  Score,
  ListNumber,
  LibraryBooks,
  LastPage,
  FirstPage,
  Info,
  SkippedAnswer,
  Correction,
  Reset,
  ShapeCircle,
  ShapeSquare,
  ShapeX,
  ShapeStar,
  ShapeTwoLines,
  ShapeCheck,
  NumericList,
  AverageTime,
  TwoStars,
  ResultBad,
  ResultGood,
}

const Icon: FC<IconProps> = ({
  name = 'arrow-right',
  ariaLabel,
  spinner,
  children,
  hoverColor,
  ...props
}) => {
  const renderedName = spinner ? 'LiaSpinnerSolid' : name
  const SvgIcon = iconMap[renderedName] || (() => <div>Icon not found</div>)

  // const label = ariaLabel || name.replace(/-/g, " ");

  return (
    <>
      {SvgIcon && (
        <StyledIcon
          {...props}
          aria-label={ariaLabel}
          spinner={spinner}
          $hoverColor={hoverColor || props.color}
          role='img'
        >
          <SvgIcon
            aria-label={ariaLabel}
            role='img'
          />
          <StyledIconChildrenWrapper>{children}</StyledIconChildrenWrapper>
        </StyledIcon>
      )}
    </>
  )
}

export default Icon
