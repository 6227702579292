import Skeleton from "@/components/atoms/Skeleton";

import * as S from "./styles";

import { InlineCardInterface } from "./types";

const CardLoading = ({ isLoading, ...props }: Partial<InlineCardInterface>) => {
  return (
    <S.StyledCard isLoading={isLoading} {...props}>
      <S.StyledCardContent>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </S.StyledCardContent>
      <S.StyledCardImageContainer>
        <Skeleton />
      </S.StyledCardImageContainer>
    </S.StyledCard>
  );
};

export default CardLoading;