import { Heading, Paragraph } from "@/components/atoms/Typography";
import styled from "styled-components";

export const StyledClassicOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.rem(12)};
  background: ${({ theme }) => theme.colorScheme.white.surface.default};
  border-radius: ${({ theme }) => theme.rem(8)};
  padding: ${({ theme }) => theme.rem(12, 16, 12)};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(40, 32, 24, 24)};
    `)}
`;

export const StyledImageContainer = styled.div`
  width: 200px;
  height: 200px;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StlyedTitle = styled(Heading)`
  ${({ theme }) => theme.typo("displaySM")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
  justify-content: center;
  align-items: center;
`;

export const StyledContentHeading = styled(Heading)`
  text-align: center;
  ${({ theme }) => theme.typo("headingMD")};
  font-weight: 600;
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
`;

export const StyledContentParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphMD")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
`;

export const StyledCTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: ${({ theme }) => theme.rem(24)};
  padding-bottom: ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
  text-align: left;

`)}
`;

export const StlyedCTAContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(4)};
`;

export const StyledCTATitle = styled(Heading)`
  ${({ theme }) => theme.typo("headingXS")};
  font-weight: 600;
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
`;

export const StyledCTAParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphSM")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
`;

export const StyledWarning = styled.div`
  color: #fe7e39;
  display: flex;
  align-items: center;
  // text-align: center;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.rem(16)};
  transition: all 0.3s ease-in-out;
`;

export const StyledWarningParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphMD")};
  text-align: center;
`;
