import * as S from "./styles";
import ButtonV2 from "@/components/atoms/ButtonV2";

interface SubjectsChartContentProps {
  onClick?: () => void;
}

const SubjectsChartContent = ({ onClick }: SubjectsChartContentProps) => {
  return (
    <S.StlyedSubjectChartContentContainer>
      <S.StyledSubjectsChartContent>
        <S.StyledShowMoreContainer>
          <S.StyledSubjectsChartParagraph>
            Rafforza la tua preparazione
          </S.StyledSubjectsChartParagraph>
          <S.StyledSubjectsChartButtonContainer>
            <ButtonV2 variant="tertiary" handleClick={onClick}>
              Dettagli
            </ButtonV2>
          </S.StyledSubjectsChartButtonContainer>
        </S.StyledShowMoreContainer>
        <S.StyledSubjectsChartHeading>
          Esercitati sulle materie del test
        </S.StyledSubjectsChartHeading>
        <S.StyledSubjectsChartDescription>
          Mettiti alla prova con esercitazioni rapide per materia e scopri il
          tuo livello di preparazione. Il grafico mostra il grado di
          apprendimento e su quali materie concentrarti.
        </S.StyledSubjectsChartDescription>
      </S.StyledSubjectsChartContent>
    </S.StlyedSubjectChartContentContainer>
  );
};

export default SubjectsChartContent;
