import { FC } from "react";
import { TableProps } from "./types";

import * as S from './styles'

const Table: FC<TableProps> = ({ columns, data }) => {
  return (
    <S.TableContainer>
      <thead>
        <S.TableRow>
          {columns.map((column, _) => (
            <S.TableHeader
              key={column.dataIndex}
              align={column.align}
              bold={column.bold}
            >
              {column.title}
            </S.TableHeader>
          ))}
        </S.TableRow>
      </thead>
      <tbody>
        {data.map((item, rowIndex) => (
          <S.TableRow key={rowIndex}>
            {columns.map((column, colIndex) => (
              <S.TableCell
                key={`${rowIndex}-${colIndex}`}
                align={column.align}
                bold={column.bold}
              >
                {column.render
                  ? column.render(item[column.dataIndex], item, rowIndex)
                  : item[column.dataIndex]}
              </S.TableCell>
            ))}
          </S.TableRow>
        ))}
      </tbody>
    </S.TableContainer>
  );
};

export default Table;
