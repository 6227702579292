import { Heading, Paragraph } from "@/components/atoms/Typography";
import styled, { css } from "styled-components";
import { InlineCardInterface } from "./types";
import Button from "@/components/atoms/Button";

export const StyledLineSVG = styled.div`
  position: absolute;
  top: -30px;
  left: -50px;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  svg path {
    stroke-width: 1px;
    stroke: var(--icon-base-tertiary, #4f4f4f);
    opacity: 0.25;
  }
`;

export const StyledCard = styled.div<Partial<InlineCardInterface>>`
  position: relative;
  overflow: hidden;
  display: flex;
  ${({ vertical }) =>
    vertical &&
    css`
      flex-direction: column-reverse;
    `}

  background-color: #fff;
  border-radius: ${({ theme }) => theme.rem(16)};
  gap: ${({ theme }) => theme.rem(8)};
  // cursor: pointer;
  transition: all 0.3s ease-in-out;
  cursor: ${({ isLoading }) => (isLoading ? "not-allowed" : "pointer")};

  ${({ theme, vertical }) =>
    theme.mediaQuery.lg(`
  min-height: 285px;
  ${
    vertical &&
    css`
      flex-direction: row;
    `
  }

  `)}

  &:hover {
    background-color: ${({ isLoading }) => (isLoading ? "#fff" : "#ffcdb2")};
    // background-color: #ffcdb2;

    ${StyledLineSVG} {
      transform: scale(2) translateY(50px);
    }
  }
`;

export const StyledCardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding: ${({ theme }) => theme.rem(24, 16)};
  gap: 16px;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(24)}};
  `)}
`;

export const StyledCardTitle = styled(Heading)`
  ${({ theme }) => theme.typo("headingSM")};
  font-weight: 600;
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
`;

export const StyledCardDescription = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphSM")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
`;

export const StyledCardImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
  justify-content: center;
  align-items: center;
  flex: 1;
  align-self: stretch;
`;

export const StyledCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledButton = styled(Button)`
  // margin-top: auto;
  width: fit-content;
`;
