import styled from "styled-components";
import { motion } from "framer-motion";
import Icon from "@/components/atoms/Icon";
import { ModalProps } from "./types";
export const StlyedOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

export const StyledModalContainer = styled(motion.div)<Partial<ModalProps>>`
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 697px) {
    width: ${({isFullScreen}) => isFullScreen ? '100%' : '90%'};
  }
`;

export const StyledModalBody = styled.div<Partial<ModalProps>>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
  background-color: #F6F6F6;
  border-radius: 16px;
  padding: ${({ theme, isFullScreen }) => isFullScreen ? theme.rem(0) : theme.rem(32, 16)};
  @media (max-width: 679px) {
    width: ${({ isFullScreen }) => (isFullScreen ? "100vw" : "90%")};
    height: ${({ isFullScreen }) => (isFullScreen ? "100dvh" : "auto")};
    margin: ${({ isFullScreen }) => (isFullScreen ? "0" : "auto")};
    border-radius: ${({ isFullScreen }) => (isFullScreen ? "0" : "16px")};
  }
`;

export const StyledCloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const StyledCloseIcon = styled(Icon)`
  cursor: pointer;
`;
