import { Heading, Paragraph } from "@/components/atoms/Typography";
import styled from "styled-components";

export const StlyedTextSerif = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(12)};
`;

export const StyledTag = styled.div`
  display: flex;
  width: fit-content;
  padding: ${({ theme }) => theme.rem(4, 32)};
  border-radius: ${({ theme }) => theme.rem(24)};
  border: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
    background-color: #D2D9D6;
`;

export const StyledTagTitle = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  font-weight: 600;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledTitle = styled(Heading)`
  ${({ theme }) => theme.typo("displaySM")};
  font-weight: 450;
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  max-width: 100%;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;`;

export const StyledDescription = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphLG")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
`;
