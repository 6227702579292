import Badge from '@/components/atoms/Badge'
import Icon from '@/components/atoms/Icon'
import CardTag, { TagStatusType } from '@/components/atoms/Tag/CardTag'
import { Heading, Paragraph } from '@/components/atoms/Typography'
import { ShapeType } from './ExerciseCard'
import * as S from './styles'
import { SchoolSubject } from '@/pages/QuizPage/interface'

export interface MixedExerciseCardProps {
  prefix?: {
    content: string
    difficulty: 'easy' | 'medium' | 'hard'
    isTextOnly?: boolean
  }
  title: string
  description?: SchoolSubject[]
  variant?: 'horizontal' | 'vertical'
  questionCount?: number
  questionTime?: number
  label?: string
  backgroundFillerImage?: ShapeType
  tag?: {
    label: string
    status: TagStatusType
  }
  image?: string
  colorAccent?: string
  colorVariant?:
    | 'brown'
    | 'green'
    | 'orange'
    | 'blu'
    | 'gray'
    | 'violet'
    | 'red'
    | 'yellow'
  handleClick?: () => void
}

const MixedExerciseCard = ({
  title = '',
  prefix = {
    content: '',
    difficulty: 'easy',
    isTextOnly: false,
  },
  variant = 'vertical',
  questionCount = 0,
  questionTime = 0,
  description = [],
  backgroundFillerImage = 'ShapeCircle',
  tag: { label: tagLabel = '', status: tagStatus = 'info' } = {
    label: '',
    status: 'info',
  },
  image,
  colorVariant = 'orange',
  handleClick = () => {},
}: MixedExerciseCardProps) => {
  const hasPrefix =
    prefix && Object.keys(prefix).length > 0 && prefix.content?.length > 0
  const hasTitle = title && title.length > 0
  const hasQuestionCount = questionCount && questionCount > 0
  const hasQuestionTime = questionTime && questionTime > 0
  const hasDescription = description && description.length > 0
  const hasTag = tagLabel && typeof tagLabel === 'string' && tagLabel.length > 0
  const hasImage = image && image.length > 0

  const backgroundImageContainerColor =
    variantColorMap[colorVariant].default.backgroundColor
  const backgroundImageContainerHoverColor =
    variantColorMap[colorVariant].hover.backgroundColor
  const shapeColor = variantColorMap[colorVariant].default.shape
  const shapeHoverColor = variantColorMap[colorVariant].hover.shape
  const colorAccent = variantColorMap[colorVariant].default.accent
  const colorHoverAccent = variantColorMap[colorVariant].hover.accent

  const getFormattedDescription = (_description: SchoolSubject[]) => {
    if (Array.isArray(_description)) {
      return _description.join('&nbsp;•&nbsp;')
    }
    return _description
  }

  return (
    <S.MixedExerciseCardContainer
      $variant={variant}
      $hoverIconColor={shapeHoverColor}
      $hoverAccentColor={colorHoverAccent}
      $hoverBackgroundColor={backgroundImageContainerHoverColor}
      onClick={handleClick}
    >
      <S.MixedExerciseCardImageContainer
        id={'card-image-container'}
        $backgroundColor={backgroundImageContainerColor}
        $backgroundColorHover={backgroundImageContainerHoverColor}
        $variant={variant}
      >
        {hasTag ? (
          <CardTag
            label={tagLabel}
            status={tagStatus}
            small
          />
        ) : null}
        <Icon
          name={backgroundFillerImage}
          color={shapeColor}
          hoverColor={shapeHoverColor}
          style={{
            fontSize: variant === 'horizontal' ? '8rem' : '9rem',
          }}
        />
        {hasImage ? (
          <S.MixedExerciseCardImage
            src={image}
            alt={title}
          />
        ) : null}
      </S.MixedExerciseCardImageContainer>
      <S.MixedExerciseCardTextContainer>
        {hasPrefix ? (
          <Paragraph
            content={prefix.content}
            typo='label'
            weight='bold'
            style={{
              color: prefixColorMap[prefix.difficulty].textColor ?? '#404853',
              textTransform: 'uppercase',
              letterSpacing: '2px',
            }}
          />
        ) : null}
        {hasTitle ? (
          <Heading
            content={title}
            typo='paragraphLG'
            weight='semibold'
          />
        ) : null}
        {hasDescription ? (
          <Paragraph
            content={getFormattedDescription(description)}
            typo='paragraphMD'
            weight='semibold'
            style={{
              color: '#404853',
            }}
          />
        ) : null}
        {hasQuestionCount || hasQuestionTime ? (
          <S.MixedExerciseCardInfoContainer>
            {hasQuestionTime ? (
              <Badge
                accentColor={colorAccent}
                badgeLabel={`${questionTime} minuti`}
              />
            ) : null}
            {hasQuestionCount ? (
              <Badge
                accentColor={colorAccent}
                badgeLabel={`${questionCount} domande`}
              />
            ) : null}
          </S.MixedExerciseCardInfoContainer>
        ) : null}
      </S.MixedExerciseCardTextContainer>
    </S.MixedExerciseCardContainer>
  )
}

const prefixColorMap = {
  easy: {
    backgroundColor: '#F5EFEB',
    textColor: '#95C9B4',
  },
  medium: {
    backgroundColor: '#D2D9D6',
    textColor: '#FA9F3D',
  },
  hard: {
    backgroundColor: '#FFF5ED',
    textColor: '#FF7474',
  },
}

const variantColorMap: any = {
  brown: {
    default: {
      accent: '#FAF7F6',
      shape: '#EEE1DA',
      backgroundColor: '#F5EFEB',
    },
    hover: {
      accent: '#EEE1DA',
      shape: '#F5EFEB',
      backgroundColor: '#EEE1DA',
    },
  },
  green: {
    default: {
      accent: '#D2D9D6',
      shape: '#B0BAB5',
      backgroundColor: '#D2D9D6',
    },
    hover: {
      accent: '#B0BAB5',
      shape: '#D2D9D6',
      backgroundColor: '#B0BAB5',
    },
  },
  orange: {
    default: {
      accent: '#FFF5ED',
      shape: '#FFE9D4',
      backgroundColor: '#FFF5ED',
    },
    hover: {
      accent: '#FFC091',
      shape: '#FFF5ED',
      backgroundColor: '#FFE9D4',
    },
  },
  blu: {
    default: {
      accent: '#CEDEE7',
      shape: '#CEDEE7',
      backgroundColor: '#F2FAFE',
    },
    hover: {
      accent: '#A7C3D2',
      shape: '#F2FAFE',
      backgroundColor: '#CEDEE7',
    },
  },
  violet: {
    default: {
      accent: '#FDF5FF',
      shape: '#DFD1EC',
      backgroundColor: '#FDF5FF',
    },
    hover: {
      accent: '#DFD1EC',
      shape: '#FDF5FF',
      backgroundColor: '#DFD1EC',
    },
  },
  gray: {
    default: {
      accent: '#E7E7E7',
      shape: '#E7E7E7',
      backgroundColor: '#FFFFFF',
    },
    hover: {
      accent: '#D1D1D1',
      shape: '#F6F6F6',
      backgroundColor: '#E7E7E7',
    },
  },
  red: {
    default: {
      accent: '#FFEBEB',
      shape: '#FFDBDB',
      backgroundColor: '#FFEBEB',
    },
    hover: {
      accent: '#FFDBDB',
      backgroundColor: '#FFA4A4',
      shape: '#FFC9C9',
    },
  },
  yellow: {
    default: {
      accent: '#FFF0D5',
      shape: '#FDDDAB',
      backgroundColor: '#FFF8ED',
    },
    hover: {
      accent: '#FDDDAB',
      shape: '#FFF0D5',
      backgroundColor: '#FDDDAB',
    },
  },
}

export default MixedExerciseCard
