import styled, { css } from "styled-components";
import gridConfig from '@theme/grid-config'


// Define the types for your custom props
interface ColProps {
  xs?: string | number;
  sm?: string | number;
  md?: string | number;
  lg?: string | number;
  xl?: string | number;
  xxl?: string | number;
  $direction?: string | Record<string, string>;
  align?: string | Record<string, string>;
  justify?: string | Record<string, string>;
  $order?: number | Record<string, number>;
  $offset?: number | Record<string, number>;
  offsetRight?: number | Record<string, number>;
  reverse?: boolean | string[];
  noGutter?: boolean;
  debug?: boolean;
}

const Col = styled.div<ColProps>`
  max-width: 100%;
  display: flex;

  ${({ theme, $direction }) => {
    if ($direction) {
      return typeof $direction === "object"
        ? Object.entries(theme.breakpoints).map(
            ([dim]) =>
              $direction[dim] &&
              theme.mediaQuery[dim](`
            flex-direction: ${$direction[dim]};
          `)
          )
        : `flex-direction: ${$direction};`;
    }
    return "flex-direction: column;";
  }};

  flex: 1 0 0%;
  box-sizing: border-box;

  ${({ theme, ...p }) =>
    Object.entries(theme.breakpoints).map(
      ([dim]) =>
        p[dim as keyof typeof p] &&
        theme.mediaQuery[dim as keyof typeof theme.breakpoints](`
    flex: 1 1 ${(p[dim as keyof typeof p] / gridConfig({ theme }).columns[dim]) * 100}%;
    max-width: ${(p[dim as keyof typeof p] / gridConfig({ theme }).columns[dim]) * 100}%;
  `)
    )}

  ${({ theme, align }) =>
    align &&
    (typeof align === "object"
      ? Object.entries(theme.breakpoints).map(
          ([dim]) =>
            align[dim] &&
            theme.mediaQuery[dim](`
          align-items: ${align[dim]};
        `)
        )
      : `align-items: ${align};`)}

  ${({ theme, justify }) =>
    justify &&
    (typeof justify === "object"
      ? Object.entries(theme.breakpoints).map(
          ([dim]) =>
            justify[dim] &&
            theme.mediaQuery[dim](`
          justify-content: ${justify[dim]};
        `)
        )
      : `justify-content: ${justify};`)}

  ${({ theme, $order }) =>
    $order &&
    (typeof $order === "object"
      ? Object.entries(theme.breakpoints).map(
          ([dim]) =>
            $order[dim] &&
            theme.mediaQuery[dim](`
          order: ${$order[dim]};
        `)
        )
      : `order: ${$order};`)}

  ${({ theme, reverse }) =>
    reverse &&
    (Array.isArray(reverse)
      ? Object.entries(theme.breakpoints).map(([dim]) =>
          theme.mediaQuery[dim](`
          flex-direction: ${
            reverse.indexOf(dim) !== -1 ? "column-reverse" : "column"
          };
        `)
        )
      : "flex-direction: column-reverse;")}

  ${({ theme, noGutter }) =>
    !noGutter &&
    Object.entries(theme.breakpoints).map(([dim]) =>
      theme.mediaQuery[dim](`
    padding-left: ${theme.rem(gridConfig({ theme }).gutterWidth[dim] / 2)};
    padding-right: ${theme.rem(gridConfig({ theme }).gutterWidth[dim] / 2)};
  `)
    )}

  ${({ theme, $offset }) =>
    $offset &&
    Object.entries(theme.breakpoints).map(([dim]) =>
      theme.mediaQuery[dim](
        typeof $offset === "object"
          ? $offset[dim] &&
              `margin-left: ${
                ($offset[dim] / gridConfig({ theme }).columns[dim]) * 100
              }%;`
          : `margin-left: ${
              ($offset / gridConfig({ theme }).columns[dim]) * 100
            }%;`
      )
    )}

  ${({ theme, offsetRight }) =>
    offsetRight &&
    Object.entries(theme.breakpoints).map(([dim]) =>
      theme.mediaQuery[dim](
        typeof offsetRight === "object"
          ? offsetRight[dim] &&
              `margin-right: ${
                (offsetRight[dim] / gridConfig({ theme }).columns[dim]) * 100
              }%;`
          : `margin-right: ${
              (offsetRight / gridConfig({ theme }).columns[dim]) * 100
            }%;`
      )
    )}
  
  ${({ debug }) =>
    debug &&
    css`
      background-color: #e1e9f0;
      box-shadow: hsla(203, 50%, 30%, 0.15) 0px 0px 0px 1px inset;
    `}
`;

export default Col;
