import axios from "../../request";
import {
  EndQuizInterface,
  GenerateQuizInterface,
  putAverageAnswerTimeInterface,
  StartQuizInterface,
  suspendQuizInterface,
} from "./types";

export const getQuizConfig = async () => {
  return axios.get("v1/quizzes/config");
};

export const generateQuiz = async (data: GenerateQuizInterface) => {
  return axios.post("v1/quizzes/generate", data);
};

export const startQuiz = async (data: StartQuizInterface) => {
  return axios.put("v1/quizzes/start", data);
};

export const endQuiz = async (data: EndQuizInterface) => {
  return axios.put("v1/quizzes/end", data);
};

export const getQuizResult = async (id: string | number) => {
  return axios.get(`v1/quizzes/${id}/result`);
};

export const suspendQuiz = async (data: suspendQuizInterface) => {
  return axios.put("v1/quizzes/suspend", data);
};

export const getQuizzesStatistics = async () => {
  return axios.get('v1/quizzes/me/subjects-statistics');
}

export const getPerformanceStatistics = async () => {
  return axios.get('v1/quizzes/me/weekly-statistics');
};

export const putAverageAnswerTime = async (
  id: number,
  data: putAverageAnswerTimeInterface
) => {
  return axios.put(`v1/questions/${id}/average-answer`, data);
};

export const getAverageTimeSubjectStatistics = async () => {
  return axios.get('v1/quizzes/me/average-time-subject-statistics');
}
