import styled from "styled-components";
import { InfoMessageProps, InfoMessageType } from "./types";
import { Paragraph } from "../Typography";

const getBackgroundColor = (type: InfoMessageType | undefined) => {
  switch (type) {
    case "neutral":
      return "#F6F6F6";
    case "negative":
      return "#FFF6F6";
    case "positive":
      return "#F0FBF3";
    case "default":
    default:
      return "#FFF";
  }
};

//Icon Background
const getIconBackgroundColor = (type: InfoMessageType | undefined) => {
  switch (type) {
    case "neutral":
      return "#E7E7E7";
    case "negative":
      return "#FF7474";
    case "positive":
      return "#3DC66B";
    case "default":
    default:
      return "#FFF";
  }
};

//Icon Color
const getIconColor = (type: InfoMessageType | undefined) => {
  switch (type) {
    case "neutral":
      return "#2D3035";
    case "negative":
      return "#FFF";
    case "positive":
      return "#FFF";
    case "default":
    default:
      return "#2D3035";
  }
};

export const StyledInfoMessage = styled.div<Partial<InfoMessageProps>>`
  background-color: ${({ hasBackground, type }) =>
    hasBackground ? getBackgroundColor(type) : "transparent"};
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.rem(4)};
  width: 100%;
`;

export const StyledParagraphLabel = styled.p`
  text-align: center;
  ${({ theme }) => theme.typo("paragraphSM")};
  font-weight: 600;
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseTertiary};

  span {
    font-weight: 600;
    color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  }
`;

export const StyledParagraphChildren = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
`;

export const StyledIconContainer = styled.div<Partial<InfoMessageProps>>`
  background-color: ${({ type }) => getIconBackgroundColor(type)};
  color: ${({ type }) => getIconColor(type)};
  padding: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
