import { Heading, Paragraph } from "@/components/atoms/Typography";
import styled from "styled-components";
import { Props } from ".";

interface SingleContentProps {
  flexDirection?: "row" | "column";
}


export const RecapContainer = styled.div<Partial<Props>>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(18)};
  padding: ${({ theme, isSummary }) => !isSummary && theme.rem(32)};
  background: ${({ theme, isSummary }) => !isSummary && theme.colorScheme.white.surface.default};
  border-radius: ${({ theme }) => theme.rem(8)};
  // border-top: ${({ isSummary }) => !isSummary && "6px solid #fe7e39"};
  position: sticky;
  top: ${({ theme }) => theme.rem(40)};
`;

export const RecapHeading = styled(Heading)`
  ${({ theme }) => theme.typo("headingSM")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  font-weight: 600;
`;

export const StyleSingleContent = styled.div<SingleContentProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ flexDirection }) =>
    flexDirection === "column" &&
    `flex-direction: ${flexDirection}; justify-content: flex-start; align-items: flex-start; gap: 8px`}
`;

export const RecapInfoHeading = styled(Heading)`
  ${({ theme }) => theme.typo("headingXS")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  font-weight: 600;
`;

export const RecapInfoParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphMD")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  font-weight: 600;
`;

export const RecapInfoSmallParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphSM")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseTertiary};
`;
