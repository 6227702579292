import { FC, useEffect, useRef, useState } from "react";
import { BaseLayoutProps } from "./types";

import * as S from "./styles";
import { getUserInfo } from "@/api/services/auth";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "@/store/slices/authSlice";
import { RootState } from "@/store";
import useIsMobile from "@/hooks/useIsMobile";

const BaseLayout: FC<BaseLayoutProps> = ({
  headerComponent,
  children,
  flexDirection = "column",
  isHeaderSticky = false,
  ...props
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const token = useSelector<RootState>((state) => state.auth.token);
  const [showNavbar, setShowNavbar] = useState(true);
  const lastScrollTop = useRef(0);
  const isAuthenticated = useSelector<RootState>((state) => state.auth.token);


  const getUserInfoHandler = async () => {
    try {
      const { data } = await getUserInfo();
      if (data) {
        dispatch(setUser(data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getUserInfoHandler();
    }
  }, [token]);

  useEffect(() => {
    scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > lastScrollTop.current) {
      // Scroll down
      setShowNavbar(false);
    } else if (scrollTop < lastScrollTop.current) {
      // Scroll up
      setShowNavbar(true);
    }
    lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
  };

  useEffect(() => {
    // if (isMobile) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    // }
  }, [isMobile]);

  const loginHandler = () => {
    window.location.href = `${import.meta.env.VITE_API_URL}/login`;
  };

  useEffect(() => {
    if (!isAuthenticated) {
      loginHandler();
    }
  }, [isAuthenticated]);

  return (
    <S.StyledContainer flexDirection={flexDirection}>
      {headerComponent && (
        <S.StyledHeader
          isHeaderSticky={isHeaderSticky}
          style={
            isHeaderSticky
              ? {
                  transform: showNavbar ? "translateY(0)" : "translateY(-100%)",
                  transition: "transform 0.3s ease-in-out",
                }
              : {}
          }
        >
          {headerComponent}
        </S.StyledHeader>
      )}
      <S.StyledMainContainer {...props}>{children}</S.StyledMainContainer>
    </S.StyledContainer>
  );
};

export default BaseLayout;
