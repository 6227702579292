interface Statistic {
  exercise: number;
  simulation: number;
  date: string;
}

interface Data {
  weeklyStatistics?: {
    verticals: {
      statistics: Statistic[];
    }[];
  };
}

interface Filter {
  type: string;
  label: string;
}

export interface NormalizedPerformanceStatistics {
  statistics: Statistic[];
  simulationsStatistics: any[];
  exercisesStatistics: any[];
  filters: Filter[];
}

const normalizePerformanceStatistics = (
  data: Data | undefined
): NormalizedPerformanceStatistics | undefined => {
  if (!data?.weeklyStatistics) {
    return;
  }

  const baseStatistics = data?.weeklyStatistics?.verticals[0]?.statistics;


  const statistics = baseStatistics.map((stat) => ({
    date: stat.date,
    exercise: stat.exercise,
    simulation: stat.simulation,
  }));

  const simulationsStatistics = baseStatistics.map((stat) => ({
    date: stat.date,
    exercise: 0,
    simulation: stat.simulation,
  }));

  const exercisesStatistics = baseStatistics.map((stat) => ({
    date: stat.date,
    simulation: 0,
    exercise: stat.exercise,
  }));

  return {
    statistics,
    simulationsStatistics,
    exercisesStatistics,
    filters: [
      { type: "all", label: "Tutti" },
      { type: "simulation", label: "Simulazioni" },
      { type: "exercise", label: "Esercitazioni" },
    ],
  };
};

export default normalizePerformanceStatistics;
