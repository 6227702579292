import * as S from "./styles";
import { Label } from "../Typography";
import { RadioButtonProps } from "./types";
import Tag from "../Tag";
import { IndexToLetter } from "@/utils/index-to-letter";

export const RadioButton = ({
  id,
  name,
  checked = false,
  onChange,
  label,
  value,
  index,
  disabled,
  status,
  onClick,
}: RadioButtonProps) => {
  const tagLetter = IndexToLetter(index) || "";
  return (
    <S.RadioButtonContainer questionStatus={status}>
      <S.HiddenRadioButton
        id={String(id)}
        name={String(name)}
        checked={checked}
        onChange={onChange}
        onClick={() => {
          if (onClick && typeof onClick === "function") {
            onClick(value);
          }
        }}
        value={value}
        disabled={disabled}
      />
      <S.StyledCheckbox checked={checked}>
        <S.StyledRadioButtonIcon
          name={checked ? "RadioAtom" : "RadioButtonUnchecked"}
          checked={checked}
          size="default"
        />
      </S.StyledCheckbox>
      <Tag label={tagLetter} variant="round" />
      <S.RadioButtonLabel as={Label} htmlFor={id}>
        {String(label)}
      </S.RadioButtonLabel>
    </S.RadioButtonContainer>
  );
};
