import App from "@/App.tsx";
import ComponentsPage from "@/pages/ComponentsPage";
import HomePage from "@/pages/HomePage";
import LoginV2 from "@/pages/LoginV2";
import LogoutPage from "@/pages/LogoutPage";
import ResultPage from "@/pages/ResultPage";
import RequireAuth from "@components/common/RequireAuth";
import ErrorPage from "@pages/ErrorPage";
import LoginPage from "@pages/LoginPage";
import NotFoundPage from "@pages/NotFoundPage";
import ProfilePage from "@pages/ProfilePage";
import QuizPage from "@pages/QuizPage";
import { createBrowserRouter } from "react-router-dom";
import ConfigurationPage from "./pages/ConfigurationPage";
import ExerciseHubPage from "./pages/ExerciseHubPage";
import HistoricalPage from "./pages/HistoricalPage";
// import { isProductionMode } from "./utils/isProductionMode";
import ExerciseHubContainer from "./pages/ExerciseHubPage/ExerciseHubContainer";
// import AllExercisesHubPage from './pages/AllExercisesHubPage';

const routes = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
      {
        path: "/quiz",
        element: <QuizPage />,
      },
      {
        path: "/configuration",
        element: <ConfigurationPage />,
      },
      {
        path: "/result",
        element: <ResultPage />,
      },
      {
        path: "/storico",
        element: <HistoricalPage />,
      },
      {
        path: "/logout",
        element: <LogoutPage />,
      },
      {
        path: "/login-v2",
        element: <LoginV2 />,
      },
      {
        path: "/components",
        element: <ComponentsPage />,
      },
      {
        path: "/exercise-hub",
        element: <ExerciseHubContainer />,
        children: [
          {
            path: "",
            element: <ExerciseHubPage />,
          },
          // {
          //   path: 'all',
          //   element: <AllExercisesHubPage />,
          // },
        ],
      },

      //ProtectedPages
      {
        element: <RequireAuth />,
        children: [
          {
            path: "/exercise-hub",
            element: <ExerciseHubContainer />,
            children: [
              {
                path: "",
                element: <ExerciseHubPage />,
              },
              // {
              //   path: 'all',
              //   element: <AllExercisesHubPage />,
              // },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export const router = createBrowserRouter(routes);
