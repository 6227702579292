interface ConvertToObjectProps {
  [key: string]: any;
}

const convertToObject = (
  text: TemplateStringsArray,
  ...vars: (
    | string
    | number
    | ((props: ConvertToObjectProps) => string | number)
  )[]
) => {
  return (props: ConvertToObjectProps): { [key: string]: string } => {
    const parsedTags = text.reduce((acc, item, i) => {
      let v = vars[i];

      if (typeof v === "function") {
        v = v(props).toString();
      } else if (v !== undefined && v !== null) {
        v = v.toString();
      } else {
        v = "";
      }

      return acc + item + v;
    }, "");

    const rules = parsedTags
      .split(";")
      .map((item) => item.trim())
      .filter((item) => !!item);

    return rules.reduce((acc, item) => {
      const [key, val] = item.split(":").map((i) => i.trim());
      return { ...acc, [key]: val };
    }, {});
  };
};

export default convertToObject;
