import colorScheme from "./colorScheme";

export default {
  // customGridConf: {},
  siteColumns: 12,
  ease: "ease",
  timing: {
    fast: "0.2s",
    normal: "0.5s",
    slow: "1s",
    verySlow: "5s",
  },
  margins: {
    default: "3.2rem",
    large: "5.2rem",
  },
  defaults: {
    size: 8,
  },
  colorScheme,
  colors: {
    primary: {
      dark1: "#00264A",
      default: "#00305C",
      light1: "#19456C",
      light2: "#406485",
      light3: "#66839D",
      light4: "#CCD6DE",
      light5: "#E5EAEF",
      light6: "#F5F7F8",
    },
    secondary: {
      dark1: "#0064D6",
      dark2: "#0064D6",
      default: "#0070F0",
      light1: "#338DF3",
      light2: "#66A9F6",
      light3: "#99C6F9",
      light4: "#CCE2FC",
      light5: "#E5F1FD",
      light6: "#F5F9FE",
    },
    grayscale: {
      white: "#FFFFFF",
    },
    brands: {
      warmGrey: "#CFC6BE",
      lightGrey: "#F8FAF2",
      neutralBlack: "#4B4846",
      warmClay: "#A07D6A",
      neutralTeal: "#B1DEE6",
      warmOrange: "#E5673E",
      oliveGreen: "#B6CFBF",
      darkGreen: "#3F605E",
      warmYellow: "#FCC06D",
      nightSky: "#3B7693",
    },
    status: {
      success: {
        default: "#007F5F",
        light1: "#95C9B4",
        light2: "#F1F8F5",
      },
      warning: {
        default: "#D9A40F",
        light1: "#E1B878",
        light2: "#FFF5EA",
      },
      error: {
        default: "#D72C0D",
        light1: "#FD5749",
        light2: "#FD5749",
      },
    },
  },
  shadows: {
    type1:
      "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
    type2:
      "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
    type3:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    type4:
      "0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)",
  },
  typography: {
    displayXL: {
      "font-size": 60,
      "line-height": 72,
      mq: {
        lg: {
          "font-size": 96,
          "line-height": 116,
        },
      },
    },
    displayLG: {
      "font-size": 48,
      "line-height": 60,
      mq: {
        lg: {
          "font-size": 60,
          "line-height": 72,
        },
      },
    },
    displayMD: {
      "font-size": 36,
      "line-height": 46,
      mq: {
        lg: {
          "font-size": 48,
          "line-height": 60,
        },
      },
    },
    displaySM: {
      "font-size": 28,
      "line-height": 40,
      mq: {
        lg: {
          "font-size": 36,
          "line-height": 46,
        },
      },
    },
    headingXXL: {
      "font-size": 40,
      "line-height": 48,
      mq: {
        lg: {
          "font-size": 60,
          "line-height": 72,
        },
      },
    },
    headingXL: {
      "font-size": 32,
      "line-height": 40,
      mq: {
        lg: {
          "font-size": 40,
          "line-height": 48,
        },
      },
    },
    headingLG: {
      "font-size": 28,
      "line-height": 32,
      mq: {
        lg: {
          "font-size": 36,
          "line-height": 46,
        },
      },
    },
    headingMD: {
      "font-size": 24,
      "line-height": 28,
      mq: {
        lg: {
          "font-size": 28,
          "line-height": 36,
        },
      },
    },
    headingSM: {
      "font-size": 20,
      "line-height": 28,
      mq: {
        lg: {
          "font-size": 24,
          "line-height": 32,
        },
      },
    },
    headingXS: {
      "font-size": 16,
      "line-height": 24,
      mq: {
        lg: {
          "font-size": 20,
          "line-height": 28,
        },
      },
    },
    paragraphLG: {
      "font-size": 16,
      "line-height": 24,
      mq: {
        lg: {
          "font-size": 18,
          "line-height": 26,
        },
      },
    },
    paragraphMD: {
      "font-size": 14,
      "line-height": 20,
      mq: {
        lg: {
          "font-size": 16,
          "line-height": 24,
        },
      },
    },
    paragraphSM: {
      "font-size": 12,
      "line-height": 16,
      mq: {
        lg: {
          "font-size": 14,
          "line-height": 20,
        },
      },
    },
    paragraphXS: {
      "font-size": 10,
      "line-height": 14,
      mq: {
        lg: {
          "font-size": 12,
          "line-height": 16,
        },
      },
    },
    label: {
      "font-size": 10,
      "line-height": 16,
      "letter-spacing": "0.04em",
      mq: {
        lg: {
          "font-size": 14,
          "line-height": 18,
          "letter-spacing": "0.04em",
        },
      },
    },
    linkMD: {
      "font-size": 16,
      "line-height": 24,
    },
    linkSM: {
      "font-size": 14,
      "line-height": 20,
    },
    buttonMD: {
      "font-size": 16,
      "line-height": 24,
    },
    buttonSM: {
      "font-size": 14,
      "line-height": 20,
    },
  },
  layers: {
    header: 100,
    menu: 99,
    overlay: 98,
    modal: 20,
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  dimensions: {
    header: {
      mobile: 72,
      desktop: 164,
    },
  },
};
