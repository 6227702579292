import { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';

import Select from '@/components/atoms/Select';
import { Heading } from '@/components/atoms/Typography';
import Pagination, { PaginationProps } from '@/components/molecules/Pagination';
import CardHistoricalList from '@/components/molecules/CardHistoricalList';
import LoadingStack from './LoadingStack';

import * as S from './styles'
import { Visible } from '@/components/atoms/GridSystem';

interface FilterType {
  type: string;
  label: string;
}

interface DataItem {
  id: number;
  userId: number;
  type: string;
  difficulty: number;
  time: number;
  questionsNumber: number;
  group: string;
  rightAnswerScore: string;
  wrongAnswerScore: string;
  nullAnswerScore: string;
  userScore: string;
  totalScore: string;
  endAt: string;
  startAt: string;
  suspendAt: string | null;
  content: object;
  createdAt: string;
  updatedAt: string;
  verticalId: number;
}

interface GroupedData {
  [key: string]: DataItem[];
}

export interface HistoricalListProps {
  filterTypes: FilterType[];
  orderBy: {
    label: string,
    options: { label: string; value: string }[],
  }
  meta: object;
  data: DataItem[];
}

function getFullMonthAndYear(input: string) {
  const [month, year] = input.split('/').map(Number);
  const date = new Date(year, month - 1);
  const formattedDate = date.toLocaleString('it-IT', { month: 'long', year: 'numeric' });

  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

const noResultsMessage: { [key: string]: string } = {
  all: 'Non hai ancora svolto nessuna Simulazione o Esercitazione',
  simulation: 'Non hai ancora svolto nessuna Simulazione',
  exercise: 'Non hai ancora svolto nessuna Esercitazione'
};

const HistoricalList: FC<HistoricalListProps> = ({ filterTypes, orderBy, meta, data = [] }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type') as string;
  const order = searchParams.get('order') as string;

  const [isLoading, setIsLoading] = useState(true)
  const [groupedData, setGroupedData] = useState<GroupedData>({})
  const [currentFilter, setCurrentFilter] = useState(type || 'all')

  const handleFilter = (type: string) => {
    setIsLoading(true);
    const params: { type?: string; order?: string } = {};
    if (type) params.type = type;
    if (order) params.order = order;
    setSearchParams(params);
    setCurrentFilter(type);
  }
  
  const handleOrderBy = (value: string) => {
    setIsLoading(true);
    const params: { type?: string; order?: string } = {};
    if (currentFilter) params.type = currentFilter;
    if (value) params.order = value;
    setSearchParams(params);
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const groupedData: GroupedData = data.reduce((acc, item) => {
        const date = new Date(item.createdAt);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const key = `${month}/${year}`;
  
        if (!acc[key]) {
          acc[key] = [];
        }
  
        acc[key].push(item);
        
        return acc;
      }, {} as GroupedData);
  
      setGroupedData(groupedData)
      setIsLoading(false)
    } else {
      setGroupedData({})
      setIsLoading(false)
    }
  }, [data])
  
  return (
    <S.HistoricalList>
      <S.HistoricalListHeading>
        {/* <Heading content="Lorem Ipsum" typo="headingXS" weight="semibold" /> */}
        <Visible breakpoints={['xs', 'sm']}>
          <S.HistoricalListFiltersOrder>
            <Select
              label={orderBy?.label}
              options={orderBy?.options}
              selectedValue={order}
              onChange={handleOrderBy}
            />
          </S.HistoricalListFiltersOrder>
        </Visible>
      </S.HistoricalListHeading>
      <S.HistoricalListFilters>
        {filterTypes ? (
          <S.HistoricalListFiltersTypes>
            {filterTypes.map((filter) => (
              <S.HistoricalListButton
                aria-current={currentFilter == filter.type}
                key={filter.type}
                onClick={() => handleFilter(filter.type)}
              >
                {filter.label}
              </S.HistoricalListButton>
            ))}
          </S.HistoricalListFiltersTypes>
        ) : null}
        <Visible breakpoints={['md', 'lg', 'xl', 'xxl']}>
          <S.HistoricalListFiltersOrder>
            <Select
              label={orderBy?.label}
              options={orderBy?.options}
              selectedValue={order}
              onChange={handleOrderBy}
            />
          </S.HistoricalListFiltersOrder>
        </Visible>
      </S.HistoricalListFilters>
      {isLoading ? (
        <LoadingStack />
      ) : (
        <S.HistoricalListItems>
          {Object.keys(groupedData).length > 0 ? (
            <>
              {Object.keys(groupedData).map((key) => (
                <S.HistoricalListItemsGroup key={key}>
                  <S.HistoricalListItemDate as={Heading} typo="headingXS" weight="semibold">
                    {getFullMonthAndYear(key)}
                  </S.HistoricalListItemDate>
                  {groupedData[key].map((item) => (
                    <CardHistoricalList key={item.id} {...item as any} />
                  ))}
                </S.HistoricalListItemsGroup>
              ))}
              <Pagination {...meta as PaginationProps} />
            </>
          ) : (
            <Heading content={noResultsMessage[currentFilter]} typo="headingXS" weight="semibold" />
          )}
        </S.HistoricalListItems>
      )}
    </S.HistoricalList>
  )
}

export default HistoricalList
