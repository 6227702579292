import {
  getQuizzesStatistics,
  getPerformanceStatistics,
  getQuizConfig,
} from "@/api/services/quizzes";
import normalizePractice from "@/models/organisms/Practice";
import normalizePerformanceStatistics from "@/models/organisms/PerformanceStatistics";
import { useEffect, useState } from "react";
import normalizeQuizzesStatistics from "@/pages/HomePage/utils";
import { Statistics } from "@/models/quizzes/statistics";
import { QuizInterface, SchoolSubject } from "@/pages/QuizPage/interface";
import { FiltersQueryProps, getHistorical } from "@/api/services/historical";
import { PracticeProps } from "@/components/organisms/Practice";

const useQuizzes = () => {
  const [quizzes, setQuizzes] = useState<QuizInterface[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPracticeLoading, setIsPracticeLoading] = useState(false);
  const [isUserHasRoles, setIsUserHasRoles] = useState(true);
  const [quizzesStatistics, setQuizzesStatistics] = useState<Statistics>();
  const [isStatisticsLoading, setIsStatisticsLoading] = useState(true);
  const [practice, setPractice] = useState<PracticeProps>();
  const [historicalSimulations, setHistoricalSimulations] = useState<any[]>([]);
  const [historicalExercises, setHistoricalExercises] = useState<any[]>([]);
  const [performanceStatistics, setPerformanceStatistics] = useState();

  const normalizedPerformanceStatistics = normalizePerformanceStatistics(
    performanceStatistics
  );

  const realSchoolSubjects =
    quizzes && quizzes.length > 0
      ? quizzes[0].attributes.schoolSubjects.list
      : [];

  const exeriseQuiz = quizzes.find(
    (quiz) => quiz.attributes.type === "exercise"
  ) as QuizInterface;
  const simulationQuiz = quizzes.find(
    (quiz) => quiz.attributes.type === "simulation"
  ) as QuizInterface;

  const normalizedQuizzes = normalizeQuizzesStatistics(
    quizzesStatistics
  );

  const getQuizzesStatisticsHandler = async () => {
    try {
      let { data } = await getQuizzesStatistics();
      console.log({ dataStatistics: data });
      if (!data) {
        data = { verticals: [{ schoolSubjects: [] }] };
      }
      // if (data.totalBySubjects && data.totalBySubjects.verticals && data.totalBySubjects.verticals.length ) {
      const schoolSubjects = data?.verticals[0]?.schoolSubjects || [
        { verticals: [{ schoolSubjects: [] }] },
      ];

      const filteredSchoolSubjects = schoolSubjects.filter(
        (subject: SchoolSubject) =>
          realSchoolSubjects.some(
            (realSubject) => realSubject.id === subject.id
          )
      );

      const additionalSchoolSubjects = realSchoolSubjects
        .filter(
          (realSubject) =>
            !schoolSubjects.some(
              (subject: SchoolSubject) => subject.id === realSubject.id
            )
        )
        .map((realSubject) => ({
          id: realSubject.id,
          label: realSubject.title,
          totalQuestion: 0,
          correctAnswers: 0,
          wrongAnswers: 0,
          nullAnswers: 0,
          averageResponseTime: 0,
        }));

      data.verticals[0].schoolSubjects = [
        ...filteredSchoolSubjects,
        ...additionalSchoolSubjects,
      ];
      // }

      setQuizzesStatistics(data);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsStatisticsLoading(false)
    }
  };

  const getPracticeHandler = async () => {
    setIsPracticeLoading(true);
    try {
      const { data: quizzes } = await getQuizConfig();
      const { data: historical } = await getHistorical({} as FiltersQueryProps);
      const normalizedPractice = normalizePractice({
        quizzes: quizzes?.data,
        historical: historical?.data,
        isPracticeLoading,
      });
      setPractice(normalizedPractice);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsPracticeLoading(false);
    }
  };

  const getPerformanceStatisticsHandler = async () => {
    try {
      const { data } = await getPerformanceStatistics();
      console.log({ performance: data });
      setPerformanceStatistics(data);
    } catch (error) {
      console.log({ error });
    }
  };

  const getAllQuizzes = async () => {
    setIsLoading(true);
    try {
      const { data } = await getQuizConfig();
      if (data?.unauthorized === true) {
        setIsUserHasRoles(false);
      } else {
        const quizzesData = data?.data;
        setQuizzes(quizzesData);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getHistoricalSimulationsHandler = async () => {
    try {
      const { data } = await getHistorical({
        type: "simulation",
      } as FiltersQueryProps);
      setHistoricalSimulations(data?.data);
    } catch (error) {
      console.log({ error });
    }
  };

  const getHistoricalExercisesHandler = async () => {
    try {
      const { data } = await getHistorical({
        type: "exercise",
      } as FiltersQueryProps);
      setHistoricalExercises(data?.data);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getPracticeHandler();
    getPerformanceStatisticsHandler();
    getAllQuizzes();
    getHistoricalSimulationsHandler();
    getHistoricalExercisesHandler();
  }, []);

  useEffect(() => {
    if (quizzes && quizzes.length > 0) {
      getQuizzesStatisticsHandler();
    }
  }, [quizzes]);

  return {
    quizzes,
    isLoading,
    isUserHasRoles,
    quizzesStatistics,
    normalizedQuizzes,
    practice,
    normalizedPerformanceStatistics,
    realSchoolSubjects,
    exeriseQuiz,
    simulationQuiz,
    historicalSimulations,
    historicalExercises,
    isPracticeLoading,
    isStatisticsLoading
  };
};

export default useQuizzes;
