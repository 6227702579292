import * as S from "./styles";
import { CountdownProps } from "./types";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const Countdown = ({ exerciseType, onTimeEnd }: CountdownProps) => {
  const { t} = useTranslation()
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log({ seconds });
      if (seconds > 1) {
        setSeconds(seconds - 1);
      } else if (seconds === 1) {
        onTimeEnd?.();
        clearInterval(interval);
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, onTimeEnd]);

  const renderedExerciseType =
    exerciseType === "exercise"
      ? t('modular_exercise')
      : t('classic_simulation');
  return (
    <S.StyledCountdownContainer>
      <S.StyledCountdown>
        <S.StyledLogo />
        <S.StyledCountdownContent>
          <S.StyledTextContainer>
            <S.StyledLabel>{renderedExerciseType}</S.StyledLabel>
            <S.StyledHeading>{t('simulation_starts_in')}</S.StyledHeading>
          </S.StyledTextContainer>
          <S.StyledSecondsContainer>
            <S.StyledSeconds>{seconds}</S.StyledSeconds>
          </S.StyledSecondsContainer>
          <S.StyledButton variant="primary" handleClick={onTimeEnd}>{t('skip')}</S.StyledButton>
        </S.StyledCountdownContent>
      </S.StyledCountdown>
    </S.StyledCountdownContainer>
  );
};

export default Countdown;
