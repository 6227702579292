import Badge from '@/components/atoms/Badge'
import ButtonV2 from '@/components/atoms/ButtonV2'
import CardTag, { TagStatusType } from '@/components/atoms/Tag/CardTag'
import { Heading, Paragraph } from '@/components/atoms/Typography'
import * as S from './styles'
import { SchoolSubject } from '@/pages/QuizPage/interface'

export interface EditorialCardProps {
  prefix?: {
    content: string
    difficulty: 'easy' | 'medium' | 'hard'
    isTextOnly?: boolean;
    type?: "skipped" | "wrong";
  }
  title: string
  description?: SchoolSubject[]
  image?: string
  ctaLabel?: string
  handleClick?: () => void
  colorVariant?:
    | 'brown'
    | 'green'
    | 'orange'
    | 'blu'
    | 'violet'
    | 'gray'
    | 'red'
    | 'yellow'
  tag?: {
    label: string
    status: TagStatusType
  }
  questionCount?: number
  questionTime?: number
}

const EditorialCard = ({
  prefix = {
    content: '',
    difficulty: 'easy',
    isTextOnly: true,
  },
  title,
  description,
  image,
  handleClick,
  colorVariant = 'brown',
  tag: { status: tagStatus, label: tagLabel } = {
    status: 'info',
    label: '',
  },
  questionCount = 0,
  questionTime = 0,
  ctaLabel = '',
}: EditorialCardProps) => {
  const hasPrefix =
    prefix && Object.keys(prefix).length > 0 && prefix.content?.length > 0
  const hasTitle = title && title.length > 0
  const hasDescription = description && description.length > 0
  const hasTag = tagLabel && typeof tagLabel === 'string' && tagLabel.length > 0
  const hasQuestionCount = questionCount && questionCount > 0
  const hasQuestionTime = questionTime && questionTime > 0
  const hasLabel = ctaLabel && ctaLabel.length > 0
  const hasImage = image && image.length > 0

  const cardBackgroundColor =
    variantColorMap[colorVariant].default.backgroundColor
  const cardHoverBackgroundColor = variantColorMap[colorVariant].default.accent
  const cardAccentColor = variantColorMap[colorVariant].default.accent
  const cardHoverAccentColor = variantColorMap[colorVariant].hover.accent

  const getFormattedDescription = (
    _description: EditorialCardProps['description'],
  ) => {
    if (Array.isArray(_description)) {
      const strings = _description.map((schoolSubject) => schoolSubject.title)
      return strings.join(' • ')
    }
    return _description
  }

  return (
    <S.EditorialCardContainer
      $backgroundColor={cardBackgroundColor}
      $hoverBackgroundColor={cardHoverBackgroundColor}
      $accentColor={cardAccentColor}
      $hoverAccentColor={cardHoverAccentColor}
      onClick={handleClick}
    >
      <S.EditorialCardTextContainer>
        {hasPrefix ? (
          <Heading
            content={prefix.content}
            typo='label'
            weight='bold'
            style={{
              color: prefixColorMap[prefix.difficulty].textColor ?? '#404853',
              textTransform: 'uppercase',
              letterSpacing: '2px',
            }}
          />
        ) : null}
        {hasTitle ? (
          <Heading
            content={title}
            typo='paragraphMD'
            weight='semibold'
          />
        ) : null}
        {hasDescription ? (
          <Paragraph
            content={getFormattedDescription(description)}
            typo='paragraphSM'
            weight='semibold'
            style={{
              color: '#404853',
            }}
          />
        ) : null}
        {hasQuestionCount || hasQuestionTime ? (
          <S.EditorialCardInfoContainer>
            {hasQuestionTime ? (
              <Badge
                accentColor={cardAccentColor}
                badgeLabel={`${questionTime} minuti`}
              />
            ) : null}
            {hasQuestionCount ? (
              <Badge
                accentColor={cardAccentColor}
                badgeLabel={`${questionCount} domande`}
              />
            ) : null}
          </S.EditorialCardInfoContainer>
        ) : null}
        {hasLabel ? (
          <ButtonV2
            handleClick={handleClick}
            ariaLabel={ctaLabel}
            type={'button'}
            typo={'buttonMD'}
            variant={'tertiary'}
            size={'small'}
            label={ctaLabel}
            iconEnd={'ArrowRightTriangle'}
            useIconColor
            iconEndSize={'defaultBig'}
            style={{
              marginBlockStart: 'auto',
            }}
          />
        ) : null}
      </S.EditorialCardTextContainer>
      <S.EditorialCardImageContainer $backgroundColor={cardAccentColor}>
        {hasTag ? (
          <CardTag
            status={tagStatus}
            label={tagLabel}
          />
        ) : null}
        {hasImage ? (
          <S.EditorialCardImage
            src={image}
            alt='Card Image'
          />
        ) : null}
      </S.EditorialCardImageContainer>
    </S.EditorialCardContainer>
  )
}

const prefixColorMap = {
  easy: {
    backgroundColor: '#F5EFEB',
    textColor: '#95C9B4',
  },
  medium: {
    backgroundColor: '#D2D9D6',
    textColor: '#FA9F3D',
  },
  hard: {
    backgroundColor: '#FFF5ED',
    textColor: '#FF7474',
  },
}

const variantColorMap = {
  brown: {
    default: {
      accent: '#F5EFEB',
      backgroundColor: '#FFFFFF',
    },
    hover: {
      accent: '#FAF7F6',
      backgroundColor: '#FFF5ED',
    },
  },
  green: {
    default: {
      accent: '#D2D9D6',
      backgroundColor: '#FFFFFF',
    },
    hover: {
      accent: '#D2D9D6',
      backgroundColor: '#F5FAF8',
    },
  },
  orange: {
    default: {
      accent: '#FFE9D4',
      backgroundColor: '#FFFFFF',
    },
    hover: {
      accent: '#FFE9D4',
      backgroundColor: '#FFE9D4',
    },
  },
  blu: {
    default: {
      accent: '#CEDEE7',
      backgroundColor: '#FFFFFF',
    },
    hover: {
      accent: '#F2FAFE',
      backgroundColor: '#CEDEE7',
    },
  },
  violet: {
    default: {
      accent: '#DFD1EC',
      backgroundColor: '#FFFFFF',
    },
    hover: {
      accent: '#FDF5FF',
      backgroundColor: '#DFD1EC',
    },
  },
  gray: {
    default: {
      accent: '#E7E7E7',
      backgroundColor: '#FFFFFF',
    },
    hover: {
      accent: '#F6F6F6',
      backgroundColor: '#E7E7E7',
    },
  },
  red: {
    default: {
      accent: '#FFC9C9',
      backgroundColor: '#FFFFFF',
    },
    hover: {
      accent: '#FFEBEB',
      backgroundColor: '#FFC9C9',
    },
  },
  yellow: {
    default: {
      accent: '#FDDDAB',
      backgroundColor: '#FFFFFF',
    },
    hover: {
      accent: '#FFF8ED',
      backgroundColor: '#FDDDAB',
    },
  },
}

export default EditorialCard
