import axios from "@/api/request";
import { UserInterface } from "@/store/slices/authSlice";

interface UserInfoPayload {
    data : UserInterface
}

export const login = async () => {
    return axios.get(`/login`);
}

export const getUserInfo = async () : Promise<UserInfoPayload> => {
    return axios.get(`/me`)
}

export const test = async () => {
    return axios.post(`/test`)
}