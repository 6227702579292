import scrollIntoView from "scroll-into-view-if-needed";
import BottomNavigation from "@/components/organisms/Quiz/BottomNavigation";
import Header from "@/components/organisms/Quiz/Header";
import QuizBody from "@/components/organisms/Quiz/QuizBody";
import * as S from "./styles";
import ProgressBar from "@/components/atoms/ProgressBar";
import MaterialQuestionsNumber from "@/components/molecules/Quiz/MaterialQuestionsNumber";
import {
  Navigate,
  useBlocker,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import {
  AnsweredQuestion,
  Question,
  QuizValues,
  SchoolSubjectQuestion,
} from "./interface";
import {
  endQuiz,
  getQuizResult,
  putAverageAnswerTime,
  startQuiz,
  suspendQuiz,
} from "@/api/services/quizzes";
import { setAnswer } from "@/api/services/questions";
import Modal from "@/components/organisms/Modal";
import exitQuiz from "@/assets/images/modals/exitQuiz.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  resetQuizSlice,
  setAnsweredQuestionsReducer,
  setCurrentQuestionNumberReducer,
  setCurrentSchoolSubjectReducer,
  setQuizIdReducer,
} from "@/store/slices/quizSlice";
import { RootState } from "@/store";
import { useTranslation } from "react-i18next";
import useIsMobile from "@/hooks/useIsMobile";
import Timer from "@/components/organisms/Quiz/Timer";
import Button from "@/components/atoms/Button";
import BottomNavigationMobile from "@/components/organisms/Configuration/BottomNavigation";
import Icon from "@/components/atoms/Icon";
import usePopstate from "@/hooks/usePopstate";
import Divider from "@/components/atoms/Divider";
import useAverageAnswerTime from "@/hooks/useAverageTime";

const MobileHeader = ({
  quiz,
  currentQuestionNumber,
  quizDateStart,
  onTimeEnd,
  onFinish,
}: {
  quiz: QuizValues;
  currentQuestionNumber: number;
  quizDateStart: string;
  onTimeEnd: () => void;
  onFinish: () => void;
}) => {
  const { t } = useTranslation();
  const isRandom = quiz.random;
  const timer = quiz.time;
  const isCorrectionMode = quiz.isCorrectionMode;
  const buttonLabel = isCorrectionMode
    ? t("go_back_to_home_page")
    : t("submit");

  const currentSchoolSubjectObject = quiz.schoolSubjectQuestions.find(
    (question) => question.schoolSubject === quiz.schoolSubject
  );

  const question =
    currentSchoolSubjectObject?.questions[currentQuestionNumber - 1] ||
    quiz.questions[currentQuestionNumber - 1];

  const schoolSubjectLabel = currentSchoolSubjectObject?.schoolSubject;

  const questionIndex = currentSchoolSubjectObject?.questions?.findIndex(
    (ques) => ques.id === question.id
  ) as number;

  return (
    <S.StyledHeaderMobile>
      <div>
        <S.StyledHeaderMobileTitle>
          {`Domanda ${questionIndex + 1} su ${
            currentSchoolSubjectObject?.questions.length
          } ${
            !isRandom && schoolSubjectLabel ? `di ${schoolSubjectLabel}` : ""
          }`}
        </S.StyledHeaderMobileTitle>
      </div>
      <S.StyledHeaderContent>
        {isCorrectionMode ? (
          <div></div>
        ) : (
          <Timer
            startTime={quizDateStart}
            quizTime={timer}
            onTimerEnd={onTimeEnd}
          />
        )}

        <Button variant="tertiary" label={buttonLabel} handleClick={onFinish} />
      </S.StyledHeaderContent>
    </S.StyledHeaderMobile>
  );
};

const QuizPage = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const answeredQuestionsState = useSelector(
    (state: RootState) => state.quiz.answeredQuestions
  );
  const quizIdState = useSelector((state: RootState) => state.quiz.quizId);
  const currentQuestionNumberState = useSelector(
    (state: RootState) => state.quiz.currentQuestionNumber
  );
  const currentSchoolSubjectState = useSelector(
    (state: RootState) => state.quiz.currentSchoolSubject
  );
  const [isFinishQuizModalOpen, setIsFinishQuizModalOpen] =
    useState<boolean>(false);
  const [isTimeoutModalOpen, setIsTimeoutModalOpen] = useState<boolean>(false);
  const [isQuestionsNumbersModalOpen, setIsQuestionsNumbersModalOpen] =
    useState<boolean>(true);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(1);
  const [currentSchoolSubject, setCurrentSchoolSubject] = useState("");
  const [isQuizRunning, setIsQuizRunning] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<number>();
  const [answeredQuestions, setAnsweredQuestions] = useState<
    AnsweredQuestion[]
  >([]);
  const [isCorrectionMode, setIsCorrectionMode] = useState(false);
  const [startQuizDate, setStartQuizDate] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { generatedQuiz, config, quizResult } = state || {};
  const { averageAnswerTime, startTimer, resetTimer } = useAverageAnswerTime();
  usePopstate(() => setIsFinishQuizModalOpen(true));

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isQuizRunning &&
      !isCorrectionMode &&
      currentLocation.pathname !== nextLocation.pathname
  );

  const currentQuiz = generatedQuiz ? generatedQuiz : quizResult?.content;

  if (!generatedQuiz && !config && !quizResult) {
    return <Navigate to="/" replace />;
  }

  useEffect(() => {
    if (quizResult) {
      setIsCorrectionMode(true);
    } else {
      setIsCorrectionMode(false);
    }
  }, [quizResult]);

  const renderedQuestions = (): SchoolSubjectQuestion[] => {
    let finalQuestions: SchoolSubjectQuestion[] = [];
    if (currentQuiz.quiz.group === "random") {
      let subjectObj: SchoolSubjectQuestion = {} as any;
      subjectObj.schoolSubject = "random";
      subjectObj.questions = currentQuiz.quiz.questions;
      finalQuestions.push(subjectObj);
    } else {
      const schoolSubjects = [
        ...new Set(
          currentQuiz.quiz.questions.map(
            (question: Question) => question.schoolSubject.label
          )
        ),
      ];
      for (let subject of schoolSubjects) {
        let subjectObj: SchoolSubjectQuestion = {} as SchoolSubjectQuestion;
        subjectObj.schoolSubject = subject as string;
        subjectObj.questions = currentQuiz.quiz.questions.filter(
          (question: Question) => question.schoolSubject.label === subject
        );
        finalQuestions.push(subjectObj);
      }
    }
    return finalQuestions;
  };

  const isSimulation = config
    ? config?.attributes?.type === "simulation"
      ? true
      : false
    : currentQuiz?.quiz?.type === "simulation"
    ? true
    : false;

  const quiz: QuizValues = {
    title: config?.attributes?.title || "",
    type: isSimulation ? t("classic_simulation") : t("modular_exercise"),
    random: currentQuiz.quiz.group === "random",
    questionsNumber: currentQuiz.quiz?.questions?.length,
    totalScore: config
      ? currentQuiz.quiz.questionsNumber *
        config.attributes.score.rightAnswerScore
      : quizResult
      ? quizResult.stats.totalScore
      : 0,
    time: currentQuiz.quiz.time,
    questions: currentQuiz.quiz.questions,
    schoolSubjectQuestions: renderedQuestions(),
    schoolSubject: currentSchoolSubject,
    isCorrectionMode: isCorrectionMode,
  };

  const currentSchoolSubjectObject = quiz.schoolSubjectQuestions.find(
    (question) => question.schoolSubject === quiz.schoolSubject
  );
  const currentQuestion =
    currentSchoolSubjectObject?.questions[currentQuestionNumber - 1] ||
    quiz.questions[currentQuestionNumber - 1];

  const resetStore = () => {
    dispatch(resetQuizSlice());
  };

  const startQuizHandler = async () => {
    if (isCorrectionMode) {
      return;
    }
    try {
      const data = {
        data: {
          id: currentQuiz.quiz.id,
        },
      };
      const { data: startedQuizDate } = await startQuiz(data);
      setStartQuizDate(startedQuizDate);
      startTimer();
      dispatch(setQuizIdReducer(currentQuiz.quiz.id));
      setIsQuizRunning(true);
    } catch (e) {
      console.log({ e });
    }
  };

  const endQuizHandler = async () => {
    setIsQuizRunning(false);
    //if user in correct mode
    if (isCorrectionMode) {
      navigate("/");
      return;
    }
    try {
      const data = {
        data: {
          id: currentQuiz.quiz.id,
        },
      };
      if (blocker && blocker.state === "blocked" && blocker.proceed) {
        blocker.proceed();
      }
      await putAverageAnswerTimeHandler(false);
      await endQuiz(data);
      const quizResult = await getQuizResultHandler();

      resetStore();
      navigate("/result", { state: { quizResult }, replace: true });
    } catch (e) {
      console.log({ e });
    }
  };

  const suspendQuizHandler = async () => {
    setIsQuizRunning(false);
    try {
      const data = {
        data: {
          id: currentQuiz.quiz.id,
        },
      };
      await putAverageAnswerTimeHandler(false);
      await suspendQuiz(data);
      if (blocker && blocker.state === "blocked" && blocker.proceed) {
        blocker.proceed();
      }
      resetStore();
      navigate("/");
    } catch (e) {
      console.log({ e });
    }
  };

  const getQuizResultHandler = async () => {
    try {
      const { data: quizResult } = await getQuizResult(generatedQuiz.quiz.id);
      return quizResult;
    } catch (e) {
      console.log({ e });
    }
  };

  const setAnswerHandler = async () => {
    if (typeof selectedAnswer !== "number") {
      return;
    }
    try {
      const data = {
        data: {
          answerId: selectedAnswer,
        },
      };
      await setAnswer(currentQuestion.id, data);
    } catch (e) {
      console.log({ e });
    }
  };

  const putAverageAnswerTimeHandler = async (mustStartTimer = true) => {
    try {
      await putAverageAnswerTime(currentQuestion.id, {
        data: {
          averageAnswerTime: averageAnswerTime,
        },
      });
      resetTimer();
      if (mustStartTimer) {
        startTimer();
      }
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    if (!generatedQuiz && !config && !quizResult) {
      navigate("/");
    } else {
      const isRandom =
        generatedQuiz?.quiz?.group === "random" ||
        quizResult?.content?.quiz?.group === "random";
      setCurrentSchoolSubject(
        isRandom ? "random" : currentQuestion?.schoolSubject?.label
      );
      if (isCorrectionMode) {
        return;
      }

      startQuizHandler();
    }
  }, [generatedQuiz, config]);

  const addAnswerHandler = async (goNext = false) => {
    const currentSchoolSubjectObject = quiz.schoolSubjectQuestions.find(
      (question) => question.schoolSubject === quiz.schoolSubject
    );
    const mappedSchoolSubjects = quiz.schoolSubjectQuestions.map(
      (subject) => subject.schoolSubject
    );
    const question =
      currentSchoolSubjectObject?.questions[currentQuestionNumber - 1] ||
      quiz.questions[currentQuestionNumber - 1];

    const questionId = question.id;

    if (selectedAnswer && selectedAnswer !== 0) {
      question.answerId = selectedAnswer;
      if (!isCorrectionMode) {
        await setAnswerHandler();
      }
      const answeredQuestionsMapped = answeredQuestions.map(
        (answer) => answer.questionId
      );
      if (!answeredQuestionsMapped.includes(questionId)) {
        setAnsweredQuestions([
          ...answeredQuestions,
          { answerId: selectedAnswer, questionId },
        ]);
      } else {
        setAnsweredQuestions([
          ...answeredQuestions.filter(
            (answer) => answer.questionId !== questionId
          ),
          {
            answerId: selectedAnswer,
            questionId,
          },
        ]);
      }
    } else {
      if (!goNext) {
        setAnsweredQuestions([
          ...answeredQuestions.filter(
            (answer) => answer.questionId !== questionId
          ),
        ]);
        if (!isCorrectionMode) {
          await setAnswerHandler();
        }
      }
    }

    if (goNext) {
      if (!isCorrectionMode) {
        putAverageAnswerTimeHandler();
      }
      setSelectedAnswer(undefined);
      if (
        currentQuestionNumber <
        (currentSchoolSubjectObject?.questions?.length as number)
      ) {
        setCurrentQuestionNumber(currentQuestionNumber + 1);
      } else if (
        currentQuestionNumber === currentSchoolSubjectObject?.questions?.length
      ) {
        const currentSchoolSubjectIndex = mappedSchoolSubjects.findIndex(
          (subject) => subject === currentSchoolSubject
        );
        const nextSchoolSubjectIndex = currentSchoolSubjectIndex + 1;
        if (mappedSchoolSubjects[nextSchoolSubjectIndex]) {
          setCurrentSchoolSubject(mappedSchoolSubjects[nextSchoolSubjectIndex]);
          setCurrentQuestionNumber(1);
        } else {
          if (!isCorrectionMode) {
            onFinishHandler();
          } else {
            navigate("/result", {
              state: { quizResult: quizResult },
              replace: true,
            });
          }
        }
      }
    }
  };

  const goBackHandler = async () => {
    if (!isCorrectionMode) {
      await putAverageAnswerTimeHandler();
    }
    const currentSchoolSubjectObject = quiz.schoolSubjectQuestions.find(
      (question) => question.schoolSubject === quiz.schoolSubject
    );
    const mappedSchoolSubjects = quiz.schoolSubjectQuestions.map(
      (subject) => subject.schoolSubject
    );

    if (
      currentQuestionNumber > 1 &&
      currentSchoolSubjectObject?.schoolSubject === "random"
    ) {
      setCurrentQuestionNumber(currentQuestionNumber - 1);
    } else if (currentQuestionNumber > 1) {
      setCurrentQuestionNumber(currentQuestionNumber - 1);
    } else if (currentQuestionNumber === 1) {
      const currentSchoolSubjectIndex = mappedSchoolSubjects.findIndex(
        (subject) => subject === currentSchoolSubject
      );
      const previousSchoolSubjectIndex = currentSchoolSubjectIndex - 1;
      if (previousSchoolSubjectIndex >= 0) {
        setCurrentSchoolSubject(
          mappedSchoolSubjects[previousSchoolSubjectIndex]
        );
        setCurrentQuestionNumber(
          quiz.schoolSubjectQuestions[previousSchoolSubjectIndex].questions
            .length
        );
      }
    }
  };

  const clickAnswerHandler = () => {
    if (selectedAnswer || selectedAnswer === 0) {
      setSelectedAnswer(0);
    }
  };

  useEffect(() => {
    if (selectedAnswer || selectedAnswer === 0) {
      if (!isCorrectionMode) {
        addAnswerHandler(false);
      }
    }
  }, [selectedAnswer]);

  useEffect(() => {
    const currentSchoolSubjectObject = quiz.schoolSubjectQuestions.find(
      (question) => question.schoolSubject === quiz.schoolSubject
    );
    const question =
      currentSchoolSubjectObject?.questions[currentQuestionNumber - 1];
    const questionId = question?.id;

    if (answeredQuestions.find((answer) => answer.questionId === questionId)) {
      setSelectedAnswer(
        answeredQuestions.find((answer) => answer.questionId === questionId)
          ?.answerId
      );
    } else {
      setSelectedAnswer(undefined);
    }
  }, [currentQuestionNumber, answeredQuestions, currentSchoolSubject]);

  const onFinishHandler = async () => {
    setIsFinishQuizModalOpen(true);
  };

  const onTimeEndHandler = () => {
    putAverageAnswerTimeHandler();
    setIsTimeoutModalOpen(true);
    setIsQuizRunning(false);
  };

  const scrollQuestionsNumber = () => {
    const questionTag =
      (document.getElementById(
        `${currentQuestionNumber}-${currentSchoolSubject.replace(/\s+/g, "_")}`
      ) as HTMLDivElement) || null;

    if (questionTag) {
      scrollIntoView(questionTag, {
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  useEffect(() => {
    if (isQuestionsNumbersModalOpen) {
      setIsQuestionsNumbersModalOpen(false);
    }

    scrollQuestionsNumber();

    if (isCorrectionMode) {
      return;
    }
    if (answeredQuestions) {
      dispatch(setAnsweredQuestionsReducer(answeredQuestions));
    }
    if (currentQuestionNumber) {
      dispatch(setCurrentQuestionNumberReducer(currentQuestionNumber));
    }
    if (currentSchoolSubject) {
      dispatch(setCurrentSchoolSubjectReducer(currentSchoolSubject));
    }
  }, [answeredQuestions, currentQuestionNumber, currentSchoolSubject]);

  const initializeValuesFromStore = () => {
    if (
      quizIdState === currentQuiz.quiz.id &&
      answeredQuestionsState &&
      currentQuestionNumberState &&
      currentSchoolSubjectState
    ) {
      setAnsweredQuestions(answeredQuestionsState);
      setCurrentQuestionNumber(currentQuestionNumberState);
      setCurrentSchoolSubject(currentSchoolSubjectState);
    }
  };

  useEffect(() => {
    if (isCorrectionMode) {
      return;
    }
    initializeValuesFromStore();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isMobile) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [currentQuestionNumber]);

  useEffect(() => {
    return () => {
      if (blocker && blocker.state === "blocked" && blocker.reset) {
        blocker.reset();
      }
    };
  }, [isQuizRunning]);

  const scrollSchoolSubjectsElements = () => {
    const parent = document.getElementById(
      "questions_number"
    ) as HTMLDivElement;
    const currentSchoolSubjectElement =
      (document.getElementById(
        `${currentSchoolSubject.replace(/\s+/g, "_")}`
      ) as HTMLDivElement) || null;

    if (currentSchoolSubjectElement) {
      scrollIntoView(currentSchoolSubjectElement, {
        behavior: "smooth",
        block: "center",
        boundary: parent,
      });
    }
  };

  useEffect(() => {
    scrollSchoolSubjectsElements();
  }, [currentSchoolSubject, isQuestionsNumbersModalOpen]);

  const exitModalHeading = quiz.isCorrectionMode
    ? "I tuoi risultati sono stati salvati, ma per il momento non potrai rivedere la correzione, sei sicuro di uscire?"
    : t("confirm_submission");

  const negativeCTA = quiz.isCorrectionMode
    ? "No, resta nella revisione"
    : t("session_return_request");
  const positiveCTA = quiz.isCorrectionMode
    ? "Sì, torna alla pagina iniziale"
    : t("exam_submission_request");

  const isLastQuestion = () => {
    const lastSchoolSubjectSection =
      quiz.schoolSubjectQuestions[quiz.schoolSubjectQuestions.length - 1];
    const lastQuestionIndex = lastSchoolSubjectSection?.questions.length - 1;
    const lastSchoolSubject = lastSchoolSubjectSection?.schoolSubject;

    if (
      lastSchoolSubject === quiz.schoolSubject &&
      currentQuestionNumber === lastQuestionIndex + 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isFirstQuestion = () => {
    const firstSchoolSubject = quiz.schoolSubjectQuestions[0].schoolSubject;

    if (
      firstSchoolSubject === quiz.schoolSubject &&
      currentQuestionNumber === 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const nextButtonLabel = (isMobile: boolean) => {
    if (!isLastQuestion()) {
      return t("next");
    } else {
      if (isCorrectionMode) {
        return t("your_statistics");
      } else {
        if (isMobile) {
          return t("submit");
        } else {
          return t("submit_simulation");
        }
      }
    }
  };

  const closeQuestionsNumbersModal = () => {
    if (!isQuestionsNumbersModalOpen) {
      return;
    }
    setIsQuestionsNumbersModalOpen(false);
  };

  const setCurrentQuestionNumberHandler = async (questioNumber: number) => {
    try {
      await putAverageAnswerTimeHandler();
      setCurrentQuestionNumber(questioNumber);
      closeQuestionsNumbersModal();
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <S.StyledBaseLayout
      isHeaderSticky={isMobile ? true : false}
      headerComponent={
        isMobile ? (
          <MobileHeader
            quiz={quiz}
            currentQuestionNumber={currentQuestionNumber}
            quizDateStart={startQuizDate}
            onTimeEnd={onTimeEndHandler}
            onFinish={onFinishHandler}
          />
        ) : (
          <Header
            quiz={quiz}
            quizDateStart={startQuizDate}
            title={quiz.title}
            type={quiz.type}
            random={quiz.random}
            totalScore={quiz.totalScore}
            timer={quiz.time}
            isCorrectionMode={quiz.isCorrectionMode}
            onFinish={onFinishHandler}
            onTimeEnd={onTimeEndHandler}
          />
        )
      }
    >
      {/* Finish Modal */}
      <Modal
        isOpen={isFinishQuizModalOpen || blocker?.state === "blocked"}
        onClose={() => {
          blocker?.reset ? blocker.reset() : undefined;
          setIsFinishQuizModalOpen(false);
        }}
      >
        <S.StyledModalContainer>
          <S.StyledModalImageContainer>
            <img src={exitQuiz} alt="exit-quiz" />
          </S.StyledModalImageContainer>
          <S.StyledModalContent>
            <S.StyledModalHeading typo="headingSM">
              {exitModalHeading}
            </S.StyledModalHeading>
          </S.StyledModalContent>
          <S.StyledModalCTAContainer>
            <S.StyledButton
              variant="quaternary"
              typo="buttonSM"
              handleClick={() => {
                blocker?.reset ? blocker.reset() : undefined;
                setIsFinishQuizModalOpen(false);
              }}
            >
              {negativeCTA}
            </S.StyledButton>
            <S.StyledButton
              iconEnd="ArrowRight"
              typo="buttonSM"
              handleClick={endQuizHandler}
            >
              {positiveCTA}
            </S.StyledButton>
            <Divider type="horizontal" />
            {!isCorrectionMode && (
              <S.StyledButtonError
                typo="buttonSM"
                variant="quaternary"
                handleClick={suspendQuizHandler}
              >
                {t("finish_exam_without_saving_request")}
              </S.StyledButtonError>
            )}
          </S.StyledModalCTAContainer>
        </S.StyledModalContainer>
      </Modal>
      {/* Timeout Modal */}
      <Modal
        isOpen={isTimeoutModalOpen}
        onClose={() => setIsTimeoutModalOpen(false)}
        closeButton={false}
        closable={false}
      >
        <S.StyledModalContainer>
          <S.StyledModalImageContainer>
            <img src={exitQuiz} alt="exit-quiz" />
          </S.StyledModalImageContainer>
          <S.StyledModalContent>
            <S.StyledModalHeading typo="headingSM">
              {t("exam_time_expired")}
            </S.StyledModalHeading>
            <S.StyledModalParagraph typo="paragraphSM">
              {t("choose_submitting_or_canceling")}
            </S.StyledModalParagraph>
          </S.StyledModalContent>
          <S.StyledModalCTAContainer>
            <S.StyledButton
              variant="quaternary"
              typo="buttonSM"
              handleClick={() => {
                suspendQuizHandler();
                setIsTimeoutModalOpen(false);
              }}
              iconEnd="XMark"
            >
              {t("cancel_session")}
            </S.StyledButton>
            <S.StyledButton
              iconEnd="ArrowRight"
              typo="buttonSM"
              handleClick={() => {
                setIsTimeoutModalOpen(false);
                endQuizHandler();
              }}
            >
              {t("submit_simulation")}
            </S.StyledButton>
          </S.StyledModalCTAContainer>
        </S.StyledModalContainer>
      </Modal>
      <Modal
        isOpen={isQuestionsNumbersModalOpen}
        onClose={() => setIsQuestionsNumbersModalOpen(false)}
        closeButton={false}
        closable={false}
        isFullScreen={true}
      >
        <S.StyledModalContainer>
          <S.StyledModalArrowContainer
            onClick={() => setIsQuestionsNumbersModalOpen(false)}
          >
            <Icon name="ArrowLeft" size="big" />
          </S.StyledModalArrowContainer>
          <S.StyledQuizQuestions>
            <>
              {!isCorrectionMode && (
                <ProgressBar
                  label={t("your_progress")}
                  value={answeredQuestions.length}
                  max={quiz.questionsNumber}
                />
              )}

              <MaterialQuestionsNumber
                totalQuestions={quiz.questionsNumber}
                currentQuestionNumber={currentQuestionNumber}
                setCurrentQuestionNumber={setCurrentQuestionNumberHandler}
                setCurrentSchoolSubject={setCurrentSchoolSubject}
                currentSubject={currentSchoolSubject}
                isRandom={quiz.random}
                questions={quiz.questions}
                answeredQuestions={answeredQuestions}
                quiz={quiz}
              />
            </>
          </S.StyledQuizQuestions>
        </S.StyledModalContainer>
      </Modal>

      <S.StyledQuizBodyContainer>
        <S.StyledQuizBody>
          <QuizBody
            quiz={quiz}
            currentQuestionNumber={currentQuestionNumber}
            selectedAnswer={selectedAnswer}
            onClick={clickAnswerHandler}
            setSelectedAnswer={setSelectedAnswer}
            answeredQuestions={answeredQuestions}
            onAddAnswer={addAnswerHandler}
          />
        </S.StyledQuizBody>
        {!isMobile && (
          <S.StyledQuizQuestions id="questions_number">
            <>
              {!isCorrectionMode && (
                <ProgressBar
                  label={t("your_progress")}
                  value={answeredQuestions.length}
                  max={quiz.questionsNumber}
                />
              )}

              <MaterialQuestionsNumber
                totalQuestions={quiz.questionsNumber}
                currentQuestionNumber={currentQuestionNumber}
                setCurrentQuestionNumber={setCurrentQuestionNumberHandler}
                setCurrentSchoolSubject={setCurrentSchoolSubject}
                currentSubject={currentSchoolSubject}
                isRandom={quiz.random}
                questions={quiz.questions}
                answeredQuestions={answeredQuestions}
                quiz={quiz}
              />
            </>
          </S.StyledQuizQuestions>
        )}
      </S.StyledQuizBodyContainer>
      {isMobile ? (
        <BottomNavigationMobile
          onBack={goBackHandler}
          onNext={() => addAnswerHandler(true)}
          nextButtonLabel={nextButtonLabel(true)}
          isBackDisabled={isFirstQuestion()}
          hasQuestionsList={true}
          hasRecap={false}
          onClickList={() => setIsQuestionsNumbersModalOpen(true)}
        />
      ) : (
        <BottomNavigation
          quiz={quiz}
          onAddAnswer={addAnswerHandler}
          nextButtonLabel={nextButtonLabel(false)}
          isFirstQuestion={isFirstQuestion()}
          currentQuestionNumber={currentQuestionNumber}
          onGoBack={goBackHandler}
        />
      )}
    </S.StyledBaseLayout>
  );
};

export default QuizPage;
