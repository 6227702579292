import styled from "styled-components";
import { Paragraph } from "../Typography";
import Icon from "../Icon";

export const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.rem(8, 12)};
  border-radius: ${({ theme }) => theme.rem(12)};
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #DCE5F0;
  }

  &:active {
    background-color: #AFC1D8;
  }

`;

export const StyledMenuItemLabel = styled(Paragraph)`
  ${({ theme }) => theme.typo("buttonMD")};
  color: ${({ theme }) => theme.colorScheme.white.button.primary.textHover};
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colorScheme.white.button.primary.textHover};
`;
