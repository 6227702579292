interface GetAriaPropsParams {
  href?: string;
  label?: string;
  ariaLabel?: string;
  type?: "button" | "submit" | "reset";
  rel?: string;
  target?: "_self" | "_blank" | "_parent" | "_top";
  disabled?: boolean;
  tabIndex?: number;
  iconStart?: string;
  iconEnd?: string;
}

export const getAriaProps = ({
  href,
  label,
  ariaLabel,
  type,
  rel,
  target,
  disabled,
  tabIndex,
  iconStart,
  iconEnd,
}: GetAriaPropsParams): { [key: string]: any } => {
  let props: { [key: string]: any } = {};

  let normalizedRel = rel || "";

  if (target === "_blank") {
    normalizedRel = `${
      normalizedRel ? `${normalizedRel} ` : ""
    }noopener noreferrer`;
  }

  props = {
    ...props,
    "aria-label": ariaLabel || label || iconStart || iconEnd,
    ...(normalizedRel ? { rel: normalizedRel } : {}),
    ...(disabled ? { "aria-disabled": true, tabIndex: "-1" } : {}),
    ...(!disabled ? { tabIndex: tabIndex?.toString() || "0" } : {}),
    ...(href ? {} : { type: type || "button" }),
  };

  return props;
};
