import { FC, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import * as S from "./styles";
import { AccordionProps } from "./types";
import Icon from "../Icon";

const AccordionV2: FC<AccordionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <S.AccordionContainer>
      <S.AccordionHeader onClick={toggleAccordion}>
        <S.TitleContainer>{title}</S.TitleContainer>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <Icon name="ChevronDown" size="big" />
        </motion.div>
      </S.AccordionHeader>
      <AnimatePresence initial={false}>
        {isOpen && (
          <S.AccordionBody
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <S.AccordionContent>
              {children}
            </S.AccordionContent>
          </S.AccordionBody>
        )}
      </AnimatePresence>
    </S.AccordionContainer>
  );
};

export default AccordionV2;
