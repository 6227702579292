export const LineButton = () => {
  return (
    <svg
      width="117"
      height="4"
      viewBox="0 0 117 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 2C9.69294 2.45174 81.2887 2.50581 116 1" pathLength="1" />
    </svg>
  );
};

export const LineCircle = () => {
  return (
    <svg
      width="169"
      height="178"
      viewBox="0 0 169 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.202 2.78554C52.2069 14.0768 19.348 41.4687 6.5061 78.5936C0.510505 95.9638 -0.980955 115.04 3.26955 132.977C7.30734 150.038 17.4029 166.054 34.1267 172.956C51.2491 180.019 71.2088 177.427 88.3479 171.857C106.243 166.039 123.006 156.411 136.74 143.526C149.962 131.135 160.436 115.544 165.088 97.8889C169.647 80.5482 168.539 61.7241 161.162 45.3134C146.485 12.694 108.613 -3.10475 74.3162 1.66059C69.8154 2.28851 65.3877 3.27475 61.0496 4.59278"
        pathLength="1"
      />
    </svg>
  );
};

export const LineTwoCircle = () => {
  return (
    <svg
      width="234"
      height="174"
      viewBox="0 0 234 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M197.594 96.2337C157.969 112.605 117.182 126.258 75.6046 136.895C61.1144 140.606 38.5788 148.804 27.2316 134.778C17.9407 123.303 29.1148 109.423 38.1587 101.513C53.4244 88.1947 70.8178 77.2079 88.6624 67.4097C105.913 57.9159 123.72 48.7561 142.14 41.575C158.433 35.2292 176.991 30.1256 194.594 32.7553C208.209 34.8074 223.069 44.4483 217.451 59.6312C214.584 67.3976 207.904 73.3685 201.606 78.6343C193.178 85.7187 184.372 92.3788 175.214 98.5999C155.55 111.987 134.374 123.129 112.162 131.998"
        pathLength="1"
      />
      <path
        d="M185.95 85.2337C146.325 101.605 105.539 115.258 63.9611 125.895C49.4708 129.606 26.9352 137.804 15.588 123.778C6.29713 112.303 17.4712 98.4232 26.5151 90.5131C41.7808 77.1947 59.1743 66.2079 77.0189 56.4097C94.2693 46.9159 112.076 37.7561 130.497 30.575C146.79 24.2292 165.347 19.1256 182.95 21.7553C196.565 23.8074 211.426 33.4483 205.807 48.6312C202.941 56.3976 196.261 62.3685 189.962 67.6343C181.535 74.7187 172.728 81.3788 163.57 87.5999C143.907 100.987 122.73 112.129 100.518 120.998"
        pathLength="1"
      />
    </svg>
  );
};

export const LineOval = () => {
  return (
    <svg
      width="225"
      height="102"
      viewBox="0 0 225 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M116.428 34.6096C97.3447 39.7984 78.6677 46.4997 60.6412 54.6479C51.6456 58.7173 42.8194 63.1373 34.1847 67.9206C26.6965 72.0574 19.2293 76.4147 12.6612 81.9451C9.30745 84.7667 6.22354 87.893 3.57907 91.3917C2.01909 93.4499 -0.389565 96.565 2.02266 98.9539C4.15185 101.06 7.8159 100.663 10.4829 100.344C15.3807 99.7671 20.2452 99.0669 25.0946 98.2388C44.9942 94.8297 64.4718 89.4222 83.676 83.2772C102.968 77.1087 122.005 70.1981 141.192 63.7751C159.284 57.7019 177.77 51.9001 194.098 41.7751C201.276 37.3255 208.007 32.0531 213.751 25.8387C216.514 22.8548 219.048 19.6495 221.261 16.2287C222.858 13.7459 224.6 10.1119 222.045 7.61062C220.998 6.59018 219.567 6.10642 218.2 5.71862C215.852 5.04695 213.48 4.49485 211.1 3.98275C206.919 3.08571 202.698 2.38816 198.458 1.90292C189.154 0.813766 179.757 0.711336 170.434 1.58818C131.297 5.25024 96.1772 25.2368 63.5487 45.8C59.5189 48.3503 55.4935 50.9182 51.486 53.4813"
        pathLength="1"
      />
    </svg>
  );
};

export const PaginationArrowLeft = () => {
  return (
    <svg
      width="117"
      height="14"
      viewBox="0 0 117 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.2732 0.428886L0.275146 8.27392L16.2416 13.7482L16.5659 12.8023L4.21257 8.56678C22.0951 8.15284 47.7853 7.68224 70.0923 7.53987C81.8418 7.46488 92.6493 7.48099 100.882 7.64424C104.999 7.72588 108.467 7.84423 111.085 8.00598C112.395 8.08687 113.486 8.17832 114.338 8.28069C115.202 8.38462 115.78 8.49575 116.087 8.60417L116.42 7.66118C115.999 7.51247 115.322 7.39192 114.457 7.28785C113.579 7.18223 112.466 7.08936 111.147 7.00788C108.508 6.84487 105.024 6.72618 100.902 6.64444C92.656 6.48092 81.8383 6.46488 70.0859 6.53989C47.3341 6.68509 21.0666 7.17161 3.1464 7.59133L12.8205 1.26585L12.2732 0.428886Z"
        fill="black"
      />
    </svg>
  );
};

export const PaginationArrowRight = () => {
  return (
    <svg
      width="117"
      height="14"
      viewBox="0 0 117 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M104.433 0.428883L116.432 8.27393L100.464 13.7482L100.14 12.8023L112.494 8.56678C94.6099 8.15285 68.9176 7.68224 46.6087 7.53988C34.8582 7.46489 24.0498 7.48099 15.8165 7.64425C11.6993 7.72588 8.2308 7.84424 5.61236 8.00598C4.30279 8.08688 3.21106 8.17832 2.35967 8.2807C1.49539 8.38463 0.91708 8.49576 0.609869 8.60418L0.277069 7.66118C0.698438 7.51248 1.37483 7.39192 2.24028 7.28785C3.11861 7.18224 4.23162 7.08937 5.55071 7.00789C8.18959 6.84488 11.6741 6.72619 15.7967 6.64444C24.0431 6.48093 34.8617 6.46489 46.6151 6.5399C69.3689 6.6851 95.6386 7.17162 113.56 7.59134L103.885 1.26587L104.433 0.428883Z"
        fill="black"
      />
    </svg>
  );
};
