import { useRef, DependencyList } from "react";
import useUnmount from "@hooks/useUnmount";
import getTargetElement from "./get-target-element";
import depsAreSame from "./deps-are-same";

type Target =
  | (() => HTMLElement | null)
  | { current: HTMLElement | null }
  | HTMLElement
  | null
  | undefined;

type ElementResult = HTMLElement | null | string | undefined | Window;

const createEffectWithTarget = (
  useEffectType: (
    effect: () => (() => void) | void,
    deps?: DependencyList
  ) => void
) => {
  const useEffectWithTarget = (
    effect: () => (() => void) | void,
    deps: DependencyList,
    target: Target | Target[]
  ) => {
    const hasInitRef = useRef(false);
    const lastElementRef = useRef<ElementResult[]>([]);
    const lastDepsRef = useRef<DependencyList>([]);
    const unLoadRef = useRef<(() => void) | undefined>();

    useEffectType(() => {
      const targets = Array.isArray(target) ? target : [target];
      const els: ElementResult[] = targets.map((item) =>
        getTargetElement(item, null)
      );
      // init run
      if (!hasInitRef.current) {
        hasInitRef.current = true;
        lastElementRef.current = els;
        lastDepsRef.current = deps;

        const effectResult = effect();
        unLoadRef.current =
          typeof effectResult === "function" ? effectResult : undefined;
        return;
      }

      if (
        els.length !== lastElementRef.current.length ||
        !depsAreSame(els, lastElementRef.current) ||
        !depsAreSame(deps, lastDepsRef.current)
      ) {
        unLoadRef.current?.();
        lastElementRef.current = els;
        lastDepsRef.current = deps;
        const effectResult = effect();
        unLoadRef.current =
          typeof effectResult === "function" ? effectResult : undefined;
      }
    });

    useUnmount(() => {
      unLoadRef.current?.();
      // for react-refresh
      hasInitRef.current = false;
    });
  };

  return useEffectWithTarget;
};

export default createEffectWithTarget;
