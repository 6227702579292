const normalizeHistoricalList = ({
  meta,
  data,
}: {
  meta: {};
  data: [];
}) => {
  return {
    filterTypes: [
      { type: "all", label: "Tutti" },
      { type: "simulation", label: "Simulazioni" },
      { type: "exercise", label: "Esercitazioni" },
    ],
    orderBy: {
      label: '',
      options: [
        { label: 'Ordina per', value: '' },
        { label: 'Più recente', value: 'desc' },
        { label: 'Meno recente', value: 'asc' },
      ],
    },
    meta,
    data,
  };
};

export default normalizeHistoricalList;
