import styled from 'styled-components'

export const Pagination = styled.nav`
  margin-top: ${({ theme }) => theme.rem(16)};
`

export const PaginationList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.rem(8)};
  padding: 0;
  list-style-type: none;
`

export const PaginationListNumbers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.rem(8)};

  ${({ theme }) => theme.mediaQuery.xl(`
    flex: 1 1 100%;
    order: 1;
  `, true)}
`

export const PaginationListPrevNext = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.rem(8)};

  ${({ theme }) => theme.mediaQuery.xl(`
    flex: 0;
    order: 2;
  `, true)}
`

export const PaginationListItem = styled.li`
  flex: 0 0 auto;
`

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(4)};
  background-color: #FFFFFF;
  color: #2D3035;
  padding: ${({ theme }) => theme.rem(12, 16)};
  border: 1px solid #DCE5F0;
  border-radius: ${({ theme }) => theme.rem(4)};
  font-size: ${({ theme }) => theme.rem(12)};
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    background-color: #FBF5F0;
    color: #2D3035;
  }

  &:focus-visible {
    outline: 2px solid #2D3035;
    outline-offset: 2px;
  }

  &[aria-current="page"] {
    background-color: #2D3035;
    color: #F0F4F9;
  }

  &:disabled {
    color: #B0B0B0;
    border: 1px solid #B0B0B0;
    background-color: transparent;
    cursor: not-allowed;
  }

  span {
    font-size: ${({ theme }) => theme.rem(12)};
    font-weight: 600;

    ${({ theme }) => theme.mediaQuery.md(`
      display: none;
    `, true)}
  }
`
