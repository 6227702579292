import styled from "styled-components";

export const StyledValueIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
`;
export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 16px;
  background: #ffe9d4;
  border-radius: 24px;
`;

export const FilledSlider = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  height: 100%;
  background: #fe7e39;
  border-radius: 24px;
  transition: width 0.4s ease;
`;

export const Slider = styled.input.attrs({ type: "range" })`
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: transparent;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 2;
  transition: all 0.4s ease;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #fff;
    border: 2px solid ##e7e7e7;
    filter: drop-shadow(4.5px 4.5px 4.5px rgba(176, 176, 176, 0.25));
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transition: all 0.4s ease;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #000;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export const Label = styled.span`
  position: absolute;
  font-size: 12px;
  color: #000;
  user-select: none;
`;

export const ValueLabel = styled(Label)<{ left: number }>`
  top: -35px;
  left: ${({ left }) => left}%;
  transform: translateX(-50%);
  white-space: nowrap;
  width: 25px;
  height: 25px;
  padding: 14px;
  border-radius: 50%;
  color: #fff;
  background-color: #3b3838;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transition: left 0.4s ease;
`;
