import { useState, useEffect, useRef } from "react";

const useAverageAnswerTime = () => {
  const [averageAnswerTime, setAverageAnswerTime] = useState(0);
  const startTimeRef = useRef<number | null>(null);
  const requestRef = useRef<number | null>(null);

  const updateAverageTime = () => {
    if (startTimeRef.current !== null) {
      const now = Date.now();
      const elapsedTime = now - startTimeRef.current;
      setAverageAnswerTime(elapsedTime);
    }
    requestRef.current = requestAnimationFrame(updateAverageTime);
  };

  const startTimer = () => {
    if (requestRef.current !== null) {
      cancelAnimationFrame(requestRef.current);
    }

    startTimeRef.current = Date.now();
    requestRef.current = requestAnimationFrame(updateAverageTime);
  };

  const resetTimer = () => {
    if (requestRef.current !== null) {
      cancelAnimationFrame(requestRef.current);
    }
    setAverageAnswerTime(0);
    startTimeRef.current = null;
  };

  useEffect(() => {
    return () => {
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, []);

  return { averageAnswerTime, startTimer, resetTimer };
};

export default useAverageAnswerTime;
