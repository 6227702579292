import { FC } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

interface DataItem {
  name: string;
  value: number;
}

interface PieChartWithPaddingAngleProps {
  data: DataItem[];
}

interface LabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const COLORS = ["#95C9B4", "#FF7474", "#FCC06D"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: LabelProps): JSX.Element => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN)  * 1.2;
  const y = cy + radius * Math.sin(-midAngle * RADIAN)  * 1.2;

  if (!percent) {
    return <></>;
  }

  return (
    <g>
      <circle cx={x} cy={y} r={15} fill="#F0F4F9" />
      <text
        x={x}
        y={y}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize="12"
        fontWeight="bold"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

const PieChartWithPaddingAngle: FC<PieChartWithPaddingAngleProps> = ({
  data,
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          innerRadius={55}
          outerRadius={80}
          fill="#8884d8"
          label={renderCustomizedLabel}
          labelLine={false}
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((_, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartWithPaddingAngle;
