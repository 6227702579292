// import Icon from "@/components/atoms/Icon";

import { useDrag } from "@use-gesture/react";

import * as S from "./styles";
import Icon from "@/components/atoms/Icon";
import ButtonV2 from "@/components/atoms/ButtonV2";

export interface BottomNavigationProps {
  onNext: () => void;
  onBack: () => void;
  onOpenRecap?: () => void;
  nextButtonLabel: string;
  isLoading?: boolean;
  isBackDisabled?: boolean;
  hasQuestionsList?: boolean;
  hasRecap?: boolean;
  onClickList?: () => void;
}

const BottomNavigation = ({
  onNext,
  onBack,
  onOpenRecap = () => {},
  nextButtonLabel,
  isLoading = false,
  isBackDisabled = false,
  hasQuestionsList = false,
  hasRecap = true,
  onClickList = () => {}
}: BottomNavigationProps) => {
  const bind = useDrag(({ direction: [_, dy], last }) => {
    //GO UP
    if (dy < 0 && last) {
      onOpenRecap();
    }
  });

  return (
    <S.StyledBottomNavigationContainer hasRecap={hasRecap}>
      {hasRecap && (
        <S.StyledRecapButtonContainer onClick={onOpenRecap} {...bind()}>
          <S.StyledRecapButton>
            <S.StyledDivider type="horizontal" />
            <S.StyledParagraph>Recap</S.StyledParagraph>
          </S.StyledRecapButton>
        </S.StyledRecapButtonContainer>
      )}

      <S.StyledButtons>
        <S.StyledButton
          label="Indietro"
          variant="secondary"
          iconStart="ArrowLeft"
          handleClick={onBack}
          disabled={isBackDisabled}
        />
        {hasQuestionsList && (
          <div onClick={onClickList}>
            <Icon name="QueueList" size="huge" />
          </div>
        )}
        <ButtonV2
          label={nextButtonLabel}
          variant="primary"
          iconEnd="ArrowRight"
          handleClick={onNext}
          isLoading={isLoading}
        />
      </S.StyledButtons>
    </S.StyledBottomNavigationContainer>
  );
};

export default BottomNavigation;
