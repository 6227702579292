import MinimalHeader from "@/components/molecules/MinimalHeader";
import Stepper from "@/components/organisms/Stepper";
import BaseLayout from "@/layouts/BaseLayout";
import styled from "styled-components";

interface StyledMainInterface {
  isSimulation?: boolean;
}

interface StyledMinimalHeader {
  isSimulation?: boolean;
}

export const StyledLayout = styled(BaseLayout)`
  background-color: #fff;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
background-color: transparent;
`)}
`;

export const StyledStepper = styled(Stepper)`
  background-color: ${({ theme }) =>
    theme.colorScheme.white.surface.foreground};
    margin-right: ${({ theme }) => theme.rem(-16)};
    margin-left: ${({ theme }) => theme.rem(-16)};
`;

export const StyledConfigurationPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledMinimalHeader = styled(MinimalHeader)<StyledMinimalHeader>`
  background-color: ${({ isSimulation }) =>
    isSimulation ? "#fff" : "transparent"};
`;

export const StyledMain = styled.div<StyledMainInterface>`
  // padding: ${({ theme }) => theme.rem(40, 32, 80, 32)};
  padding-top: ${({ theme }) => theme.rem(16)};
  display: flex;
  gap: ${({ theme }) => theme.rem(48)};
  margin-bottom: ${({ isSimulation }) => (isSimulation ? "0" : "150px")};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
  margin-bottom: 0;
`)}
`;

export const StyledSection = styled.section`
  flex: 1;
`;
