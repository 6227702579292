import styled from "styled-components";
import { TimeProps } from "./types";

const svgPath = "/assets/images/time-white.svg";

export const StyledTime = styled.div<Partial<TimeProps>>`
  background: url(${svgPath}) no-repeat center;
  background-color: ${({ selected }) => (selected ? "#4f4f4f" : "transparent")};
  background-size: contain;
  width: ${({ theme }) => theme.rem(100)};
  height: ${({ theme }) => theme.rem(100)};
  border: 2px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
  border-radius: 50%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in 0s;
  color: ${({ selected }) => (selected ? "#fff" : "#888")};

  p:first-of-type {
    text-align: center;
    font-weight: 600;
    font-size: ${({ theme }) => theme.rem(16)};
  }
  p:last-of-type {
    text-align: center;
    font-weight: 400;
    font-size: ${({ theme }) => theme.rem(13)};
  }

  &:hover {
    cursor: pointer;
    background-color: #888;
    p:first-of-type {
      color: ${({ theme }) => theme.colorScheme.white.button.primaryText};
    }
    p:last-of-type {
      color: ${({ theme }) => theme.colorScheme.white.button.primaryText};
    }
  }
`;
