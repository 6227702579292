import Time from "@/components/molecules/Quiz/Radio/Time";
import { TimeRadioProps } from "./types";

import * as S from './styles'


const TimeRadio = ({selectedTime, setSelectedTime, TIMES}: TimeRadioProps) => {
  return (
    <S.StyledTimeRadioContainer>
      {TIMES.map((time) => (
        <Time
          key={time.id}
          time={time.label}
          value={time.value}
          selected={selectedTime?.value === time.value}
          onClick={() => setSelectedTime(time)}
        />
      ))}
    </S.StyledTimeRadioContainer>
  );
};

export default TimeRadio;
