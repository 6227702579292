import React, { useEffect, useRef } from 'react';
import { createPopper } from '@popperjs/core';
import { PopoverContainer } from './styles';
import { Transition } from 'react-transition-group';

interface PopoverProps {
  children: React.ReactNode;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const Popover: React.FC<PopoverProps> = ({ children, open, anchorEl, onClose }) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node) &&
          anchorEl && !anchorEl.contains(event.target as Node)) {
        onClose();
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose, anchorEl]);

  useEffect(() => {
    if (anchorEl && popoverRef.current) {
      createPopper(anchorEl, popoverRef.current, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [120, 10],
            },
          },
        ],
      });
    }
  }, [anchorEl, open]);

  return (
    <Transition in={open} timeout={300} unmountOnExit>
      {state => (
        <PopoverContainer ref={popoverRef} state={state} style={{ zIndex: 1050 }}>
          {children}
        </PopoverContainer>
      )}
    </Transition>
  );
};

export default Popover;
