import styled, { css } from "styled-components";

const boldStyle = css`
  font-weight: 600;
`;

export const TableContainer = styled.table`
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
`;

export const TableRow = styled.tr`
  backgroud-color: #f0f4f9;

  &:nth-child(even) {
    background-color: #fff;
  }
`;

export const TableHeader = styled.th<{
  align?: "left" | "right" | "center";
  bold?: boolean;
}>`
  ${({ theme }) => theme.typo("paragraphMD")};

  background-color: #dce5f0;
  color: #000;
  text-align: ${({ align }) => align || "center"};
  padding: ${({ theme }) => theme.rem(24, 16)};
  vertical-align: middle;
  border: 1px solid #ddd;
  vertical-align: middle;
  font-weight: 600;
`;

export const TableCell = styled.td<{
  align?: "left" | "right" | "center";
  bold?: boolean;
}>`
  ${({ theme }) => theme.typo("paragraphSM")};
  text-align: ${({ align }) => align || "center"};
  padding: ${({ theme }) => theme.rem(12, 16)};
  border-bottom: 1px solid #ddd;
  ${({ bold }) => bold && boldStyle};
`;
