import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import { QuizInterface } from "@/pages/QuizPage/interface.ts";

import Row from "@/components/atoms/GridSystem/Row.ts";
import Col from "@/components/atoms/GridSystem/Col.ts";
import { Heading } from "@/components/atoms/Typography/index.tsx";
import { SpacerBase, Visible } from "@/components/atoms/GridSystem/index.ts";
import CardLoading from "@/components/organisms/Card/loading";
import CardHistoricalList from "@/components/molecules/CardHistoricalList/index.tsx";

import simulationImage from "@/assets/images/quiz/simulation-illustration.png";
import exerciseImage from "@/assets/images/quiz/exercise-illustration.png";

import * as S from "./styles.ts";
import ExerciseHubCard from "@/components/molecules/Cards/ExerciseHubCard.tsx";
import ButtonV2 from "@/components/atoms/ButtonV2/index.tsx";
import { Swiper, SwiperSlide } from "@/components/molecules/Swiper/index.tsx";

export interface PracticeProps {
  quizzesData: QuizInterface[];
  historicalData: any[];
  isPracticeLoading: boolean;
}

const Practice: FC<PracticeProps> = ({
  quizzesData,
  historicalData,
  isPracticeLoading = true,
}) => {
  console.log({isPracticeLoading})
  const navigate = useNavigate();

  const isSimulation = (quiz: QuizInterface) => {
    return quiz.attributes.type === "simulation";
  };

  const getTitle = (type: string) => {
    return type === "simulation"
      ? t("classic_simulation")
      : t("modular_exercise");
  };

  const getDescription = (type: string) => {
    return type === "simulation"
      ? t("challenge_yourself")
      : t("costumize_modular_exercise");
  };

  const getCtaLabel = (type: string) => {
    return type === "simulation"
      ? t("practice_simulation_cta_label")
      : t("practice_exercise_cta_label");
  };

  const goToConfigPage = (quiz: QuizInterface) => {
    navigate("/configuration", { state: quiz });
  };

  const goToHistoricalPage = () => {
    navigate("/storico");
  };

  return (
    <S.Practice>
      <Heading content="Inizia le tue esercitazioni" typo="headingSM" weight="semibold" />
      <SpacerBase xs={3} />
      <Row>
        <Col xs={12} md={7}>
          <S.PracticePerformance>
            <S.PracticePerformanceHeader>
              <Heading
                content="Performance"
                typo="headingXS"
                weight="semibold"
              />
              <ButtonV2
                label="Storico"
                variant="secondary"
                iconEnd="ArrowRight"
                handleClick={goToHistoricalPage}
              />
            </S.PracticePerformanceHeader>
            {isPracticeLoading ? (
              <div style={{ padding: "16px" }}>
                <CardLoading isLoading />
              </div>
            ) : (
              <>
                {historicalData && historicalData.length > 0 ? (
                  <S.PracticePerformanceList>
                    {historicalData.map((quiz: QuizInterface) => (
                      <CardHistoricalList
                        key={quiz.id}
                        {...(quiz as any)}
                        isEmbedded
                      />
                    ))}
                  </S.PracticePerformanceList>
                ) : (
                  <div style={{ padding: "16px" }}>
                    <Heading
                      content={'Non hai ancora svolto nessuna Simulazione o Esercitazione'}
                      typo="headingXS"
                      weight="semibold"
                    />
                  </div>
                )}
              </>
            )}
          </S.PracticePerformance>
        </Col>
        <Visible breakpoints={["md", "lg", "xl", "xxl", "xxxl"]}>
          <Col xs={12} md={5}>
            <S.PracticeCards>
              {quizzesData && quizzesData.length > 0
                ? quizzesData.map((quiz: QuizInterface) => (
                    <ExerciseHubCard
                      key={quiz.id}
                      title={getTitle(quiz.attributes.type)}
                      description={getDescription(quiz.attributes.type)}
                      image={
                        isSimulation(quiz) === true
                          ? simulationImage
                          : exerciseImage
                      }
                      ctaLabel={getCtaLabel(quiz.attributes.type)}
                      handleClick={() => goToConfigPage(quiz)}
                    />
                  ))
                : Array.from(Array(2).keys()).map((key) => (
                    <CardLoading isLoading key={key} />
                  ))}
            </S.PracticeCards>
          </Col>
        </Visible>
      </Row>
      <Visible breakpoints={["xs", "sm"]}>
        <S.PracticeCardsMobile>
          <Swiper
            hasPaddingBottom
            spaceBetween={8}
            slidesPerView={1.15}
            pagination
          >
            {quizzesData && quizzesData.length > 0
              ? quizzesData.map((quiz: QuizInterface) => (
                  <SwiperSlide key={quiz.id} style={{ overflow: "hidden" }}>
                    <ExerciseHubCard
                      title={getTitle(quiz.attributes.type)}
                      description={getDescription(quiz.attributes.type)}
                      image={
                        isSimulation(quiz) === true
                          ? simulationImage
                          : exerciseImage
                      }
                      ctaLabel={getCtaLabel(quiz.attributes.type)}
                      handleClick={() => goToConfigPage(quiz)}
                    />
                  </SwiperSlide>
                ))
              : Array.from(Array(2).keys()).map((key) => (
                  <SwiperSlide key={key}>
                    <CardLoading isLoading />
                  </SwiperSlide>
                ))}
          </Swiper>
        </S.PracticeCardsMobile>
      </Visible>
    </S.Practice>
  );
};

export default Practice;
