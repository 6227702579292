import { useId } from "react";
import * as S from "./styles";
import { ProgressBarProps } from "./types";

const ProgressBar = ({ label, value, max }: ProgressBarProps) => {
  const uid = useId();
  return (
    <S.StyledProgressBarContainer>
      <S.StyledProgressBarLabel content={label} typo="headingXS" />
      <S.StyledProgressBarValue>
        <S.StyledProgressBarNumeric as="Label" htmlFor={uid}>
          {`${value}/${max}`}
        </S.StyledProgressBarNumeric>
        <S.StyledProgress id={uid} value={value} max={max}></S.StyledProgress>
      </S.StyledProgressBarValue>
    </S.StyledProgressBarContainer>
  );
};

export default ProgressBar;
