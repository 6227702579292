import { Paragraph } from "@/components/atoms/Typography";


import * as S from "./styles";
import { TimeProps } from "./types";

const Time = ({time = 0, value = 0, selected, onClick}: TimeProps) => {
  return (
    <S.StyledTime selected={selected} value={value} onClick={onClick}>
      <Paragraph>{String(time)}</Paragraph>
      <Paragraph>MIN</Paragraph>
    </S.StyledTime>
  );
};

export default Time;
