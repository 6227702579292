import styled, { css } from "styled-components";
import { StyledIcon } from "../Icon/styles";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export const ResetButton = styled.button`
  width: auto;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-align: inherit;
  color: inherit;
  border: none;
  background: transparent;
  line-height: normal;
  overflow: hidden;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
  -webkit-appearance: none;
  font-family: "PublicSans", sans-serif;
`;

interface StyledButtonProps {
  colorScheme?: string;
  variant?: "primary" | "secondary" | "tertiary" | "quaternary";
  fullWidth?: boolean;
  isActive?: boolean;
  iconStart?: string;
  iconEnd?: string;
  $label?: boolean;
  children?: ReactNode;
}

const primaryHoverFocusActiveState = css<StyledButtonProps>`
  box-shadow: ${({ theme, colorScheme }) =>
    theme.styledVariant("variant", {
      default: "none",
      primary: `0 2px 0 0 ${
        theme.colorScheme[colorScheme!]?.button.primaryStrokeColor
      }`,
    })};

  transform: ${({ theme, iconStart, iconEnd, $label, children }) =>
    theme.styledVariant("variant", {
      default: "none",
      primary:
        (iconStart || iconEnd) && (!$label || !children)
          ? null
          : "translateY(-2px)",
    })};
`;

export const StyledUnderline = styled.span`
  display: inline-flex;
  position: absolute;
  bottom: 4px;
  left: 8px;
  fill: none;
  stroke-width: 2px;
  pointer-events: none;

  svg {
    path {
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
      transition: stroke-dashoffset 0.6s cubic-bezier(1, 0, 0, 1);
    }
  }
`;

export const StyledButton = styled(ResetButton)<StyledButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ theme, colorScheme }) => css`
    background-color: ${theme.colorScheme[colorScheme!].button
      .primaryDefaultBackground};
    /* color: ${theme.colorScheme[colorScheme!].surface.textPrimary}; */
    border-color: ${theme.colorScheme[colorScheme!].button.primaryStrokeColor};
  `}

  color: ${({ theme, colorScheme }) =>
    theme.styledVariant("variant", {
      primary: theme.colorScheme[colorScheme!].button.primaryText,
      secondary: theme.colorScheme[colorScheme!].button.secondaryText,
      tertiary: theme.colorScheme[colorScheme!].button.tertiaryText,
    })};

  background-color: ${({ theme, colorScheme }) =>
    theme.styledVariant("variant", {
      primary: theme.colorScheme[colorScheme!].button.primaryDefaultBackground,
      secondary: "transparent",
      // tertiary: theme.colorScheme[colorScheme!].button.primaryDefaultBackground,
      quaternary: "transparent",
    })};

  border: ${({ theme }) =>
    theme.styledVariant("variant", {
      primary: "1px solid",
      secondary: "2px solid transparent",
      tertiary: "1px solid transparent",
      quaternary: `1px solid ${theme.colorScheme.white.surface.borderSecondary}`,
    })};

  border-radius: ${({ theme }) =>
    theme.styledVariant("variant", {
      default: theme.rem(100),
      tertiary: theme.rem(2),
    })};

  padding: ${({ theme, $label, iconStart, iconEnd, children }) =>
    theme.styledVariant("variant", {
      primary:
        (iconStart || iconEnd) && (!$label || !children)
          ? theme.rem(8)
          : theme.rem(12, 16),
      secondary:
        (iconStart || iconEnd) && (!$label || !children)
          ? theme.rem(0)
          : iconEnd
          ? theme.rem(12, 16, 12, 24)
          : theme.rem(12, 24, 12, 16),
      tertiary: theme.rem(4, 8, 6, 8),
      quaternary:
        (iconStart || iconEnd) && (!$label || !children)
          ? theme.rem(8)
          : theme.rem(12, 16),
    })};

  // ${({ variant, iconStart, iconEnd }) =>
    variant != "primary" &&
    variant != "quaternary" &&
    css`
      &:first-of-type {
        margin-left: ${({ theme }) =>
          theme.styledVariant("variant", {
            secondary: iconEnd
              ? theme.rem(-24)
              : iconStart
              ? theme.rem(0)
              : theme.rem(0),
            tertiary: theme.rem(-8),
          })};
      }
    `}

  outline: ${({ theme }) =>
    theme.styledVariant("variant", {
      default: "none",
      primary: "2px solid transparent",
      quaternary: "2px solid transparent",
    })};

  outline-offset: ${({ theme }) =>
    theme.styledVariant("variant", {
      default: "unset",
      primary: "8px",
      quaternary: "8px",
    })};

  ${StyledUnderline} {
    right: ${({ theme, iconStart }) =>
      iconStart ? theme.rem(0) : theme.rem(8)};
    stroke: ${({ theme, colorScheme }) =>
      theme.colorScheme[colorScheme!].button.interactiveLine};
  }

  box-shadow: 0 0 0 0;
  transform: translateY(0);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, outline 0.3s ease-in-out,
    transform 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7),
    box-shadow 0.3s cubic-bezier(0.5, 2.5, 0.7, 0.7);

  ${({ theme, colorScheme, variant, iconEnd }) =>
    variant == "secondary" &&
    iconEnd &&
    css`
      &::after {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        right: 16px;
        background-color: ${theme.colorScheme[colorScheme!].button
          .primaryHoverBackground};
        border-radius: ${theme.rem(100)};
        transition: width 0.8s cubic-bezier(1, 0, 0, 1),
          height 0.6s cubic-bezier(1, 0, 0, 1),
          right 0.8s cubic-bezier(1, 0, 0, 1), background 0.3s ease-in-out;
        z-index: 0;
        pointer-events: none;
      }
    `}

  ${({ theme, colorScheme, variant, iconStart }) =>
    variant == "secondary" &&
    iconStart &&
    css`
      &::after {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        left: 24px;
        background-color: ${theme.colorScheme[colorScheme!].button
          .primaryHoverBackground};
        border-radius: ${theme.rem(100)};
        transition: width 0.8s cubic-bezier(1, 0, 0, 1),
          height 0.6s cubic-bezier(1, 0, 0, 1),
          left 0.8s cubic-bezier(1, 0, 0, 1), background 0.3s ease-in-out;
        z-index: 0;
        pointer-events: none;
      }
    `}

  &:not([disabled]),
  &:not([aria-disabled="true"]) {
    // HOVER
    @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
      &:hover {
        color: ${({ theme, colorScheme }) =>
          theme.styledVariant("variant", {
            primary: theme.colorScheme[colorScheme!].button.primaryHoverText,
            secondary:
              theme.colorScheme[colorScheme!].button.secondaryTextHover,
            tertiary: theme.colorScheme[colorScheme!].button.tertiaryText,
            quaternary: "#fff",
          })};

        background-color: ${({ theme, colorScheme }) =>
          theme.styledVariant("variant", {
            default: "transparent",
            primary:
              theme.colorScheme[colorScheme!].button.primaryHoverBackground,
              quaternary: "#4F4F4F",
          })};

        border-color: ${({ theme, colorScheme }) =>
          theme.styledVariant("variant", {
            default: "transparent",
            primary: theme.colorScheme[colorScheme!].button.primaryStrokeColor,
            quaternary: "#4F4F4F",
          })};

        ${primaryHoverFocusActiveState}

        // VARIANT SECONDARY - HOVER
        ${({ variant, iconEnd }) =>
          variant == "secondary" &&
          iconEnd &&
          css`
            &::after {
              width: calc(100% - 10px);
              height: 100%;
              right: 5px;
            }
          `}


        // VARIANT SECONDARY - HOVER
        ${({ variant, iconStart }) =>
          variant == "secondary" &&
          iconStart &&
          css`
            &::after {
              width: calc(100% - 10px);
              height: 100%;
              left: 5px;
            }
          `}

        // VARIANT TERTIARY - HOVER
        ${({ variant, $label, children }) =>
          variant == "tertiary" &&
          ($label || children) &&
          css`
            ${StyledUnderline} {
              svg {
                path {
                  stroke-dashoffset: 0;
                }
              }
            }
          `}
      }
    }

    //FOCUS-VISIBLE - TAB BUTTON
    &:focus-visible {
      outline: 2px solid
        ${({ theme, colorScheme }) =>
          theme.colorScheme[colorScheme!]?.button.primaryStrokeFocused};
    }

    // FOCUS
    &:focus,
    &:focus-visible {
      ${({ theme, colorScheme }) => css`
        color: ${theme.styledVariant("variant", {
          default: theme.colorScheme[colorScheme!]?.button.primaryHoverText,
          tertiary: theme.colorScheme[colorScheme!]?.button.tertiaryText,
          quaternary: "#FFF",
        })};
      `}
      background-color: ${({ theme, colorScheme }) =>
        theme.styledVariant("variant", {
          default: "transparent",
          primary:
            theme.colorScheme[colorScheme!]?.button.primaryHoverBackground,
            quaternary: "#4F4F4F",
        })};

      ${primaryHoverFocusActiveState}

      // VARIANT SECONDARY - FOCUS
      ${({ variant, iconEnd }) =>
        variant == "secondary" &&
        iconEnd &&
        css`
          &::after {
            width: calc(100% - 10px);
            height: 100%;
            right: 5px;
          }
        `}

        // VARIANT SECONDARY - FOCUS
        ${({ variant, iconStart }) =>
        variant == "secondary" &&
        iconStart &&
        css`
          &::after {
            width: calc(100% - 10px);
            height: 100%;
            left: 5px;
          }
        `}

      // VARIANT TERTIARY - FOCUS
      ${({ variant, $label, children }) =>
        variant == "tertiary" &&
        ($label || children) &&
        css`
          ${StyledUnderline} {
            svg {
              path {
                stroke-dashoffset: 0;
              }
            }
          }
        `}
    }

    // ACTIVE and ARIA CURRENT
    &:active,
    &[aria-current="true"] {
      background-color: ${({ theme, colorScheme }) =>
        theme.styledVariant("variant", {
          default: "transparent",
          primary:
            theme.colorScheme[colorScheme!].button.primaryPressedBackground,
            quaternary: "#1B1818",
        })};

      ${primaryHoverFocusActiveState}

      // VARIANT SECONDARY - ACTIVE
      ${({ theme, colorScheme, variant }) =>
        variant == "secondary" &&
        css`
          &::after {
            background-color: ${theme.colorScheme[colorScheme!].button
              .primaryPressedBackground};
          }
        `}

        // VARIANT TERTIARY - ACTIVE
        ${({ variant, colorScheme }) =>
        variant == "tertiary" &&
        css`
          ${StyledUnderline} {
            stroke: ${({ theme }) =>
              theme.colorScheme[colorScheme!].button.primaryPressedBackground};
          }
        `}
    }
  }

  // ARIA CURRENT
  &[aria-current="true"] {
    // VARIANT TERTIARY
    ${({ variant, $label, children }) =>
      variant == "tertiary" &&
      ($label || children) &&
      css`
        ${StyledUnderline} {
          svg {
            path {
              stroke-dashoffset: 0;
            }
          }
        }
      `}
  }

  // ICONS
  & > ${StyledIcon} {
    min-width: 30px;
    min-height: 30px;
    border-radius: 100%;
    pointer-events: none;
    z-index: 1;

    background-color: ${({ theme, colorScheme }) =>
      theme.styledVariant("variant", {
        default: theme.colorScheme[colorScheme!].button.iconBackground,
        tertiary: "transparent",
      })};

    color: ${({ theme, colorScheme }) =>
      theme.styledVariant("variant", {
        default: theme.colorScheme[colorScheme!].button.iconColor,
        tertiary: theme.colorScheme[colorScheme!].button.tertiaryIconColor,
      })};

    padding: ${({ theme }) =>
      theme.styledVariant("variant", {
        default: theme.rem(8),
        tertiary: "0",
      })};

    &:first-child {
      margin-right: ${({ theme, $label, children, iconStart, iconEnd }) =>
        ($label || children) && (iconStart || iconEnd) ? theme.rem(8) : "0"};
    }

    &:last-of-type {
      margin-left: ${({ theme, $label, children, iconStart, iconEnd }) =>
        theme.styledVariant("variant", {
          default:
            ($label || children) && (iconStart || iconEnd) ? theme.rem(8) : "0",
          tertiary:
            ($label || children) && (iconStart || iconEnd) ? theme.rem(4) : "0",
        })};
    }
  }

  // DISABLED
  &[disabled],
  &[aria-disabled="true"] {
    pointer-events: none;
    cursor: not-allowed;
    padding: ${({ theme }) => theme.rem(12, 16)};
    color: ${({ theme, colorScheme }) =>
      theme.colorScheme[colorScheme!].button.disabledText};
    border-color: transparent;

    padding: ${({ theme, iconStart, iconEnd, $label, children }) =>
      theme.styledVariant("variant", {
        primary: theme.rem(12, 16),
        secondary:
          (iconStart || iconEnd) && (!$label || !children)
            ? theme.rem(0)
            : theme.rem(8),
        tertiary: theme.rem(4, 8),
      })};

    background-color: ${({ theme, colorScheme }) =>
      theme.styledVariant("variant", {
        default: "transparent",
        primary: theme.colorScheme[colorScheme!].button.disabledBackground,
      })};

    & > i {
      background-color: ${({ theme, colorScheme }) =>
        theme.styledVariant("variant", {
          default: "transparent",
          secondary: theme.colorScheme[colorScheme!].button.disabledBackground,
        })};
    }

    // VARIANT SECONDARY - DISABLED
    ${({ variant }) =>
      variant == "secondary" &&
      css`
        &::after {
          width: 0%;
          height: 0%;
        }
      `}
  }

  // VARIANT FULL WIDTH
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  // VARIANT TERTIARY - ACTIVE

  ${({ isActive, colorScheme }) =>
    isActive &&
    css`
      @media screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
        color: ${({ theme }) =>
          theme.styledVariant("variant", {
            primary: theme.colorScheme[colorScheme!].button.primaryHoverText,
            secondary:
              theme.colorScheme[colorScheme!].button.secondaryTextHover,
            tertiary: "initial",
          })};

        background-color: ${({ theme }) =>
          theme.styledVariant("variant", {
            default: "transparent",
            primary:
              theme.colorScheme[colorScheme!].button.primaryHoverBackground,
          })};

        border-color: ${({ theme }) =>
          theme.styledVariant("variant", {
            default: "transparent",
            primary: theme.colorScheme[colorScheme!].button.primaryStrokeColor,
          })};

        ${primaryHoverFocusActiveState}

        
      })
    `}
`;

// Add similar interfaces and styles for any other styled components that use props

// // VARIANT TERTIARY - HOVER
// ${({ variant }) =>
// variant == "tertiary" &&
// css`
//   ${StyledUnderline} {
//     svg {
//       path {
//         stroke-dashoffset: 0;
//       }
//     }
//   }
// `}

export const StyledLink = styled(Link)`
  a {
    width: 100%;
  }
`;
