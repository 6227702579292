import { ReactNode, Fragment, FC } from "react";
import { StyledLink } from "./styles";

interface LinkWrapperProps {
  target?: "_blank" | "_self" | "self" | '_parent';
  href?: string;
  children: ReactNode;
}

const LinkWrapper: FC<LinkWrapperProps> = ({
  target = "_self",
  href,
  children,
}) => {
  let isInternalLink = false;

  if (href) {
    if (["_self", "self"].includes(target)) {
      isInternalLink = true;
    }
  }

  if (isInternalLink && href) {
    return <StyledLink to={href}>{children}</StyledLink>;
  } else if (href) {
    return (
      <a href={href} target={target} rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default LinkWrapper;
