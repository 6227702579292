import SegmentedControl from "@/components/atoms/SegmentedControl";

import * as S from "./styles";
import { QuizInterface } from "@/pages/QuizPage/interface";
import { Card } from "../../Quiz/CardRadio/types";
import { Dispatch, SetStateAction, useEffect } from "react";
import TimeRadio from "../../Quiz/TimeRadio";
import { Time } from "../../Quiz/TimeRadio/types";
import ValueIndicator from "@/components/molecules/Quiz/ValueIndicator";
import CardRadio, { CARDS } from "../../Quiz/CardRadio";
import SegmentedControlGroup from "@/components/atoms/SegmentedControlGroup";
import simulationImage from "@/assets/images/configuration/simulation-illustrator.png";
import exerciseImage from "@/assets/images/configuration/exercise-illustrator.png";
import useIsMobile from "@/hooks/useIsMobile";
import Recap from "../Recap";

import { useTranslation } from "react-i18next";
import Icon from "@/components/atoms/Icon";
import { QuestionSet } from "@/pages/ConfigurationPage";
import { isProductionMode } from "@/utils/isProductionMode";
import Divider from "@/components/atoms/Divider";
import TextSerif from "../../TextSerif";
import ButtonV2 from "@/components/atoms/ButtonV2";

export const TIMES = [
  {
    id: 0,
    label: "5",
    value: 5,
  },
  {
    id: 1,
    label: "15",
    value: 15,
  },
  {
    id: 3,
    label: "30",
    value: 30,
  },
  {
    id: 4,
    label: "45",
    value: 45,
  },
  {
    id: 5,
    label: "60",
    value: 60,
  },
  ...(!isProductionMode()
    ? [
        {
          id: 6,
          label: "3000",
          value: 3000,
        },
      ]
    : []),
];

interface Props {
  quiz: QuizInterface;
  setSelectedDifficulty: (val: NumberOrString) => void;
  selectedDifficulty: NumberOrString | undefined;
  selectedQuizTime: Time | undefined | { value: number };
  setSelectedQuizTime: Dispatch<
    SetStateAction<Time | undefined | { value: number }>
  >;
  selectedQuizQuestionsNumber: number;
  setSelectedQuizQuestionsNumber: (val: number) => void;
  selectedQuizMode: Card | undefined;
  setSelectedQuizMode: Dispatch<SetStateAction<Card | undefined>>;
  selectedSchoolSubjects: Option[];
  setSelectedSchoolSubjects: (val: Option[]) => void;
  currentStep: number;
  activeSteps: { label: string; active: boolean }[];
  questionSet: QuestionSet | undefined;
  createQuiz: () => void;
  isLoading: boolean;
}

const ClassicOptions = ({
  quiz,
  setSelectedDifficulty,
  selectedDifficulty,
  selectedQuizTime,
  setSelectedQuizTime,
  selectedQuizQuestionsNumber,
  setSelectedQuizQuestionsNumber,
  selectedQuizMode,
  setSelectedQuizMode,
  selectedSchoolSubjects,
  setSelectedSchoolSubjects,
  currentStep,
  activeSteps,
  questionSet,
  createQuiz,
  isLoading,
}: Props) => {
  const { t } = useTranslation();

  const DIFFICULTY_OPTIONS = [
    { label: t("difficulty_low"), value: 1 },
    { label: t("difficulty_mediun"), value: 3 },
    { label: t("difficuly_high"), value: 5 },
  ];

  const { min = 1, max = 200, minWarning, maxWarning } = questionSet || {};

  const { attributes } = quiz || {};
  // console.log({ attributes });
  const { time, questionsNumber, difficulty, group, type, schoolSubjects } =
    attributes || {};
  const { isConfigurableByUser: isModeConfigurable } = group;
  const {
    questionsNumber: totalQuestionsNumber,
    isConfigurableByUser: isQuestionsNumberConfigurable,
  } = questionsNumber || {};
  const { minutes, isConfigurableByUser: isTimeConfigurable } = time || {};
  const { isConfigurableByUser: isDifficultyConfigurable } = difficulty || {};
  const { isConfigurableByUser: isSchoolSubjectsConfigurableByUser } =
    schoolSubjects;

  const isMobile = useIsMobile();

  const isSimulation = type === "simulation";

  // const pageTitle = isSimulation
  //   ? t("classic_simulation")
  //   : t("modular_exercise");

  // const pageDescription = isSimulation
  //   ? t("first_attempt_used_for_ranking_and_statistics")
  //   : t("guided_customization_of_modular_exercise");

  // const pageHeading = isSimulation
  //   ? `Hai a disposizione ${minutes} minuti complessivi per rispondere a ${totalQuestionsNumber} domande.`
  //   : "Crea la tua esercitazione personalizzata";

  const pageHeading = isSimulation
    ? t("classic_simulation_title", { minutes, totalQuestionsNumber })
    : t("create_your_custom_exercise");

  const imgSrc = isSimulation ? simulationImage : exerciseImage;

  const mappedSchoolSubjects =
    schoolSubjects?.list?.length > 0
      ? schoolSubjects.list.map((subject) => ({
          label: subject.title,
          value: subject.id,
        }))
      : undefined;

  useEffect(() => {
    if (
      selectedSchoolSubjects &&
      mappedSchoolSubjects &&
      selectedSchoolSubjects.length === mappedSchoolSubjects.length
    ) {
      setSelectedSchoolSubjects([{ value: "ALL", label: "Tutte le materie" }]);
    }
  }, [selectedSchoolSubjects]);

  return (
    <S.StyledClassicOptionsContainer>
      {isMobile && (isSimulation || (isMobile && currentStep === 0)) && (
        <>
          {/* {!isMobile && (
              <S.StlyedTitle font="primary">{pageTitle}</S.StlyedTitle>
            )} */}
          {/* <S.StyledImageContainer>
                <S.StyledImage src={imgSrc} alt={type} />
              </S.StyledImageContainer> */}

          <S.StyledContentContainer>
            <TextSerif tag="Test Medicina, Odontoiatria, Veterinaria" />
            <S.StyledContentHeading>{pageHeading}</S.StyledContentHeading>
            <S.StyledImageContainer>
              <S.StyledImage src={imgSrc} alt={type} />
            </S.StyledImageContainer>
            {/* <S.StyledContentParagraph>{pageTitle}</S.StyledContentParagraph>
              <S.StyledContentParagraph>
                {pageDescription}
              </S.StyledContentParagraph> */}
          </S.StyledContentContainer>
        </>
      )}

      {isTimeConfigurable &&
        (!isMobile ||
          (isMobile && activeSteps[currentStep].label === "time")) && (
          <>
            <S.StyledCTAContainer>
              <S.StlyedCTAContentContainer>
                <S.StyledCTATitle>{t("exercise_duration")}</S.StyledCTATitle>
                <S.StyledCTAParagraph>
                  {t("choose_exercise_duration_for_custom_practice")}
                </S.StyledCTAParagraph>
              </S.StlyedCTAContentContainer>
              <TimeRadio
                selectedTime={selectedQuizTime}
                setSelectedTime={setSelectedQuizTime}
                TIMES={TIMES}
              />
            </S.StyledCTAContainer>
            {!isMobile && <Divider type="horizontal" />}
          </>
        )}

      {isQuestionsNumberConfigurable &&
        (!isMobile ||
          (isMobile &&
            activeSteps[currentStep].label === "questions-number")) && (
          <>
            <S.StyledCTAContainer>
              <S.StlyedCTAContentContainer>
                <S.StyledCTATitle>{t("how_many_questions")}</S.StyledCTATitle>
                <S.StyledCTAParagraph>
                  {t("decide_number_of_questions_for_custom_exercise")}
                </S.StyledCTAParagraph>
              </S.StlyedCTAContentContainer>
              <ValueIndicator
                value={selectedQuizQuestionsNumber}
                setValue={setSelectedQuizQuestionsNumber}
                min={min}
                max={max}
              />
              {minWarning && selectedQuizQuestionsNumber <= minWarning && (
                <S.StyledWarning>
                  <Icon size="huge" name="InformationCircle" />
                  <div>
                    <S.StyledWarningParagraph>
                      {t("too_few_questions_selected_time")}
                    </S.StyledWarningParagraph>
                    <S.StyledWarningParagraph>
                      {t("you_can_still_proceed")}
                    </S.StyledWarningParagraph>
                  </div>
                </S.StyledWarning>
              )}
              {maxWarning && selectedQuizQuestionsNumber >= maxWarning && (
                <S.StyledWarning>
                  <Icon size="huge" name="InformationCircle" />
                  <div>
                    <S.StyledWarningParagraph>
                      {t("too_many_questions_selected_time")}
                    </S.StyledWarningParagraph>
                    <S.StyledWarningParagraph>
                      {t("you_can_still_proceed")}
                    </S.StyledWarningParagraph>
                  </div>
                </S.StyledWarning>
              )}
            </S.StyledCTAContainer>
            {!isMobile && <Divider type="horizontal" />}
          </>
        )}

      {isSchoolSubjectsConfigurableByUser &&
        mappedSchoolSubjects &&
        (!isMobile ||
          (isMobile &&
            activeSteps[currentStep].label === "school-subjects")) && (
          <>
            <S.StyledCTAContainer>
              <S.StlyedCTAContentContainer>
                <S.StyledCTATitle>{t("select_subjects")}</S.StyledCTATitle>
                <S.StyledCTAParagraph>
                  {t("customize_exercise_by_selecting_subjects")}
                </S.StyledCTAParagraph>
              </S.StlyedCTAContentContainer>
              <SegmentedControlGroup
                options={mappedSchoolSubjects}
                selectedValue={selectedSchoolSubjects}
                onChange={setSelectedSchoolSubjects}
                disabled={!isSchoolSubjectsConfigurableByUser}
                allItemsString={t("all_subjects")}
              />
            </S.StyledCTAContainer>
            {!isMobile && <Divider type="horizontal" />}
          </>
        )}
      {isDifficultyConfigurable &&
        (!isMobile ||
          isSimulation ||
          (isMobile && activeSteps[currentStep].label === "difficulty")) && (
          <>
            <S.StyledCTAContainer>
              <S.StlyedCTAContentContainer>
                <S.StyledCTATitle>
                  {t("choose_challenge_level")}
                </S.StyledCTATitle>
                <S.StyledCTAParagraph>
                  {t("customize_exercise_by_selecting_difficulty")}
                </S.StyledCTAParagraph>
              </S.StlyedCTAContentContainer>
              <SegmentedControl
                options={DIFFICULTY_OPTIONS}
                selectedValue={selectedDifficulty}
                onChange={setSelectedDifficulty}
                disabled={!isDifficultyConfigurable}
              />
            </S.StyledCTAContainer>
            {!isSimulation && !isMobile && <Divider type="horizontal" />}
          </>
        )}
      {isModeConfigurable &&
        (!isMobile ||
          (isMobile && activeSteps[currentStep].label === "mode")) && (
          <S.StyledCTAContainer>
            <S.StlyedCTAContentContainer>
              <S.StyledCTATitle>{t("exercise_mode")}</S.StyledCTATitle>
              <S.StyledCTAParagraph>
                {t("choose_how_to_engage_with_simulation_questions")}
              </S.StyledCTAParagraph>
            </S.StlyedCTAContentContainer>
            <CardRadio
              CARDS={CARDS}
              selectedCard={selectedQuizMode}
              setSelectedCard={setSelectedQuizMode}
            />
          </S.StyledCTAContainer>
        )}
      {isMobile && currentStep === 0 && (
        <ButtonV2
          iconEnd="ArrowRight"
          handleClick={createQuiz}
          isLoading={isLoading}
        >
          {isSimulation ? "Avvia Simulazione" : "Crea esercitazione"}
        </ButtonV2>
      )}

      {isMobile && activeSteps[currentStep].label === "summary" && (
        <Recap
          quiz={quiz}
          selectedDifficulty={selectedDifficulty}
          selectedQuizTime={selectedQuizTime}
          selectedQuizQuestionsNumber={selectedQuizQuestionsNumber}
          selectedQuizMode={selectedQuizMode}
          selectedSchoolSubjects={selectedSchoolSubjects}
          onGenerateQuiz={() => {}}
          isLoading={false}
          showGenerateButton={false}
          isSummary={true}
        />
      )}
    </S.StyledClassicOptionsContainer>
  );
};

export default ClassicOptions;
