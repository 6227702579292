import { createSlice } from "@reduxjs/toolkit";


interface Exam {
  id: number;
  userId: number;
  verticalId: number;
  date: string;
  streakDate: string;
  streakCounter: number;
  createdAt: string;
  updatedAt: string;
}

export interface UserInterface {
  id: number;
  name: string;
  email: string;
  free: boolean;
  exams: Exam[]
}
interface InitialState {
  token: string | undefined;
  user: UserInterface | undefined;
}

const initialState: InitialState = {
  token: undefined,
  user: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload
    },
    login(state, action) {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    logout(state) {
      state.token = undefined;
      state.user = undefined;
    },
  },
});

export const { login, logout, setToken, setUser } = authSlice.actions;
export const authReducer = authSlice.reducer;
