import * as S from "./styles";
import { MenuItemProps } from "./types";

const MenuItem = ({
  label,
  onClick,
  icon,
  ...props
}: MenuItemProps) => {
  return (
    <>
      <S.StyledMenuItem onClick={onClick} {...props}>
        <S.StyledMenuItemLabel>{label}</S.StyledMenuItemLabel>
        <S.StyledIcon name={icon} />
      </S.StyledMenuItem>
    </>
  );
};

export default MenuItem;
