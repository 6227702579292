import styled from "styled-components";
import { CardProps } from "./types";
import { Paragraph } from "@/components/atoms/Typography";
import Icon from "@/components/atoms/Icon";

export const StyledCardRadioContainer = styled.div<Partial<CardProps>>`
  padding: ${({ theme }) => theme.rem(16)};
  border: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
  border-radius: ${({ theme }) => theme.rem(8)};
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: ${({ selected }) => (selected ? "#3B3838" : "transparent")};
  color: ${({ selected }) => (selected ? "#fff" : "#3B3838")};
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCardRadioContent = styled.div`
  display: flex;
  gap: 16px;
`;

export const StyledCardRadioIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  color: transparent;
`;

export const StyledTitle = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphMD")};
  font-weight: 600;
`;
