import { FC } from "react";
import { TooltipProps } from "recharts";
import { Paragraph } from "@/components/atoms/Typography";
import * as S from "./styles";

interface CustomPayload {
  dataKey: string;
  value: number;
  fill?: string;
}

const formatTime = (minutes: number): string => {
  if (minutes > 0 && minutes < 1) {
    return '< 1 minuto';
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);

  const hoursPart = hours > 0 ? `${hours}h` : '';
  const minutesPart = remainingMinutes > 0 ? `${remainingMinutes}m` : '';

  return `${hoursPart} ${minutesPart}`.trim();
};


const CustomTooltip: FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    const simulation = payload.find(
      (p) => (p as CustomPayload).dataKey === "simulation"
    ) as CustomPayload;
    const exercise = payload.find(
      (p) => (p as CustomPayload).dataKey === "exercise"
    ) as CustomPayload;
    const simulationValue = simulation?.value;
    const exerciseValue = exercise?.value;
    

    const renderedSimulationValue = simulationValue ? formatTime(simulationValue) : 0;
    const renderedExerciseValue = exerciseValue ? formatTime(exerciseValue) : 0;

    return (
      <S.StyledCustomTooltip>
        <Paragraph content={label} typo="paragraphMD" weight="semibold" />
        {simulationValue !== undefined && (
          <Paragraph
            content={`Simulazioni: ${
              renderedSimulationValue
            }`}
            typo="paragraphSM"
            style={{ color: simulation?.fill }}
          />
        )}
        {exerciseValue !== undefined && (
          <Paragraph
            content={`Esercitazioni: ${
              renderedExerciseValue
            }`}
            typo="paragraphSM"
            style={{ color: exercise?.fill }}
          />
        )}
      </S.StyledCustomTooltip>
    );
  }

  return null;
};

export default CustomTooltip;
