import Icon from "../Icon";
import { Paragraph } from "../Typography";
import * as S from "./styles";
import { LaunchCardProps, Livel } from "./types";

export const subjectIcons: { [key: string]: string } = {
  Chimica: "Chimica",
  "Fisica e matematica": "Fisica",
  "Fisica e Matematica": "Fisica",
  "Ragionamento logico e problemi": "RagionamentoLogico",
  Biologia: "Biologia",
  "Competenze di lettura": "CulturaGenerale",
  "Competenze di lettura e conoscenze acquisite negli studi": "CulturaGenerale",
};

const LaunchCard = ({
  subject,
  time,
  questionsNumber,
  correctAnswers,
  onClick,
  isFullVersion = false,
}: LaunchCardProps) => {
  const icon = subjectIcons[subject || ""];
  const correctAnswersPercentage =
    questionsNumber && Math.round((correctAnswers / questionsNumber) * 100);
  const percentageLabel = (): Livel => {
    let label: Livel;
    if (correctAnswersPercentage > 66) {
      label = "Ottimo!";
    } else if (
      correctAnswersPercentage > 33 &&
      correctAnswersPercentage <= 66
    ) {
      label = "Medio";
    } else {
      label = "Scarso";
    }
    return label;
  };

  const percentageColor = (): number => {
    switch (percentageLabel()) {
      case "Ottimo!":
        return 3;
      case "Medio":
        return 2;
      case "Scarso":
        return 1;
      default:
        return 1;
    }
  };
  return (
    <S.StyledLaunchCard subject={subject} onClick={onClick}>
      <S.StyledLaunchCardContent isFullVersion={isFullVersion}>
        <S.StyledIconContainer subject={subject}>
          <Icon name={icon} />
        </S.StyledIconContainer>
        <S.StyledSubjectInfo>
          <S.StyledSubjectHeading weight="semibold" typo="headingXS">
            {subject}
          </S.StyledSubjectHeading>
          <S.StyledSubjectLevelItems isFullVersion={isFullVersion}>
            <S.StyledSubjectLevelDescription typo="paragraphSM" weight="semibold">
              {percentageLabel()}
            </S.StyledSubjectLevelDescription>
            <S.StyledSubjectLevelItemsLines>
              {[...Array(3).keys()].map((item) => (
                <S.StyledSubjectLevelItem
                  key={item}
                  className={percentageColor() === item + 1 ? "active" : ""}
                />
              ))}
            </S.StyledSubjectLevelItemsLines>
          </S.StyledSubjectLevelItems>
          <S.StyledSubjectDetails isFullVersion={isFullVersion}>
            <S.StyledSubjectDetailsItem>
              <Icon size="defaultBig" name="Alarm" />
              <S.StyledSubjectItemParagraph typo="paragraphSM">
                {`${time}`}
              </S.StyledSubjectItemParagraph>
            </S.StyledSubjectDetailsItem>
            <S.StyledSubjectDetailsItem>
              <Icon size="defaultBig" name="Quiz" />
              <S.StyledSubjectItemParagraph typo="paragraphSM">
                {`${questionsNumber} quesiti visti`}
              </S.StyledSubjectItemParagraph>
            </S.StyledSubjectDetailsItem>
            <S.StyledSubjectDetailsItem>
              <Icon size="defaultBig" name="Seen" />
              <S.StyledSubjectItemParagraph typo="paragraphSM">
                {`${correctAnswersPercentage}%`}
              </S.StyledSubjectItemParagraph>
            </S.StyledSubjectDetailsItem>
          </S.StyledSubjectDetails>
        </S.StyledSubjectInfo>
      </S.StyledLaunchCardContent>
      <S.StyledLaunchCardCTAContainer isFullVersion={isFullVersion}>
        <Paragraph typo="buttonMD" weight="semibold">
          Esercitati Ora
        </Paragraph>
        <Icon name="ArrowRight" />
      </S.StyledLaunchCardCTAContainer>
    </S.StyledLaunchCard>
  );
};

export default LaunchCard;
