import LoadingUI from "@/components/organisms/LoadingUI";
import { setToken, setUser } from "@/store/slices/authSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const LogoutPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    navigate("/");
  };
  useEffect(() => {
    logout();
  }, []);
  return <LoadingUI />
};

export default LogoutPage;
