import { createGlobalStyle } from "styled-components";
import { primaryFont, secondaryFont } from "./fonts";

// import "swiper/css";
// import "swiper/css/a11y";
// import "swiper/css/scrollbar";
// import "swiper/css/mousewheel";
// import "swiper/css/navigation";
// import "swiper/css/effect-fade";
// import "swiper/css/thumbs";

const GlobalStyles = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  // blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    border: 0;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  picture {
    display: block;
  }

  body {
    ${secondaryFont.style}
    line-height: 1;
    overscroll-behavior-y: none;
    background: #F0F4F9;
  }

  ol,
  ul {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  // blockquote,
  // q {
  //   quotes: none;
  // }

  // blockquote::before,
  // blockquote::after,
  // q::before,
  // q::after {
  //   content: '';
  //   content: none;
  // }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    ${secondaryFont.style}

  }

  ::-webkit-input-placeholder {
    color: transparent;
  }
  ::-moz-placeholder {
    color: transparent;
  }
  :-ms-input-placeholder {
    color: transparent;
  }
  :-moz-placeholder {
    color: transparent;
  }

  // Basic Styles
  html {
    width: 100%;
    ${primaryFont.style}
    font-weight: 400;
  }

  body {
    width: 100%;
    overflow-x: hidden;
  }

  .sr-only {
    width: 1px;
    height: 1px;
    position: absolute;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
  }

  /* .text-highlighted { */
  .highlighted {
    ${primaryFont.style}
    font-style: italic;
    color: #E5683E;
  }

  .text-italic {
    ${primaryFont.style}
    font-style: italic;
  }

  .spinner-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    border: 0px;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
  }
  .spinner {
    width: 64px;
    height: 64px;
    animation: spin 1s linear infinite;
    border: 2px solid #FFFDFC;
    border-top: 2px solid #FFB683;
    border-radius: 50%;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  [data-rsbs-overlay]{
    z-index: 999;
  }
`;
export default GlobalStyles;
