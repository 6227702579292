import { useEffect, useState } from "react";
import * as S from "./styles";
import TextSerif from "@/components/organisms/TextSerif";

// import stydyImage from "@/assets/images/landing-page/alpha-test-study.png";
// import Footer from "./components/organisms/Footer";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import BaseLayout from "@/layouts/BaseLayout";
import { useTranslation } from "react-i18next";
import LoadingUI from "@/components/organisms/LoadingUI";
import Sidebar from "@/components/organisms/Sidebar";
// import InfoMessage from "@/components/atoms/InfoMessage";

//Hooks
import useQuizzes from "@/hooks/useQuizzes";
import SimpleBarChart from "@/components/organisms/SimpleBarChart";
// import LaunchCard from "@/components/atoms/LaunchCard";
import PerformanceStatistics from "@/components/organisms/PerformanceStatistics";
import useIsMobile from "@/hooks/useIsMobile";
import SubjectsChartContent from "./SubjectsChartContent";

import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { Heading } from "@/components/atoms/Typography";
import IconButton from "@/components/atoms/ButtonIcon";
import Modal from "@/components/organisms/Modal";
import LaunchCardModalContent from "./LaunchCardModalContent";
import { generateQuiz } from "@/api/services/quizzes";
import { useNavigate } from "react-router-dom";
import Countdown from "@/components/organisms/Countdown";
// import { UserInterface } from "@/store/slices/authSlice";
import { NormalizedPerformanceStatistics } from "@/models/organisms/PerformanceStatistics";
import StreakCounter from "@/components/organisms/StreakCounter";
import ExamDate from "@/components/organisms/ExamDate";
import Practice, { PracticeProps } from "@/components/organisms/Practice";

const HomePage = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const isAuthenticated = useSelector<RootState>((state) => state.auth.token);

  console.log({ isAuthenticated });

  const {
    isStatisticsLoading,
    normalizedQuizzes,
    quizzesStatistics,
    isLoading,
    practice,
    normalizedPerformanceStatistics,
  } = useQuizzes();

  // const userInfo = useSelector<RootState>(
  //   (state) => state.auth.user
  // ) as UserInterface;
  const [showFullCardsModal, setShowFullCardsModal] = useState(false);
  const [showStartTestModal, setShowStartTestModal] = useState(false);
  const [currentTestSubject, setCurrentTestSubject] = useState<string>("");
  const [currentTestSubjectId, setCurrentTestSubjectId] = useState<number>();
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [generatedQuiz, setGeneratedQuiz] = useState();
  const [isQuizReady, setIsQuizReady] = useState(false);

  // const [quizzes, setQuizzes] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [isUserHasRoles, setIsUserHasRoles] = useState(true);
  // const navigate = useNavigate();

  // const getAllQuizzes = async () => {
  //   setIsLoading(true);
  //   try {
  //     const { data } = await getQuizConfig();
  //     if (data?.unauthorized === true) {
  //       setIsUserHasRoles(false);
  //     } else {
  //       const quizzesData = data?.data;
  //       setQuizzes(quizzesData);
  //     }
  //   } catch (e) {
  //     console.log({ e });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   getAllQuizzes();
  // }, []);

  const loginHandler = () => {
    window.location.href = `${import.meta.env.VITE_API_URL}/login`;
  };

  const onDismissHandler = (modalType: "full-cards" | "test") => {
    if (modalType === "full-cards") {
      setShowFullCardsModal(false);
    } else {
      setShowStartTestModal(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      loginHandler();
    }
  }, [isAuthenticated]);

  const onLaunchCardClick = (subject: string, id: number) => {
    setShowFullCardsModal(false);
    setCurrentTestSubject(subject);
    setCurrentTestSubjectId(id);
    setShowStartTestModal(true);
  };

  const renderedStatistics =
    quizzesStatistics?.verticals &&
    quizzesStatistics?.verticals &&
    quizzesStatistics?.verticals[0].schoolSubjects.map((el) => {
      return {
        subject: el.label,
        questionsNumber: el.totalQuestion,
        correctAnswers: el.correctAnswers,
        time: el.averageResponseTime,
        onClick: () => onLaunchCardClick(el.label, el.id),
      };
    });

  const generateQuizHandler = async () => {
    // setIsLoading(true);
    try {
      const data = {
        data: {
          type: "exercise",
          difficulty: 3,
          time: 10,
          group: "schoolSubject",
          questionsNumber: 7,
          schoolSubjects: [currentTestSubjectId],
          rightAnswerScore: 0,
          wrongAnswerScore: 0,
          nullAnswerScore: 0,
          idConfig: 2,
        },
      };
      const { data: generatedQuiz } = await generateQuiz(data);
      setGeneratedQuiz(generatedQuiz);
      setIsQuizReady(true);
    } catch (e) {
      console.log({ e });
    } finally {
      // setIsLoading(false);
    }
  };

  const startQuizHandler = async () => {
    try {
      await generateQuizHandler();
      setShowStartTestModal(false);
    } catch (error) {
      console.log({ error });
    } finally {
    }
  };

  if (!isAuthenticated) {
    return <LoadingUI />;
  }

  const onTimeEnd = () => {
    const quiz = {
      attributes: {
        type: "exercise",
        title: currentTestSubject,
        score: {
          hasScore: true,
          nullAnswerQuestion: 1,
          rightAnswerQuestion: 1,
          wrongAnswerQuestion: 1,
        },
      },
    };

    navigate("/quiz", {
      state: { generatedQuiz: generatedQuiz, config: quiz },
      replace: true,
    });
  };

  console.log({ isStatisticsLoading });

  return (
    <BaseLayout
      headerComponent={<Sidebar />}
      flexDirection="row"
      isHeaderSticky={isMobile ? true : false}
    >
      {isMobile && (
        <BottomSheet
          blocking={true}
          open={showFullCardsModal}
          onDismiss={() => onDismissHandler("full-cards")}
          defaultSnap={({ snapPoints, lastSnap }) =>
            lastSnap ?? Math.min(...snapPoints)
          }
          snapPoints={({ maxHeight }) => [maxHeight]}
          header={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton name="XArrow" style={{ visibility: "hidden" }} />
              <Heading typo="headingSM" weight="semibold">
                Trend
              </Heading>
              <IconButton
                name="XMark"
                onClick={() => onDismissHandler("full-cards")}
              />
            </div>
          }
        >
          {/* <S.StyledBottomSheetListContainer> */}
          <S.StyledBottomSheetListContainer
            items={renderedStatistics}
            isFullVersion={true}
          />
          {/* </S.StyledBottomSheetListContainer> */}
        </BottomSheet>
      )}

      {isMobile ? (
        <BottomSheet
          blocking={true}
          open={showStartTestModal}
          onDismiss={() => onDismissHandler("test")}
          defaultSnap={({ snapPoints, lastSnap }) =>
            lastSnap ?? Math.min(...snapPoints)
          }
          snapPoints={({ maxHeight }) => [maxHeight]}
          header={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton name="XArrow" style={{ visibility: "hidden" }} />
              <Heading
                typo="headingSM"
                weight="semibold"
                style={{ visibility: "hidden" }}
              >
                Trend
              </Heading>
              <IconButton name="XMark" onClick={onDismissHandler} />
            </div>
          }
        >
          <LaunchCardModalContent
            onClose={() => onDismissHandler("test")}
            onStart={startQuizHandler}
            subject={currentTestSubject}
            isLoading={isLoading}
          />
        </BottomSheet>
      ) : (
        <Modal
          isOpen={showStartTestModal}
          onClose={() => onDismissHandler("test")}
        >
          <LaunchCardModalContent
            isLoading={isLoading}
            onClose={() => onDismissHandler("test")}
            onStart={startQuizHandler}
            subject={currentTestSubject}
          />
        </Modal>
      )}

      {isQuizReady && (
        <Countdown exerciseType={"exercise"} onTimeEnd={onTimeEnd} />
      )}

      <S.StyledMainContainer>
        <S.StyledHeaderContainer>
          <TextSerif
            tag="Test Medicina, Odontoiatria, Veterinaria"
            title={`${t("welcome.back")}`}
          />
          <S.StyledStreakContainer>
            <S.StyledStreak>
              <StreakCounter />
            </S.StyledStreak>
            <S.StyledStreak>
              <ExamDate />
            </S.StyledStreak>
          </S.StyledStreakContainer>
        </S.StyledHeaderContainer>
        <S.StyledSubjectsContainer>
          {isStatisticsLoading || !renderedStatistics ? (
            <S.StyledSkeletonContainer>
              <S.StyledSkeleton />
              {isMobile ? null : <S.StyledSkeleton />}
            </S.StyledSkeletonContainer>
          ) : (
            <>
              <S.StyledSubjectsChartContainer>
                {!isMobile && <SubjectsChartContent />}
                <S.StyledSubjectsChart>
                  <SimpleBarChart data={normalizedQuizzes?.mappedStatistics} />
                </S.StyledSubjectsChart>
                <S.StyledSubjectsChartFooter>
                  <S.StyledInfoMessage
                    type="negative"
                    info="🚨 Potenzia le tue conoscenze in "
                    message={
                      normalizedQuizzes?.lowestScoreSubject?.subject || ""
                    }
                    hasBackground={true}
                  />
                </S.StyledSubjectsChartFooter>
              </S.StyledSubjectsChartContainer>
              {!isMobile ? (
                // <S.StyledLaunchCardContainer>
                <S.StyledLaunchCardContainer
                  items={renderedStatistics}
                  isFullVersion={true}
                />
              ) : null}
            </>
          )}
        </S.StyledSubjectsContainer>
        {isMobile ? (
          <>
            <S.StyledLaunchCardContainerMobile>
              <SubjectsChartContent
                onClick={() => setShowFullCardsModal(true)}
              />
              {isStatisticsLoading || !renderedStatistics ? (
                <S.StyledSkeletonContainer
                  style={{ marginTop: 16, height: 200 }}
                >
                  <S.StyledSkeleton />
                </S.StyledSkeletonContainer>
              ) : (
                <S.StyledLaunchCardListContainerMobile
                  items={renderedStatistics}
                  isFullVersion={false}
                />
              )}
            </S.StyledLaunchCardContainerMobile>
          </>
        ) : null}
      </S.StyledMainContainer>

      <Practice {...(practice as PracticeProps)} />

      <PerformanceStatistics
        {...(normalizedPerformanceStatistics as NormalizedPerformanceStatistics)}
      />
    </BaseLayout>
  );
};

export default HomePage;
