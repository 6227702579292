import convertToObject from "@utils/convertToObject";
import themeConfig from "./config";

interface ProcessCssParams {
  [key: string]: string | number;
}

export interface ThemeColors {
  primary: { [key: string]: string };
  secondary: { [key: string]: string };
  grayscale: { [key: string]: string };
  brands: { [key: string]: string };
  status: {
    success: { [key: string]: string };
    warning: { [key: string]: string };
    error: { [key: string]: string };
  };
}

export interface ThemeTypographyStyle {
  "font-size": number;
  "line-height": number;
  "letter-spacing"?: string;
  mq?: {
    [key: string]: {
      "font-size": number;
      "line-height": number;
      "letter-spacing"?: string;
    };
  };
}

export interface ThemeTypography {
  [key: string]: ThemeTypographyStyle;
}

export interface ThemeBreakpoints {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

export interface Theme {
  siteColumns: number;
  ease: string;
  timing: { [key: string]: string };
  margins: { [key: string]: string };
  defaults: {
    size: number;
  };
  colorScheme: any;
  colors: ThemeColors;
  shadows: { [key: string]: string };
  typography: ThemeTypography;
  layers: { [key: string]: number };
  breakpoints: ThemeBreakpoints;
  dimensions: {
    header: {
      mobile: number;
      desktop: number;
    };
  };
}

interface RawCss {
  raw: string[];
}

type Props = { [key: string]: any };
type MapOfStyles = { [key: string]: any };
type Args = [string, MapOfStyles] | [TemplateStringsArray, ...any[]];

let theme: Theme = themeConfig;

const processCss = (css: string | ProcessCssParams | RawCss): string => {
  if (!css) return "";

  let injectedCss = "";

  if (typeof css === "string") return css;

  if ("raw" in css && Array.isArray(css.raw)) {
    return css.raw[0];
  }

  for (const [key, value] of Object.entries(css as ProcessCssParams)) {
    let processedValue = value;
    if (key === "font-size" || key === "line-height") {
      processedValue = rem(value as number);
    }
    injectedCss += `${key}: ${processedValue}; `;
  }

  return injectedCss;
};

export let mediaQuery: {
  [key: string]: (css: string | ProcessCssParams, until?: boolean) => string;
} = {};

for (const key of Object.keys(theme.breakpoints)) {
  mediaQuery[key] = (css, until = false): string => {
    const query = until ? "max-width" : "min-width";
    const breakpoint = theme.breakpoints[key as keyof ThemeBreakpoints];
    return `
      @media screen and (${query}: ${breakpoint}px) {
        ${processCss(css)}
      }
    `;
  };
}

export function rem(...values: number[]): string {
  return values.map((px) => `${px / 16}rem`).join(" ");
}

export function col(...values: number[]): string[] {
  return values.map((col) => `calc(${col} / ${theme.siteColumns} * 100vw);`);
}

export function color(
  key: keyof ThemeColors,
  variant: string = "default"
): string {
  const colorValue = theme.colors[key];
  if (typeof colorValue === "object" && colorValue !== null) {
    // Record<string, string> (un tipo di oggetto con chiavi di tipo stringa e valori di tipo stringa)
    if (variant in colorValue) {
      const variantValue = (colorValue as Record<string, string>)[variant];
      return variantValue;
    }
  } else if (typeof colorValue === "string") {
    return colorValue;
  }

  return "inherit";
}

export function getColorByScheme(
  scheme: string,
  type: string = "button",
  variant: string = "primaryHoverBackground"
): string {
  if (!theme.colorScheme[scheme]) return "white";
  return theme.colorScheme[scheme][type][variant]
    ? theme.colorScheme[scheme][type][variant]
    : theme.colorScheme[scheme];
}

export function spacing(scalar: number = 1): string {
  return rem(scalar * theme.defaults.size);
}

export function typo(key: string): string {
  let styles = '';

  const typeStyles = theme.typography[key];
  if (typeStyles) {
    for (const [property, value] of Object.entries(typeStyles)) {
      if (property === 'mq' && typeStyles.mq) {
        for (const [breakpoint, styleObj] of Object.entries(typeStyles.mq)) {
          const breakpointStyles = Object.entries(styleObj)
            .map(([prop, val]) => {
              const cssValue = (prop === 'font-size' || prop === 'line-height') ? rem(val as number) : `${val}`;
              return `${prop}: ${cssValue};`;
            })
            .join(' ');

          // Utilizzando un cast esplicito per assicurarsi che il breakpoint sia valido
          const breakpointPx = theme.breakpoints[breakpoint as keyof ThemeBreakpoints];
          styles += `
            @media (min-width: ${breakpointPx}px) {
              ${breakpointStyles}
            }
          `;
        }
      } else if (property !== 'mq') {
        const cssValue = (property === 'font-size' || property === 'line-height') ? rem(value as number) : `${value}`;
        styles += `${property}: ${cssValue}; `;
      }
    }
  }

  return styles;
}

export const styledVariant =
  (...args: Args) =>
  (props: Props): any => {
    let mapOfStyles: MapOfStyles;

    if (typeof args[0] === "object" && "raw" in args[0]) {
      // Caso di tagged template literal
      mapOfStyles = convertToObject(
        ...(args as [TemplateStringsArray, ...any[]])
      )(props);
    } else {
      // Uso normale con oggetto
      mapOfStyles = args[args.length - 1] as MapOfStyles;
    }

    const styleKeys = Object.keys(mapOfStyles);

    if (typeof args[0] === "string") {
      // Se il primo argomento è una stringa, styledVariant funziona in modo diverso:
      const val = props[args[0]];

      if (mapOfStyles[val]) return mapOfStyles[val];
    } else {
      // Altrimenti, procediamo nel modo normale
      const matchingKeys = styleKeys.filter((key) => props[key]);

      if (matchingKeys.length) return mapOfStyles[matchingKeys.pop()!];
    }

    // Se finora non abbiamo trovato corrispondenze, cerchiamo un elemento "default" nella nostra mappa
    if (mapOfStyles.hasOwnProperty("default")) {
      return mapOfStyles.default;
    }

    // Altrimenti, restituiamo semplicemente l'ultimo elemento, qualunque esso sia
    return mapOfStyles[styleKeys.pop()!];
  };

export const checkBreakpoint = {
  xs: ({ width }: { width: number }) => width >= theme.breakpoints.xs,
  sm: ({ width }: { width: number }) => width >= theme.breakpoints.sm,
  md: ({ width }: { width: number }) => width >= theme.breakpoints.md,
  lg: ({ width }: { width: number }) => width >= theme.breakpoints.lg,
  xl: ({ width }: { width: number }) => width >= theme.breakpoints.xl,
  xxl: ({ width }: { width: number }) => width >= theme.breakpoints.xxl,
  not: {
    xs: ({ width }: { width: number }) => width < theme.breakpoints.xs,
    sm: ({ width }: { width: number }) => width < theme.breakpoints.sm,
    md: ({ width }: { width: number }) => width < theme.breakpoints.md,
    lg: ({ width }: { width: number }) => width < theme.breakpoints.lg,
    xl: ({ width }: { width: number }) => width < theme.breakpoints.xl,
    xxl: ({ width }: { width: number }) => width < theme.breakpoints.xxl,
  },
};
