import { AnimatePresence } from "framer-motion";
import ReactDOM from "react-dom";
import * as S from "./styles";
import { ModalProps } from "./types";

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};
// const containerVariant = {
//   initial: { top: "-50%", transition: { type: "spring" } },
//   isOpen: { top: "50%" },
//   exit: { top: "-50%" },
// };

const Modal = ({
  onClose,
  children,
  isOpen,
  // outBody,
  closeButton = true,
  closable = true,
  isFullScreen = false,
}: ModalProps) => {
  const onCloseHandler = () => {
    if (!closable) {
      return;
    }
    onClose();
  };
  const modalContent = isOpen ? (
    <S.StlyedOverlay
      initial="initial"
      animate="isOpen"
      exit="exit"
      variants={modalVariant}
      onClick={onCloseHandler}
    >
      <S.StyledModalContainer
        onClick={(e) => e.stopPropagation()}
        isFullScreen={isFullScreen}
      >
        <S.StyledModalBody isFullScreen={isFullScreen}>
          {closeButton && (
            <S.StyledCloseIconContainer onClick={onClose}>
              <S.StyledCloseIcon name="XMark" size="huge" />
            </S.StyledCloseIconContainer>
          )}
          {children}
          {/* {typeof outBody === "function" ? outBody() : null} */}
        </S.StyledModalBody>
      </S.StyledModalContainer>
    </S.StlyedOverlay>
  ) : null;

  return ReactDOM.createPortal(
    <AnimatePresence>{modalContent}</AnimatePresence>,
    document.getElementById("modal-root")!
  );
};

export default Modal;
