import useLatest from "./useLatest";
import useEffectWithTarget from "./useEffectWithTarget";
import getTargetElement, { TargetType } from "@utils/get-target-element";

interface Options {
  target?: TargetType;
  capture?: boolean;
  once?: boolean;
  passive?: boolean;
}
const useEventListener = (
  eventName: string,
  handler: (event: Event) => void,
  options: Options = {}
) => {
  const handlerRef = useLatest(handler);

  useEffectWithTarget(
    () => {
      const targetElement = getTargetElement(options.target, window);
      if (!targetElement?.addEventListener) {
        return;
      }

      const eventListener = (event: Event) => {
        if (typeof handlerRef.current === "function") {
          handlerRef.current(event);
        }
      };

      targetElement.addEventListener(eventName, eventListener, {
        capture: options.capture,
        once: options.once,
        passive: options.passive,
      });

      return () => {
        targetElement.removeEventListener(eventName, eventListener, {
          capture: options.capture,
        });
      };
    },
    [eventName, options.capture, options.once, options.passive],
    options.target
  );
};

export default useEventListener;
