import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom"; // Import useSearchParams hook

import {
  FiltersQueryProps,
  getHistorical,
  getHistoricalChart,
} from "@/api/services/historical";
import normalizeHistoricalCharts from "@/models/organisms/HistoricalCharts";
import normalizeHistoricalList from "@/models/organisms/HistoricalList";
import { HistoricalChartsProps } from "@/components/organisms/HistoricalCharts";
import { HistoricalListProps } from "@/components/organisms/HistoricalList";
import { isProductionMode } from "@/utils/isProductionMode";

const useHistorical = () => {
  const isProduction = isProductionMode();
  const [searchParams] = useSearchParams();

  const page = searchParams.get("page") as string;
  const type = searchParams.get("type") as string;
  const order = searchParams.get("order") as string;

  const [historicalCharts, setHistoricalCharts] =
    useState<HistoricalChartsProps>();
  const [historicalList, setHistoricalList] = useState<HistoricalListProps>();

  const getHistoricalChartsData = async () => {
    try {
      const [chartSimulation, chartExercise] = await Promise.all([
        getHistoricalChart({ type: "simulation" } as FiltersQueryProps),
        getHistoricalChart({ type: "exercise" } as FiltersQueryProps),
      ]);

      const normalizedHistoricalCharts = normalizeHistoricalCharts({
        simulations: chartSimulation.data,
        exercises: chartExercise.data,
      });

      setHistoricalCharts(normalizedHistoricalCharts as any);
    } catch (error) {
      console.log({ error });
    }
  };

  const getHistoricalListData = async (args: FiltersQueryProps) => {
    try {
      const { data } = await getHistorical(args);
      const normalizedHistoricalList = normalizeHistoricalList(data);
      setHistoricalList(normalizedHistoricalList);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    const args = {
      page: page && !isNaN(parseInt(page, 10)) ? parseInt(page, 10) : 1,
      type: type || "all",
      order: order || "desc",
    };

    getHistoricalListData(args);
  }, [page, type, order]);

  useEffect(() => {
    if (!isProduction) {
      getHistoricalChartsData();
    }
  }, []);

  return {
    historicalCharts,
    historicalList,
  };
};

export default useHistorical;
