// fontStyles.ts
export const primaryFont = {
  style: `
      font-family: 'CoFoRobert', serif;
      font-weight: 500;
      font-style: normal;
    `,
};

export const secondaryFont = {
  style: `
      font-family: 'PublicSans', sans-serif;
      font-weight: 400;
      font-style: normal;
    `,
};
