import Icon from "@atoms/Icon";
import { ButtonLabel } from "@atoms/Typography";
import { SectionThemeContext } from "@contexts/SectionTheme";
import { getAriaProps } from "@utils/linkUtils";
import React, { Ref, forwardRef, useContext } from "react";
import LinkWrapper from "./LinkWrapper";
import { StyledButton } from "./styles";

interface ButtonProps {
  type?: "button" | "submit" | "reset";
  href?: string;
  label?: string;
  typo?: "linkMD" | "linkSM" | "buttonMD" | "buttonSM";
  variant?: "primary" | "secondary" | "tertiary" | "quaternary";
  size?: "small" | "large";
  handleClick?: () => void;
  iconStart?: string;
  iconEnd?: string;
  iconStartSize?: string;
  iconEndSize?: string;
  useIconColor?: boolean;
  uppercase?: boolean;
  handleOnKeyDown?: () => void;
  scrollTo?: string;
  inheritedColorScheme?: string;
  ariaLabel?: string;
  tabIndex?: number;
  disabled?: boolean;
  target?: "_self" | "_blank" | "_parent";
  children?: React.ReactNode;
  innerRef?: Ref<HTMLButtonElement>;
  colorScheme?: "white" | "dark";
  isLoading?: boolean;
  hasLine?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
}

const ButtonV2: React.FC<ButtonProps> = ({
  children,
  type = "button",
  href,
  label,
  typo = "buttonMD",
  variant = "primary",
  handleClick = () => {},
  iconStart,
  iconEnd,
  iconStartSize,
  iconEndSize,
  useIconColor = false,
  uppercase,
  handleOnKeyDown,
  scrollTo,
  inheritedColorScheme = null,
  innerRef,
  colorScheme = "white",
  isLoading = false,
  hasLine = true,
  fullWidth = false,
  size = "small",
  ...props
}) => {
  // existing implementation...
  // Button Variant
  const btnProps = Object.assign(
    {},
    label ? { $label: label } : null,
    iconStart ? { iconStart } : null,
    iconEnd ? { iconEnd } : null,
    variant ? { variant } : null
  );

  // Button label props
  const buttonLabelProps = Object.assign(
    {},
    typo ? { typo } : null,
    uppercase ? { uppercase } : null
  );

  // Get Aria props
  const ariaProps = getAriaProps({
    href,
    target: props.target,
    type,
    label,
    ariaLabel: props.ariaLabel,
    disabled: props.disabled,
    tabIndex: props.tabIndex,
    iconStart,
    iconEnd,
  });

  const sectionThemeContext = useContext(SectionThemeContext);
  const selectedColorScheme = sectionThemeContext
    ? sectionThemeContext.selectedColorScheme
    : "white";

  const iconSizeDispatcher = {
    primary: "medium",
    secondary: "medium",
    tertiary: "small",
    quaternary: "medium",
  };

  const handleScrollTo = () => {
    const offset = 104;
    let element;
    if (scrollTo) {
      element = document.getElementById(scrollTo);
    }

    if (!!element) {
      const y = element.getBoundingClientRect().top + window.scrollY - offset;

      // Scroll to section
      window.scrollTo({ top: y, behavior: "smooth" });
      // Set focus on first focusable element of current section
      const focusable = element.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      if (focusable && focusable.length > 0) {
        (focusable[0] as HTMLElement).focus({ preventScroll: true });
      }
    }
  };

  const onClick = () => {
    if (isLoading) {
      return;
    } else {
      handleClick();
    }
  };

  return (
    <LinkWrapper href={href} target={props.target}>
      <StyledButton
        {...props}
        {...btnProps}
        {...ariaProps}
        ref={innerRef}
        href={href}
        as={href ? "a" : "button"}
        onClick={scrollTo ? handleScrollTo : onClick}
        colorScheme={colorScheme || selectedColorScheme}
        variant={variant}
        fullWidth={fullWidth}
        size={size}
      >
        {iconStart || (iconStart && isLoading) ? (
          <Icon
            name={iconStart}
            size={iconStartSize ? iconStartSize : iconSizeDispatcher[variant]}
            useIconColor={useIconColor}
            aria-hidden
            spinner={isLoading}
          />
        ) : null}
        {label || children ? (
          <ButtonLabel {...buttonLabelProps} {...btnProps}>
            {label ? label : null}
            {children ? children : null}
          </ButtonLabel>
        ) : null}
        {iconEnd || (iconEnd && isLoading) ? (
          <Icon
            name={iconEnd}
            size={iconEndSize ? iconEndSize : iconSizeDispatcher[variant]}
            useIconColor={useIconColor}
            aria-hidden
            spinner={isLoading}
          />
        ) : null}
      </StyledButton>
    </LinkWrapper>
  );
};

export const ButtonForwardingRef = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <ButtonV2 {...props} innerRef={ref} />
);

export default ButtonV2;
