import { TagStatusType } from '@/components/atoms/Tag/CardTag'
import EditorialCard from './EditorialCard'
import MixedExerciseCard from './MixedExerciseCard'
import { SchoolSubject } from '@/pages/QuizPage/interface'

export type ShapeType =
  | 'ShapeTwoLines'
  | 'ShapeCircle'
  | 'ShapeX'
  | 'ShapeSquare'
  | 'ShapeCheck'
  | 'ShapeStar'

export interface ExerciseCardProps {
  prefix?: {
    content: string
    difficulty: 'easy' | 'medium' | 'hard'
    isTextOnly?: boolean
  }
  title: string
  description?: SchoolSubject[]
  variant?: 'horizontal' | 'vertical' | 'background'
  questionCount?: number
  questionTime?: number
  label?: string
  backgroundFillerImage?: ShapeType
  tag?: {
    label: string
    status: TagStatusType
  }
  image?: string
  colorAccent?: string
  colorVariant?:
    | 'brown'
    | 'green'
    | 'orange'
    | 'blu'
    | 'gray'
    | 'violet'
    | 'red'
    | 'yellow'
  ctaLabel?: string
}

const ExerciseCard = ({
  variant = 'background',
  ...rest
}: ExerciseCardProps) => {
  switch (variant) {
    case 'horizontal':
    case 'vertical':
      return (
        <MixedExerciseCard
          variant={variant}
          {...rest}
        />
      )
    default:
    case 'background':
      return <EditorialCard {...rest} />
  }
}

export default ExerciseCard
