import { useEffect } from 'react';

const UseBeforeUnload = (callback: () => void) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      callback();
      const confirmationMessage = 'Sei sicuro di voler lasciare questa pagina?';
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener("popstate", handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener("popstate", handleBeforeUnload)

    };
  }, [callback]);
};

export default UseBeforeUnload;
