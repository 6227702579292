import { useContext, FC } from "react";
import { SectionThemeContext } from "@contexts/SectionTheme";
import * as S from "./styles";

// Define interfaces for props
interface TextComponentProps {
  children?: string;
  content?: string;
  underline?: boolean;
  typo?: string;
  [key: string]: any;
}

const injectHtml = (children: string) => {
  return { __html: children };
};

const Heading: FC<TextComponentProps> = ({
  children,
  content,
  underline,
  ...props
}) => {
  const sectionThemeContext = useContext(SectionThemeContext);
  const selectedColorScheme = sectionThemeContext
    ? sectionThemeContext.selectedColorScheme
    : "white";

  return underline ? (
    <S.HeadingUnderlined>
      <S.Heading {...props} colorScheme={selectedColorScheme}>
        {children ? (
          <span dangerouslySetInnerHTML={injectHtml(children)} />
        ) : (
          content
        )}
      </S.Heading>
      <svg viewBox="0 0 724 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2 15.5C129.135 9.67997 451.124 -1.00771 722 2.80176"
          stroke="#FF9F5C"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </svg>
    </S.HeadingUnderlined>
  ) : (
    <S.Heading {...props} colorScheme={selectedColorScheme}>
      {children ? (
        <span dangerouslySetInnerHTML={injectHtml(children)} />
      ) : (
        content
      )}
    </S.Heading>
  );
};

const Paragraph: FC<TextComponentProps> = ({ children, content, ...props }) => {
  const sectionThemeContext = useContext(SectionThemeContext);
  const selectedColorScheme = sectionThemeContext
    ? sectionThemeContext.selectedColorScheme
    : "white";

  return (
    <S.Paragraph
      {...props}
      colorScheme={selectedColorScheme}
      dangerouslySetInnerHTML={{
        __html: children || content || "",
      }}
    />
  );
};

const Label: FC<TextComponentProps> = ({ children, content, ...props }) => {
  const sectionThemeContext = useContext(SectionThemeContext);
  const selectedColorScheme = sectionThemeContext
    ? sectionThemeContext.selectedColorScheme
    : "white";

  return (
    <S.Label
      {...props}
      colorScheme={selectedColorScheme}
      dangerouslySetInnerHTML={{
        __html: children || content || "",
      }}
    />
  );
};

const ButtonLabel: FC<TextComponentProps> = ({
  children,
  content,
  ...props
}) => {
  const sectionThemeContext = useContext(SectionThemeContext);
  const selectedColorScheme = sectionThemeContext
    ? sectionThemeContext.selectedColorScheme
    : "white";

  return (
    <S.ButtonLabel {...props} colorScheme={selectedColorScheme}>
      {children || content}
    </S.ButtonLabel>
  );
};

export { Heading, Paragraph, Label, ButtonLabel };
