import styled from "styled-components";
import { motion } from "framer-motion";

export const AccordionContainer = styled.div`
  border-radius: 4px;
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.rem(16, 12)};
  background-color: #fff;
  border-radius: ${({ theme }) => theme.rem(8)};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`;

export const AccordionBody = styled(motion.div)`
  overflow: hidden;
  background: #f6f6f6;
  border: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderTertiary};
  border-radius: ${({ theme }) => theme.rem(0, 0, 8, 8)};
`;

export const AccordionContent = styled.div`
  padding: ${({ theme }) => theme.rem(16, 12)};
`;
