import styled, { DefaultTheme, css, keyframes } from 'styled-components'

const variantStyles = (theme: DefaultTheme, size = 'default') =>
  ({
    small: css`
      font-size: ${theme.rem(12)};
    `,
    medium: css`
      font-size: ${theme.rem(14)};
    `,
    default: css`
      font-size: ${theme.rem(16)};
    `,
    defaultBig: css`
      font-size: ${theme.rem(19)};
    `,
    big: css`
      font-size: ${theme.rem(24)};
    `,
    huge: css`
      font-size: ${theme.rem(32)};
    `,
    large: css`
      font-size: ${theme.rem(48)};
    `,
    '2x-large': css`
      font-size: ${theme.rem(64)};
    `,
    '3x-large': css`
      font-size: ${theme.rem(96)};
    `,
    '4x-large': css`
      font-size: ${theme.rem(128)};
    `,
  }[size])

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

interface StyledIconProps {
  size?:
    | 'small'
    | 'medium'
    | 'default'
    | 'big'
    | 'huge'
    | 'large'
    | 'extra-large'
  unlockedProportions?: boolean
  useIconColor?: boolean
  color?: string
  round?: boolean
  spinner?: boolean
  $colorClassName?: string
  $hoverColor?: string
}

export const StyledIcon = styled.i<StyledIconProps>`
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  ${({ theme, size }) => variantStyles(theme, size)}
  transition: all 0.3s ease-in-out;

  svg {
    width: 1em;
    height: 1em;
    transition: all 0.3s ease-in-out;

    ${({ unlockedProportions }) =>
      unlockedProportions &&
      css`
        height: auto;
        width: auto;
      `}
  }

  path {
    ${({ useIconColor, color = 'currentColor', $colorClassName }) =>
      !useIconColor &&
      css`
        transition: all 0.3s ease-in-out;
        fill: ${color};
        &#timecard {
          opacity: 0.45;
        }
        &.stroked {
          stroke: ${color};
        }

        &.${$colorClassName}#timecard {
          fill: ${color};
          opacity: 1;
        }
      `}
  }

  ${({ round, theme }) =>
    round &&
    css`
      padding: ${theme.rem(8)};
      background-color: ${theme.colors.white};
      box-shadow: ${theme.commons.boxShadow};
      border-radius: 50%;
    `}

  ${({ spinner }) =>
    spinner &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}
`

export const StyledIconChildrenWrapper = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
