import * as S from "./styles";
import Divider from "@/components/atoms/Divider";
// import Polygon from "@/components/atoms/Polygon";
import { QuizInterface } from "@/pages/QuizPage/interface";
import { Time } from "../../Quiz/TimeRadio/types";
import { Card } from "../../Quiz/CardRadio/types";

import { useTranslation } from "react-i18next";
import ButtonV2 from "@/components/atoms/ButtonV2";

export interface Props {
  quiz: QuizInterface;
  selectedDifficulty: string | number | undefined;
  selectedQuizTime: Time | undefined | { value: number };
  selectedQuizQuestionsNumber: number;
  selectedQuizMode: Card | undefined;
  selectedSchoolSubjects: Option[];
  onGenerateQuiz: () => void;
  isLoading: boolean;
  showGenerateButton?: boolean;
  isSummary?: boolean;
}

const Recap = ({
  quiz,
  selectedDifficulty,
  selectedQuizTime,
  selectedQuizQuestionsNumber,
  selectedQuizMode,
  onGenerateQuiz,
  selectedSchoolSubjects,
  isLoading,
  showGenerateButton = true,
  isSummary = false,
}: Props) => {
  const { t } = useTranslation();
  const { attributes } = quiz || {};
  const {
    time,
    questionsNumber,
    difficulty,
    type: exerciseType,
  } = attributes || {};
  const { difficultyValue } = difficulty || {};

  const isSimulation = exerciseType === "simulation";

  const exersizeModeString = () => {
    switch (selectedQuizMode?.value) {
      case "random":
        return t("random");
      case "schoolSubject":
        return t("questions_divided_by_subject");
      default:
        return undefined;
    }
  };

  const getDifficultyValue = () => {
    const difficulyValueString = selectedDifficulty; //1,3,5
    switch (difficulyValueString) {
      case 1:
        return t("easy");
      case 3:
        return t("medium");
      case 5:
        return t("difficult");
      default:
        undefined;
    }
  };

  const difficultyString = getDifficultyValue();

  // const complexityDifficulty = () => {
  //   switch (selectedDifficulty) {
  //     case 1:
  //       return "Semplice";
  //     case 3:
  //       return "Nella media";
  //     case 5:
  //       return "Sfidante";
  //     default:
  //       return undefined;
  //   }
  // };
  // const OPTIONS = ["Semplice", "Nella media", "Sfidante"];

  const renderedSchoolSubjects = selectedSchoolSubjects.map(
    (subject) => subject.label
  );
  return (
    <S.RecapContainer isSummary={isSummary}>
      {!isSummary && <S.RecapHeading>Recap</S.RecapHeading>}
      {/* {time ? (
        <>
          <S.StyleSingleContent>
            <S.RecapInfoHeading>{t("total_time")}</S.RecapInfoHeading>
            <S.RecapInfoParagraph>{`${selectedQuizTime?.value} minuti`}</S.RecapInfoParagraph>
          </S.StyleSingleContent>
          <Divider type="horizontal" />
        </>
      ) : null} */}
      {time && (
        <>
          <S.StyleSingleContent flexDirection="column">
            <S.RecapInfoHeading>{t("total_time")}</S.RecapInfoHeading>
            <S.RecapInfoSmallParagraph>
              {`${selectedQuizTime?.value} minuti`}
            </S.RecapInfoSmallParagraph>
          </S.StyleSingleContent>
          <Divider type="horizontal" />
        </>
      )}

      {questionsNumber && (
        <>
          <S.StyleSingleContent flexDirection="column">
            <S.RecapInfoHeading>{t("questions_number")}</S.RecapInfoHeading>
            <S.RecapInfoSmallParagraph>
              {`${selectedQuizQuestionsNumber}`}
            </S.RecapInfoSmallParagraph>
          </S.StyleSingleContent>
          <Divider type="horizontal" />
        </>
      )}

      {renderedSchoolSubjects && renderedSchoolSubjects.length > 0 ? (
        <>
          <S.StyleSingleContent flexDirection="column">
            <S.RecapInfoHeading>{t("subjects")}</S.RecapInfoHeading>
            <S.RecapInfoSmallParagraph>{`${renderedSchoolSubjects.join(
              ", "
            )}`}</S.RecapInfoSmallParagraph>
          </S.StyleSingleContent>
          <Divider type="horizontal" />
        </>
      ) : null}

      {difficultyValue && (
        <>
          <S.StyleSingleContent flexDirection="column">
            <S.RecapInfoHeading>{t("questions_difficulty")}</S.RecapInfoHeading>
            <S.RecapInfoSmallParagraph>
              {difficultyString}
            </S.RecapInfoSmallParagraph>
          </S.StyleSingleContent>
          {(showGenerateButton || !isSimulation) && (
            <Divider type="horizontal" />
          )}
        </>
      )}

      {exersizeModeString() && !isSimulation && (
        <>
          <S.StyleSingleContent flexDirection="column">
            <S.RecapInfoHeading>{t("exercise_mode")}</S.RecapInfoHeading>
            <S.RecapInfoSmallParagraph>
              {exersizeModeString()}
            </S.RecapInfoSmallParagraph>
          </S.StyleSingleContent>
          {showGenerateButton && <Divider type="horizontal" />}
        </>
      )}

      {/* {complexityDifficulty && (
        <>
          <S.StyleSingleContent flexDirection="column">
            <S.RecapInfoHeading>Difficoltà complessiva</S.RecapInfoHeading>
            <Polygon options={OPTIONS} selectedValue={complexityDifficulty()} />
          </S.StyleSingleContent>
        </>
      )} */}
      {showGenerateButton && (
        <>
          <ButtonV2
            // iconEnd="ArrowRight"
            handleClick={onGenerateQuiz}
            isLoading={isLoading}
          >
            { isSimulation ? t("start_simulation") : t("start_exercise")}
          </ButtonV2>
        </>
      )}
    </S.RecapContainer>
  );
};

export default Recap;
