import ButtonV2 from '@/components/atoms/ButtonV2'
import { Heading, Paragraph } from '@/components/atoms/Typography'
import * as S from './styles'

export interface ExerciseHubCardProps {
  prefix?: string
  title?: string
  description?: string
  image?: string
  ctaLabel?: string
  handleClick?: () => void
  tagLabel?: string
  $noMargin?: boolean
}

const colorMapping = {
  defualt: '#FFFFFF',
  hover: '#FFF5ED',
  accent: '#FFE9D4',
}

const ExerciseHubCard = ({
  prefix,
  title,
  description,
  image,
  ctaLabel,
  handleClick,
  tagLabel,
  $noMargin
}: ExerciseHubCardProps) => {
  const hasPrefix = prefix && prefix.length > 0
  const hasTitle = title && title.length > 0
  const hasDescription = description && description.length > 0
  const hasTag = tagLabel && tagLabel.length > 0
  const hasLabel = ctaLabel && ctaLabel.length > 0

  const buildTagLabel = (tagLabel: string) => {
    const hasPipe = tagLabel.includes('|')
    if (hasPipe) {
      const middlePart = '&nbsp;|&nbsp;'
      const tagParts = tagLabel.split('|')
      const normalPart = tagParts[0] + middlePart
      const boldPart = tagParts[1]

      return (
        <>
          <Paragraph
            content={normalPart}
            typo='paragraphSM'
            style={{
              color: '#666D75',
            }}
          />
          <Paragraph
            content={boldPart}
            typo='paragraphSM'
          />
        </>
      )
    }

    return (
      <Paragraph
        content={tagLabel}
        typo='paragraphMD'
      />
    )
  }

  return (
    <S.ExerciseHubCardContainer
      $backgroundColor={colorMapping.defualt}
      $hoverBackgroundColor={colorMapping.hover}
      $accentColor={colorMapping.accent}
      $noMargin={$noMargin}
      onClick={handleClick}
    >
      <S.ExerciseHubCardTextContainer>
        {hasTag ? (
          <S.ExerciseHubCardBadge className={'badge'}>
            {buildTagLabel(tagLabel)}
          </S.ExerciseHubCardBadge>
        ) : null}
        {hasPrefix ? (
          <Heading
            content={prefix}
            typo='label'
            weight='semibold'
            style={{
              color: '#404853',
              textTransform: 'uppercase',
            }}
          />
        ) : null}
        {hasTitle ? (
          <Heading
            content={title}
            typo='headingXS'
            weight='semibold'
          />
        ) : null}
        {hasDescription ? (
          <Paragraph
            content={description}
            typo='paragraphSM'
          />
        ) : null}
        {hasLabel ? (
          <ButtonV2
            handleClick={handleClick}
            ariaLabel={ctaLabel}
            type={'button'}
            typo={'buttonSM'}
            variant={'tertiary'}
            size={'small'}
            label={ctaLabel}
            iconEnd={'ArrowRight'}
            useIconColor
            iconEndSize={'default'}
            style={{
              marginBlockStart: 'auto',
              width: 'fit-content',
            }}
          />
        ) : null}
      </S.ExerciseHubCardTextContainer>
      <S.ExerciseHubCardImageContainer>
        <S.ExerciseHubCardImage src={image} />
      </S.ExerciseHubCardImageContainer>
    </S.ExerciseHubCardContainer>
  )
}

export default ExerciseHubCard
