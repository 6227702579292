import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from "recharts";
import Icon from "@/components/atoms/Icon";
import { Paragraph } from "@/components/atoms/Typography";
import useIsMobile from "@/hooks/useIsMobile";
import { theme } from "@/theme";

type DataType = {
  subject: string;
  Voto: number;
  fullMark: number;
};

const subjectIcons: { [key: string]: string } = {
  Chimica: "Chimica",
  "Fisica e matematica": "Fisica",
  "Fisica e Matematica": "Fisica",
  "Ragionamento logico e problemi": "RagionamentoLogico",
  Biologia: "Biologia",
  "Competenze di lettura": "CulturaGenerale",
  "Competenze di lettura e conoscenze acquisite negli studi": "CulturaGenerale",
};

const subjectIconsBg: { [key: string]: string } = {
  "Competenze di lettura": "#DFD1EC",
  "Competenze di lettura e conoscenze acquisite negli studi": "#DFD1EC",
  Chimica: "#D2D9D6",
  "Fisica e matematica": "#FFF0D5",
  "Fisica e Matematica": "#FFF0D5",
  "Ragionamento logico e problemi": "#E7E7E7",
  Biologia: "#CEDEE7",
};

interface CustomTickProps {
  x: number;
  y: number;
  payload: { value: string };
}

const CustomTick: React.FC<CustomTickProps> = ({ x, y, payload }) => {
  const isMobile = useIsMobile();
  const icon = subjectIcons[payload.value] || "Inventory";
  return (
    <g transform={`translate(${x},${y})`}>
      <foreignObject
        x={isMobile ? -14 : -20}
        width={isMobile ? 30 : 60}
        height={isMobile ? 30 : 60}
      >
        <Icon
          name={icon}
          style={{
            background: subjectIconsBg[payload.value],
            padding: isMobile ? 5 : 10,
            borderRadius: "50%",
          }}
        />
      </foreignObject>
    </g>
  );
};

const CustomBarShape: React.FC<any> = ({ x, y, width, height, fill }) => {
  const radius = 10;
  return (
    <g>
      <path
        d={`
          M${x},${y + height}
          L${x},${y + radius}
          Q${x},${y} ${x + radius},${y}
          L${x + width - radius},${y}
          Q${x + width},${y} ${x + width},${y + radius}
          L${x + width},${y + height}
          Z
        `}
        fill={fill}
      />
    </g>
  );
};

const yAxisTickFormatter = (value: number): string => {
  switch (value) {
    case 33:
      return "Scarso";
    case 66:
      return "Medio";
    case 100:
      return "Ottimo";
    default:
      return `${value}%`;
  }
};

const CustomYAxisTick: React.FC<CustomTickProps> = ({ x, y, payload }) => {
  const renderedValue = yAxisTickFormatter(Number(payload.value));
  return (
    <g transform={`translate(${x},${y})`}>
      <foreignObject x={-40} y={-12} width={60} height={100}>
        <Paragraph weight="semibold" typo="paragraphSM">
          {renderedValue}
        </Paragraph>
      </foreignObject>
    </g>
  );
};

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
}) => {
  if (active && payload && payload.length) {
    const value = payload[0].value;
    const subject = payload[0].payload.subject;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          padding: "8px 12px",
          background: "#fff",
          borderRadius: 8,
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.4)",
        }}
      >
        <Paragraph typo="paragraphMD" weight="semibold">{subject}</Paragraph>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon name="Seen" style={{ marginRight: 5 }} />
          <Paragraph typo="paragraphSM" style={{color: theme.colorScheme.white.surface.textBaseTertiary}}>{`${value}%`}</Paragraph>
        </div>
      </div>
    );
  }
  return null;
};

const SimpleBarChart = ({ data }: { data: DataType[] | undefined }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <CartesianGrid
          strokeDasharray="1 1"
          fillOpacity={0.1}
          vertical={false}
        />
        <XAxis
          interval={0}
          dataKey="subject"
          tick={(props) => <CustomTick {...props} />}
          tickLine={false}
          height={40}
        />
        <YAxis
          ticks={[33, 66, 100]}
          tickFormatter={yAxisTickFormatter}
          tickSize={15}
          tickLine={false}
          tick={(props) => <CustomYAxisTick {...props} />}
        />
        <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
        <Bar
          dataKey="Voto"
          name="voto"
          barSize={20}
          fill="#CFDBEA"
          shape={<CustomBarShape />}
          isAnimationActive={true}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SimpleBarChart;
