import Divider from "@/components/atoms/Divider";
import Icon from "@/components/atoms/Icon";
import { Paragraph } from "@/components/atoms/Typography";
import styled from "styled-components";

export const StyledHeaderContainer = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.rem(16, 40)};
  background: ${({ theme }) =>
    theme.colorScheme.white.navigation.backgroundTop};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  border-bottom: 1px solid
    ${({ theme }) => theme.colorScheme.white.navigation.border};

`;

export const StyledContentContainer = styled.div`
display: flex;
align-items: center;
gap: ${({ theme }) => theme.rem(24)};

`

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: ${({ theme }) => theme.rem(5)};
  box-shadow: 0px 0.545px 1.091px 0px rgba(16, 24, 40, 0.05);
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
`

export const StyledIcon = styled(Icon)`
  width: 100%;
  height: 100%;
`

export const StyledHeaderDescription = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphSM")};
  color: ${({ theme }) => theme.colorScheme.white.navigation.textSecondary};
`;

export const StyledHeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(4)};
`;

export const StyledHeaderAction = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(24)};
`;

export const StyledDivider = styled(Divider)`
  border: 1px solid #92A8C4;
  height: ${({ theme }) => theme.rem(18)};
`;
