import { motion } from 'framer-motion'
import { forwardRef } from 'react'
import { useMatch } from 'react-router-dom'
import Icon from '../Icon'
import * as S from './styles'
import { ButtonMenuProps } from './types'

const MenuButton = forwardRef<HTMLDivElement, ButtonMenuProps>(
  ({ label, onClick, icon, to, isExpanded = false, ...props }, ref) => {
    const labelVariants = {
      hidden: {
        opacity: 0,
        x: -50,
        transition: {
          delay: 0.2,
        },
      },
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          delay: 0.2,
        },
      },
    }

    const iconVariants = {
      collapsed: { x: 0 },
      expanded: { x: -10 },
    }

    const match = useMatch(`${to}/*`)

    return (
      <S.StyledMenuButton
        className={match ? 'active' : undefined}
        onClick={onClick}
        initial='collapsed'
        animate={isExpanded ? 'expanded' : 'collapsed'}
        isExpanded={isExpanded}
        ref={ref}
        {...props}
      >
        {isExpanded && (
          <motion.div
            variants={labelVariants}
            initial='hidden'
            animate={isExpanded ? 'visible' : 'hidden'}
            exit='hidden'
          >
            <S.StyledParagraph>{label}</S.StyledParagraph>
          </motion.div>
        )}

        <motion.div variants={iconVariants}>
          <Icon name={icon} />
        </motion.div>
      </S.StyledMenuButton>
    )
  },
)

export default MenuButton
