import styled from "styled-components";

import LogoAlphaTest from "@/assets/images/logo-black.svg?react";
import IconButton from "@/components/atoms/ButtonIcon";

export const StyledHeading = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.rem(16, 24)};
  height: 80px;
  background-color: #fff;
  border-bottom: 1px solid
    ${({ theme }) => theme.colorScheme.white.navigation.border};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(16, 80)};
    background-color: #F0F4F9;
  `)}
`;

export const StyledLogo = styled(LogoAlphaTest)`
  fill: #000;
  width: 35px;
  height: 20px;
  cursor: pointer;
`;

export const StyledLogoContainer = styled.div`
  display: none;
  ${({ theme }) =>
    theme.mediaQuery.lg(`
    display: block;
    width: 400px;
    `)}
`;

export const StyledTitleContainer = styled.div`
  ${({ theme }) =>
    theme.mediaQuery.lg(`
    width: 400px;
    `)}
`;
export const StyledCTAContainer = styled.div``;

export const StyledHiddenIconButton = styled(IconButton)`
  visibility: hidden;
`;
