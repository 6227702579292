import styled, { css } from "styled-components";
import { BaseLayoutProps } from "./types";

export const StyledContainer = styled.div<Partial<BaseLayoutProps>>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  ${({ theme, flexDirection }) =>
    theme.mediaQuery.md(`
    flex-direction: ${flexDirection};
  `)}
`;

export const StyledHeader = styled.div<Partial<BaseLayoutProps>>`
  display: flex;

  ${({ isHeaderSticky }) =>
    isHeaderSticky &&
    css`
      width: 100%;
      z-index: ${({ theme }) => theme.layers.header};
      position: sticky;
      top: 0;
      left: 0;
    `}
`;

export const StyledMainContainer = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.rem(0, 16, 50)};
  overflow-x: hidden;
  ${({ theme }) =>
    theme.mediaQuery.lg(`
    padding: ${theme.rem(0, 50, 50, 58)};
`)}
`;
