import { RadioButton } from "@/components/atoms/RadioButton";

import * as S from "./styles";
import { RadioButtonProps } from "@/components/atoms/RadioButton/types";

const Answer = ({
  id,
  name,
  onChange,
  checked = false,
  label,
  value,
  index,
  disabled,
  status,
  onClick,
  ...props
}: RadioButtonProps) => {
  return (
    <S.StyledAnswer>
      <RadioButton
        id={id}
        name={name}
        onChange={onChange}
        checked={checked}
        label={label}
        value={value}
        index={index}
        disabled={disabled}
        status={status}
        onClick={onClick}
        {...props}
      />
    </S.StyledAnswer>
  );
};

export default Answer;
