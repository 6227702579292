import { CardRadioProps } from "./types";
import Card from "@/components/molecules/Quiz/Radio/Card";
import { useTranslation } from "react-i18next";

import * as S from "./styles";

export const CARDS = [
  {
    id: 1,
    icon: "Subject",
    title: "per_section",
    description: "questions_divided_by_subject",
    value: "schoolSubject",
  },
  {
    id: 2,
    icon: "Random",
    title: "random",
    description: "mixed_questions_from_all_subjects",
    value: "random",
  },
];

const CardRadio = ({ selectedCard, setSelectedCard }: CardRadioProps) => {
  const { t } = useTranslation();
  return (
    <S.StyledCardRadioContainer
    >
      {CARDS.map((card) => (
        <Card
          icon={card.icon}
          key={card.id}
          title={t(card.title)}
          description={t(card.description)}
          value={card.value}
          selected={selectedCard?.value === card.value}
          onClick={() => setSelectedCard(card)}
        />
      ))}
    </S.StyledCardRadioContainer>
  );
};

export default CardRadio;
