import styled, { css } from 'styled-components'

interface EditorialCardContainer {
  $backgroundColor: string
  $hoverBackgroundColor: string
  $accentColor: string
  $hoverAccentColor: string
}

interface EditorialCardImageContainerProps {
  $backgroundColor: string
}

// EditorialCard
export const EditorialCardContainer = styled.div<EditorialCardContainer>`
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-areas: 'text image';
  // column-gap: ${({ theme }) => theme.rem(8)};
  row-gap: ${({ theme }) => theme.rem(8)};
  // margin: ${({ theme }) => theme.rem(16)};
  border-radius: ${({ theme }) => theme.rem(8)};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  transition: all 0.3s ease-in-out;
  min-width: 320px;
  // max-width: 380px;
  // height: 200px;
  height: 100%;
  border: 0.458px solid #dce5f0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ $hoverBackgroundColor }) => $hoverBackgroundColor};
    button {
      background-color: ${({ $hoverAccentColor }) => $hoverAccentColor};
    }
    #badge {
      background-color: #ffffff;
    }
  }

  ${({ theme }) =>
    theme.mediaQuery.md(`
    // width: ${theme.rem(560)};
    // max-width: unset;
    // height: ${theme.rem(220)};
    // column-gap: ${theme.rem(22)};
  `)}
`

export const EditorialCardTextContainer = styled.div`
  grid-area: text;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(8)};
  padding-block: ${({ theme }) => theme.rem(24)};
  padding-inline-start: ${({ theme }) => theme.rem(16)};
  transition: all 0.3s ease-in-out;
  white-space: wrap;

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding-block: ${theme.rem(32)};
    padding-inline-start: ${theme.rem(22)};
    height: 100%;
  `)}
`

export const EditorialCardImageContainer = styled.div<EditorialCardImageContainerProps>`
  grid-area: image;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: ${({ theme }) => theme.rem(8)};
  border-top-right-radius: ${({ theme }) => theme.rem(8)};
  border-bottom-right-radius: ${({ theme }) => theme.rem(8)};
  position: relative;
  transition: all 0.3s ease-in-out;
  // max-height: ${({ theme }) => theme.rem(200)};
  height: 100%;

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding-block: ${theme.rem(22)};
    padding-inline: ${theme.rem(12)};
    // max-height: ${theme.rem(220)};
    height: 100%;
  `)}
`

export const EditorialCardImage = styled.img`
  width: ${({ theme }) => theme.rem(125)};
  aspect-ratio: 1;
  object-fit: cover;
  position: absolute;

  ${({ theme }) => theme.mediaQuery.md} {
    min-width: ${({ theme }) => theme.rem(185)};
  }
`
export const EditorialCardInfoContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(12)};
  margin-block-start: auto;
`

// ExerciseHubCard
interface ExerciseHubCardContainerProps {
  $backgroundColor?: string
  $hoverBackgroundColor?: string
  $accentColor?: string
  $noMargin?: boolean
}

export const ExerciseHubCardContainer = styled.div<ExerciseHubCardContainerProps>`
  display: grid;
  grid-template-areas: 'image' 'text';
  grid-template-rows: minmax(40%, 60%) minmax(40%, 60%);
  grid-template-columns: 1fr;
  // min-width: ${({ theme }) => theme.rem(320)};
  // max-width: ${({ theme }) => theme.rem(400)};
  aspect-ratio: 0.9;
  border-radius: ${({ theme }) => theme.rem(8)};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  transition: all 0.3s ease-in-out;
  border: 0.458px solid #dce5f0;
  cursor: pointer;
  overflow: hidden;

  ${({ theme, $noMargin }) => !$noMargin && css`
    margin: ${theme.rem(16)};
  `}

  &:hover {
    background-color: ${({ $hoverBackgroundColor }) => $hoverBackgroundColor};
    .badge,
    button {
      background-color: ${({ $accentColor }) => $accentColor};
    }
    .badge {
      max-width: max-content;
      flex-wrap: wrap;
      z-index: 1;

      & > p {
        white-space: normal;
      }
    }
  }

  ${({ theme }) =>
    theme.mediaQuery.md(`
    grid-template-areas: 'text image';
    grid-template-rows: 1fr;
    grid-template-columns: 60% 40%;
    // max-width: ${theme.rem(600)};
    min-height: ${theme.rem(280)};
    column-gap: ${theme.rem(16)};
    aspect-ratio: unset;
  `)}
`

export const ExerciseHubCardTextContainer = styled.div`
  height: 100%;
  grid-area: text;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(6)};
  padding-block: ${({ theme }) => theme.rem(36) + ' ' + theme.rem(22)};
  padding-inline: ${({ theme }) => theme.rem(16)};
  position: relative;

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding-block: ${theme.rem(62)} ${theme.rem(32)};
    padding-inline: unset;
    padding-inline-start: ${theme.rem(16)};
    height: 100%;
    gap: ${theme.rem(12)};
  `)}
`

export const ExerciseHubCardImageContainer = styled.div`
  grid-area: image;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: ${({ theme }) => theme.rem(8)};
  border-bottom-right-radius: ${({ theme }) => theme.rem(8)};
  position: relative;
`

export const ExerciseHubCardBadge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  padding: ${({ theme }) => theme.rem(8) + ' ' + theme.rem(12)};
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  background-color: #cfdbea;
  border-radius: 0px 0px 16px 0px;
  max-width: 275px;
  z-index: 1;
  transition: all 0.2 ease-in-out;
  max-width: 275px;

  ${({ theme }) => theme.mediaQuery.md(`
    border-radius: 8px 0 16px 0;
    padding: ${theme.rem(12)} ${theme.rem(16)};
  `)}

  & > p:first-child {
    white-space: nowrap;
  }

  & > p:last-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const ExerciseHubCardImage = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: ${({ theme }) => theme.rem(8)};
  border-top-left-radius: ${({ theme }) => theme.rem(8)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    min-width: ${theme.rem(185)};
    border-top-left-radius:  ${theme.rem(0)};
    border-top-right-radius:  ${theme.rem(8)};
    border-bottom-right-radius:  ${theme.rem(8)};
  `)}
`

// MixedExerciseCard

interface MixedExerciseCardContainerProps {
  $backgroundColor?: string
  $accentColor?: string
  $hoverIconColor?: string
  $hoverAccentColor?: string
  $hoverBackgroundColor?: string
  $variant: 'horizontal' | 'vertical'
}

interface MixedExerciseCardImageContainerProps {
  $backgroundColor?: string
  $backgroundColorHover?: string
  $variant: 'horizontal' | 'vertical'
}

export const MixedExerciseCardContainer = styled.div<MixedExerciseCardContainerProps>`
  cursor: pointer;
  display: flex;
  transition: all 0.3s ease-in-out;
  ${({ $variant }) => {
    switch ($variant) {
      case 'horizontal':
        return css`
          flex-direction: row;
          column-gap: ${({ theme }) => theme.rem(24)};
          height: ${({ theme }) => theme.rem(170)};
        `
      case 'vertical':
        return css`
          flex-direction: column;
          row-gap: ${({ theme }) => theme.rem(12)};
          // width: 45%;
          height: ${({ theme }) => theme.rem(315)};

          ${({ theme }) =>
            theme.mediaQuery.md(`
            // width: 30%;
            // height: ${theme.rem(400)};
          `)}
          @media (min-width: 1024px) {
            width: unset;
          }
        `
    }
  }}

  ${({ $hoverIconColor, $hoverAccentColor, $hoverBackgroundColor }) =>
    $hoverIconColor
      ? css`
          &:hover {
            #card-image-container {
              background-color: ${$hoverBackgroundColor};
            }
            svg {
              scale: 1.15;
              path {
                fill: ${$hoverIconColor};
              }
            }
            #badge {
              background-color: ${$hoverAccentColor};
            }
          }
        `
      : ''}
`

export const MixedExerciseCardImageContainer = styled.div<MixedExerciseCardImageContainerProps>`
  display: flex;
  height: 170px;
  aspect-ratio: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 17.143px;
  border-radius: ${({ theme }) => theme.rem(16)};
  border: var(--selectedItems, 1.714px) solid var(--border-tertiary, #dce5f0);
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ?? 'transparent'};
  position: relative;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ $backgroundColorHover }) => $backgroundColorHover};
  }

  ${({ theme, $variant }) =>
    $variant === 'vertical' &&
    theme.mediaQuery.md(`
    height: 220px;
  `)}
`
export const MixedExerciseCardImage = styled.img`
  width: ${({ theme }) => theme.rem(145)};
  aspect-ratio: 1;
  object-fit: cover;
  position: absolute;
  padding: 1rem;

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding: .75rem;
  `)}
`
export const MixedExerciseCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.rem(12)};
  align-self: stretch;
`

export const MixedExerciseCardInfoContainer = styled.div`
  display: flex;
  margin-block: auto 1rem;
  gap: ${({ theme }) => theme.rem(12)};
`
