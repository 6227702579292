import ButtonV2 from "@/components/atoms/ButtonV2";

import * as S from "./styles";
import { Paragraph } from "@/components/atoms/Typography";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import useIsMobile from "@/hooks/useIsMobile";
import { MinimalHeaderProps } from "./types";
import IconButton from "@/components/atoms/ButtonIcon";

const MinimalHeader: FC<MinimalHeaderProps> = ({
  title,
  hasGoBackIcon,
  onIconClick,
  ...props
}) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const navigateToHome = () => {
    navigate("/");
  };
  return (
    <S.StyledHeading {...props}>
      {hasGoBackIcon && isMobile ? (
        <IconButton name="ArrowLeft" onClick={onIconClick} />
      ) : (
        <S.StyledLogoContainer>
          <S.StyledLogo onClick={navigateToHome} />
        </S.StyledLogoContainer>
      )}

      <S.StyledTitleContainer>
        <Paragraph typo="paragraphMD" weight="semibold">
          {title}
        </Paragraph>
      </S.StyledTitleContainer>
      {!hasGoBackIcon || !isMobile ? (
        <S.StyledCTAContainer>
          <ButtonV2
            iconStart={isMobile ? undefined : "ArrowLeft"}
            variant={isMobile ? "quaternary" : "secondary"}
            handleClick={navigateToHome}
          >
            Torna alla home
          </ButtonV2>
        </S.StyledCTAContainer>
      ) : (
        <S.StyledHiddenIconButton name="ArrowLeft"/>
      )}
    </S.StyledHeading>
  );
};

export default MinimalHeader;
