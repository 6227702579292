import styled, { css } from "styled-components";
import { subjectIconsBg } from "@/components/atoms/LaunchCard/styles";

interface ChartContainerProps {
  $unstyled?: boolean;
}

export const ChartContainer = styled.div<ChartContainerProps>`
  position: relative;

  ${({ $unstyled }) =>
    !$unstyled &&
    css`
      background-color: white;
      min-width: 300px;
      max-width: 100%;
      min-height: 450px;
      max-height: 650px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: visible;
      padding-block: ${({ theme }) => theme.rem(16)};
      border-radius: 16px;
      border: 1px solid #dce5f0;
      margin-block-start: 1rem;
      overflow: hidden;

      ${({ theme }) =>
        theme.mediaQuery.md(`
      padding-block: ${theme.rem(32)};
      width: 90%;
        `)}
      ${({ theme }) =>
        theme.mediaQuery.lg(`
      min-width: unset;
      max-width: unset;
      width: auto;
        `)}
    `}

  .custom-prev-nav,
  .custom-next-nav {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f0f4f9;
    color: #404853;
    padding: ${({ theme }) => theme.rem(8)};
    margin-top: 30px;
    border: 0.5px solid #cfdbea;
    border-radius: 50%;
    font-size: ${({ theme }) => theme.rem(14)};
    font-weight: 500;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    svg,
    i,
    path {
      transition: none;
    }

    &:hover {
      background-color: #404853;
      color: #f0f4f9;
    }

    &:focus-visible {
      outline: 2px solid #404853;
      outline-offset: 2px;
    }

    &[aria-current="true"] {
      background-color: #404853;
      color: #f0f4f9;
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  .custom-prev-nav {
    left: ${({ theme }) => theme.rem(16)};
  }

  .custom-next-nav {
    right: ${({ theme }) => theme.rem(16)};
  }
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: fit-content;
  gap: 16px;
  margin-bottom: 16px;
  overflow: visible;
`;

export const ChartChipWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  overflow: hidden;
  gap: ${({ theme }) => theme.rem(8)};
`;

interface ChartChipProps {
  $active?: boolean;
}

export const ChartChip = styled.div<ChartChipProps>`
  display: flex;
  padding: ${({ theme }) => theme.rem(8) + " " + theme.rem(24)};
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: ${({ theme }) => theme.rem(24)};
  border: 0.5px solid #cfdbea;
  background: #fff;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: #666d75;
  height: fit-content;
  white-space: nowrap;
  user-select: none;

  > p {
    text-transform: capitalize;
  }

  &:hover {
    color: #fff;
    background: #404853;
    opacity: 0.85;
    scale: 1.05;
  }

  ${({ $active }) =>
    $active &&
    css`
      color: #fff;
      background: #404853;
    `}
`;

export const ChartInfo = styled.div`
  width: 90%;
`;

export const ChartIconWrapper = styled.span`
  display: flex;
  width: 18px;
  height: 18px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 24px;
  background: #ff7474;
`;

export const StyledResultCardInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.rem(16)};
  padding: ${({ theme }) => theme.rem(16)};
`;
export const StyledResultReportInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledBox = styled.div`
  width: 8px;
  height: 8px;
`;

export const StyledSuccessBox = styled(StyledBox)`
  background-color: #95c9b4;
`;

export const StyledErrorBox = styled(StyledBox)`
  background-color: #ff7474;
`;

export const StyledWarningBox = styled(StyledBox)`
  background-color: #fcc06d;
`;

export const ChartSubjectsInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.mediaQuery.md(`
    top: 51%;
  `)}
`;

export const ChartSubjectsInfoIcon = styled.div<{ subject: string }>`
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: ${({ subject }) => subjectIconsBg[subject || ""]};
`;

export const ChartSubjectsInfoTitle = styled.div`
  max-width: 80px;
  text-align: center;
  font-size: ${({ theme }) => theme.rem(10)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
