import useIsMobile from '@/hooks/useIsMobile'
import Icon from '../Icon'
import { Paragraph } from '../Typography'
import * as S from './styles'

export type TimeCardProps = {
  time: 5 | 10 | 15 | 20 | 25 | 30 | 35 | 40 | 45 | 50 | 55 | 60
  handleTimeCardClick?: (time: TimeCardProps['time']) => void
  isUncolored?: boolean
}

const timeLabels = {
  5: '5’',
  10: '10’',
  15: '15’',
  20: '20’',
  25: '25’',
  30: '30’',
  35: '35’',
  40: '40’',
  45: '45’',
  50: '50’',
  55: '55’',
  60: '60’',
}

const timeCardBackgrounds = {
  5: '#FFF0D5',
  10: '#D2D9D6',
  15: '#F5EFEB',
  20: '#CEDEE7',
  25: '#FFF0D5',
  30: '#D2D9D6',
  35: '#F5EFEB',
  40: '#CEDEE7',
  45: '#FFF0D5',
  50: '#D2D9D6',
  55: '#F5EFEB',
  60: '#CEDEE7',
}

const timeCardColors = {
  5: '#B9710A',
  10: '#3C4541',
  15: '#88634D',
  20: '#404853',
  25: '#B9710A',
  30: '#3C4541',
  35: '#88634D',
  40: '#404853',
  45: '#B9710A',
  50: '#3C4541',
  55: '#88634D',
  60: '#404853',
}

const timeCardColorClassNames = {
  5: 'five',
  10: 'ten',
  15: 'fifteen',
  20: 'twenty',
  25: 'twenty-five',
  30: 'thirty',
  35: 'thirty-five',
  40: 'forty',
  45: 'forty-five',
  50: 'fifty',
  55: 'fifty-five',
  60: 'sixty',
}

const uncoloredStyle = {
  backgroundColor: '#FFFFFF',
  color: '#404853',
  border: '1px solid #CFDBEA',
}

const TimeCard = ({
  time = 5,
  handleTimeCardClick = () => {},
  isUncolored = true,
}: TimeCardProps) => {
  const isMobile = useIsMobile()
  const timeLabelStyle = {
    color: isUncolored ? uncoloredStyle.color : timeCardColors[time],
  }

  const backgroundColor = isUncolored
    ? uncoloredStyle.backgroundColor
    : timeCardBackgrounds[time]

  const iconColor = isUncolored ? uncoloredStyle.color : timeCardColors[time]

  return (
    <S.StyledTimeCardWrapper
      $backgroundColor={backgroundColor}
      onClick={() => handleTimeCardClick(time)}
    >
      <Icon
        name={'TimeCardCircle'}
        size={isMobile ? 'large' : '3x-large'}
        color={iconColor}
        $colorClassName={timeCardColorClassNames[time]}
        style={{ position: 'relative' }}
      >
        <Paragraph
          typo={isMobile ? 'headingXS' : 'headingMD'}
          weight={'semibold'}
          style={timeLabelStyle}
        >
          {timeLabels[time]}
        </Paragraph>
      </Icon>
    </S.StyledTimeCardWrapper>
  )
}

export default TimeCard
