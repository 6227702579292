import PieChartWithPaddingAngle from "@/components/atoms/PieChartWithPaddingAngle";
import Icon from "@/components/atoms/Icon";
import { Paragraph } from "@/components/atoms/Typography";
import { useId, useState, useRef, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import { subjectIcons } from "@/components/atoms/LaunchCard";

import * as S from "./styles";
import InfoMessage from "@/components/atoms/InfoMessage";

export type PieChartDataType = {
  subject: string;
  data: {
    name: string;
    value: number;
  }[];
};

interface PieChartProps {
  data: PieChartDataType[];
  withoutWrapper?: boolean;
  copy?: {
    title: string;
    infoBox: string;
  };
}

const _copy = {
  title: "Tempo medio per domanda",
  infoBox: "Puoi fare di meglio rispetto alla tua media!",
};

const PieChart = ({ data, withoutWrapper, copy = _copy }: PieChartProps) => {
  const uniqueId = useId();
  const [activeSubject, setActiveSubject] = useState(0);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef(false);
  const startXRef = useRef(0);
  const scrollLeftRef = useRef(0);

  const hasData = data.every(
    (item) =>
      item.subject &&
      Array.isArray(item.data) &&
      item.data.every(
        (entry) =>
          typeof entry.name === "string" && typeof entry.value === "number"
      )
  );
  const activeSubjectData: PieChartDataType = data[activeSubject] ?? {
    subject: "",
    data: [],
  };
  const subjectList = hasData ? data.map((item) => item.subject) : [];

  if (!hasData) {
    console.warn("PieChart: data is not valid");
    return null;
  }

  const handleSubjectClick = (index: number) => {
    setActiveSubject(index);
  };

  useEffect(() => {
    setIsPrevDisabled(activeSubject === 0);
    setIsNextDisabled(activeSubject === subjectList.length - 1);

    if (scrollContainerRef.current) {
      const activeElement = scrollContainerRef.current.children[
        activeSubject
      ] as HTMLElement;
      if (activeElement) {
        scrollContainerRef.current.scrollTo({
          left:
            activeElement.offsetLeft -
            (scrollContainerRef.current.clientWidth -
              activeElement.clientWidth) /
              2,
          behavior: "smooth",
        });
      }
    }
  }, [activeSubject, subjectList.length]);

  const handleMouseDown = (e: React.MouseEvent) => {
    isDraggingRef.current = true;
    startXRef.current = e.pageX - scrollContainerRef.current!.offsetLeft;
    scrollLeftRef.current = scrollContainerRef.current!.scrollLeft;
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    isDraggingRef.current = true;
    startXRef.current =
      e.touches[0].pageX - scrollContainerRef.current!.offsetLeft;
    scrollLeftRef.current = scrollContainerRef.current!.scrollLeft;
  };

  const handleMouseLeaveOrUp = () => {
    isDraggingRef.current = false;
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDraggingRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current!.offsetLeft;
    const walk = x - startXRef.current;
    scrollContainerRef.current!.scrollLeft = scrollLeftRef.current - walk;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDraggingRef.current) return;
    const x = e.touches[0].pageX - scrollContainerRef.current!.offsetLeft;
    const walk = x - startXRef.current;
    scrollContainerRef.current!.scrollLeft = scrollLeftRef.current - walk;
  };


  const correctItem = hasData ? activeSubjectData.data.find((item) => item.name === "Corrette") : undefined;
  const corrects = correctItem ? correctItem.value : 0;
  const errorItem = hasData ? activeSubjectData.data.find((item) => item.name === "Errate") : undefined;
  const errors = errorItem ? errorItem.value : 0;
  const skippedItem = hasData ? activeSubjectData.data.find((item) => item.name === "Non date") : undefined;
  const skipped = skippedItem ? skippedItem.value : 0;

  const userHasMoreCorrectAnswers = corrects > (errors + skipped);

  console.log({corrects, errors, skipped})
  
  const infoBoxType = userHasMoreCorrectAnswers ? "positive" : "negative";
  const infoBoxMessage = userHasMoreCorrectAnswers ? `Ottimo lavoro in ${activeSubjectData?.subject}. Continua così!` : `Puoi fare di meglio in ${activeSubjectData?.subject}. Continua ad allenarti!`;

  return (
    <S.ChartContainer $unstyled={withoutWrapper}>
      <button
        className="custom-prev-nav custom-prev-nav--chart-pie"
        disabled={isPrevDisabled}
        onClick={() => setActiveSubject((prevState) => prevState - 1)}
      >
        <Icon name="ArrowLeft" />
      </button>
      <button
        className="custom-next-nav custom-next-nav--chart-pie"
        disabled={isNextDisabled}
        onClick={() => setActiveSubject((prevState) => prevState + 1)}
      >
        <Icon name="ArrowRight" />
      </button>
      {activeSubjectData ? (
        <S.ChartSubjectsInfo>
          <S.ChartSubjectsInfoIcon
            subject={subjectIcons[activeSubjectData?.subject]}
          >
            <Icon name={subjectIcons[activeSubjectData?.subject]} size="huge" />
          </S.ChartSubjectsInfoIcon>
        </S.ChartSubjectsInfo>
      ) : null}
      {!withoutWrapper ? (
        <S.ChartWrapper>
          <Paragraph
            content={copy.title}
            typo={"headingXS"}
            weight={"semibold"}
            style={{
              paddingInline: "1.5rem",
            }}
          />
          {hasData && subjectList.length > 0 ? (
            <S.ChartChipWrapper
              ref={scrollContainerRef}
              onMouseDown={handleMouseDown}
              onMouseLeave={handleMouseLeaveOrUp}
              onMouseUp={handleMouseLeaveOrUp}
              onMouseMove={handleMouseMove}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleMouseLeaveOrUp}
              onTouchMove={handleTouchMove}
              onDragStart={(e) => e.preventDefault()}
            >
              {subjectList.map((subject, index) => (
                <div
                  key={`${subject}-${uniqueId}-${index}`}
                  style={{
                    width: "fit-content",
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  <S.ChartChip
                    key={`${subject}-${uniqueId}-${index}-chip`}
                    $active={activeSubject === index}
                    onClick={() => handleSubjectClick(index)}
                  >
                    <Paragraph content={subject} typo={"paragraphMD"} />
                  </S.ChartChip>
                </div>
              ))}
            </S.ChartChipWrapper>
          ) : null}
        </S.ChartWrapper>
      ) : null}
      <ResponsiveContainer>
        <PieChartWithPaddingAngle data={activeSubjectData.data} />
      </ResponsiveContainer>
      {!withoutWrapper ? (
        <S.ChartInfo>
          <InfoMessage
            info={infoBoxMessage}
            type={infoBoxType}
            hasBackground
            hasIcon
          />
        </S.ChartInfo>
      ) : null}
      <div>
        <S.StyledResultCardInfo>
          <S.StyledResultReportInfoItem>
            <S.StyledSuccessBox />
            <Paragraph typo="paragraphXS">Risposta corretta</Paragraph>
          </S.StyledResultReportInfoItem>
          <S.StyledResultReportInfoItem>
            <S.StyledWarningBox />
            <Paragraph typo="paragraphXS">Risposta non data</Paragraph>
          </S.StyledResultReportInfoItem>
          <S.StyledResultReportInfoItem>
            <S.StyledErrorBox />
            <Paragraph typo="paragraphXS">Risposta errata</Paragraph>
          </S.StyledResultReportInfoItem>
        </S.StyledResultCardInfo>
      </div>
    </S.ChartContainer>
  );
};

export default PieChart;
