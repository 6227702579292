import styled from "styled-components";
import { Paragraph } from "@/components/atoms/Typography";

export const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseTertiary};
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledDateParagrah = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseTertiary};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    color: ${theme.colorScheme.white.surface.textBasePrimary};
    font-weight: 600;
  `)}
`;
