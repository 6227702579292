import axios from "../../request";

export interface FiltersQueryProps {
  page: number;
  type: string;
  order: string;
}

export const getHistorical = async ({ page = 1, type = 'all', order = 'desc' }: FiltersQueryProps) => {
  return axios.get(`v1/quizzes/me/history?page=${page}&type=${type}&order=${order}`);
};

export const getHistoricalChart = async ({ type = 'simulation' }: FiltersQueryProps) => {
  return axios.get(`v1/quizzes/me/history-widget?type=${type}`);
};
