import Divider from '@/components/atoms/Divider'
import { Paragraph } from '@/components/atoms/Typography'
import { motion } from 'framer-motion'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components'

interface StyledNavigationContainer {
  isExpanded: boolean
}

//DESKTOP
export const StyledDesktopNavigation = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledNavigationContainer = styled.nav<StyledNavigationContainer>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.rem(12)};
  border-right: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
  position: sticky;
  width: ${({ isExpanded }) => (isExpanded ? '250px' : '80px')};
  transition: width 0.3s ease-in-out;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh;
  box-shadow: ${({ isExpanded }) =>
    isExpanded ? '10px 34px 24px 0px rgba(0, 0, 0, 0.05)' : undefined};
`

export const StyledLogoContainer = styled.a<StyledNavigationContainer>`
  cursor: pointer;
  align-self: ${({ isExpanded }) => (isExpanded ? 'flex-start' : 'center')};
`

export const StyledNavigationAppLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.rem(16)};
  width: 100%;
`

export const StyledNavigationUserLinks = styled.div<StyledNavigationContainer>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isExpanded }) => (isExpanded ? 'flex-start' : 'center')};
  gap: ${({ theme }) => theme.rem(24)};
  width: 100%;
`

export const IconContainer = styled(NavLink)<NavLinkProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.rem(8)};
  border-radius: ${({ theme }) => theme.rem(8)};
  transition: background-color 0.3s ease-in-out;

  &.active {
    background-color: ${({ theme }) =>
      theme.colorScheme.dark.surface.backgroundColor};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.colorScheme.dark.button.secondary.bgHover};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.colorScheme.dark.button.secondary.bgPressed};
  }
`

export const StyledSettings = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.rem(8)};
  border-radius: ${({ theme }) => theme.rem(8)};
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &.active {
    background-color: ${({ theme }) =>
      theme.colorScheme.dark.surface.backgroundColor};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.colorScheme.dark.button.secondary.bgHover};
  }

  &:active {
    background-color: ${({ theme }) =>
      theme.colorScheme.dark.button.secondary.bgPressed};
  }
`

export const StyledButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.rem(8)};
  border-radius: ${({ theme }) => theme.rem(8)};
  transition: background-color 0.3s ease-in-out;
  background: rgb(255, 0, 0, 0.5);
  &:hover {
    background-color: rgba(255, 183, 131, 0.5);
  }

  &.active {
    background-color: ${({ theme }) =>
      theme.colorScheme.white.navigation.backgroundSelected};
  }
`

export const StyledPopoverItemsContainer = styled.div`
  padding: ${({ theme }) => theme.rem(12, 8)};
  width: ${({ theme }) => theme.rem(250)};
  border-radius: ${({ theme }) => theme.rem(24)};
  border: 2px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderTertiary};
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
`

export const StlyedDivider = styled(Divider)`
  margin-top: ${({ theme }) => theme.rem(8)};
  margin-bottom: ${({ theme }) => theme.rem(8)};
`

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: ${({ theme }) => theme.rem(12)};
  padding-bottom: ${({ theme }) => theme.rem(16)};
`

//MOBILE
export const StyledMobileNavigation = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
    width: 100%;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
    display: flex;
  }
`

export const StyledMobileHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.rem(12, 8)};
  height: 80px;
  border-bottom: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderTertiary};
`

export const StyledMobileLinks = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(12)};
`

export const StyledMobileProfile = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(12)};
  align-items: center;
`

export const StyledMobileModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
`

export const StyledUSerNameParagraph = styled(Paragraph)`
  word-break: break-word;
`
