import React from "react";
import * as S from "./styles";

const SegmentedControl: React.FC<SegmentedControlProps> = ({
  options,
  selectedValue,
  onChange,
  disabled,
}) => {

  const handleClick = (value: NumberOrString) => {
    if (disabled) {
      return;
    }
    onChange(value);

  }
  return (
    <S.SegmentControlContainer>
      {options.map((option) => (
        <S.StlyedOption
          key={option.value}
          isSelected={option.value === selectedValue}
          onClick={() => handleClick(option.value)}
          disabled={disabled}
        >
          <S.StlyedParagraph>{option.label}</S.StlyedParagraph>
        </S.StlyedOption>
      ))}
    </S.SegmentControlContainer>
  );
};

export default SegmentedControl;
