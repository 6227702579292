import styled from "styled-components";
import { Paragraph } from "../Typography";

const BORDER_RADIUS_VALUE = "24px";

export const SegmentControlContainer = styled.div`
  display: flex;
  border: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
  border-radius: ${BORDER_RADIUS_VALUE};
`;

export const StlyedOption = styled.div<{ isSelected: boolean, disabled: boolean }>`
  flex: 1;
  padding: 8px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isSelected }) =>
    isSelected ? "#3B3838" : "transparent"};
  color: ${({ isSelected, theme }) =>
    isSelected ? "#FFFFFF" : theme.colorScheme.white.surface.textBaseTertiary};

  &:first-child {
    border-radius: ${BORDER_RADIUS_VALUE} 0 0 ${BORDER_RADIUS_VALUE};
  }

  &:last-child {
    border-radius: 0 ${BORDER_RADIUS_VALUE} ${BORDER_RADIUS_VALUE} 0;
  }

  &:not(:last-child) {
    border-right: 1px solid #ccc; // Separa le opzioni visivamente
  }

  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`;

export const StlyedParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("desktopMD")};
  font-weight: 600;
`;
