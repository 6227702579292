import Button from "@/components/atoms/Button";
import { Heading, Paragraph } from "@/components/atoms/Typography";
import BaseLayout from "@/layouts/BaseLayout";
import styled from "styled-components";

// {{ display: "flex", flexDirection: "column", minHeight: "100vh" }}

export const StyledBaseLayout = styled(BaseLayout)`
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
  padding-right: 0;
  padding-left: 0;
`;

export const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(24)};
`;

export const StyledModalImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(8)};
  align-items: center;
`;

export const StyledModalCTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
  align-items: center;
`;

export const StyledModalArrowContainer = styled.div`
  padding: ${({ theme }) => theme.rem(32, 32, 0, 32)};
`;

export const StyledQuizBodyContainer = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: ${({ theme }) => theme.rem(80)};
  position: relative;
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
`;

export const StyledQuizBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding-top: ${({ theme }) => theme.rem(40)};
  padding-bottom: ${({ theme }) => theme.rem(50)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
  width: 85vw;
  max-height: 100vh;
  overflow: scroll;
  padding-bottom: 250px;
`)}
`;

export const StyledQuizQuestions = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.rem(16, 16, 40, 16)};
  background-color: ${({ theme }) =>
    theme.colorScheme.white.quiz.scrollbarForeground};
  gap: ${({ theme }) => theme.rem(16)};
  // max-height: 100vh;
  padding-bottom: 200px;
  // overflow: scroll;
  position: relative;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
    width: 30vw;
    // min-height: 100%;
  `)};
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-self: stretch;
`;

export const StyledButtonError = styled(Button)`
  background-color: #ffc9c9;
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  border: 1px solid #f00;
  display: flex;
  align-self: stretch;
`;

export const StyledModalParagraph = styled(Paragraph)`
  text-align: center;
`;

export const StyledModalHeading = styled(Heading)`
  text-align: center;
`;

//HEADER MOBILE
export const StyledHeaderMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.rem(16)};
  padding: ${({ theme }) => theme.rem(16)};
  border-bottom: 1px solid
    ${({ theme }) => theme.colorScheme.white.navigation.border};
  background-color: #fff;
`;

export const StyledHeaderMobileTitle = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphSM")};
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.rem(8)};
  z-index: 0;
`;
