import styled from "styled-components";

export const StyledAvatar = styled.div`
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  background-color: #3b3838;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #DCE5F0;
`;

export const StyledLabel = styled.p`
  color: ${({ theme }) => theme.colorScheme.white.surface.primaryHoverText};
  font-weight: 600;
  text-transform: uppercase;
`;
