import { AvatarProps } from "./types";

import * as S from "./styles";

const Avatar = ({ label }: AvatarProps) => {
  const formatLabel = (label: string): string => {
    const words = label.split(" ");

    if (label.length > 2) {
      if (words.length === 1) {
        // Signle word, return the first letter
        return words[0][0];
      } else if (words.length === 2) {
        // two words, return the first letter of each
        return `${words[0][0]}${words[1][0]}`;
      } else {
        // more than two words, return the first letter of the first and the last
        return `${words[0][0]}${words[words.length - 1][0]}`;
      }
    }
    // if the label length is 2 or less, return the label as it is
    return label;
  };
  return (
    <S.StyledAvatar>
      <S.StyledLabel>{formatLabel(label)}</S.StyledLabel>
    </S.StyledAvatar>
  );
};

export default Avatar;
