import Counter from "@/components/atoms/Counter";

import * as S from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "@/store";
import { UserInterface } from "@/store/slices/authSlice";

const Label = <S.StyledParagraph>Streak attuale</S.StyledParagraph>;

const StreakCounter = () => {
  const user = useSelector<RootState>(
    (state) => state.auth.user
  ) as UserInterface;
  if (!user?.exams) {
    return;
  }

  const renderedValue = user.exams[0].streakCounter

  return <Counter icon="TwoStars" value={renderedValue} label={Label} />;
};

export default StreakCounter;
