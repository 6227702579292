import { Outlet } from "react-router-dom";
import * as S from "./styles";
import { isProductionMode } from "./utils/isProductionMode";

const App = () => {
  console.log({ isProduction: isProductionMode() });
  return (
    <S.StyledApp>
      <Outlet />
    </S.StyledApp>
  );
};

export default App;
