import Button from "@/components/atoms/Button";
import { Heading, Paragraph } from "@/components/atoms/Typography";
import BaseLayout from "@/layouts/BaseLayout";
import styled from "styled-components";

export const StyledBaseLayout = styled(BaseLayout)`
  display: flex;
  padding: ${({ theme }) => theme.rem(0, 16)};
`;

export const StyledLoginPage = styled.div`
  display: flex;
`;

export const StyledContentContainer = styled.div`
  flex: 1;
  min-height: 100dvh;
  padding: ${({ theme }) => theme.rem(48, 32)};
`;

export const StyledLeftContainer = styled(StyledContentContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.rem(32)};
`;

export const StyledRightContainer = styled(StyledContentContainer)`
  background-color: #3b3838;
  color: #fff;
  min-width: 0px;
`;

export const StyledLogoImageContainer = styled.div`
  width: 70px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledContentBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(32)};
  height: 500px;
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(24)};
`;

export const StyledCTAContainer = styled.div`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.rem(24)};
  gap: ${({ theme }) => theme.rem(24)};
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const StyledSwiperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(24)};
`;
export const StyledImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(24)};
`;

export const StyledImageContainer = styled.div`
  height: 230px;
  width: 100%;
  border-radius: ${({ theme }) => theme.rem(24)};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.rem(24)};
  }
`;

export const StyledSingleImageContainer = styled.div`
  height: 300px;
  width: 100%;
  border-radius: ${({ theme }) => theme.rem(24)};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${({ theme }) => theme.rem(24)};
  }
`;

export const StyledFirstImagesRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(24)};
`;

export const StyledSwiperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.rem(16)};
`;

export const StyledSwiperHeading = styled(Heading)`
  text-align: center;
`;

export const StyledSwiperParagraph = styled(Paragraph)`
  text-align: center;
`;
