import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Heading } from "@/components/atoms/Typography";
import Icon from "@/components/atoms/Icon";
import CardHistoricalListPoint from "./Point.tsx";

import * as S from "./styles.ts";
import { getQuizResult } from "@/api/services/quizzes/index.ts";

interface Question {
  schoolSubject: {
    label: string;
  };
}

interface Quiz {
  questions: Question[];
}

interface Content {
  quiz: Quiz;
}

export interface CardHistoricalListProps {
  id: string;
  type: "simulation" | "exercise";
  createdAt: string;
  questionsNumber: number;
  userScore: string;
  content: Content;
  isEmbedded?: boolean;
}

const getTime = (dateString: string) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString("it-IT", {
    day: "numeric",
    month: "long",
  });

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const formattedTime = `${hours}:${minutes}`;

  const result = {
    date: formattedDate,
    time: formattedTime,
  };

  return result;
};

const getUniqueSchoolSubjects = (questions: Question[]) => {
  const subjects = questions.map((question) => question.schoolSubject.label);
  return [...new Set(subjects)];
};

const CardHistoricalList: FC<CardHistoricalListProps> = (data) => {
  const navigate = useNavigate();

  const { type, createdAt, questionsNumber, content, isEmbedded, userScore } =
    data;

  const subjects = getUniqueSchoolSubjects(content.quiz.questions);

  const time = getTime(createdAt);

  const getQuizResultHandler = async (id: string) => {
    try {
      const { data: quizResult } = await getQuizResult(id);
      console.log({ quizResult });
      navigate("/result", { state: { quizResult} });
    } catch (e) {
      console.log({ e });
    }
  };

  return (
    <S.CardHistoricalList $isEmbedded={isEmbedded} onClick={() => getQuizResultHandler(data.id)}>
      <S.CardHistoricalListInfo>
        <S.CardHistoricalListIconImage
          as="img"
          src={
            type === "simulation"
              ? "/assets/images/simulation-icon.svg"
              : "/assets/images/exercise-icon.svg"
          }
        />
        <div>
          <S.CardHistoricalListDate>
            <Heading content={time.date} typo="paragraphMD" weight="semibold" />
            <Heading content={time.time} typo="paragraphSM" />
          </S.CardHistoricalListDate>
          <Heading
            content={
              type === "simulation"
                ? "Simulazione Classica"
                : "Esercitazione Componibile"
            }
            typo="paragraphMD"
            weight="semibold"
          />
        </div>
      </S.CardHistoricalListInfo>
      <S.CardHistoricalListPoints>
        {type === "simulation" ? (
          <CardHistoricalListPoint
            iconName="Score"
            content={userScore || "0"}
            labelDesktop="Punteggio"
            labelMobile="punteggio"
          />
        ) : (
          <>
            <CardHistoricalListPoint
              iconName="LibraryBooks"
              content={subjects.length.toString()}
              labelDesktop={subjects.length > 1 ? "Materie" : "Materia"}
              labelMobile={subjects.length > 1 ? "materie" : "materia"}
            />
            <CardHistoricalListPoint
              iconName="ListNumber"
              content={questionsNumber.toString()}
              labelDesktop="Numero di domande"
              labelMobile="domande"
            />
          </>
        )}
      </S.CardHistoricalListPoints>
      <S.CardHistoricalListCTA>
        <Icon name="ArrowRight" />
      </S.CardHistoricalListCTA>
    </S.CardHistoricalList>
  );
};

export default CardHistoricalList;
