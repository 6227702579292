import { IconButtonProps } from "./types";

import * as S from "./styles";
import Icon from "../Icon";

const IconButton = ({ name, ...props }: IconButtonProps) => {
  return (
    <S.StyledIconButtonCotainer {...props}>
      <Icon name={name} />
    </S.StyledIconButtonCotainer>
  );
};

export default IconButton;
