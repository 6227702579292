import Icon from "@/components/atoms/Icon";
import { Heading, Paragraph } from "@/components/atoms/Typography";
// import QuizCard from "@/components/organisms/Card";
// import { InlineCardInterface } from "@/components/organisms/Card/types";
import styled from "styled-components";

interface IconType {
  type: string;
}

export const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
  padding-bottom: ${({ theme }) => theme.rem(50)};
`;

export const StyledMain = styled.div`
  padding: ${({ theme }) => theme.rem(16, 0, 0, 0)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(24)};
  width: 100%;
`;

export const StyledPageTitle = styled(Heading)``;

export const StyledPageDescription = styled(Paragraph)``;

export const StyledScoreCards = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(16)};
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>
    theme.mediaQuery.lg(`
      flex-direction: row;
    `)}
`;

export const StyledSingleCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: ${({ theme }) => theme.rem(30)};
  height: 100%;
  padding: ${({ theme }) => theme.rem(24)};
  border: 1px solid #dce5f0;
  border-radius: ${({ theme }) => theme.rem(24)};
  align-items: center;
  justify-content: space-between;
`;

export const StyledResultCardInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.rem(16)};
  padding: ${({ theme }) => theme.rem(16)};
`;
export const StyledResultReportInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledBox = styled.div`
  width: 8px;
  height: 8px;
`;

export const StyledSuccessBox = styled(StyledBox)`
  background-color: #95c9b4;
`;

export const StyledErrorBox = styled(StyledBox)`
  background-color: #fe8980;
`;

export const StyledWarningBox = styled(StyledBox)`
  background-color: #fcc06d;
`;

export const StyledTimeBoxContainer = styled.div`
  padding: ${({ theme }) => theme.rem(32, 40)};
  border-radius: ${({ theme }) => theme.rem(24)};
  background-color: #f0f4f9;
  width: 100%;
`;

export const StyledTimeBoxHeading = styled(Heading)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
  text-align: center;
`;

//Popover
export const StyledPopoverContainer = styled.div`
  width: 270px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.rem(16)};
  gap: ${({ theme }) => theme.rem(8)};
  border-radius: ${({ theme }) => theme.rem(8)};
  background-color: #fff;
`;

export const StyledPopoverItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledPopoverItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledPopoverIconContainer = styled.div<IconType>`
  padding: ${({ theme }) => theme.rem(4)};
  border-radius: ${({ theme }) => theme.rem(4)};
  display: flex;
  align-items: center;
  background-color: ${({ type }) => {
    switch (type) {
      case "correct":
        return "#D2D9D6";
      case "wrong":
        return "#FFC9C9";
      case "skipped":
        return "#FFF0D5";
    }
  }};
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const StyledMaterialsTableContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.rem(48)};
`;

//Horizontal Cards Container
export const StyledHorizontalCardsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(16)};
  padding-top: ${({ theme }) => theme.rem(24)};
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
      flex-direction: row;
    `)}
`;
