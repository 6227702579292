import styled from 'styled-components'

type StyledTimeCardWrapperProps = {
  $backgroundColor: string
}

export const StyledTimeCardWrapper = styled.div<StyledTimeCardWrapperProps>`
  width: ${({ theme }) => theme.rem(68)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  aspect-ratio: 1;
  border-radius: ${({ theme }) => theme.rem(16)};
  border: ${({ theme }) => theme.rem(1)} solid ${({ theme }) => theme.colorScheme.white.surface.borderTertiary};
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    background-color: #fff5ed;
  }

  ${({ theme }) => theme.mediaQuery.md(`
    width: ${theme.rem(120)};
    border-radius: ${theme.rem(18)};
  `)}
`
