import styled from "styled-components";

interface IPerformanceStatisticsLegendItemColorProps {
  $type: "simulation" | "exercise";
}

const colorDispatcher: Record<
  IPerformanceStatisticsLegendItemColorProps["$type"],
  string
> = {
  simulation: "#7790AF",
  exercise: "#BA9179",
};

export const PerformanceStatistics = styled.div`
  margin-top: ${({ theme }) => theme.rem(24)};
`;

export const PerformanceStatisticsWrapper = styled.div`
  padding: ${({ theme }) => theme.rem(40, 75)};
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
  border-radius: ${({ theme }) => theme.rem(8)};
  margin-top: ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>
    theme.mediaQuery.md(
      `
    padding: ${theme.rem(24, 16)};
  `,
      true
    )}
`;

export const PerformanceStatisticsLegend = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(12)};
  margin-top: ${({ theme }) => theme.rem(12)};

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    margin-bottom: ${theme.rem(24)};
  `,
      true
    )}
`;

export const PerformanceStatisticsLegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(5)};
`;

export const PerformanceStatisticsLegendItemColor = styled.div<IPerformanceStatisticsLegendItemColorProps>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${({ $type }) => colorDispatcher[$type]};
`;

export const PerformanceStatisticsFilters = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(12)};
`;

export const PerformanceStatisticsButton = styled.button`
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
  color: #404853;
  padding: ${({ theme }) => theme.rem(8, 16)};
  border: 0.5px solid #cfdbea;
  border-radius: ${({ theme }) => theme.rem(40)};
  font-size: ${({ theme }) => theme.rem(14)};
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.rem(24)};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

  &:hover {
    background-color: #404853;
    color: #f0f4f9;
  }

  &:focus-visible {
    outline: 2px solid #404853;
    outline-offset: 2px;
  }

  &[aria-current="true"] {
    background-color: #404853;
    color: #f0f4f9;
  }
`;

//Custom Tooltip
export const StyledCustomTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(4)};
  padding: ${({ theme }) => theme.rem(8, 12)};
  background: #fff;
  border-radius: ${({ theme }) => theme.rem(8)};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
`;
