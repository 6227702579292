import { useState, createContext, useEffect, ReactNode } from "react";

type ColorScheme = 'white' | 'dark';

interface SectionThemeContextType {
  selectedColorScheme: ColorScheme;
  setSelectedColorScheme: (colorScheme: ColorScheme) => void;
}

interface SectionThemeProviderProps {
    children: ReactNode;
    colorScheme?: ColorScheme;
  }

export const SectionThemeContext = createContext<SectionThemeContextType>({
  selectedColorScheme: "white",
  setSelectedColorScheme: () => {},
});



const SectionThemeProvider: React.FC<SectionThemeProviderProps> = ({
  children,
  colorScheme = "white",
}) => {
  const [selectedColorScheme, setSelectedColorScheme] =
    useState<ColorScheme>(colorScheme);

  useEffect(() => {
    setSelectedColorScheme(colorScheme);
  }, [colorScheme]);

  return (
    <SectionThemeContext.Provider
      value={{ selectedColorScheme, setSelectedColorScheme }}
    >
      {children}
    </SectionThemeContext.Provider>
  );
};

export default SectionThemeProvider;
