import { TextSerifInterface } from "./types";

import * as S from "./styles";

const TextSerif = ({ title, description, tag }: TextSerifInterface) => {
  return (
    <S.StlyedTextSerif>
      {title && <S.StyledTitle>{title}</S.StyledTitle>}
      {tag && (
        <>
          <S.StyledTag>
            <S.StyledTagTitle typo="paragraphSM">{tag}</S.StyledTagTitle>
          </S.StyledTag>
        </>
      )}
      <S.StyledContent>
        {description && (
          <S.StyledDescription>{description}</S.StyledDescription>
        )}
      </S.StyledContent>
    </S.StlyedTextSerif>
  );
};

export default TextSerif;
