import React from "react";
import * as S from "./styles";

const SegmentedControl: React.FC<SegmentedControlGroupProps> = ({
  options,
  selectedValue,
  onChange,
  disabled = false,
  allItemsString = "",
}) => {
  const allOptionsValue = "ALL";

  const handleClick = (selectedOption: Option) => {
    if (disabled) {
      return;
    }

    const isSelectingAll = selectedOption.value === allOptionsValue;


    const isSelected = selectedValue.some(
      (option) => option.value === selectedOption.value
    );

    let newValueArray: Option[] = [];

    if (isSelectingAll) {
      if (!isSelected) {
        newValueArray = [selectedOption];
      }
    } else {
      newValueArray = selectedValue.filter(
        (option) => option.value !== allOptionsValue
      );

      if (isSelected) {
        newValueArray = newValueArray.filter(
          (option) => option.value !== selectedOption.value
        );
      } else {
        newValueArray.push(selectedOption);
      }
    }
    onChange(newValueArray);
  };

  const renderedOptions: Option[] = allItemsString
    ? [
        {
          value: allOptionsValue,
          label: allItemsString,
        },
        ...options,
      ]
    : options;

    if(selectedValue.length === 0) {
      selectedValue = [renderedOptions[0]]
      onChange(selectedValue);
    }

  return (
    <S.SegmentControlContainer>
      {renderedOptions.map((option) => (
        <S.StlyedOption
          key={option.value}
          isSelected={selectedValue.some(
            (selectedOption) => selectedOption.value === option.value
          )}
          onClick={() => handleClick(option)}
          disabled={disabled}
        >
          <S.StlyedParagraph>{option.label}</S.StlyedParagraph>
        </S.StlyedOption>
      ))}
    </S.SegmentControlContainer>
  );
};

export default SegmentedControl;
