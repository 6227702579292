import useIsMobile from "@/hooks/useIsMobile";
import { MutableRefObject, ReactNode, useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";

export interface SwiperProps {
  children: ReactNode;
  slidesPerView?: number | "auto";
  spaceBetween?: number;
  breakpoints?: Record<
    number,
    {
      slidesPerView: number | "auto";
      grid?: { rows: number; fill?: "row" | "column" };
    }
  >;
  navigation?: boolean;
  pagination?: boolean;
  autoPlay?: boolean;
  useDynamicSliderCSS?: boolean;
  on?: Record<string, Function>;
  grid?: { rows: number; fill?: "row" | "column" };
  hasPaddingBottom?: boolean;
  freeMode?: boolean;
  cssMode?: boolean;
  [key: string]: any;
}

interface SwiperContainer extends HTMLElement {
  initialize?: () => void;
  slidesPerView?: number;
  breakpoints?: Record<number, { slidesPerView: number }>;
  grid?: { rows: number; fill?: "row" | "column" };
}

export function Swiper({
  children,
  slidesPerView,
  breakpoints,
  navigation = false,
  pagination = false,
  autoPlay = false,
  hasPaddingBottom = false,
  on = {},
  useDynamicSliderCSS = false,
  noMobilePaddingInlineEnd = false,
  freeMode,
  cssMode,
  grid = { rows: 1, fill: "row" },
  ...rest
}: SwiperProps) {
  const isMobile = useIsMobile();
  const swiperRef: MutableRefObject<SwiperContainer | null> = useRef(null);
  const hasBreakpoints = breakpoints && Object.keys(breakpoints).length > 0;

  const dynamicSliderCSS = useDynamicSliderCSS
    ? `
      .swiper {
        padding-inline-end: 4rem;
        overflow: visible;
      }
      .swiper-slide:last-child {
        margin: 25px;
      }
  `
    : ``;

  const paddingBottomSliderCSS = hasPaddingBottom
    ? `
    .swiper {
      padding-bottom: 35px;
    }
    `
    : "";

  useEffect(() => {
    register();

    const params = {
      ...(hasBreakpoints
        ? {
            breakpoints: breakpoints,
          }
        : {
            slidesPerView: slidesPerView,
            grid: grid,
          }),
      pagination: pagination ? { clickable: true } : false,
      injectStyles: [
        `
        ${paddingBottomSliderCSS}
        ${dynamicSliderCSS}
        .swiper-pagination.swiper-pagination-bullets{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
        }
        .swiper-slide-active{
            padding-bottom: 100px;
        }
        .swiper-pagination-bullet {
            background-color: #888888;
            height: 8px;
            min-width: 8px;
            border-radius: 50%;
            opacity: 0.5;
            margin: 5px;
            transition: background-color,width 0.4s ease;
          }
          .swiper-pagination-bullet-active {
            background-color: red;
            width: 32px;
            height: 8px;
            border-radius: 4px;
            background-color: #FE7E39;
            opacity: 1;
            transform: scale(1.2);
            transition: all 0.4s ease;
          }
        `,
      ],
      ...rest,
    };

    if (swiperRef.current) {
      Object.assign(swiperRef.current, params);

      swiperRef.current.initialize?.();

      for (const [eventName, eventHandler] of Object.entries(on)) {
        swiperRef.current?.addEventListener(eventName, (e: any) =>
          eventHandler(e.detail)
        );
      }
    }
  }, [
    slidesPerView,
    breakpoints,
    rest,
    on,
    grid,
    pagination,
    dynamicSliderCSS,
    paddingBottomSliderCSS,
  ]);

  const swiperContainerStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    marginInlineStart: useDynamicSliderCSS ? "-1rem" : "0",
    paddingInlineEnd:
      useDynamicSliderCSS && !isMobile
        ? "6rem"
        : noMobilePaddingInlineEnd
        ? 0
        : "0rem",
  };

  return (
    <swiper-container
      init="false"
      ref={swiperRef}
      navigation={navigation ? 'true' : undefined}
      pagination={pagination ? { clickable: true } : undefined}
      autoplay={autoPlay ? 'true' : undefined}
      free-mode={freeMode ? 'true' : undefined}
      css-mode={cssMode ? 'true' : undefined}
      touch-events-target='container'
      grab-cursor={true}
      style={{ ...swiperContainerStyle }}
    >
      {children}
    </swiper-container>
  );
}

interface SwiperSlideProps {
  children: ReactNode;
  [key: string]: any;
}

export function SwiperSlide({ children, ...rest }: SwiperSlideProps) {
  return <swiper-slide {...rest}>{children}</swiper-slide>;
}