import { Fragment, useRef, useState } from "react";
import * as S from "./styles";
import Logo from "@/assets/images/logo-alpha-test.svg";
import Divider from "@/components/atoms/Divider";
import Icon from "@/components/atoms/Icon";
import MenuButton from "@/components/atoms/MenuButton";
import MenuItem from "@/components/atoms/MenuItem";
import { MenuItemProps } from "@/components/atoms/MenuItem/types";
import Popover from "@/components/atoms/Popover";
import { Paragraph } from "@/components/atoms/Typography";
import Avatar from "@/components/molecules/Avatar";
import useIsMobile from "@/hooks/useIsMobile";
import { RootState } from "@/store";
import { UserInterface } from "@/store/slices/authSlice";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { NavigationLinkInterface } from "./types";
import { isProductionMode } from "@/utils/isProductionMode";

const Sidebar = () => {
  const showSimulatorPage = !isProductionMode() || true
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const userInfo = useSelector<RootState>(
    (state) => state.auth.user
  ) as UserInterface;

  const divRef = useRef<HTMLDivElement>(null);
  const mobileRef = useRef<HTMLDivElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedMobile, setIsExpandedMobile] = useState(false);

  const togglePopover = () => {
    setPopoverOpen((prevState) => !prevState);
  };

  const navigateHandler = (path: string) => () => {
    if (!path || typeof path !== "string") {
      navigate("/");
      return;
    }
    navigate(path);
    return;
  };

  const logoutHandler = () => {
    window.location.replace(`${import.meta.env.VITE_API_URL}/logout`);
  };

  const MENU_ITEMS: MenuItemProps[] = [
    {
      label: "Log out",
      onClick: logoutHandler,
      icon: "Logout",
    },
  ];

  const NAVIGATION_LINKS: NavigationLinkInterface[] = [
    {
      label: "Home",
      onClick: navigateHandler("/"),
      icon: "Home",
      to: "/",
    },
    ...(showSimulatorPage
      ? [
          {
            label: "Quiz",
            onClick: navigateHandler("/exercise-hub"),
            icon: "Quiz",
            to: "/exercise-hub",
          },
        ]
      : []),
    {
      label: "Storico",
      onClick: navigateHandler("/storico"),
      icon: "Inventory",
      to: "/storico",
    },
  ];

  const toggleExpanded = () => {
    if (isMobile) {
      setIsExpandedMobile((prevState) => !prevState);
      setIsExpanded(false);
    } else {
      setIsExpanded((prevState) => !prevState);
      setIsExpandedMobile(false);
    }
  };

  const expnandedIcon = isExpanded ? "XMark" : "List";

  function capitalizeWords(inputString: string) {
    const words = inputString.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return capitalizedWords.join(" ");
  }

  const labelVariants = {
    hidden: {
      opacity: 0,
      x: -20,
      transition: {
        delay: 5,
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.3,
      },
    },
  };

  const renderedSubscripeType = userInfo?.free ? "Piano Free" : "Piano Premium";

  return (
    <>
      <S.StyledDesktopNavigation>
        <S.StyledNavigationContainer isExpanded={isExpanded}>
          <S.StyledNavigationAppLinks>
            <S.StyledLogoContainer
              onClick={toggleExpanded}
              isExpanded={isExpanded}
            >
              <Icon name={expnandedIcon} size="big" />
            </S.StyledLogoContainer>
            {NAVIGATION_LINKS.map((navigationLink) => (
              <MenuButton
                key={navigationLink.label}
                icon={navigationLink.icon}
                onClick={navigationLink.onClick}
                label={navigationLink.label}
                to={navigationLink.to}
                isExpanded={isExpanded}
              />
            ))}
          </S.StyledNavigationAppLinks>
          <S.StyledNavigationUserLinks isExpanded={isExpanded}>
            <Divider type="horizontal" />
            <S.StyledNavigationAppLinks>
              <MenuButton
                icon="MoreVertical"
                onClick={togglePopover}
                label="Settings"
                ref={!isMobile ? divRef : undefined}
                isExpanded={isExpanded}
              />
              <Popover
                anchorEl={divRef.current}
                open={popoverOpen}
                onClose={() => setPopoverOpen(false)}
              >
                <S.StyledPopoverItemsContainer>
                  {MENU_ITEMS.map((menuItem) => (
                    <Fragment key={menuItem.label}>
                      {menuItem.hasDivider && (
                        <S.StlyedDivider type="horizontal" />
                      )}
                      <MenuItem
                        label={menuItem.label}
                        onClick={menuItem.onClick}
                        icon={menuItem.icon}
                      />
                    </Fragment>
                  ))}
                </S.StyledPopoverItemsContainer>
              </Popover>
            </S.StyledNavigationAppLinks>
            {userInfo && (
              <>
                <Divider type="horizontal" />
                <S.AvatarContainer>
                  {!isExpanded && <Avatar label={userInfo.name} />}
                  {isExpanded && (
                    <motion.div
                      variants={labelVariants}
                      initial="hidden"
                      animate={isExpanded ? "visible" : "hidden"}
                      exit="hidden"
                      style={{ display: "flex", alignItems: "center", gap: 12 }}
                    >
                      <Avatar label={userInfo.name} />
                      <div>
                        <S.StyledUSerNameParagraph
                          typo="paragraphMD"
                          weight="bold"
                        >{`${capitalizeWords(
                          userInfo.name
                        )}`}</S.StyledUSerNameParagraph>
                        <Paragraph typo="paragraphXS">
                          {renderedSubscripeType}
                        </Paragraph>
                      </div>
                    </motion.div>
                  )}
                </S.AvatarContainer>
              </>
            )}
          </S.StyledNavigationUserLinks>
        </S.StyledNavigationContainer>
      </S.StyledDesktopNavigation>
      <S.StyledMobileNavigation>
        <S.StyledMobileHeader>
          <S.StyledMobileLinks>
            <MenuButton
              icon="List"
              onClick={toggleExpanded}
              label={""}
              isExpanded={false}
            />
            <div style={{ width: 36, height: 32 }}>
              <img
                src={Logo}
                alt="Alpha Test Logo"
                style={{ height: "100%" }}
              />
            </div>
          </S.StyledMobileLinks>
          <S.StyledMobileProfile>
            <div>
              <MenuButton
                icon="Settings"
                onClick={togglePopover}
                ref={isMobile ? mobileRef : undefined}
                label={""}
                isExpanded={false}
              />
            </div>
            {userInfo && (
              <div>
                <Avatar label={userInfo.name} />
              </div>
            )}
          </S.StyledMobileProfile>
        </S.StyledMobileHeader>
        <AnimatePresence>
          {isExpandedMobile && (
            <motion.div
              key="styled-mobile-modal"
              initial={{ opacity: 0, x: -300 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -300 }}
              transition={{ duration: 0.2 }}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                background: "white",
                zIndex: 1000,
              }}
            >
              <S.StyledMobileModal>
                <S.StyledMobileHeader>
                  <S.StyledMobileLinks>
                    <MenuButton
                      icon="XMark"
                      onClick={toggleExpanded}
                      label={""}
                      isExpanded={false}
                    />
                    <div style={{ width: 36, height: 32 }}>
                      <img
                        src={Logo}
                        alt="Alpha Test Logo"
                        style={{ height: "100%" }}
                      />
                    </div>
                  </S.StyledMobileLinks>
                  <S.StyledMobileProfile></S.StyledMobileProfile>
                </S.StyledMobileHeader>
                <div
                  style={{ marginTop: 30, paddingRight: 20, paddingLeft: 20 }}
                >
                  {NAVIGATION_LINKS.map((navigationLink) => (
                    <MenuButton
                      key={navigationLink.label}
                      icon={navigationLink.icon}
                      onClick={navigationLink.onClick}
                      label={navigationLink.label}
                      to={navigationLink.to}
                      isExpanded={isExpandedMobile}
                    />
                  ))}
                </div>
              </S.StyledMobileModal>
            </motion.div>
          )}
        </AnimatePresence>
      </S.StyledMobileNavigation>
      <Popover
        anchorEl={isMobile ? mobileRef.current : divRef.current}
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
      >
        <S.StyledPopoverItemsContainer>
          {MENU_ITEMS.map((menuItem) => (
            <Fragment key={menuItem.label}>
              {menuItem.hasDivider && <S.StlyedDivider type="horizontal" />}
              <MenuItem
                label={menuItem.label}
                onClick={menuItem.onClick}
                icon={menuItem.icon}
              />
            </Fragment>
          ))}
        </S.StyledPopoverItemsContainer>
      </Popover>
    </>
  );
};

export default Sidebar;
