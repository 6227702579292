import styled from 'styled-components'

export const HistoricalCharts = styled.div`
  margin-top: ${({ theme }) => theme.rem(24)};
`



export const HistoricalChart = styled.div`
  padding: ${({ theme }) => theme.rem(24)};
  border: 1px solid #DCE5F0;
  border-radius: ${({ theme }) => theme.rem(8)};
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};

  ${({ theme }) => theme.mediaQuery.lg(`
    margin-bottom: ${theme.rem(32)};
  `, true)}

  .recharts-default-legend {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.rem(40)};

    ${({ theme }) => theme.mediaQuery.lg(`
      flex-direction: column;
      gap: ${theme.rem(8)};
    `, true)}
  }
`

export const HistoricalChartWrapper = styled.div`
  position: relative;
`

export const HistoricalChartLegendLeft = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-50%) rotate(-90deg);
`

export const HistoricalChartLegendRight = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(50%) rotate(-90deg);
`
