import { Heading, Paragraph } from "@/components/atoms/Typography";

import logo from "@/assets/images/logo-alpha-test.svg";

import * as S from "./styles";
// import Carousel from "@/components/organisms/Carousel";
import { Swiper, SwiperSlide } from "@/components/molecules/Swiper";

import FirstSlideOne from "@/assets/images/login-page/first-slide-1.png";
import FirstSlideTwo from "@/assets/images/login-page/first-slide-2.png";
import FirstSlideThree from "@/assets/images/login-page/first-slide-3.png";

import SecondSlideOne from "@/assets/images/login-page/second-slide-1.png";
import SecondSlideTwo from "@/assets/images/login-page/second-slide-2.png";
import SecondSlideThree from "@/assets/images/login-page/second-slide-3.jpeg";

import ThirdSlideOne from "@/assets/images/login-page/third-slide-1.jpeg";
import ThirdSlideTwo from "@/assets/images/login-page/third-slide-2.png";
import ThirdSlideThree from "@/assets/images/login-page/third-slide-3.jpeg";

const LoginV2 = () => {
  return (
    <S.StyledBaseLayout>
      <S.StyledLoginPage>
        <S.StyledLeftContainer>
          <S.StyledLogoImageContainer>
            <img src={logo} alt="Alpha Test Logo" />
          </S.StyledLogoImageContainer>
          <S.StyledContentBody>
            <S.StyledContent>
              <Heading typo="displayMD" font="primary">
                Ti diamo il benvenuto su Mydesk, il sistema di e-learning di
                Alpha Test
              </Heading>
              <Paragraph typo="paragraphLG">
                Entrando con le tue credenziali, puoi fare la simulazione,
                scaricare il materiale informativo a tua disposizione e
                confrontare il tuo risultato con quello degli altri consultando
                la graduatoria finale.
              </Paragraph>
            </S.StyledContent>
            <S.StyledCTAContainer>
              <S.StyledButton iconEnd="ArrowRight">Accedi</S.StyledButton>
            </S.StyledCTAContainer>
          </S.StyledContentBody>
        </S.StyledLeftContainer>
        <S.StyledRightContainer>
          <Swiper
            slidesPerView={1}
            pagination={true}
            autoPlay={false}
            breakpoints={{ 768: { slidesPerView: 1 } }}
            on={{
              slideChange: () => console.log("slide changed"),
              //   progress: (s: unknown, progress: string) => console.log(`progress is ${progress}`),
            }}
          >
            <SwiperSlide>
              <S.StyledSwiperContainer>
                <S.StyledImagesContainer>
                  <S.StyledFirstImagesRow>
                    <S.StyledImageContainer>
                      <img src={FirstSlideOne} alt="First Slide" />
                    </S.StyledImageContainer>
                    <S.StyledImageContainer>
                      <img src={FirstSlideTwo} alt="First Slide" />
                    </S.StyledImageContainer>
                  </S.StyledFirstImagesRow>
                  <S.StyledFirstImagesRow>
                    <S.StyledSingleImageContainer>
                      <img src={FirstSlideThree} alt="First Slide" />
                    </S.StyledSingleImageContainer>
                  </S.StyledFirstImagesRow>
                </S.StyledImagesContainer>
                <S.StyledSwiperContent>
                  <S.StyledSwiperHeading typo="headingSM">
                    Sei un corsista Alpha Test?
                  </S.StyledSwiperHeading>
                  <S.StyledSwiperParagraph typo="paragraphMD">
                    Entrando con le tue credenziali segui le lezioni on-demand,
                    accedi alle dirette in calendario ed esercitati con le
                    simulazioni della prova ufficiale, studia il materiale
                    didattico del corso e contatta sul forum i tuoi docenti e i
                    tuoi compagni di corso.
                  </S.StyledSwiperParagraph>
                </S.StyledSwiperContent>
              </S.StyledSwiperContainer>
            </SwiperSlide>
            <SwiperSlide>
              <S.StyledSwiperContainer>
                <S.StyledImagesContainer>
                  <S.StyledFirstImagesRow>
                    <S.StyledImageContainer>
                      <img src={SecondSlideOne} alt="Second Slide" />
                    </S.StyledImageContainer>
                    <S.StyledImageContainer>
                      <img src={SecondSlideTwo} alt="Second Slide" />
                    </S.StyledImageContainer>
                  </S.StyledFirstImagesRow>
                  <S.StyledFirstImagesRow>
                    <S.StyledSingleImageContainer>
                      <img src={SecondSlideThree} alt="Second Slide" />
                    </S.StyledSingleImageContainer>
                  </S.StyledFirstImagesRow>
                </S.StyledImagesContainer>
                <S.StyledSwiperContent>
                  <S.StyledSwiperHeading typo="headingSM">
                    Hai acquistato un libro Alpha Test con contenuti digitali?
                  </S.StyledSwiperHeading>
                  <S.StyledSwiperParagraph typo="paragraphMD">
                    Accedi seguendo le istruzioni che trovi sul libro nella
                    pagina dedicata. L’abbinamento del libro con MyDesk ti
                    consente di potenziare lo studio, offrendoti maggiori
                    possibilità di successo nella selezione a cui ti stai
                    preparando.
                  </S.StyledSwiperParagraph>
                </S.StyledSwiperContent>
              </S.StyledSwiperContainer>
            </SwiperSlide>
            <SwiperSlide>
              <S.StyledSwiperContainer>
                <S.StyledImagesContainer>
                  <S.StyledFirstImagesRow>
                    <S.StyledImageContainer>
                      <img src={ThirdSlideOne} alt="Second Slide" />
                    </S.StyledImageContainer>
                    <S.StyledImageContainer>
                      <img src={ThirdSlideTwo} alt="Second Slide" />
                    </S.StyledImageContainer>
                  </S.StyledFirstImagesRow>
                  <S.StyledFirstImagesRow>
                    <S.StyledSingleImageContainer>
                      <img src={ThirdSlideThree} alt="Second Slide" />
                    </S.StyledSingleImageContainer>
                  </S.StyledFirstImagesRow>
                </S.StyledImagesContainer>
                <S.StyledSwiperContent>
                  <S.StyledSwiperHeading typo="headingSM">
                    Sei qui per svolgere una simulazione gratuita?
                  </S.StyledSwiperHeading>
                  <S.StyledSwiperParagraph typo="paragraphMD">
                    Entrando con le tue credenziali, puoi fare la simulazione,
                    scaricare il materiale informativo a tua disposizione e
                    confrontare il tuo risultato con quello degli altri
                    consultando la graduatoria finale. Il tutto per aiutarti a
                    raggiungere i tuoi obiettivi.
                  </S.StyledSwiperParagraph>
                </S.StyledSwiperContent>
              </S.StyledSwiperContainer>
            </SwiperSlide>
          </Swiper>
        </S.StyledRightContainer>
      </S.StyledLoginPage>
    </S.StyledBaseLayout>
  );
};

export default LoginV2;
