import Divider from "@/components/atoms/Divider";
import { Paragraph } from "@/components/atoms/Typography";
import styled from "styled-components";
import { BottomNavigationProps } from ".";
import ButtonV2 from "@/components/atoms/ButtonV2";

export const StyledBottomNavigationContainer = styled.div<Partial<BottomNavigationProps>>`
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding: ${({ theme, hasRecap }) => hasRecap ? theme.rem(8, 16) : theme.rem(16)};
  gap: ${({ theme }) => theme.rem(16)};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255);
  border-top: 1px solid #dddd;
  border-radius: 16px 16px 0 0;
  z-index: 5;
`;

export const StyledRecapButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.rem(16)};
  gap: ${({ theme }) => theme.rem(8)};
  border-bottom: 1px solid #ddd;
  height: 60px;
`;

export const StyledRecapButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledDivider = styled(Divider)`
  border-top: 3px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
  border-radius: 12px;
`;

export const StyledParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("buttonMD")};
  color: ${({ theme }) => theme.colorScheme.white.surface.textBasePrimary};
  font-weight: 600;
`;

export const StyledButtons = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.rem(8)};
  align-items: center;
  justify-content: space-between;
  height: 60px;
`;

export const StyledButton = styled(ButtonV2)`
  &:first-of-type {
    margin-left: 0;
  }
`;
