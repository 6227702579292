import { Statistics } from "@/models/quizzes/statistics";

interface MappedStatistics {
  subject: string;
  Voto: number;
  fullMark: number;
}

const normalizeQuizzesStatistics = (
  statistics: Statistics | undefined,
) => {
  if (!statistics) {
    return;
  }

  const schoolSubjects = statistics.verticals[0].schoolSubjects;

  const mappedStatistics = schoolSubjects.map((subject) => {
    return {
      subject: subject.label,
      Voto: subject.totalQuestion ? Math.round((subject.correctAnswers / subject.totalQuestion) * 100) : 0,
      fullMark: 100,
    };
  });
  const findLowestScoreSubject = (quizzes: MappedStatistics[] | undefined) => {
    if (!quizzes || quizzes.length === 0) {
      return null;
    }
    return quizzes.reduce(
      (lowest, current) =>
        current.Voto < (lowest?.Voto || 101) ? current : lowest,
      quizzes[0]
    );
  };

  const lowestScoreSubject = findLowestScoreSubject(mappedStatistics);

  return { mappedStatistics, lowestScoreSubject };
};

export default normalizeQuizzesStatistics;
