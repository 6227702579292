import { Paragraph } from "@/components/atoms/Typography";
import styled from "styled-components";

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.rem(16)};
  gap: ${({ theme }) => theme.rem(16)};
  border-bottom: 1px solid #3b3838;
  margin-bottom: ${({ theme }) => theme.rem(16)};
`;

export const StepperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const IconContainer = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 40px;
  // height: 40px;
  // border-radius: 50%;
  // background-color: #E7E7E7;
`;

export const IconContainerHidden = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  // background-color: #E7E7E7;
  visiblity: hidden;
`;

export const StepIndicatorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: ${({ theme }) => theme.rem(8, 16)};
`;

export const StyledParagraph = styled(Paragraph)`
  font-weight: 600;
`;

export const StepIndicator = styled.div<{ active: boolean }>`
  width: 30px;
  height: 4px;
  line-height: 30px;
  border-radius: 8px;
  background: ${({ active }) => (active ? "#1B1818" : "#B0B0B0")};
  color: white;
  text-align: center;
  cursor: pointer;
`;
