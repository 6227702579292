import { FC } from "react";
import IconButton from "../ButtonIcon";
import Icon from "../Icon";
import * as S from "./styles";
import { HorizontalCardProps } from "./types";

const HorizontalCard: FC<HorizontalCardProps> = ({
  label,
  title,
  iconBg,
  icon,
  onClick,
}) => {
  return (
    <S.StyledHorizontalCard onClick={onClick}>
      <S.StyledHorizontalCardContainer>
        <S.StyledIconContainer iconBg={iconBg}>
          <Icon name={icon} size="big" />
        </S.StyledIconContainer>
        <S.StyledHorizontalCardContent>
          <S.StyledParagraph typo="paragraphSM">{label}</S.StyledParagraph>
          <S.StyledHeading typo="headingXS" weight="semibold">
            {title}
          </S.StyledHeading>
        </S.StyledHorizontalCardContent>
      </S.StyledHorizontalCardContainer>
      <IconButton name="ArrowRight" />
    </S.StyledHorizontalCard>
  );
};

export default HorizontalCard;
