import { QuizInterface } from "@/pages/QuizPage/interface";

const normalizePractice = ({
  quizzes,
  historical,
  isPracticeLoading
}: {
  quizzes: QuizInterface[];
  historical: any[];
  isPracticeLoading: boolean
}) => {
  return {
    quizzesData: quizzes,
    historicalData: historical.slice(0, 5),
    isPracticeLoading
  };
};

export default normalizePractice;
