import styled from "styled-components";

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) =>
    theme.colorScheme.white.surface.borderSecondary};
`;

export default HorizontalLine;
