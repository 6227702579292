import {
  ExerciseHubCardContainer,
  ExerciseHubCardImage,
} from "@/components/molecules/Cards/styles";
import styled from "styled-components";
import CardLoading from "../Card/loading";

export const Practice = styled.div`
  margin-top: ${({ theme }) => theme.rem(24)};
`;

export const PracticeCards = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.rem(16)};

  ${({ theme }) =>
    theme.mediaQuery.lg(
      `
    margin-top: ${theme.rem(16)};
  `,
      true
    )}

  ${ExerciseHubCardContainer} {
    margin: 0;
    overflow: hidden;

    ${ExerciseHubCardImage} {
      object-fit: contain;
      min-width: auto;
    }
  }
`;

export const PracticeCardsMobile = styled.div`
  overflow: hidden;
  margin-top: ${({ theme }) => theme.rem(16)};
  display: flex;
  gap: ${({ theme }) => theme.rem(16)};
  width: 100%;

  ${ExerciseHubCardContainer} {
    margin: 0;
    overflow: hidden;
    min-width: auto;
    aspect-ratio: 0.7;

    ${ExerciseHubCardImage} {
      object-fit: contain;
      min-width: auto;
    }
  }
`;

export const PracticePerformance = styled.div`
  height: 100%;
  border-radius: ${({ theme }) => theme.rem(8)};
  background-color: ${({ theme }) => theme.colorScheme.white.surface.default};
  overflow: hidden;
`;

export const PracticePerformanceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.rem(12)};
`;

export const PracticePerformanceList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSliderWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  margin-block-end: 1.5rem;
  `

export const StyledCardLoading = styled(CardLoading)`
  flex: 1;
`;