import { FC, useEffect, useState } from "react";
import { Heading } from "@/components/atoms/Typography";
import { Col, Row } from "@/components/atoms/GridSystem";

import { ChartDataItem, PerformanceStatisticsProps } from "./types";

import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import * as S from "./styles";
import CustomTooltip from "./CustomTooltip";

interface Statistic {
  exercise: number;
  simulation: number;
  date: string;
}

type Time = {
  hours: number;
  minutes: number;
  seconds: number;
};

interface NormalizedStatistics {
  chartData: ChartDataItem[];
  averageExerciseTime: string;
  averageSimulationTime: string;
  averageBothTime: string;
}

interface Totals {
  totalExercise: number;
  totalSimulation: number;
}

function formatTime(time: Time): string {
  let formattedTime = '';

  if (time.hours > 0) {
    formattedTime += `${time.hours}h `;
  }
  if (time.minutes > 0) {
    formattedTime += `${time.minutes}m `;
  }
  if (time.seconds > 0) {
    formattedTime += `${time.seconds}s`;
  }

  return formattedTime.trim();
}

function normalizeStatistics(statistics: Statistic[]): NormalizedStatistics {
  const chartData = statistics.map((stat) => ({
    date: new Date(stat.date)
      .toLocaleDateString("it-IT", { weekday: "short" })
      .replace(/^\S/, (s) => s.toUpperCase()),
    simulation: stat.simulation,
    exercise: stat.exercise,
  }));

  const totals = statistics.reduce(
    (acc, { exercise, simulation }) => {
      acc.totalExercise += exercise / 60;
      acc.totalSimulation += simulation / 60;
      return acc;
    },
    { totalExercise: 0, totalSimulation: 0 } as Totals
  );

  const averageExercise = totals.totalExercise / statistics.length;
  const averageSimulation = totals.totalSimulation / statistics.length;

  const calculateTime = (value: number): Time => ({
    hours: Math.floor(value),
    minutes: Math.floor((value % 1) * 60),
    seconds: Math.round(((value * 60) % 1) * 60),
  });


  const averageExerciseTime = calculateTime(averageExercise);
  const averageSimulationTime = calculateTime(averageSimulation);
  const averageBothTime = calculateTime(
    (averageExercise + averageSimulation)
  );


  return {
    chartData,
    averageExerciseTime: formatTime(averageExerciseTime),
    averageSimulationTime: formatTime(averageSimulationTime),
    averageBothTime: formatTime(averageBothTime),
  };
}

const PerformanceStatistics: FC<PerformanceStatisticsProps> = ({
  statistics: baseStatistics = [],
  simulationsStatistics,
  exercisesStatistics,
  filters,
}) => {
  const [statistics, setStatistics] = useState<Statistic[]>(baseStatistics);
  const [averageExercise, setAverageExercise] = useState<string>("");
  const [averageSimulation, setAverageSimulation] = useState<string>("");
  const [averageBoth, setAverageBoth] = useState<string>("");
  const [chartData, setChartData] = useState<ChartDataItem[] | undefined>();
  const [currentFilter, setCurrentFilter] = useState<string>("all");

  const handleFilter = (type: string) => () => {
    if (type === "all") {
      setStatistics(baseStatistics);
    } else if (type === "simulation") {
      setStatistics(simulationsStatistics);
    } else if (type === "exercise") {
      setStatistics(exercisesStatistics);
    }

    setCurrentFilter(type);
  };

  useEffect(() => {
    const {
      chartData,
      averageExerciseTime,
      averageSimulationTime,
      averageBothTime,
    } = normalizeStatistics(statistics);

    setChartData(chartData);
    setAverageExercise(averageExerciseTime);
    setAverageSimulation(averageSimulationTime);
    setAverageBoth(averageBothTime);
  }, [statistics]);

  useEffect(() => {
    const {
      chartData,
      averageExerciseTime,
      averageSimulationTime,
      averageBothTime,
    } = normalizeStatistics(baseStatistics);

    setChartData(chartData);
    setAverageExercise(averageExerciseTime);
    setAverageSimulation(averageSimulationTime);
    setAverageBoth(averageBothTime);
  }, [baseStatistics]);

  const CustomBarShape: React.FC<any> = ({
    x,
    y,
    width,
    height,
    fill,
    payload,
    type,
  }) => {
    const radius = 10;
    const hasRadius =
      (type === "simulation" && payload.simulation && !payload.exercise) ||
      (type === "exercise" && payload.exercise && !payload.simulation) ||
      (type === "exercise" &&
        payload.exercise &&
        payload.simulation);
    return (
      <g>
        <path
          d={`
              M${x},${y + height}
              L${x},${y + (hasRadius ? radius : 0)}
              Q${x},${y} ${x + (hasRadius ? radius : 0)},${y}
              L${x + width - (hasRadius ? radius : 0)},${y}
              Q${x + width},${y} ${x + width},${y + (hasRadius ? radius : 0)}
              L${x + width},${y + height}
              Z
              `}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <S.PerformanceStatistics>
      <Heading
        content="Le tue performance dell’ultima settimana"
        typo="headingSM"
        weight="semibold"
      />
      <S.PerformanceStatisticsWrapper>
        <Row>
          <Col
            xs={12}
            md={5}
            $direction="column"
            justify="space-between"
            align="flex-start"
          >
            <div>
              <Heading
                content="Media giornaliera"
                typo="paragraphLG"
                weight="semibold"
              />
              <Heading
                content={averageBoth}
                typo="headingXXL"
                weight="semibold"
              />
              <Heading
                content="Mettiti alla prova con la Simulazione Classica, che ricrea una prova di esame realistica, per prepararti al meglio."
                typo="paragraphXS"
              />
            </div>
            <S.PerformanceStatisticsLegend>
              <S.PerformanceStatisticsLegendItem>
                <S.PerformanceStatisticsLegendItemColor $type="simulation" />
                <Heading content="Simulazioni" typo="paragraphSM" />
                <Heading
                  content={averageSimulation}
                  typo="paragraphMD"
                  weight="semibold"
                />
              </S.PerformanceStatisticsLegendItem>
              <S.PerformanceStatisticsLegendItem>
                <S.PerformanceStatisticsLegendItemColor $type="exercise" />
                <Heading content="Esercitazioni" typo="paragraphSM" />
                <Heading
                  content={averageExercise}
                  typo="paragraphMD"
                  weight="semibold"
                />
              </S.PerformanceStatisticsLegendItem>
            </S.PerformanceStatisticsLegend>
          </Col>
          <Col xs={12} md={6} $offset={{ xs: 0, md: 1 }}>
            <S.PerformanceStatisticsFilters>
              {filters
                ? filters.map((filter) => (
                    <S.PerformanceStatisticsButton
                      aria-current={currentFilter == filter.type}
                      key={filter.type}
                      onClick={handleFilter(filter.type)}
                    >
                      {filter.label}
                    </S.PerformanceStatisticsButton>
                  ))
                : null}
            </S.PerformanceStatisticsFilters>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData}>
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  horizontal={false}
                />
                <XAxis dataKey="date" tickLine={false} />
                <Tooltip cursor={{ fill: "transparent" }} content={<CustomTooltip />} />
                <Bar
                  dataKey="simulation"
                  stackId="a"
                  fill="#7790AF"
                  shape={<CustomBarShape type="simulation" />}
                  barSize={32}
                />
                <Bar
                  dataKey="exercise"
                  stackId="a"
                  fill="#BA9179"
                  shape={<CustomBarShape type="exercise" />}
                  barSize={32}
                />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </S.PerformanceStatisticsWrapper>
    </S.PerformanceStatistics>
  );
};

export default PerformanceStatistics;
