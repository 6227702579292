export type TargetType =
  | (() => HTMLElement | null)
  | { current: HTMLElement | null }
  | HTMLElement
  | null
  | undefined;

export default function getTargetElement(
  target: TargetType,
  defaultElement: HTMLElement | null | Window
): HTMLElement | null | undefined | Window {
  // Return type already handles undefined
  if (target === undefined || target === null) {
    return defaultElement;
  }

  let targetElement: HTMLElement | null;

  if (typeof target === "function") {
    targetElement = target();
  } else if ("current" in target) {
    targetElement = target.current;
  } else {
    targetElement = target;
  }

  return targetElement;
}
