import { rem } from "./helpers";

interface GridConfig {
  columns: { [key: string]: number };
  gutterWidth: { [key: string]: number };
  paddingWidth: { [key: string]: number };
  container: { [key: string]: string | number };
}

const GRID_CUSTOM_CONF_KEY = "gridCustomConf";

export const GRID_BASE_CONF: GridConfig = {
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  },
  gutterWidth: {
    xs: 16,
    sm: 16,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24,
  },
  paddingWidth: {
    xs: 16,
    sm: 16,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24,
  },
  container: {
    xs: "100%",
    sm: rem(540),
    md: rem(720),
    lg: rem(960),
    xl: rem(1140),
    xxl: rem(1280),
  },
};
interface Props {
  theme?: any;
}

const hasCustomConf = ({ theme }: Props): string =>
  JSON.stringify((theme && theme[GRID_CUSTOM_CONF_KEY]) || {});

const resolveConfig = ({ theme }: Props): GridConfig => {
  const themeConf: GridConfig = (theme && theme[GRID_CUSTOM_CONF_KEY]) || {};

  const conf: GridConfig = {
    ...GRID_BASE_CONF,
    ...themeConf,
  };

  return conf;
};

let configs: [string, GridConfig] = ["", GRID_BASE_CONF];

export default function gridConfig(props: Props = {}): GridConfig {
  const customConf = hasCustomConf(props);

  if (configs[0] === customConf) {
    return configs[1];
  }

  const conf = resolveConfig(props);

  configs[0] = customConf;
  configs[1] = conf;

  return conf;
}
