import { ButtonLabel } from "@/components/atoms/Typography";
import CardLoading from "@/components/organisms/Card/loading";
import styled from "styled-components";

export const StyledSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: ${({ theme }) => theme.rem(32)};
`;

export const StyledSliderWrapper = styled.div`
  display: flex;
  // margin-bottom: 60px;

  ${({ theme }) =>
    theme.mediaQuery.md(`
   width: 100%;
   `)}
`;

export const StyledHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${({ theme }) => theme.rem(4)};
`;

export const StyledTimeCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};
  flex-wrap: wrap;
  margin-block: ${({ theme }) => theme.rem(8)};
  margin-top: ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
   gap: ${theme.rem(24)};
 `)}
`;

export const StyledMixedExerciseCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.rem(12)};
  margin-block: ${({ theme }) => theme.rem(24)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
   gap: ${theme.rem(24)};
   justify-content: flex-start;
 `)}
`;

export const StyledChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${({ theme }) =>
    theme.mediaQuery.md(`
  width: 93%;
 `)}

  ${({ theme }) =>
    theme.mediaQuery.lg(`
      display: grid;
      align-items: unset;
      flex-direction: unset;
      grid-template-columns: 50% 50%;
      width: 100%;
      column-gap: ${theme.rem(24)};
  `)}
`;

export const StyledCardLoading = styled(CardLoading)`
  flex: 1;
`;

export const StyledExercisesHubCards = styled.div`
  display: flex;
  width: 100%;
  // overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.md(`
    width: 100%;
  `)}

  .swiper-container {
    margin-bottom: 30px;

    ${({ theme }) =>
      theme.mediaQuery.md(`
      margin-bottom: 0;
    `)}
  }
`;

export const StyledSliderWrapperTwo = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.md(`
      width: 100%;
  `)}
`;

export const StyledButtonLabel = styled(ButtonLabel)`
  cursor: pointer;
  pointer-events: auto;

`;
