import { useState, useRef, useEffect, FC } from "react";
import { TimerProps } from "./types";
import { motion } from "framer-motion";

import * as S from "./styles";
import Icon from "@/components/atoms/Icon";

const Timer: FC<TimerProps> = ({ startTime, quizTime, onTimerEnd }) => {
  const Ref = useRef<number>(0);

  const getDeadTime = (): Date => {
    let deadline = new Date(startTime);
    deadline.setMinutes(deadline.getMinutes() + quizTime);
    return deadline;
  };

  const [timer, setTimer] = useState<string>();
  const [isTimeShown, setIsTimeShown] = useState<boolean>(true);

  const renderedTimer = isTimeShown ? `${timer} Rimanenti` : "";

  const toggleShowTime = () => {
    setIsTimeShown((prevState) => !prevState);
  };

  const startTimer = (deadline: Date): void => {
    const now = new Date();
    const total = Date.parse(deadline.toString()) - Date.parse(now.toString());
    const totalSeconds = Math.floor(total / 1000);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (total >= 0) {
      setTimer(
        [
          hours.toString().padStart(2, "0"),
          minutes.toString().padStart(2, "0"),
          seconds.toString().padStart(2, "0"),
        ].join(":")
      );
    } else {
      setTimer(['00', '00', '00'].join(':'));
      clearInterval(Ref.current);
      onTimerEnd();
    }
  };

  const clearTimer = (deadline: Date): void => {
    if (Ref.current) clearInterval(Ref.current);
    const id = window.setInterval(() => startTimer(deadline), 1000);
    Ref.current = id;
  };

  useEffect(() => {
    const deadline = getDeadTime();
    clearTimer(deadline);

    return () => {
      if (Ref.current) clearInterval(Ref.current);
    };
  }, [startTime, quizTime]);

  return (
    <>
      {timer ? (
        <motion.div
          initial={{ x: 200 }}
          animate={{ x: 0 }}
          transition={{ type: "spring", stiffness: 50 }}
        >
          <S.StyledTimer>
            <Icon
              name={isTimeShown ? "Eye" : "EyeSlash"}
              onClick={toggleShowTime}
              ariaLabel={isTimeShown ? "Mostra il tempo rimanente" : "Nascondi il tempo rimanente"}
            />
            {isTimeShown && <S.StyledTimerText>{renderedTimer}</S.StyledTimerText>}
          </S.StyledTimer>
        </motion.div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Timer;