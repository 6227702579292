import { Heading } from "@/components/atoms/Typography";
import Timer from "../Timer";

import * as S from "./styles";
import { QuizValues } from "@/pages/QuizPage/interface";

import { useTranslation } from "react-i18next";
import ButtonV2 from "@/components/atoms/ButtonV2";

interface HeaderInterface {
  totalScore: number;
  type: string;
  title: string;
  random: boolean;
  timer: number;
  onFinish: () => void;
  onTimeEnd: () => void;
  isCorrectionMode: boolean;
  quiz: QuizValues;
  quizDateStart: string;
}

const Header = ({
  title = "Esercitazione",
  // type = "Esercitazione Componibile",
  // random = false,
  // totalScore,
  timer,
  // onExit,
  onFinish,
  onTimeEnd,
  isCorrectionMode,
  quizDateStart,
  quiz,
}: HeaderInterface) => {
  const { t } = useTranslation();
  // const renderedType = random ? `${type} (Random)` : type;

  const buttonLabel = quiz.isCorrectionMode
    ? t("go_back_to_home_page")
    : t("submit_simulation");
  return (
    <S.StyledHeaderContainer>
      <S.StyledContentContainer>
        {/* <S.StyledIconContainer onClick={onExit}>
          <S.StyledIcon name="XMark" size="huge" />
        </S.StyledIconContainer> */}
        <S.StyledHeaderText>
          <Heading typo="headingXS" weight="semibold">{title}</Heading>
          {/* <S.StyledHeaderDescription>
            {`${renderedType} | Massimi punti disponibili: ${totalScore}`}
          </S.StyledHeaderDescription> */}
        </S.StyledHeaderText>
      </S.StyledContentContainer>

      <S.StyledHeaderAction>
        {!isCorrectionMode && quizDateStart && (
          <>
            <Timer
              startTime={quizDateStart}
              quizTime={timer}
              onTimerEnd={onTimeEnd}
            />
            <S.StyledDivider type="vertical" />
          </>
        )}

        <ButtonV2
          variant="quaternary"
          // iconEnd="ArrowRight"
          label={buttonLabel}
          handleClick={onFinish}
        />
      </S.StyledHeaderAction>
    </S.StyledHeaderContainer>
  );
};

export default Header;
