import styled from "styled-components";
import { Heading, Paragraph } from "../Typography";
import { LaunchCardProps } from "./types";

interface StyledSubjectLevelItemProps {
  className: string;
}

interface isFullVersionInterface {
  isFullVersion: boolean;
}

export const subjectIconsBg: { [key: string]: string } = {
  "Competenze di lettura": "#DFD1EC",
  "Competenze di lettura e conoscenze acquisite negli studi": "#DFD1EC",
  Chimica: "#D2D9D6",
  "Fisica e matematica": "#FFF0D5",
  "Fisica e Matematica": "#FFF0D5",
  "Ragionamento logico e problemi": "#E7E7E7",
  Biologia: "#CEDEE7",
};

export const StyledLaunchCardCTAContainer = styled.div<isFullVersionInterface>`
  padding: ${({ theme }) => theme.rem(4, 16, 4, 56)};
  transition: all 0.3s ease-in-out;
  background-color: #f0f4f9;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};
  height: 50px;
  display: none;

  ${({ isFullVersion }) =>
    isFullVersion &&
    `
    display: flex;
  `}

  ${({ theme }) =>
    theme.mediaQuery.md(`
    display: flex;
  `)}
`;

export const StyledLaunchCard = styled.div<Partial<LaunchCardProps>>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.rem(8)};
  background: ${({ theme }) => theme.colorScheme.white.surface.default};
  overflow: hidden;
  border: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderTertiary};
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${({ subject }) => subjectIconsBg[subject || ""]};
  }

  &:hover ${StyledLaunchCardCTAContainer} {
    background: ${({ subject }) => subjectIconsBg[subject || ""]};
  }

  ${({ theme }) =>
    theme.mediaQuery.md(
      `
    &:hover{
      background: transparent;
    }
    `
    )}
`;

export const StyledLaunchCardContent = styled.div<isFullVersionInterface>`
  display: flex;
  width: 100%;
  padding: ${({ theme }) => theme.rem(8)};
  gap: ${({ theme }) => theme.rem(12)};
  align-items: center;

  ${({ theme, isFullVersion }) =>
    isFullVersion &&
    `
    align-items: flex-start;
    padding: ${theme.rem(16, 12)};
  `}

  ${({ theme }) =>
    theme.mediaQuery.md(`
    align-items: flex-start;
    padding: ${theme.rem(16, 12)};
  `)}
`;

export const StyledIconContainer = styled.div<Partial<LaunchCardProps>>`
  min-width: ${({ theme }) => theme.rem(32)};
  height: ${({ theme }) => theme.rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${({ subject }) => subjectIconsBg[subject || ""]};
`;

export const StyledSubjectInfo = styled.div`
  display: flex;
  width: 100%;
  max-width: 80%;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(16)};
`;

export const StyledSubjectHeading = styled(Heading)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseSecondary};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) =>
    theme.mediaQuery.md(`
      -webkit-line-clamp: 1;
    `)}
`;

export const StyledSubjectLevel = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(4)};
`;

export const StyledSubjectLevelItems = styled.div<isFullVersionInterface>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};
  flex-direction: row-reverse;

  ${({ isFullVersion }) =>
    isFullVersion &&
    `
    flex-direction: row;
  `}

  ${({ theme }) =>
    theme.mediaQuery.md(`
    flex-direction: row;
    gap: ${theme.rem(16)};

  `)}
`;

export const StyledSubjectLevelDescription = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseTertiary};
`;

export const StyledSubjectDetails = styled.div<isFullVersionInterface>`
  justify-content: space-between;
  align-items: center;
  display: none;

  ${({ isFullVersion }) =>
    isFullVersion &&
    `
    display: flex;
  `}

  ${({ theme }) =>
    theme.mediaQuery.md(`
    display: flex;
  `)}
`;

export const StyledSubjectDetailsItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledSubjectItemParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.colorScheme.white.surface.textBaseTertiary};
`;

export const StyledSubjectLevelItem = styled.div<StyledSubjectLevelItemProps>`
  height: 7px;
  flex: 1;
  background: #cfdbea;
  border-radius: ${({ theme }) => theme.rem(4)};

  &.active:first-of-type {
    background-color: #ff7474;
  }

  &.active:nth-of-type(2) {
    background-color: #fcc06d;
  }

  &.active:nth-of-type(3) {
    background-color: #92e3ac;
  }
`;

export const StyledSubjectLevelItemsLines = styled.div`
  display: flex;
  // width: 100%;
  flex: 1;
  gap: ${({ theme }) => theme.rem(4)};

  ${({ theme }) =>
  theme.mediaQuery.md(`
  gap: ${theme.rem(16)};
`)}
`;
