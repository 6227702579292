import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { Paragraph } from "@/components/atoms/Typography";

const ValueIndicator: React.FC<ValueIndicatorProps> = ({
  value,
  setValue,
  min,
  max,
  grades,
}) => {
  // States to track the current value, and min/max changes
  const [displayValue, setDisplayValue] = useState(value);
  const [animatedValue, setAnimatedValue] = useState(value);
  const [prevMin, setPrevMin] = useState(min);
  const [prevMax, setPrevMax] = useState(max);

  // Calculate the percentage based on the previous min/max
  const percentage = ((animatedValue - prevMin) / (prevMax - prevMin)) * 100;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);

    // If grades are provided, snap to the closest grade
    if (grades) {
      const closestGrade = grades.reduce(
        (prev, curr) =>
          Math.abs(curr - newValue) < Math.abs(prev - newValue) ? curr : prev,
        max
      );
      setValue(closestGrade);
    } else {
      setValue(newValue);
    }
  };

  // Gradually animate the displayed value to the new value
  useEffect(() => {
    const increment = value > displayValue ? 1 : -1;
    if (displayValue !== value) {
      const interval = setInterval(() => {
        setDisplayValue((prev) => {
          if (
            (increment > 0 && prev >= value) ||
            (increment < 0 && prev <= value)
          ) {
            clearInterval(interval);
            return value;
          }
          return prev + increment;
        });
      }, 20); // Adjust timing for animation speed
    }
  }, [value, displayValue]);

  // Handle changes in min/max and reset previous min/max
  useEffect(() => {
    setPrevMin(min);
    setPrevMax(max);
    setAnimatedValue(value);
  }, [min, max, value]);

  return (
    <S.StyledValueIndicatorContainer>
      <Paragraph>{String(min)}</Paragraph>
      <S.SliderContainer>
        <S.FilledSlider width={percentage} />
        <S.Slider min={min} max={max} value={value} onChange={handleChange} />
        <S.ValueLabel left={percentage}>{displayValue}</S.ValueLabel>
      </S.SliderContainer>
      <Paragraph>{String(max)}</Paragraph>
    </S.StyledValueIndicatorContainer>
  );
};

export default ValueIndicator;
