import { FC } from "react";
import { Material } from "../MaterialsTable";
import AccordionV2 from "@/components/atoms/AccordionV2";
import Icon from "@/components/atoms/Icon";
import { subjectIcons } from "@/components/atoms/LaunchCard";

import * as S from "./styles";
import Divider from "@/components/atoms/Divider";

interface MaterialsAccordionListProps {
  materials: Material[];
  isSimulation?: boolean;
}

interface AccordionTitleProps {
  material: Material;
  isSimulation?: boolean;
}

interface BodyItemProps {
  label: string;
  icon: string;
  value: number | string;
  hidden?: boolean;
  full?: boolean;
}

const BodyItem: FC<BodyItemProps> = ({ label, icon, value, hidden, full }) => {
  return (
    <S.StyledBodyItem hidden={hidden} full={full}>
      <S.StyledBodyItemParagraph typo="pargraphXS">
        {label}
      </S.StyledBodyItemParagraph>
      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
        <Icon name={icon} size="defaultBig" />
        <S.StyledBodyItemValue typo="pargraphSM" weight="semibold">
          {value.toString()}
        </S.StyledBodyItemValue>
      </div>
    </S.StyledBodyItem>
  );
};

const AccordionTitle: FC<AccordionTitleProps> = ({ material }) => {
  console.log({ material });
  return (
    <S.StyledAccordionTitle>
      <S.StyledIconContainer schoolSubject={material.schoolSubject}>
        <Icon name={subjectIcons[material.schoolSubject || ""]} size="big" />
      </S.StyledIconContainer>
      <S.StyledTitleParagraph typo="pargraphLG" weight="semibold">
        {material.schoolSubject}
      </S.StyledTitleParagraph>
    </S.StyledAccordionTitle>
  );
};

const AccordionBody: FC<AccordionTitleProps> = ({ material, isSimulation }) => {
  const {
    correctAnswersNumber,
    skippedAnswersNumber,
    wrongAnswersNumber,
    averageTime,
    totalQuestion,
    score,
  } = material;
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <div
        style={{ display: "flex", justifyContent: "space-between", gap: 24 }}
      >
        <BodyItem label="Corrette" icon="Seen" value={correctAnswersNumber} />
        <Divider type="vertical" />
        <BodyItem label="Errate" icon="XMark" value={wrongAnswersNumber} />
        <Divider type="vertical" />
        <BodyItem
          label="Non date"
          icon="SkippedAnswer"
          value={skippedAnswersNumber}
        />
      </div>
      <div
        style={{ display: "flex", justifyContent: "space-between", gap: 24 }}
      >
        {
          isSimulation ? (
            <BodyItem label="Punteggio" icon="Score" value={score} />
          ) : (
            <BodyItem label="Domande" icon="NumericList" value={totalQuestion} />
          )
        }
        <Divider type="vertical" />
        <BodyItem label="Tempo medio" icon="AverageTime" value={averageTime} full />
        {/* <S.StyledHiddenDivider type="vertical" />
        <BodyItem label="Tempo medio" icon="AverageTime" value={0} hidden /> */}
      </div>
    </div>
  );
};

const MaterialsAccordionList: FC<MaterialsAccordionListProps> = ({
  materials,
  isSimulation,
}) => {
  console.log({ materials });
  return (
    <>
      {materials.map((material) => (
        <AccordionV2 title={<AccordionTitle material={material} />}>
          <AccordionBody material={material} isSimulation={isSimulation} />
        </AccordionV2>
      ))}
    </>
  );
};

export default MaterialsAccordionList;
