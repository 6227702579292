import styled from 'styled-components'

interface StyledBadgeProps {
  $backgroundColor: string
}

export const StyledBadgeContainer = styled.div<StyledBadgeProps>`
  display: flex;
  flex-wrap: nowrap;
  padding: ${({ theme }) => theme.rem(4) + ' ' + theme.rem(12)};
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  transition: all 0.3s ease-in-out;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : '#DCE5F0'};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding: ${theme.rem(8)} ${theme.rem(16)};
    `)}
`
