import { useEffect, useState } from "react";
import {
  generateQuiz,
  getAverageTimeSubjectStatistics,
  getQuizzesStatistics,
} from "@/api/services/quizzes";
import { useNavigate } from "react-router-dom";
import useQuizzes from "@/hooks/useQuizzes";
import { EditorialCardProps } from "@/components/molecules/Cards/EditorialCard";
import { VerticalChartDataType } from "@/components/molecules/Charts/VerticalBarChart";
import { PieChartDataType } from "@/components/molecules/Charts/PieChart";

const getDifficultyValue = (difficulty: "easy" | "medium" | "hard"): number => {
  const difficultyMap: { [key in "easy" | "medium" | "hard"]: number } = {
    easy: 1,
    medium: 3,
    hard: 5,
  };
  return difficultyMap[difficulty];
};

const getQuestionsStatus = (
  type: "skipped" | "wrong" | undefined
): number | undefined => {
  if (!type) {
    return;
  }
  const questionsMap: { [key in "skipped" | "wrong"]: number } = {
    skipped: 0,
    wrong: -1,
  };
  return questionsMap[type];
};

const mapAverageTimeSubjects = (data: any): VerticalChartDataType[] => {
  const { lastQuiz, quizzes } = data;

  return quizzes.schoolSubjects.map((subject: any) => {
    const quizSubject = lastQuiz.schoolSubjects.find(
      (q: any) => q.label === subject.label
    );

    return {
      subject: subject.label,
      data: [
        {
          name: "ultima prova",
          value: quizSubject ? quizSubject.averageResponseTime : 0
        },
        {
          name: "ultime 5 prove",
          value: subject.averageResponseTime,
        },
      ],
    };
  });
};

const mapSubjectStatistics = (data: any): PieChartDataType[] => {
  const { verticals } = data;

  return verticals[0].schoolSubjects.map((subject: any) => ({
    subject: subject.label,
    data: [
      { name: "Corrette", value: subject.correctAnswers },
      { name: "Errate", value: subject.wrongAnswers },
      { name: "Non date", value: subject.nullAnswers },
    ],
  }));
};

const useExerciseHub = () => {
  const { realSchoolSubjects, exeriseQuiz } = useQuizzes();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isQuizReady, setIsQuizReady] = useState(false);
  const [title, setTitle] = useState("");
  const [generatedQuiz, setGeneratedQuiz] = useState();
  const [averageTimeSubjects, setAverageTimeSubjects] = useState<VerticalChartDataType[]>([]);
  const [subjectStatistics, setSubjectStatistics] = useState<PieChartDataType[]>([]);

  const getAverageTimeSubjectsHandler = async () => {
    try {
      const { data } = await getAverageTimeSubjectStatistics();
      const mappedData = mapAverageTimeSubjects(data);
      setAverageTimeSubjects(mappedData);
    } catch (error) {
      console.log({ error });
    }
  };

  const getSubjectStatisticsHandler = async () => {
    try {
      const { data } = await getQuizzesStatistics();
      const mappedData = mapSubjectStatistics(data);
      setSubjectStatistics(mappedData);
    } catch (error) {
      console.log({ error });
    }
  };

  const generateQuizHandler = async ({
    type = "exercise",
    difficuly = 3,
    time = 10,
    group = "schoolSubject",
    questionsNumber = 10,
    schoolSubjects = [],
    rightAnswerScore = 0,
    wrongAnswerScore = 0,
    nullAnswerScore = 0,
    answerType,
  }: {
    type?: "exercise" | "simulation";
    difficuly?: number;
    time?: number;
    group?: "random" | "schoolSubject";
    questionsNumber?: number;
    schoolSubjects?: number[];
    rightAnswerScore?: number;
    wrongAnswerScore?: number;
    nullAnswerScore?: number;
    answerType?: number;
  }) => {
    setIsLoading(true);

    try {
      const data = {
        data: {
          type: type,
          difficulty: difficuly,
          time: time,
          group: group,
          questionsNumber: questionsNumber,
          schoolSubjects: schoolSubjects,
          rightAnswerScore: rightAnswerScore,
          wrongAnswerScore: wrongAnswerScore,
          nullAnswerScore: nullAnswerScore,
          idConfig: exeriseQuiz.id,
          answerType,
        },
      };
      const { data: generatedQuiz } = await generateQuiz(data);
      setGeneratedQuiz(generatedQuiz);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const onTimeEnd = ({
    type = "exercise",
    hasScore = false,
    nullAnswerQuestion = 0,
    rightAnswerQuestion = 0,
    wrongAnswerQuestion = 0,
  }) => {
    const quiz = {
      attributes: {
        type: type,
        title: title,
        score: {
          hasScore: hasScore,
          nullAnswerQuestion: nullAnswerQuestion,
          rightAnswerQuestion: rightAnswerQuestion,
          wrongAnswerQuestion: wrongAnswerQuestion,
        },
      },
    };

    navigate("/quiz", {
      state: { generatedQuiz: generatedQuiz, config: quiz },
      replace: true,
    });
  };

  const handleTimeCick = async (time: number) => {
    setTitle(`${time} minuti`);
    await generateQuizHandler({
      time: time,
      schoolSubjects: realSchoolSubjects.map(
        (schoolSubject) => schoolSubject.id
      ) as never[],
    });
  };

  const handleExerciseCard = async (card: EditorialCardProps) => {
    setTitle(card.title);
    await generateQuizHandler({
      time: card.questionTime,
      difficuly: getDifficultyValue(card.prefix?.difficulty || "medium"),
      questionsNumber: card.questionCount,
      schoolSubjects:
        card.description && card.description.length > 0
          ? card.description.map((schoolSubject) => schoolSubject.id)
          : [],
      answerType: getQuestionsStatus(card.prefix?.type || undefined),
    });
  };

  useEffect(() => {
    if (generatedQuiz) {
      setIsQuizReady(true);
    }
  }, [generatedQuiz]);

  useEffect(() => {
    getAverageTimeSubjectsHandler();
    getSubjectStatisticsHandler();
  }, []);

  return {
    isLoading,
    isQuizReady,
    generateQuizHandler,
    onTimeEnd,
    realSchoolSubjects,
    handleTimeCick,
    handleExerciseCard,
    title,
    averageTimeSubjects,
    subjectStatistics,
  };
};

export default useExerciseHub;
