import styled from "styled-components";

export const StyledCounter = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.rem(8)};
  flex-direction: column;

  ${({ theme }) =>
    theme.mediaQuery.lg(`
  flex-direction: row;
  gap: ${theme.rem(16)};
  align-items: center;

  `)}
`;

export const StyledIconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #cfdbea;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(4)};
`;
