import { Paragraph } from "@/components/atoms/Typography";
import * as S from "./styles";
import { MaterialQuestionsNumberProps } from "./types";
import { QuestionStatus } from "@/components/organisms/Quiz/QuizBody";
import { Question } from "@/pages/QuizPage/interface";

const MaterialQuestionsNumber = ({
  currentQuestionNumber,
  answeredQuestions,
  // currentSubject,
  totalQuestions,
  isRandom,
  questions,
  setCurrentQuestionNumber,
  quiz,
  setCurrentSchoolSubject,
}: MaterialQuestionsNumberProps) => {
  const isQuestionHasAnwer = (id: NumberOrString): boolean => {
    const isQuestionHasAnswer =
      answeredQuestions
        .map((answer) => answer.questionId)
        .findIndex((questionId) => questionId === id) !== -1;
    return isQuestionHasAnswer;
  };

  const questionStatus = (id: NumberOrString): QuestionStatus => {
    // const question = questions[index];
    const question = questions.find(
      (question) => question.id === id
    ) as Question;
    if (quiz.isCorrectionMode) {
      if (!question.answerUserId) {
        return QuestionStatus.SKIPPED;
      }
      if (question.answerCorrectId === question.answerUserId) {
        return QuestionStatus.CORRECT;
      } else {
        return QuestionStatus.INCORRECT;
      }
    } else {
      return QuestionStatus.UNANSWERED;
    }
  };

  // const currentQuestionSchoolSubject = (id: NumberOrString) => {
  //   const question = questions.find(
  //     (question) => question.id === id
  //   ) as Question;

  //   return question.schoolSubject.label.replace(/\s+/g, "_");
  // };

  const materialLabel = isRandom ? "Domande" : "Sezioni";

  return (
    <S.StyledMaterialQuestionsNumber>
      <S.StyledMaterialLabel content={materialLabel} uppercase />
      <S.StyledMaterialQuestions>
        {isRandom ? (
          [...Array(totalQuestions).keys()].map((item) => {
            return (
              <S.StyledMaterialTag
                id={`${item + 1}-random`}
                label={String(item + 1)}
                variant="round"
                key={item}
                size="md"
                activeSchoolSubject={quiz.schoolSubject === "random"}
                active={currentQuestionNumber === item + 1}
                answered={isQuestionHasAnwer(questions[item].id)}
                onClick={() => setCurrentQuestionNumber(item + 1)}
                status={questionStatus(questions[item].id)}
              />
            );
          })
        ) : (
          <S.StyledSectionContainer>
            {quiz.schoolSubjectQuestions.length > 0 &&
              quiz.schoolSubjectQuestions.map((schoolSubjectSection) => (
                <S.StyledSection
                  id={`${schoolSubjectSection.schoolSubject.replace(
                    /\s+/g,
                    "_"
                  )}`}
                  active={
                    quiz.schoolSubject === schoolSubjectSection.schoolSubject
                  }
                >
                  <Paragraph typo="paragraphLG">
                    {schoolSubjectSection.schoolSubject}
                  </Paragraph>
                  <S.StyledMaterialQuestions>
                    {[
                      ...Array(schoolSubjectSection.questions.length).keys(),
                    ].map((item) => {
                      return (
                        <S.StyledMaterialTag
                          id={`${
                            item + 1
                          }-${schoolSubjectSection.schoolSubject.replace(
                            /\s+/g,
                            "_"
                          )}`}
                          label={String(item + 1)}
                          variant="round"
                          key={item}
                          size="md"
                          activeSchoolSubject={
                            schoolSubjectSection.schoolSubject ===
                            quiz.schoolSubject
                          }
                          active={
                            currentQuestionNumber === item + 1 &&
                            schoolSubjectSection.schoolSubject ===
                              quiz.schoolSubject
                          }
                          answered={isQuestionHasAnwer(
                            schoolSubjectSection.questions[item].id
                          )}
                          onClick={() => {
                            setCurrentQuestionNumber(item + 1);
                            if (!isRandom) {
                              setCurrentSchoolSubject(
                                schoolSubjectSection.schoolSubject
                              );
                            }
                          }}
                          status={questionStatus(
                            schoolSubjectSection.questions[item].id
                          )}
                        />
                      );
                    })}
                  </S.StyledMaterialQuestions>
                </S.StyledSection>
              ))}
          </S.StyledSectionContainer>
          // [...Array(totalQuestions).keys()].map((item) => {
          //   return (
          //     <S.StyledMaterialTag
          //       label={String(item + 1)}
          //       variant="round"
          //       key={item}
          //       size="md"
          //       active={currentQuestionNumber === item + 1}
          //       answered={isQuestionHasAnwer(item)}
          //       onClick={() => setCurrentQuestionNumber(item + 1)}
          //       status={questionStatus(item)}
          //     />
          //   );
          // })
        )}
      </S.StyledMaterialQuestions>
    </S.StyledMaterialQuestionsNumber>
  );
};

export default MaterialQuestionsNumber;
