import { useState } from "react";
import Divider from "@/components/atoms/Divider";
import TimeCard, { TimeCardProps } from "@/components/atoms/TimeCard";
import { Heading } from "@/components/atoms/Typography";
import { ExerciseCard, ExerciseHubCard } from "@/components/molecules/Cards";
import { EditorialCardProps } from "@/components/molecules/Cards/EditorialCard";
import { ExerciseHubCardProps } from "@/components/molecules/Cards/ExerciseHubCard";
import { MixedExerciseCardProps } from "@/components/molecules/Cards/MixedExerciseCard";
import { PieChart, VerticalBarChart } from "@/components/molecules/Charts";
import { Swiper, SwiperSlide } from "@/components/molecules/Swiper";
import Sidebar from "@/components/organisms/Sidebar";
import useIsMobile from "@/hooks/useIsMobile";
import BaseLayout from "@/layouts/BaseLayout";
import { useTranslation } from "react-i18next";
import * as S from "./styles";
import useExerciseHub from "./useExerciseHub";
import Countdown from "@/components/organisms/Countdown";

import FirstEditorialCardImage from "@/assets/images/exercise-hub/editorial-1.png";
// import SecondEditorialCardImage from "@/assets/images/exercise-hub/editorial-2.png";

import simulationImage from "@/assets/images/quiz/simulation-illustration.png";
import exerciseImage from "@/assets/images/quiz/exercise-illustration.png";
import useQuizzes from "@/hooks/useQuizzes";
import { useNavigate } from "react-router-dom";
import { Col, Row, SpacerBase } from "@/components/atoms/GridSystem";
import { Question } from "../QuizPage/interface";
import { isProductionMode } from "@/utils/isProductionMode";

const copy = {
  heading: {
    prefix: "exercise_hub",
    title: "what_do_you_want_to_do_today",
  },
  timeCards: {
    prefix: "Esercitazioni adattate alle tue esigenze",
    title: "Oggi vai di fretta?",
    paragraph:
      "Scegli la durata della tua esercitazione veloce. Il test includerà tutte le materie.",
  },
  editorialCards: {
    prefix: "Esercitazioni adattate alle tue esigenze",
    title: "Mischiamo le carte in tavola",
    paragraph: "Migliora con le nostre esercitazioni create su misura.",
    cta: "Mostra altri",
  },
  studyHubCards: {
    prefix: "Esercitazioni adattate alle tue esigenze",
    title: "Test completi",
  },
  verticalBarChart: {
    withoutWrapper: true,
    copy: {
      title: "Tempo medio per domanda",
      infoBox: "Puoi fare di meglio rispetto alla tua media!",
    },
    prefix: "Esercitazioni adattate alle tue esigenze",
    title: "I tuoi risultati a portata di mano",
  },
  pieChart: {
    withoutWrapper: true,
    copy: {
      title: "Split domande per materia",
      infoBox: "Puoi fare di meglio rispetto alla tua media!",
    },
  },
  mixedExerciseCards: {
    prefix: "Esercitazioni create da AlphaTest per te",
    title: "I consigli di Alpha Test per uno studio più efficace",
    paragraph:
      "Migliora con le nostre collezioni di esercizi create per i tuoi bisogni.",
  },
};

const ExerciseHubPage = () => {
  const {
    handleTimeCick,
    isQuizReady,
    onTimeEnd,
    realSchoolSubjects,
    handleExerciseCard,
    averageTimeSubjects,
    subjectStatistics,
  } = useExerciseHub();

  const navigate = useNavigate();
  const {
    historicalSimulations,
    historicalExercises,
    exeriseQuiz,
    simulationQuiz,
    isLoading,
  } = useQuizzes();

  const getUniqueSchoolSubjects = (questions: Question[]) => {
    const subjects = questions.map((question) => question.schoolSubject.label);
    return [...new Set(subjects)];
  };

  const historicalSimulationsScore =
    historicalSimulations && historicalSimulations.length > 0
      ? historicalSimulations[0].userScore
      : null;
  const historicalExercisesSubjects =
    historicalExercises && historicalExercises.length > 0
      ? getUniqueSchoolSubjects(historicalExercises[0].content.quiz.questions)
      : null;

  console.log({ realSchoolSubjects });

  const mock = {
    timeCards: [5, 10, 15, 20],
    editorialCards: [
      {
        title: "Impara dai tuoi errori",
        questionTime: 10,
        description: realSchoolSubjects,
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "wrong",
        },
        questionCount: 6,
        colorVariant: "orange",
        tag: {
          label: "Domande sbagliate",
          status: "danger",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Impara dai tuoi errori",
        questionTime: 20,
        description: realSchoolSubjects,
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "wrong",
        },
        questionCount: 12,
        colorVariant: "orange",
        tag: {
          label: "Domande sbagliate",
          status: "danger",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Impara dai tuoi errori",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Fisica e Matematica"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "wrong",
        },
        questionCount: 6,
        colorVariant: "yellow",
        tag: {
          label: "Domande sbagliate",
          status: "danger",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Impara dai tuoi errori",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Biologia"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "wrong",
        },
        questionCount: 6,
        colorVariant: "blu",
        tag: {
          label: "Domande sbagliate",
          status: "danger",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Impara dai tuoi errori",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Chimica"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "wrong",
        },
        questionCount: 6,
        colorVariant: "green",
        tag: {
          label: "Domande sbagliate",
          status: "danger",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Impara dai tuoi errori",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Competenze di lettura"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "wrong",
        },
        questionCount: 6,
        colorVariant: "violet",
        tag: {
          label: "Domande sbagliate",
          status: "danger",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Impara dai tuoi errori",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Ragionamento logico e problemi"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "wrong",
        },
        questionCount: 6,
        colorVariant: "gray",
        tag: {
          label: "Domande sbagliate",
          status: "danger",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Scopri nuove domande",
        questionTime: 10,
        description: realSchoolSubjects,
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "new",
        },
        questionCount: 6,
        colorVariant: "orange",
        tag: {
          label: "Mai viste",
          status: "success",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Scopri nuove domande",
        questionTime: 20,
        description: realSchoolSubjects,
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "new",
        },
        questionCount: 12,
        colorVariant: "orange",
        tag: {
          label: "Mai viste",
          status: "success",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Scopri nuove domande",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Fisica e Matematica"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "new",
        },
        questionCount: 6,
        colorVariant: "yellow",
        tag: {
          label: "Mai viste",
          status: "success",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Scopri nuove domande",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Biologia"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "new",
        },
        questionCount: 6,
        colorVariant: "blu",
        tag: {
          label: "Mai viste",
          status: "success",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Scopri nuove domande",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Chimica"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "new",
        },
        questionCount: 6,
        colorVariant: "green",
        tag: {
          label: "Mai viste",
          status: "success",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Scopri nuove domande",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Competenze di lettura"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "new",
        },
        questionCount: 6,
        colorVariant: "violet",
        tag: {
          label: "Mai viste",
          status: "success",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Scopri nuove domande",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Ragionamento logico e problemi"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "new",
        },
        questionCount: 6,
        colorVariant: "gray",
        tag: {
          label: "Mai viste",
          status: "success",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Supera le domande più difficili",
        questionTime: 10,
        description: realSchoolSubjects,
        prefix: {
          content: "Difficile",
          difficulty: "hard" as const,
        },
        questionCount: 6,
        colorVariant: "orange",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Supera le domande più difficili",
        questionTime: 20,
        description: realSchoolSubjects,
        prefix: {
          content: "Difficile",
          difficulty: "hard" as const,
        },
        questionCount: 12,
        colorVariant: "orange",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Supera le domande più difficili",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Fisica e Matematica"
        ),
        prefix: {
          content: "Difficile",
          difficulty: "hard" as const,
        },
        questionCount: 6,
        colorVariant: "yellow",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Supera le domande più difficili",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Biologia"
        ),
        prefix: {
          content: "Difficile",
          difficulty: "hard" as const,
        },
        questionCount: 6,
        colorVariant: "blu",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Supera le domande più difficili",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Chimica"
        ),
        prefix: {
          content: "Difficile",
          difficulty: "hard" as const,
        },
        questionCount: 6,
        colorVariant: "green",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Supera le domande più difficili",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Competenze di lettura"
        ),
        prefix: {
          content: "Difficile",
          difficulty: "hard" as const,
        },
        questionCount: 6,
        colorVariant: "violet",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Supera le domande più difficili",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Ragionamento logico e problemi"
        ),
        prefix: {
          content: "Difficile",
          difficulty: "hard" as const,
        },
        questionCount: 6,
        colorVariant: "gray",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Recupera le domande saltate",
        questionTime: 10,
        description: realSchoolSubjects,
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "skipped",
        },
        questionCount: 6,
        colorVariant: "orange",
        tag: {
          label: "Domande saltate",
          status: "warning",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Recupera le domande saltate",
        questionTime: 20,
        description: realSchoolSubjects,
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "skipped",
        },
        questionCount: 12,
        colorVariant: "orange",
        tag: {
          label: "Domande saltate",
          status: "warning",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Recupera le domande saltate",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Fisica e Matematica"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "skipped",
        },
        questionCount: 6,
        colorVariant: "yellow",
        tag: {
          label: "Domande saltate",
          status: "warning",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Recupera le domande saltate",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Biologia"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "skipped",
        },
        questionCount: 6,
        colorVariant: "blu",
        tag: {
          label: "Domande saltate",
          status: "warning",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Recupera le domande saltate",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Chimica"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "skipped",
        },
        questionCount: 6,
        colorVariant: "green",
        tag: {
          label: "Domande saltate",
          status: "warning",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Recupera le domande saltate",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Competenze di lettura"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "skipped",
        },
        questionCount: 6,
        colorVariant: "violet",
        tag: {
          label: "Domande saltate",
          status: "warning",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Recupera le domande saltate",
        questionTime: 10,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Ragionamento logico e problemi"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
          type: "skipped",
        },
        questionCount: 6,
        colorVariant: "gray",
        tag: {
          label: "Domande saltate",
          status: "warning",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Ripassa rapidamente Fisica e Matematica",
        questionTime: 5,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Fisica e Matematica"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
        },
        questionCount: 3,
        colorVariant: "yellow",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Ripassa rapidamente Biologia",
        questionTime: 5,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Biologia"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
        },
        questionCount: 3,
        colorVariant: "blu",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Ripassa rapidamente Chimica",
        questionTime: 5,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Chimica"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
        },
        questionCount: 3,
        colorVariant: "green",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Ripassa rapidamente Competenze di lettura",
        questionTime: 5,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Competenze di lettura"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
        },
        questionCount: 3,
        colorVariant: "violet",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
      {
        title: "Ripassa rapidamente Ragionamento logico",
        questionTime: 5,
        description: realSchoolSubjects.filter(
          (subject) => subject.title === "Ragionamento logico e problemi"
        ),
        prefix: {
          content: "Media",
          difficulty: "medium" as const,
        },
        questionCount: 3,
        colorVariant: "gray",
        tag: {
          label: "Tutte le domande",
          status: "neutral",
        },
        variant: "background",
        image: FirstEditorialCardImage,
        handleClick: () => {
          handleExerciseCard(this as unknown as EditorialCardProps);
        },
      },
    ],
    studyHubCards: [
      {
        tagLabel: historicalSimulationsScore
          ? `Ultimo punteggio | ${historicalSimulationsScore}`
          : null,
        title: "Simulazione Standard",
        description:
          "Mettiti alla prova con una Simulazione Standard, che ricrea una prova di esame realistica, per prepararti al meglio.",
        image: simulationImage,
        ctaLabel: "Esercitati adesso",
        handleClick: () => {
          navigate("/configuration", { state: simulationQuiz });
        },
      },
      {
        tagLabel:
          historicalExercisesSubjects && historicalExercisesSubjects.length > 0
            ? `Ultime materie | ${historicalExercisesSubjects.join(", ")}`
            : null,
        title: "Esercitazione componibile",
        description:
          "Personalizza ogni dettaglio dell'allenamento, adatta l'esercitazione ai tuoi obiettivi di studio e rendi più efficace la tua preparazione.",
        image: exerciseImage,
        ctaLabel: "Esercitati adesso",
        handleClick: () => {
          navigate("/configuration", { state: exeriseQuiz });
        },
      },
    ],
    mixedExerciseCards: [
      {
        prefix: {
          content: "Materia A | Materia B",
          difficulty: "easy" as const,
        },
        tag: {
          label: "Media",
          status: "danger",
        },
        description: ["Matematica", "Fisica"],
        image:
          "https://placehold.co/200x200/transparent/white/png?text=PLACEHOLDER",
        title: "Esercitazione mista",
        handleClick: () => {},
        questionTime: 30,
        questionCount: 10,
        colorVariant: "gray",
        backgroundFillerImage: "ShapeSquare",
      },
      {
        prefix: {
          content: "Materia A | Materia B",
          difficulty: "medium" as const,
        },
        tag: {
          label: "Media",
          status: "warning",
        },
        description: ["Chimica", "Fisica"],
        title: "Esercitazione mista",
        image:
          "https://placehold.co/200x200/transparent/white/png?text=PLACEHOLDER",
        handleClick: () => {},
        questionTime: 30,
        questionCount: 10,
        colorVariant: "brown",
        backgroundFillerImage: "ShapeX",
      },
      {
        prefix: {
          content: "Materia A | Materia B",
          difficulty: "hard" as const,
        },
        tag: {
          label: "Media",
          status: "success",
        },
        description: ["Chimica", "Matematica"],
        title: "Esercitazione mista",
        image:
          "https://placehold.co/200x200/transparent/white/png?text=PLACEHOLDER",
        handleClick: () => {},
        questionTime: 30,
        questionCount: 10,
        colorVariant: "orange",
        backgroundFillerImage: "ShapeCheck",
      },
      {
        prefix: {
          content: "Materia A | Materia B",
          difficulty: "easy" as const,
        },
        tag: {
          label: "Media",
          status: "danger",
        },
        description: ["Chimica", "Matematica"],
        title: "Esercitazione mista",
        image:
          "https://placehold.co/200x200/transparent/white/png?text=PLACEHOLDER",
        handleClick: () => {},
        questionTime: 30,
        questionCount: 10,
        colorVariant: "blu",
        backgroundFillerImage: "ShapeCircle",
      },
      {
        prefix: {
          content: "Materia A | Materia B",
          difficulty: "easy" as const,
        },
        tag: {
          label: "Media",
          status: "warning",
        },
        description: ["Chimica", "Matematica"],
        title: "Esercitazione mista",
        handleClick: () => {},
        questionTime: 30,
        questionCount: 10,
        colorVariant: "green",
        backgroundFillerImage: "ShapeTwoLines",
      },
      {
        prefix: {
          content: "Materia A | Materia B",
          difficulty: "easy" as const,
        },
        tag: {
          label: "Media",
          status: "danger",
        },
        title: "Esercitazione mista",
        handleClick: () => {},
        questionTime: 30,
        questionCount: 10,
        colorVariant: "violet",
        backgroundFillerImage: "ShapeStar",
      },
      {
        prefix: "Materia A | Materia B",
        title: "Esercitazione mista",
        handleClick: () => {},
        questionTime: 30,
        questionCount: 10,
        colorVariant: "red",
        backgroundFillerImage: "ShapeSquare",
      },
      {
        prefix: "Materia A | Materia B",
        title: "Esercitazione mista",
        handleClick: () => {},
        questionTime: 30,
        questionCount: 10,
        colorVariant: "yellow",
        backgroundFillerImage: "ShapeX",
      },
    ],
    verticalBarChartData: [
      {
        subject: "cultura generale",
        data: [
          {
            name: "ultima prova",
            value: 332000,
          },
          {
            name: "ultime 5 prove",
            value: 1200000,
          },
        ],
      },
      {
        subject: "logica",
        data: [
          {
            name: "ultima prova",
            value: 302000,
          },
          {
            name: "ultime 5 prove",
            value: 2200000,
          },
        ],
      },
      {
        subject: "biologia",
        data: [
          {
            name: "ultima prova",
            value: 382000,
          },
          {
            name: "ultime 5 prove",
            value: 820000,
          },
        ],
      },
      {
        subject: "matematica",
        data: [
          {
            name: "ultima prova",
            value: 32000,
          },
          {
            name: "ultime 5 prove",
            value: 20000,
          },
        ],
      },
      {
        subject: "fisica",
        data: [
          {
            name: "ultima prova",
            value: 432000,
          },
          {
            name: "ultime 5 prove",
            value: 205000,
          },
        ],
      },
      {
        subject: "chimica",
        data: [
          {
            name: "ultima prova",
            value: 33000,
          },
          {
            name: "ultime 5 prove",
            value: 200000,
          },
        ],
      },
    ],
    pieChart: [
      {
        subject: "cultura generale",
        data: [
          {
            name: "Corrette",
            value: 15,
          },
          {
            name: "Errate",
            value: 25,
          },
          {
            name: "Non date",
            value: 60,
          },
        ],
      },
      {
        subject: "logica",
        data: [
          {
            name: "Corrette",
            value: 11,
          },
          {
            name: "Errate",
            value: 25,
          },
          {
            name: "Non date",
            value: 65,
          },
        ],
      },
      {
        subject: "biologia",
        data: [
          {
            name: "Corrette",
            value: 15,
          },
          {
            name: "Errate",
            value: 45,
          },
          {
            name: "Non date",
            value: 40,
          },
        ],
      },
      {
        subject: "matematica",
        data: [
          {
            name: "Corrette",
            value: 5,
          },
          {
            name: "Errate",
            value: 25,
          },
          {
            name: "Non date",
            value: 70,
          },
        ],
      },
      {
        subject: "fisica",
        data: [
          {
            name: "Corrette",
            value: 65,
          },
          {
            name: "Errate",
            value: 25,
          },
          {
            name: "Non date",
            value: 10,
          },
        ],
      },
      {
        subject: "chimica",
        data: [
          {
            name: "Corrette",
            value: 5,
          },
          {
            name: "Errate",
            value: 35,
          },
          {
            name: "Non date",
            value: 60,
          },
        ],
      },
    ],
  };
  const { t } = useTranslation();
  const [, setSelectedTime] = useState<TimeCardProps["time"] | null>(null);
  const [, setTitle] = useState("");
  const isMobile = useIsMobile();
  const timeCards = mock.timeCards;
  const editorialCards = mock.editorialCards || [];
  const studyHubCards = mock.studyHubCards || [];
  const mixedExerciseCards = mock.mixedExerciseCards || [];
  const hasTimeCards = timeCards.length > 0;
  const hasExerciseHubCards = studyHubCards.length > 0;
  const hasMixedExerciseCards =
    mixedExerciseCards.length > 0 && !isProductionMode();

  const handleTimeCardClick = (time: TimeCardProps["time"]) => {
    setSelectedTime(time);
    setTitle(`${time} minuti`);
    handleTimeCick(time);
  };

  return (
    <BaseLayout
      headerComponent={<Sidebar />}
      flexDirection={"row"}
      isHeaderSticky={isMobile ? true : false}
    >
      {isQuizReady && (
        <Countdown exerciseType={"exercise"} onTimeEnd={() => onTimeEnd({})} />
      )}
      <S.StyledSectionWrapper style={{ paddingBlockEnd: "24px" }}>
        <Heading typo={"headingXS"} weight="semibold" textTertiary>
          {t(copy.heading.prefix)}
        </Heading>
        <Heading typo={"headingLG"} weight={"semibold"}>
          {t(copy.heading.title)}
        </Heading>
      </S.StyledSectionWrapper>
      {hasTimeCards ? (
        <S.StyledSectionWrapper
          style={{ paddingBlockStart: "0", paddingBlockEnd: "16px" }}
        >
          <S.StyledHeadingWrapper>
            <Heading
              content={copy.timeCards.prefix}
              typo="paragraphMD"
              style={{
                color: "#404853",
                letterSpacing: "unset",
              }}
            />
            <Heading
              content={copy.timeCards.title}
              typo="headingSM"
              weight="semibold"
            />
            <Heading
              content={copy.timeCards.paragraph}
              typo="paragraphMD"
              style={{
                color: "#404853",
              }}
            />
          </S.StyledHeadingWrapper>
          <S.StyledTimeCardWrapper>
            {timeCards.map((time, index) => (
              <TimeCard
                key={index}
                time={time as TimeCardProps["time"]}
                handleTimeCardClick={handleTimeCardClick}
                isUncolored
              />
            ))}
          </S.StyledTimeCardWrapper>
        </S.StyledSectionWrapper>
      ) : (
        <></>
      )}
      <S.StyledHeadingWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div>
            <Heading
              content={copy.timeCards.prefix}
              typo="paragraphMD"
              style={{
                color: "#404853",
                letterSpacing: "unset",
              }}
            />

            <Heading
              content={copy.editorialCards.title}
              typo="headingSM"
              weight="semibold"
            />
            <Heading
              content={copy.editorialCards.paragraph}
              typo="paragraphMD"
              style={{
                color: "#404853",
              }}
            />
          </div>
          {/* <div>
            <S.StyledButtonLabel onClick={() => navigate('all')} content={copy.editorialCards.cta} />
          </div> */}
        </div>
      </S.StyledHeadingWrapper>
      <S.StyledSliderWrapper
        style={{ paddingBlockStart: "16px", paddingBlockEnd: "56px" }}
      >
        <Swiper
          hasPaddingBottom
          grid={{ rows: 2, fill: "row" }}
          slidesPerView={1}
          pagination
          spaceBetween={16}
          breakpoints={{
            0: {
              slidesPerView: 1,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
            768: {
              slidesPerView: 2,
              grid: {
                rows: 2,
                fill: "row",
              },
            },
          }}
        >
          {editorialCards.map((card, index) => (
            <SwiperSlide key={index}>
              <ExerciseCard
                {...({
                  ...card,
                  handleClick: () =>
                    handleExerciseCard(card as EditorialCardProps),
                } as EditorialCardProps)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </S.StyledSliderWrapper>
      <Divider type={"horizontal"} />
      <S.StyledSectionWrapper
        style={{ paddingBlockStart: "16px", paddingBlockEnd: "56px" }}
      >
        <S.StyledHeadingWrapper>
          <Heading
            content={copy.studyHubCards.prefix}
            typo="paragraphMD"
            style={{
              color: "#404853",
              letterSpacing: "unset",
            }}
          />
          <Heading
            content={copy.studyHubCards.title}
            typo="headingSM"
            weight="semibold"
          />
        </S.StyledHeadingWrapper>
        <SpacerBase xs={2} />
        <S.StyledSliderWrapper>
          <S.StyledExercisesHubCards>
            {isLoading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: 32,
                  marginTop: 16,
                }}
              >
                {Array.from(Array(2).keys()).map((key) => (
                  <S.StyledCardLoading key={key} />
                ))}
              </div>
            ) : (
              <>
                {hasExerciseHubCards ? (
                  <Swiper
                    hasPaddingBottom
                    // slidesPerView={1}
                    spaceBetween={16}
                    pagination
                    breakpoints={{
                      0: {
                        slidesPerView: 1.15,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                    }}
                  >
                    {studyHubCards.map((card, index) => (
                      <SwiperSlide key={index}>
                        <ExerciseHubCard
                          {...({
                            ...card,
                            $noMargin: true,
                          } as ExerciseHubCardProps)}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : null}
              </>
            )}
          </S.StyledExercisesHubCards>
          {/* {hasExerciseHubCards ? (
            <Swiper {...(studyHubCardSliderProps as SwiperProps)}>
              {studyHubCards.map((card, index) => (
                <SwiperSlide
                  key={index}
                  style={{ marginBlockEnd: "1rem", width: "fit-content" }}
                >
                  <ExerciseHubCard {...(card as ExerciseHubCardProps)} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null} */}
        </S.StyledSliderWrapper>
      </S.StyledSectionWrapper>
      <Divider type={"horizontal"} />
      <S.StyledSectionWrapper style={{ paddingBlockStart: "32px" }}>
        <S.StyledHeadingWrapper>
          <Heading
            content={copy.verticalBarChart.prefix}
            typo="paragraphMD"
            style={{
              color: "#404853",
              letterSpacing: "unset",
            }}
          />
          <Heading
            content={copy.verticalBarChart.title}
            typo="headingSM"
            weight="semibold"
          />
        </S.StyledHeadingWrapper>
        {averageTimeSubjects || subjectStatistics ? (
          <S.StyledChartWrapper>
            {averageTimeSubjects && averageTimeSubjects.length > 0 ? (
              <VerticalBarChart
                data={averageTimeSubjects}
                copy={copy.verticalBarChart.copy}
                margin={{ top: 0, right: 48, bottom: 0, left: 48 }}
              />
            ) : null}
            {subjectStatistics && subjectStatistics.length > 0 ? (
              <PieChart data={subjectStatistics} copy={copy.pieChart.copy} />
            ) : null}
          </S.StyledChartWrapper>
        ) : null}
      </S.StyledSectionWrapper>
      <Divider type={"horizontal"} />
      {hasMixedExerciseCards ? (
        <S.StyledSectionWrapper>
          <S.StyledHeadingWrapper>
            <Heading
              content={copy.mixedExerciseCards.prefix}
              typo="label"
              textTertiary
              style={{
                letterSpacing: "2px",
                textTransform: "uppercase",
              }}
            />
            <Heading
              content={copy.mixedExerciseCards.title}
              typo="headingSM"
              weight="semibold"
            />
            <Heading
              content={copy.mixedExerciseCards.paragraph}
              typo="paragraphMD"
              style={{
                color: "#404853",
              }}
            />
          </S.StyledHeadingWrapper>
          <S.StyledMixedExerciseCardWrapper>
            <Row style={{ rowGap: isMobile ? 16 : 24 }}>
              {mixedExerciseCards.map((card, index) => (
                <Col xs={6} md={4} lg={3}>
                  <ExerciseCard
                    key={index}
                    variant={"vertical"}
                    {...(card as MixedExerciseCardProps)}
                  />
                </Col>
              ))}
            </Row>
          </S.StyledMixedExerciseCardWrapper>
        </S.StyledSectionWrapper>
      ) : null}
    </BaseLayout>
  );
};

export default ExerciseHubPage;
