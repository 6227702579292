import styled from 'styled-components';

export const PopoverContainer = styled.div<{ state: string }>`
  position: absolute;
  z-index: 1000;
  transition: opacity 0.1s ease-in-out;
  overflow: hidden;

  opacity: ${props => (props.state === 'entering' || props.state === 'exiting' ? 0 : 1)};
  display: ${props => (props.state === 'exited' ? 'none' : 'block')};
`;
