import styled from "styled-components";

const Page = styled.main`
  padding-top: ${({ theme }) => theme.rem(130)};
  position: relative;

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding-top: ${theme.rem(82)};
  `)}
`;

export default Page;
