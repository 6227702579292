import { ReactNode, FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyles, helpers } from '../theme';

interface ThemeProviderProps {
  children: ReactNode;
}

const CustomThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  return (
    <ThemeProvider theme={{ ...theme, ...helpers }}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
