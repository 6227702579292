import styled from "styled-components";
import { Paragraph } from "../Typography";

const BORDER_RADIUS_VALUE = "24px";

export const SegmentControlContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.rem(8)};
  border-radius: ${BORDER_RADIUS_VALUE};
`;

export const StlyedOption = styled.div<{
  isSelected: boolean;
  disabled: boolean;
}>`
  flex: 1 0 calc(50% - 8px);
  border: 1px solid
    ${({ theme }) => theme.colorScheme.white.surface.borderSecondary};
  text-align: center;
  padding: 8px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  background-color: ${({ isSelected }) =>
    isSelected ? "#3B3838" : "transparent"};
  color: ${({ isSelected, theme }) =>
    isSelected ? "#FFFFFF" : theme.colorScheme.white.surface.textBaseTertiary};
  border-radius: ${BORDER_RADIUS_VALUE};

  &:first-child {
    flex: 1 0 100%;
  }

  &:last-child {
    flex: 0 0 50%;

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}px)  {
      flex: 1 0 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px)  {
    flex: 1 0 100%;
  }

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`;

export const StlyedParagraph = styled(Paragraph)`
  ${({ theme }) => theme.typo("desktopMD")};
  font-weight: 600;
`;
