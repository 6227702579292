import { Paragraph } from "@/components/atoms/Typography";
import styled from "styled-components";

export const StyledTimer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colorScheme.white.surface.borderPrimary};
  border-radius: 24px;
  padding: ${({ theme }) => theme.rem(8, 16)};
  gap: ${({ theme }) => theme.rem(8)};
`;

export const StyledTimerText = styled(Paragraph)`
  ${({ theme }) => theme.typo("paragraphSM")};
  font-weight: 600;
`;
