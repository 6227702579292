export default {
  dark: {
    surface: {
      backgroundColor: "#FFFFFF",
      foreground: "#F8F5F2",
      textBasePrimary: "#1B1818",
      textBaseSecondary: "#3B3838",
      textBaseTertiary: "#5D5D5D",
      borderPrimary: "#3B3838",
      borderSecondary: "#B0B0B0",
      borderTertiary: "#E7E7E7",
    },
    button: {
      iconButton: {
        iconDefault: '#2D3035',
        iconDefaultBg: '#F0F4F9',
        borderDefault: '#B0B0B0',
        bgHover: '#FFE9D4',
        borderHover: 'transparent',
        bgActive: '#FE9973',
        borderActive: 'transparent',
        bgDisabled: '#E7E7E7',
        borderDisabled: 'transparent',
      },
      primary: {
        textDefault: "#2D3035",
        bgDefault: "#FFC091",
        textHover: "#2D3035",
        bgHover: "#FFE9D4",
        textPressed: "#2D3035",
        bgPressed: "#FE9973",
        textFocused: "#2D3035",
        bgFocused: "#FFE9D4",
        strokeFocus: "#2D3035",
        textDisabled: "#B0B0B0",
        bgDisabled: "#E7E7E7",
        iconDefault: "#2D3035",
        bgIconDefault: "transparent",
        iconBorderDefault: "transparent",
        iconBorderBg: "transparent",
        iconBorderHover: "transparent",
        iconHover: "#2D3035",
        iconHoverBg: 'transparent',

        iconPressed: "#2D3035",
        iconPressedBg: "transparent",
        iconFocusedBg: "transparent",
        border: "transparent",
      },
      secondary: {
        textDefault: "#2D3035",
        bgDefault: "#fff",
        textHover: "#2D3035",
        bgHover: "#FFF5ED",
        textPressed: "#2D3035",
        bgPressed: "#FFC091",
        textFocused: "#2D3035",
        bgFocused: "#FFF5ED",
        strokeFocus: "#2D3035",
        textDisabled: "#B0B0B0",
        bgDisabled: "transparent",
        iconDefault: "#2D3035",
        bgIconDefault: "#F0F4F9",
        iconBorderDefault: "#B0B0B0",
        iconBorderBg: "#F0F4F9",
        iconBorderHover: "transparent",
        iconHover: "#2D3035",
        iconHoverBg: '#FFE9D4',
        iconPressed: "#2D3035",
        iconPressedBg: "#FE9973",
        iconFocusedBg: "#FFE9D4",
        border: "#404853",
      },
      tertiary: {
        textDefault: "#1B1818",
        bgDefault: "transparent",
        textHover: "#1B1818",
        bgHover: "#FFE9D4",
        textPressed: "#1B1818",
        bgPressed: "#FFC091",
        textFocused: "#1B1818",
        bgFocused: "#FFE9D4",
        strokeFocus: "#2D3035",
        textDisabled: "#B0B0B0",
        bgDisabled: "transparent",
        iconDefault: "#2D3035",
        bgIconDefault: "transparent",
        iconBorderDefault: "transparent",
        iconBorderBg: "transparent",
        iconBorderHover: "transparent",
        iconHover: "#2D3035",
        iconHoverBg: 'transparent',
        iconPressed: "#2D3035",
        iconPressedBg: "transparent",
        iconFocusedBg: "#FFE9D4",
        border: "transparent",
      },
      quaternary: {
        textDefault: "#F0F4F9",
        bgDefault: "#2D3035",
        textHover: "#F0F4F9",
        bgHover: "#404853",
        textPressed: "#F0F4F9",
        bgPressed: "#171819",
        textFocused: "#F0F4F9",
        bgFocused: "#404853",
        strokeFocus: "#3B3838",
        textDisabled: "#B0B0B0",
        bgDisabled: "transparent",
        iconDefault: "#2D3035",
        bgIconDefault: "#F0F4F9",
        iconBorderDefault: "#B0B0B0",
        iconBorderBg: "#F0F4F9",
        iconBorderHover: "#B0B0B0",
        iconHover: "#2D3035",
        iconHoverBg: '#F0F4F9',
        iconPressed: "#2D3035",
        iconPressedBg: "#F0F4F9",
        iconFocusedBg: "#F0F4F9",
        border: "#B0B0B0",
      },
      primaryText: "#FFFFFF",
      primaryDefaultBackground: "#1B1818",
      primaryHoverBackground: "#FFB683",
      primaryPressedBackground: "#FF9F5C",
      iconBackground: "#FFB683",
      iconColor: "#1B1818",
      primaryStrokeColor: "#1B1818",
      primaryStrokeFocused: "#1B1818",
      interactiveLine: "#FF9F5C",
      secondaryText: "#3B3838",
      primaryHoverText: "#3B3838",
      disabledBackground: "#F2F2F2",
      disabledText: "#999999",
      secondaryTextHover: "#1B1818",
      tertiaryText: "#1B1818",
      tertiaryIconColor: "#1B1818",
    },
    chip: {
      success: {
        backgroundColor: "#3A614E",
        textColor: "#E0EBE3",
        iconColor: "#E0EBE3",
      },
    },
  },
  white: {
    surface: {
      default: "#FFFFFF",
      backgroundColor: "#3B3838",
      foreground: "#F0F4F9",
      textBasePrimary: "#2D3035",
      textBaseSecondary: "#404853",
      textBaseTertiary: "#5D5D5D",
      textTertiary: "#666D75",
      borderPrimary: "#92A8C4",
      borderSecondary: "#CFDBEA",
      borderTertiary: "#DCE5F0",
    },
    button: {
      iconButton: {
        iconDefault: '#2D3035',
        iconDefaultBg: '#F0F4F9',
        borderDefault: '#B0B0B0',
        bgHover: '#FFE9D4',
        borderHover: 'transparent',
        bgActive: '#FE9973',
        borderActive: 'transparent',
        bgDisabled: '#E7E7E7',
        borderDisabled: 'transparent',
      },
      primary: {
        textDefault: "#2D3035",
        bgDefault: "#FFC091",
        textHover: "#2D3035",
        bgHover: "#FFE9D4",
        textPressed: "#2D3035",
        bgPressed: "#FE9973",
        textFocused: "#2D3035",
        bgFocused: "#FFE9D4",
        strokeFocus: "#2D3035",
        textDisabled: "#B0B0B0",
        bgDisabled: "#E7E7E7",
        iconDefault: "#2D3035",
        bgIconDefault: "transparent",
        iconBorderDefault: "transparent",
        iconBorderBg: "transparent",
        iconBorderHover: "transparent",
        iconHover: "#2D3035",
        iconHoverBg: 'transparent',

        iconPressed: "#2D3035",
        iconPressedBg: "transparent",
        iconFocusedBg: "transparent",
        border: "transparent",
      },
      secondary: {
        textDefault: "#2D3035",
        bgDefault: "transparent",
        textHover: "#2D3035",
        bgHover: "#FFF5ED",
        textPressed: "#2D3035",
        bgPressed: "#FFC091",
        textFocused: "#2D3035",
        bgFocused: "#FFF5ED",
        strokeFocus: "#2D3035",
        textDisabled: "#B0B0B0",
        bgDisabled: "transparent",
        iconDefault: "#2D3035",
        bgIconDefault: "#F0F4F9",
        iconBorderDefault: "#B0B0B0",
        iconBorderBg: "#FFE9D4",
        iconBorderHover: "transparent",
        iconHover: "#2D3035",
        iconHoverBg: '#FFE9D4',
        iconPressed: "#2D3035",
        iconPressedBg: "#FE9973",
        iconFocusedBg: "#FFE9D4",
        border: "#CFDBEA",
      },
      tertiary: {
        textDefault: "#1B1818",
        bgDefault: "transparent",
        textHover: "#1B1818",
        bgHover: "#FFE9D4",
        textPressed: "#1B1818",
        bgPressed: "#FFC091",
        textFocused: "#1B1818",
        bgFocused: "#FFE9D4",
        strokeFocus: "#2D3035",
        textDisabled: "#B0B0B0",
        bgDisabled: "transparent",
        iconDefault: "#2D3035",
        bgIconDefault: "transparent",
        iconBorderDefault: "transparent",
        iconBorderBg: "transparent",
        iconBorderHover: "transparent",
        iconHover: "#2D3035",
        iconHoverBg: 'transparent',
        iconPressed: "#2D3035",
        iconPressedBg: "transparent",
        iconFocusedBg: "#FFE9D4",
        border: "transparent",
      },
      quaternary: {
        textDefault: "#F0F4F9",
        bgDefault: "#2D3035",
        textHover: "#F0F4F9",
        bgHover: "#404853",
        textPressed: "#F0F4F9",
        bgPressed: "#171819",
        textFocused: "#F0F4F9",
        bgFocused: "#404853",
        strokeFocus: "#3B3838",
        textDisabled: "#B0B0B0",
        bgDisabled: "transparent",
        iconDefault: "#2D3035",
        bgIconDefault: "#F0F4F9",
        iconBorderDefault: "#B0B0B0",
        iconBorderBg: "#F0F4F9",
        iconBorderHover: "#B0B0B0",
        iconHover: "#2D3035",
        iconHoverBg: '#F0F4F9',
        iconPressed: "#2D3035",
        iconPressedBg: "#F0F4F9",
        iconFocusedBg: "#F0F4F9",
        border: "transparent",
      },
      primaryText: "#FFFFFF",
      primaryDefaultBackground: "#1B1818",
      primaryHoverBackground: "#FFB683",
      primaryPressedBackground: "#FF9F5C",
      iconBackground: "#FFB683",
      iconColor: "#1B1818",
      primaryStrokeColor: "#1B1818",
      primaryStrokeFocused: "#1B1818",
      interactiveLine: "#FF9F5C",
      secondaryText: "#3B3838",
      primaryHoverText: "#3B3838",
      disabledBackground: "#F2F2F2",
      disabledText: "#999999",
      secondaryTextHover: "#1B1818",
      tertiaryText: "#1B1818",
      tertiaryIconColor: "#1B1818",
    },
    chip: {
      success: {
        backgroundColor: "#3A614E",
        textColor: "#E0EBE3",
        iconColor: "#E0EBE3",
      },
    },
    navigation: {
      backgroundTop: "#F0F4F9",
      textPrimary: "#FFF",
      textSecondary: "#E7E7E7",
      border: "#DCE5F0",
      icon: "#FFF",
      backgroundSelected: "#FFB683",
    },
    quiz: {
      radioButtonBgReplyDefault: "#2D3035", //D
      radioButtonTextDefault: "#4F4F4F", //D
      radioButtonTextReplyDefault: "#F6F6F6", //D
      radioButtonEnabled: "#1B1818", //D
      scrollbarForeground: "#F0F4F9", //D
      scrollbarProgressNumberBackgroundSelected: "#404853", //D
      scrollbarProgressNumberBackgroundDone: "#FFAB72", //D
      scrollbarProgressNumberTextDone: "#F0F4F9", //D
      scrollbarProgressNumberTextSelected: "#1B1818", //D
      scrollbarProgressNumberBorderSecondary: "#3B3838",
      scrollbarProgressNumberBorderPrimary: "#3B3838", //D
      scrollbarCardBackgroundPrimary: "#3B3838",
      scrollbarBackgroundProgress: "#FFF", //D
      scrollbarBorderSecondary: "#B0B0B0",
      scrollbarCardBorderPrimary: "#92A8C4", //D
      scrollbarCardBackgroundActive: "#F6F6F6", //D
      scrollbarCardBorderSecondary: "#CFDBEA", //D
      scrollbarCardTextPrimary: "#3B3838", //D
      scrollbarCardBackgroundDefault: '#fff' //D
    },
  },
};
