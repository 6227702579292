import { useState, useEffect } from 'react';

const useIsMobile = (): boolean => {
  const query = '(max-width: 768px)';
  const [isMobile, setIsMobile] = useState<boolean>(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);

    // Funzione per aggiornare lo stato in base all'evento
    const handleResize = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches);
    };

    // Aggiunta del listener
    mediaQueryList.addEventListener('change', handleResize);

    // Funzione di pulizia per rimuovere il listener
    return () => mediaQueryList.removeEventListener('change', handleResize);
  }, []);

  return isMobile;
};

export default useIsMobile;
