import styled from "styled-components";
import Icon from "../Icon";
import { Heading, Paragraph } from "../Typography";

interface StyledIconInterface {
  bg: string;
}

export const CircleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
`;

export const Svg = styled.svg`
  transform: rotate(
    -240deg
  );
`;

export const Circle = styled.circle`
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
`;

export const ProgressCircle = styled(Circle)`
  stroke-width: 20;
`;

export const ProgressText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #333;
`;

export const StyledIcon = styled(Icon)<StyledIconInterface>`
  margin-bottom: 8px;
  font-size: 20px;
  background-color: ${({ bg }) => bg};
  border-radius: 50%;
  padding: 8px;
`;

export const StyledHeading = styled(Heading)`
  color: #404040;
`;

export const StyledParagraph = styled(Paragraph)`
  color: #404040;
`;
