import * as S from "./styles";

import TestModalImage from "@/assets/images/landing-page/test-modal.png";
import ButtonV2 from "@/components/atoms/ButtonV2";

interface LaunchCardModalContentProps {
  onClose: () => void;
  onStart: () => void;
  subject: string;
  isLoading: boolean;
}

const LaunchCardModalContent = ({
  onClose,
  onStart,
  subject,
  isLoading
}: LaunchCardModalContentProps) => {
  return (
    <S.StyledModalContainer>
      <S.StyledModalContent>
        <S.StyledImageContainer>
          <img src={TestModalImage} alt="test-modal-image" />
        </S.StyledImageContainer>
        <S.StyledContentInfo>
          <S.StyledContentDescription>
            <S.StyledModalParagraph typo="paragraphMD" weight="semibold">
              Non abbatterti, sbagliare succede anche ai migliori
            </S.StyledModalParagraph>
            <S.StyledModalHeading
              typo="headingMD"
              weight="semibold"
            >
               {`Lancia un'esercitazione da 10 minuti e migliora in ${subject}`}
            </S.StyledModalHeading>
          </S.StyledContentDescription>
          <S.StyledContentCTA>
            <ButtonV2 variant="secondary" fullWidth handleClick={onClose} isLoading={isLoading}>
              Indietro
            </ButtonV2>
            <ButtonV2 fullWidth handleClick={onStart} isLoading={isLoading}>
              Avvia esercizio
            </ButtonV2>
          </S.StyledContentCTA>
        </S.StyledContentInfo>
      </S.StyledModalContent>
    </S.StyledModalContainer>
  );
};

export default LaunchCardModalContent;
