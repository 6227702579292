import styled, { css } from "styled-components";
import { primaryFont, secondaryFont } from "@theme/fonts";

interface BaseProps {
  uppercase?: boolean;
  colorScheme?: string;
  typo?: string;
  as?: React.ElementType;
  font?: "primary" | "secondary";
}

const helpers = css<BaseProps>`
  font-weight: ${({ theme }) =>
    theme.styledVariant("weight", {
      light: 300,
      medium: 500,
      semibold: 600,
      bold: 700,
      extraBold: 800,
      default: 400,
    })};
  text-align: ${({ theme }) =>
    theme.styledVariant("textAlign", {
      default: "inherit",
      center: "center",
      right: "right",
    })};
  color: ${({ theme, colorScheme }) => theme.styledVariant`
    textPrimary: ${theme.colorScheme[`${colorScheme}`].surface.textPrimary};
    textSecondary: ${theme.colorScheme[`${colorScheme}`].surface.textSecondary};
    textTertiary: ${theme.colorScheme.white.surface.textTertiary};
    default: inherit;
  `};
`;

const base = css<BaseProps>`
  ${helpers}
  ${({ uppercase }) => uppercase && "text-transform: uppercase;"};
  ${({ font }) =>
    font && `${font === "primary" ? primaryFont.style : secondaryFont.style}`};
`;

export const Heading = styled.div<BaseProps>`
  ${base};
  ${({ theme, as }) => theme.typo(as || "div")};
  ${({ theme, typo }) => theme.typo(typo || "displaySM")};

  span {
    font-family: inherit;
    font-weight: inherit;
  }
`;

export const HeadingUnderlined = styled.span``;

export const Paragraph = styled.p<BaseProps>`
  ${base};
  ${({ theme, typo }) => theme.typo(typo || "paragraphMD")};

  span {
    font-family: inherit;
    font-weight: inherit;
  }

  a {
    color: ${({ theme, colorScheme }) =>
      theme.colorScheme[`${colorScheme}`].surface.textPrimary};
    background-image: linear-gradient(0deg, currentColor, currentColor);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: background-size 0.3s ease-in-out;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased;

    &:hover,
    &:focus {
      background-size: 0% 1px;
      outline-width: 0;
    }
  }

  b,
  strong {
    font-weight: 600;
    color: ${({ theme, colorScheme }) =>
      theme.colorScheme[`${colorScheme}`].surface.textPrimary};
  }

  em {
    font-style: italic;
  }

  ol,
  ul {
    margin-block-start: ${({ theme }) => theme.rem(8)};
    margin-block-end: ${({ theme }) => theme.rem(8)};
    padding-inline-start: ${({ theme }) => theme.rem(16)};
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  > p:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.rem(8)};
  }
`;

export const Label = styled.div<BaseProps>`
  ${base};
  ${({ theme, typo }) => theme.typo(typo || "label")};
`;

export const ButtonLabel = styled.span<BaseProps>`
  ${base};
  font-weight: 600;
  ${({ theme, typo }) => theme.typo(typo || "buttonMD")};
  letter-spacing: 0.01em;
  pointer-events: none;
  z-index: 1;
`;
