import { useState, useEffect, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as S from "./styles";
import BaseLayout from "@/layouts/BaseLayout";
import MinimalHeader from "@/components/molecules/MinimalHeader";
import CircularProgressBar from "@/components/atoms/CircularProgressBar";
import PieChartWithPaddingAngle from "@/components/atoms/PieChartWithPaddingAngle";
import Icon from "@/components/atoms/Icon";
import Popover from "@/components/atoms/Popover";
import Divider from "@/components/atoms/Divider";
import MaterialsTable from "@/components/organisms/MaterialsTable";
import HorizontalCard from "@/components/atoms/HorizontalCard";
import { Swiper, SwiperSlide } from "@/components/molecules/Swiper";
import MaterialsAccordionList from "@/components/organisms/MaterialsAccordionList";
import useIsMobile from "@/hooks/useIsMobile";
import useQuizzes from "@/hooks/useQuizzes";
import InfoMessage from "@/components/atoms/InfoMessage";
import { getQuizResult } from "@/api/services/quizzes";
import { QuizInterface } from "../QuizPage/interface";
import { Heading, Paragraph } from "@/components/atoms/Typography";

interface lastSimuationResult {
  stats: {
    averageAnswerTimeTotal: number;
  };
}

const ResultPage = () => {
  const { quizzes, historicalSimulations } = useQuizzes();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const divRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [lastSimulationResult, setLastSimulationResult] =
    useState<lastSimuationResult>();

  const { state } = location;
  const { quizResult } = state || {};

  const millisecondsToMinutesAndSeconds = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")} min`;
  };

  const millisecondsToSeconds = (milliseconds: number) => {
    return Math.round(milliseconds / 1000);
  };

  if (!quizResult) {
    return <Navigate to="/" replace />;
  }

  const { stats, content } = quizResult || {};
  const {
    correctAnswers,
    nullAnswers,
    wrongAnswers,
    totalScore,
    userScore,
    averageAnswerTimeTotal,
    detail,
    nullAnswerScore,
    wrongAnswerScore,
    rightAnswerScore,
  } = stats || {};


  const answerScore = {
    correct: rightAnswerScore || 0,
    wrong: wrongAnswerScore || 0,
    skipped: nullAnswerScore || 0,
  };

  const userPercentage =
    userScore && totalScore && parseFloat(userScore) > 0
      ? parseFloat(((userScore / totalScore) * 100).toFixed(2))
      : 0;

  const isSimulation = content.quiz.type === "simulation";
  const questionsNumber = content.quiz.questionsNumber || 0;

  const quizType = isSimulation ? "simulation" : "exercise";

  const renderedQuizType = isSimulation ? "Simulazione" : "Esercitazione";

  const errorNumber = wrongAnswers || 0;
  const correctNumber = correctAnswers || 0;
  const skippedNumber = nullAnswers || 0;

  const navigateHandler = (to: string) => {
    navigate(to, { state: { quizResult } });
  };

  const goToConfigPage = (quiz: QuizInterface) => {
    navigate("/configuration", { state: quiz });
  };

  const createQuizHandler = () => {
    let quiz;
    if (quizzes && quizzes.length > 0) {
      if (isSimulation) {
        quiz = quizzes.find(
          (quiz) => quiz.attributes.type === "simulation"
        ) as QuizInterface;
      } else {
        quiz = quizzes.find(
          (quiz) => quiz.attributes.type === "exercise"
        ) as QuizInterface;
      }
      goToConfigPage(quiz);
    } else {
      navigateHandler("/");
    }
  };

  const pieChartData = [
    {
      name: "Corrette",
      value: correctNumber,
    },
    {
      name: "Errate",
      value: errorNumber,
    },
    {
      name: "Non date",
      value: skippedNumber,
    },
  ];

  const closePopoverHandler = () => {
    setIsPopoverOpen(false);
  };

  const togglePopoverHandler = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const calculateScore = (
    correctAnswers: number,
    wrongAnswers: number,
    skippedAnswers: number
  ): number => {
    const pointsPerCorrectAnswer = answerScore.correct;
    const pointsPerWrongAnswer = answerScore.wrong;
    const pointsPerSkippedAnswer = answerScore.skipped;

    const score =
      correctAnswers * pointsPerCorrectAnswer +
      wrongAnswers * pointsPerWrongAnswer +
      skippedAnswers * pointsPerSkippedAnswer;

      let normalizedScore = score !== 0 ? Number(score.toFixed(2)) : 0;

      return normalizedScore;
    };
  const materials = detail?.schoolSubjects.map(
    (schoolSubject: {
      id: number;
      label: string;
      correctAnswers: number;
      wrongAnswers: number;
      nullAnswers: number;
      totalAnswers: number;
      averageResponseTime: number;
      totalQuestion: number;
    }) => ({
      id: schoolSubject.id,
      schoolSubject: schoolSubject.label,
      correctAnswersNumber: schoolSubject.correctAnswers,
      wrongAnswersNumber: schoolSubject.wrongAnswers,
      skippedAnswersNumber: schoolSubject.nullAnswers,
      totalAnswersNumber: schoolSubject.totalAnswers,
      totalQuestion: schoolSubject.totalQuestion,
      score: calculateScore(
        schoolSubject.correctAnswers,
        schoolSubject.wrongAnswers,
        schoolSubject.nullAnswers
      ),
      averageTime: millisecondsToMinutesAndSeconds(
        schoolSubject.averageResponseTime
      ),
    })
  );

  const renderedHeaderTitle = isSimulation
    ? "Risultati simulazione"
    : "Risultati esercitazione";

  const currentSimulationIndex =
    historicalSimulations && historicalSimulations.length > 0
      ? historicalSimulations.findIndex(
          (quiz: QuizInterface) => quiz.id === content.quiz.id
        )
      : -1;

  const lastSimulation =
    currentSimulationIndex >= 0 &&
    historicalSimulations[currentSimulationIndex + 1]
      ? historicalSimulations[currentSimulationIndex + 1]
      : null;
  const lastUserScore = lastSimulation ? lastSimulation.userScore : null;
  const lastTotalScore = lastSimulation ? lastSimulation.totalScore : null;

  const lastUserPercentage =
    lastUserScore && lastTotalScore && parseFloat(lastUserScore) > 0
      ? parseFloat(((lastUserScore / lastTotalScore) * 100).toFixed(2))
      : 0;

  const userPercentageDifference = userPercentage - lastUserPercentage;

  const finalScoreMessage =
    isSimulation && lastUserScore
      ? {
          type: userPercentageDifference > 0 ? "positive" : "negative",
          info:
            userPercentageDifference > 0
              ? "Sei migliorato rispetto all'ultima prova"
              : "Sei peggiorato rispetto all'ultima prova",
        }
      : null;

  const averageTotalTimeDifference =
    lastSimulationResult &&
    lastSimulationResult?.stats?.averageAnswerTimeTotal &&
    averageAnswerTimeTotal
      ? averageAnswerTimeTotal -
        lastSimulationResult?.stats?.averageAnswerTimeTotal
      : 0;

  const geSimulationResult = async (id: number) => {
    try {
      const response = await getQuizResult(id);
      setLastSimulationResult(response.data);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (lastSimulation) {
      geSimulationResult(lastSimulation.id);
    }
  }, [historicalSimulations]);

  const averageTimeMessage =
    isSimulation && averageTotalTimeDifference
      ? {
          type:
            millisecondsToSeconds(averageTotalTimeDifference) <= 0
              ? "positive"
              : "negative",
          info: `${millisecondsToSeconds(
            averageTotalTimeDifference
          )}s rispetto all'ultima prova`,
        }
      : null;

  const progressIconName = (): string => {
    let name = '';
    if (userPercentage > 66) {
      name = "ResultGood";
    } else if (userPercentage > 33 && userPercentage <= 66) {
      name = "List";
    } else {
      name = "ResultBad";
    }
    return name;
  };

  const progressIconBg = (): string => {
    let bg = '';
    if (userPercentage > 66) {
      bg = "#B9EAD6";
    } else if (userPercentage > 33 && userPercentage <= 66) {
      bg = "#FDDFB6";
    } else {
      bg = "#FFA4A4";
    }
    return bg;
  };
  

  return (
    <BaseLayout headerComponent={<MinimalHeader title={renderedHeaderTitle} />} isHeaderSticky>
      <Popover
        onClose={closePopoverHandler}
        open={isPopoverOpen}
        anchorEl={divRef.current}
      >
        <S.StyledPopoverContainer>
          <S.StyledPopoverItem>
            <S.StyledPopoverItemContent>
              <S.StyledPopoverIconContainer type="correct">
                <Icon name="Seen" />
              </S.StyledPopoverIconContainer>
              <Paragraph typo="paragraphMD">Risposta corretta</Paragraph>
            </S.StyledPopoverItemContent>
            <Paragraph typo="paragraphMD" weight="semibold">
              {`${answerScore.correct} pt`}
            </Paragraph>
          </S.StyledPopoverItem>
          <Divider type="horizontal" />
          <S.StyledPopoverItem>
            <S.StyledPopoverItemContent>
              <S.StyledPopoverIconContainer type="wrong">
                <Icon name="XMark" />
              </S.StyledPopoverIconContainer>
              <Paragraph typo="paragraphMD">Risposta sbagliata</Paragraph>
            </S.StyledPopoverItemContent>
            <Paragraph typo="paragraphMD" weight="semibold">
              {`${answerScore.wrong} pt`}
            </Paragraph>
          </S.StyledPopoverItem>
          <Divider type="horizontal" />
          <S.StyledPopoverItem>
            <S.StyledPopoverItemContent>
              <S.StyledPopoverIconContainer type="skipped">
                <Icon name="SkippedAnswer" />
              </S.StyledPopoverIconContainer>
              <Paragraph typo="paragraphMD">Risposta non data</Paragraph>
            </S.StyledPopoverItemContent>
            <Paragraph typo="paragraphMD" weight="semibold">
              {`${answerScore.skipped} pt`}
            </Paragraph>
          </S.StyledPopoverItem>
        </S.StyledPopoverContainer>
      </Popover>
      <S.StyledContainer>
        <S.StyledMain>
          <S.StyledPageTitle typo="dktopSM" weight="semibold">
            {`I risultati della tua ${renderedQuizType.toLowerCase()}`}
          </S.StyledPageTitle>
          <S.StyledScoreCards>
            <Swiper
              hasPaddingBottom
              spaceBetween={16}
              slidesPerView={1}
              pagination
              breakpoints={{
                0: {
                  slidesPerView: 1.25,
                },
                768: {
                  slidesPerView: 2,
                },
                1100: {
                  slidesPerView: 3,
                },
              }}
            >
              <SwiperSlide>
                {!isSimulation ? (
                  <S.StyledSingleCard>
                    <Heading typo="headingSM" weight="semibold">
                      Numero di domande
                    </Heading>
                    <S.StyledTimeBoxContainer>
                      <S.StyledTimeBoxHeading
                        typo="headingXXL"
                        weight="semibold"
                      >
                        {questionsNumber}
                      </S.StyledTimeBoxHeading>
                    </S.StyledTimeBoxContainer>
                    <div></div>
                  </S.StyledSingleCard>
                ) : (
                  <S.StyledSingleCard>
                    <Heading typo="headingSM" weight="semibold">
                      Punteggio finale
                    </Heading>
                    <CircularProgressBar label={userScore} value={userPercentage} icon={progressIconName()} bg={progressIconBg()} />
                    {finalScoreMessage &&
                    finalScoreMessage.info &&
                    finalScoreMessage.type ? (
                      <InfoMessage
                        info={finalScoreMessage.info}
                        type={finalScoreMessage.type as "positive" | "negative"}
                        hasBackground
                        hasIcon
                      />
                    ) : (
                      <div></div>
                    )}
                  </S.StyledSingleCard>
                )}
              </SwiperSlide>
              <SwiperSlide>
                <S.StyledSingleCard>
                  <Heading typo="headingSM" weight="semibold">
                    Resoconto per risposta
                  </Heading>
                  <div style={{ width: 200, height: 200 }}>
                    <PieChartWithPaddingAngle data={pieChartData} />
                  </div>
                  <div>
                    <S.StyledResultCardInfo>
                      <S.StyledResultReportInfoItem>
                        <S.StyledSuccessBox />
                        <Paragraph typo="paragraphXS">{`${correctNumber} ${t(
                          "correct_questions"
                        )}`}</Paragraph>
                      </S.StyledResultReportInfoItem>
                      <S.StyledResultReportInfoItem>
                        <S.StyledWarningBox />
                        <Paragraph typo="paragraphXS">{`${skippedNumber} ${t(
                          "skipped_questions"
                        )}`}</Paragraph>
                      </S.StyledResultReportInfoItem>
                      <S.StyledResultReportInfoItem>
                        <S.StyledErrorBox />
                        <Paragraph typo="paragraphXS">{`${errorNumber} ${t(
                          "wrong_questions"
                        )}`}</Paragraph>
                      </S.StyledResultReportInfoItem>
                    </S.StyledResultCardInfo>
                  </div>
                </S.StyledSingleCard>
              </SwiperSlide>
              <SwiperSlide>
                <S.StyledSingleCard>
                  <Heading typo="headingSM" weight="semibold">
                    Tempo medio di risposta
                  </Heading>
                  <S.StyledTimeBoxContainer>
                    <S.StyledTimeBoxHeading typo="headingXXL" weight="semibold">
                      {millisecondsToMinutesAndSeconds(averageAnswerTimeTotal)}
                    </S.StyledTimeBoxHeading>
                  </S.StyledTimeBoxContainer>

                  {isSimulation ? (
                    averageTimeMessage &&
                    averageTimeMessage?.type &&
                    averageTimeMessage?.info ? (
                      <InfoMessage
                        info={averageTimeMessage.info}
                        type={
                          averageTimeMessage.type as "positive" | "negative"
                        }
                        hasBackground
                        hasIcon
                      />
                    ) : (
                      <div></div>
                    )
                  ) : (
                    <div></div>
                  )}
                </S.StyledSingleCard>
              </SwiperSlide>
            </Swiper>
          </S.StyledScoreCards>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Heading typo="headingSM" weight="semibold">
              Nel dettaglio
            </Heading>
            {isSimulation && (
              <div ref={divRef} onClick={togglePopoverHandler}>
                <S.StyledIcon name="Info" />
              </div>
            )}
          </div>
          {isMobile ? (
            <MaterialsAccordionList
              materials={materials}
              isSimulation={isSimulation}
            />
          ) : (
            <S.StyledMaterialsTableContainer>
              <MaterialsTable
                materials={materials}
                isSimulation={isSimulation}
              />
            </S.StyledMaterialsTableContainer>
          )}

          <div>
            <Heading typo="headingSM" weight="semibold">
              Rivedi o riprova la Simulazione
            </Heading>
            <S.StyledHorizontalCardsContainer>
              <HorizontalCard
                icon="Correction"
                iconBg="#FDDDAB"
                label="Rivedi tutte le tue risposte"
                title="Correzione esercizio"
                onClick={() => navigateHandler("/quiz")}
              />
              <HorizontalCard
                icon="Reset"
                iconBg="#CFDBEA"
                label={
                  quizType === "simulation"
                    ? "Simulazione Classica"
                    : "Esercitazione Componibile"
                }
                title={
                  quizType === "simulation"
                    ? "Prova un'altra simulazione"
                    : "Crea la tua esercitazione "
                }
                onClick={createQuizHandler}
              />
              <HorizontalCard
                icon="Home"
                iconBg="#FFE9D4"
                label="Esci"
                title="Torna alla Home"
                onClick={() => navigateHandler("/")}
              />
            </S.StyledHorizontalCardsContainer>
          </div>
        </S.StyledMain>
        <S.StyledMain />
      </S.StyledContainer>
    </BaseLayout>
  );
};

export default ResultPage;
