import { useState, useEffect, ReactNode, FC } from "react";
import useEventListener from "@hooks/useEventListener";
import { theme } from "@theme/index";

interface VisibleProps {
  children: ReactNode;
  breakpoints?: string[];
}

const getScreen = () => {
  const breakpoints = Object.values(theme.breakpoints);
  const viewport = window.innerWidth;

  if (!viewport) {
    return undefined;
  }

  let screenClassIndex = breakpoints.findIndex(
    (breakpoint) => viewport < breakpoint
  );

  if (screenClassIndex === -1) {
    screenClassIndex = breakpoints.length - 1;
  }

  const screenClass = Object.keys(theme.breakpoints)[screenClassIndex];

  console.log(screenClass);

  return screenClass;
};

const Visible: FC<VisibleProps> = ({ children, breakpoints = [] }) => {
  const [currentScreen, setCurrentScreen] = useState<string | null | undefined>(
    null
  );

  const setScreen = () => {
    const newScreen = getScreen();
    if (currentScreen !== newScreen) {
      setCurrentScreen(newScreen);
    }
  };

  useEffect(() => {
    setScreen();
  }, []);

  useEventListener("orientationchange", setScreen);
  useEventListener("resize", setScreen);

  const isVisible = () => {
    if (Array.isArray(breakpoints) && currentScreen) {
      return breakpoints.includes(currentScreen);
    } else if (typeof breakpoints === "string") {
      return breakpoints === currentScreen;
    }

    return false;
  };

  return isVisible() ? <>{children}</> : null;
};

export default Visible;
