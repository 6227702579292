import LaunchCard from "@/components/atoms/LaunchCard";
import { LaunchCardListProps } from "./types";

const LaunchCardList = ({
  items,
  isFullVersion = true,
  ...props
}: LaunchCardListProps) => {
  if (!items || !items.length) {
    return;
  }

  return (
    <div {...props}>
      {items.map((item) => (
        <div>
          <LaunchCard
            subject={item.subject}
            questionsNumber={item.questionsNumber}
            correctAnswers={item.correctAnswers}
            time={item.time}
            isFullVersion={isFullVersion}
            onClick={item.onClick}
          />
        </div>
      ))}
    </div>
  );
};

export default LaunchCardList;
