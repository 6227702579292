import React from "react";
import Table from "../../atoms/Table";
import { TableColumn } from "@/components/atoms/Table/types";

export interface Material {
  schoolSubject: string;
  correctAnswersNumber: number;
  wrongAnswersNumber: number;
  skippedAnswersNumber: number;
  score: number;
  averageTime: string;
  totalQuestion: number;
}

interface MaterialsTableProps {
  materials: Material[];
  isSimulation: boolean;
}

const MaterialTables: React.FC<MaterialsTableProps> = ({ materials, isSimulation }) => {
  const columns: TableColumn[] = [
    { title: "Materia", dataIndex: "schoolSubject", align: "left", bold: true },
    { title: "Risposte corrette", dataIndex: "correctAnswersNumber" },
    { title: "Risposte errate", dataIndex: "wrongAnswersNumber" },
    { title: "Non date", dataIndex: "skippedAnswersNumber" },
  ];

  if (isSimulation) {
    columns.push({ title: "Punteggio", dataIndex: "score" });
  } else {
    columns.push({ title: "Numero di domande", dataIndex: "totalQuestion" });
  }

  columns.push({ title: "Tempo medio", dataIndex: "averageTime" });

  return <Table columns={columns} data={materials} />;
};

export default MaterialTables;
