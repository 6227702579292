import IconButton from "@/components/atoms/ButtonIcon";
import ButtonV2 from "@/components/atoms/ButtonV2";
import Sidebar from "@/components/organisms/Sidebar";
import BaseLayout from "@/layouts/BaseLayout";

const ComponentsPage = () => {
  return (
    <BaseLayout headerComponent={<Sidebar />} flexDirection="row">
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <h1>Components Page</h1>
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <p>Buttons</p>
          <section>
            <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
              <div style={{ display: "flex", gap: 30 }}>
                <ButtonV2 variant="primary" iconEnd="ArrowRight">
                  Primary Button
                </ButtonV2>
                <ButtonV2 variant="secondary" iconEnd="ArrowRight">
                  Secondary Button
                </ButtonV2>
                <ButtonV2 variant="tertiary" iconEnd="ArrowRight">
                  Tertiary Button
                </ButtonV2>
                <ButtonV2 variant="quaternary" iconEnd="ArrowRight">
                quaternary Button
                </ButtonV2>
              </div>
              <div style={{ display: "flex", gap: 30 }}>
                <ButtonV2 variant="primary" iconEnd="ArrowRight" disabled>
                  Disabled Primary Button
                </ButtonV2>
                <ButtonV2 variant="secondary" iconEnd="ArrowRight" disabled>
                  Disabled Secondary Button
                </ButtonV2>
                <ButtonV2 variant="tertiary" iconEnd="ArrowRight" disabled>
                  Disabled Tertiary Button
                </ButtonV2>
                <ButtonV2 variant="quaternary" iconEnd="ArrowRight" disabled>
                  Disabled quaternary Button
                </ButtonV2>
              </div>
              <div>
                <IconButton name="ArrowRight" />
              </div>
            </div>
          </section>
        </div>
      </div>
    </BaseLayout>
  );
};

export default ComponentsPage;
