import styled, { keyframes } from "styled-components";

const shine = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: #f6f7f8;
  border-radius: 12px;
  background-image: linear-gradient(
    to right,
    #eeeeee 8%,
    #dddddd 18%,
    #eeeeee 33%
  );
  background-size: 800px 104px;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.8),
      transparent
    );
    animation: ${shine} 2s infinite;
  }
`;
